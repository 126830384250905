import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import RegisterApi from "../../redux/services/registrationApis";
import { toast } from "react-toastify";
export const login = createAsyncThunk("auth/login", async (props, thunkAPI) => {
  try {
    const data = await RegisterApi.login(props);
    return { user: data };
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
export const register = createAsyncThunk(
  "register/register",
  async (props, thunkAPI) => {
    try {
      const response = await RegisterApi.register(props);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const registerStepOne = createAsyncThunk(
  "register/registerStepOne",
  async (props, thunkAPI) => {
    try {
      const response = await RegisterApi.registerStepOneApi(props);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const registerStepTwo = createAsyncThunk(
  "register/registerStepTwo",
  async (props, thunkAPI) => {
    try {
      const response = await RegisterApi.registerStepTwoApi(props);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const registerStepThree = createAsyncThunk(
  "register/registerStepThree",
  async (props, thunkAPI) => {
    try {
      const response = await RegisterApi.registerStepThreeApi(props);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const registerStepFour = createAsyncThunk(
  "register/registerStepFour",
  async (props, thunkAPI) => {
    try {
      const response = await RegisterApi.registerStepFourApi(props);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const registerStepFive = createAsyncThunk(
  "register/registerStepFive",
  async (props, thunkAPI) => {
    try {
      const response = await RegisterApi.registerStepFiveApi(props);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const registerStepSix = createAsyncThunk(
  "register/registerStepSix",
  async (props, thunkAPI) => {
    try {
      const response = await RegisterApi.registerStepSixApi(props);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const sendPasswordOtp = createAsyncThunk(
  "register/sendPasswordOtp",
  async (props, thunkAPI) => {
    try {
      const response = await RegisterApi.sendPasswordOtpApi(props);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const generatePassword = createAsyncThunk(
  "register/generatePassword",
  async (props, thunkAPI) => {
    try {
      const response = await RegisterApi.generatePasswordApi(props);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getDesignation = createAsyncThunk(
  "register/getDesignation",
  async (props, thunkAPI) => {
    try {
      const response = await RegisterApi.getDesignationApi(props);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getCountries = createAsyncThunk(
  "register/getCountries",
  async (props, thunkAPI) => {
    try {
      const response = await RegisterApi.getCountriesApi(props);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//getstates
export const getStates = createAsyncThunk(
  "register/getStates",
  async (props, thunkAPI) => {
    try {
      const response = await RegisterApi.getStatesApi(props);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//getcities
export const getCities = createAsyncThunk(
  "register/getCities",
  async (props, thunkAPI) => {
    try {
      const response = await RegisterApi.getCitiesApi(props);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const verifyAddress = createAsyncThunk(
  "register/verifyAddress",
  async (props, thunkAPI) => {
    try {
      const response = await RegisterApi.verifyAddressApi(props);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const verifyOTP = createAsyncThunk(
  "register/verifyOTP",
  async (props, thunkAPI) => {
    try {
      const response = await RegisterApi.verifyOTPApi(props);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const verifyPasswordOTP = createAsyncThunk(
  "register/verifyPasswordOTP",
  async (props, thunkAPI) => {
    try {
      const response = await RegisterApi.verifyPasswordOTPApi(props);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//getVendorCategories
export const getVendorCategories = createAsyncThunk(
  "register/getVendorCategories",
  async (props, thunkAPI) => {
    try {
      const response = await RegisterApi.getVendorCategoriesApi(props);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//getVendorSubCategories
export const getVendorSubCategories = createAsyncThunk(
  "register/getVendorSubCategories",
  async (props, thunkAPI) => {
    try {
      const response = await RegisterApi.getVendorSubCategoriesApi(props);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAmenitiesCategories = createAsyncThunk(
  "register/getAmenitiesCategories",
  async (props, thunkAPI) => {
    try {
      const response = await RegisterApi.getAmenitiesCategoriesApi(props);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getCuisineCategories = createAsyncThunk(
  "register/getCuisineCategories",
  async (props, thunkAPI) => {
    try {
      const response = await RegisterApi.getCuisineCategoriesApi(props);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getGoodForCategories = createAsyncThunk(
  "register/getGoodForCategories",
  async (props, thunkAPI) => {
    try {
      const response = await RegisterApi.getGoodForCategoriesApi(props);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getMealsCategories = createAsyncThunk(
  "register/getMealsCategories",
  async (props, thunkAPI) => {
    try {
      const response = await RegisterApi.getMealsCategoriesApi(props);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getParkingCategories = createAsyncThunk(
  "register/getParkingCategories",
  async (props, thunkAPI) => {
    try {
      const response = await RegisterApi.getParkingCategoriesApi(props);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAtmosphereCategories = createAsyncThunk(
  "register/getAtmosphereCategories",
  async (props, thunkAPI) => {
    try {
      const response = await RegisterApi.getAtmosphereCategoriesApi(props);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getCategories = createAsyncThunk(
  "register/getCategories",
  async (type, thunkAPI) => {
    try {
      const response = await RegisterApi.getCategoriesApi(type);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getProfileDetails = createAsyncThunk(
  "register/getProfileDetails",
  async (type, thunkAPI) => {
    try {
      let response;
      if (type.isSetting) {
        response = await RegisterApi.getProfileSettingsDetailsApi(type);
      } else {
        response = await RegisterApi.getProfileDetailsApi(type);
      }
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateProfile = createAsyncThunk(
  "register/updateProfile",
  async (type, thunkAPI) => {
    try {
      const response = await RegisterApi.updateProfileApi(type);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateSettingProfile = createAsyncThunk(
  "register/updateSettingProfile",
  async (type, thunkAPI) => {
    try {
      const response = await RegisterApi.updateSettingsProfileApi(type);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateSettingsDocument = createAsyncThunk(
  "register/updateSettingsDocument",
  async (type, thunkAPI) => {
    try {
      const response = await RegisterApi.updateSettingsDocument(type);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getSettingAddress = createAsyncThunk(
  "register/getSettingAddress",
  async (type, thunkAPI) => {
    try {
      const response = await RegisterApi.getSettingAddress(type);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateSettingAddress = createAsyncThunk(
  "register/updateSettingAddress",
  async (type, thunkAPI) => {
    try {
      const response = await RegisterApi.updateSettingAddress(type);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const logout = createAsyncThunk("register/logout", async () => {
  await RegisterApi.logout();
  localStorage.clear();
});
const initialState = {
  isLoggedIn: false,
  user: null,
  countries: [],
  designations: [],
  states: [],
  cities: [],
  address: {},
  vendorId: "",
  vendorType: "",
  managerId: "",
  outletId: "",
  isBar: false,
  idProofs: [],
  vendorCategories: [],
  vendorSubCategories: [],
  amenitiesCategories: [],
  cuisineCategories: [],
  goodForCategories: [],
  mealsCategories: [],
  parkingCategories: [],
  otherAttractions: [],
  themes: [],
  payments: [],
  moreFacilities: [],
  profile: null,
  settingsAddressDetails: null,
  verifyStatus: true,
};
const registerSlice = createSlice({
  name: "register",
  initialState,

  extraReducers: {
    [getCategories.fulfilled]: (state, action) => {
      if (action.payload?.data?.length) {
        switch (action.payload.data[0].type) {
          case "designation":
            state.designations = action.payload.data;
            break;
          case "idProof":
            state.idProofs = action.payload.data;
            break;
          case "theme":
            state.themes = action.payload.data;
            break;
          case "cuisine":
            state.cuisineCategories = action.payload.data;
            break;
          case "otherAttraction":
            state.otherAttractions = action.payload.data;
            break;
          case "meals":
            state.mealsCategories = action.payload.data;
            break;
          case "parking":
            state.parkingCategories = action.payload.data;
            break;
          case "payment":
            state.payments = action.payload.data;
            break;
          case "moreFacilities":
            state.moreFacilities = action.payload.data;
            break;
          case "vendorCategory":
            state.vendorCategories = action.payload.data;
            break;
          case "vendorSubCategory":
            state.vendorSubCategories = action.payload.data;
            break;
          default:
            return null;
        }
      }
    },
    [getCategories.rejected]: (state, action) => {
      state.designations = [];
      state.idProofs = [];
      state.themes = [];
      state.cuisineCategories = [];
      state.otherAttractions = [];
      state.mealsCategories = [];
      state.parkingCategories = [];
      state.payments = [];
      state.moreFacilities = [];
      state.vendorCategories = [];
      state.vendorSubCategories = [];
    },
    [login.fulfilled]: (state, action) => {
      localStorage.setItem(
        "pendingStep",
        action.payload.user.data.data.pendingStep
      );

      if (state.verifyStatus) {
        if (action.payload.user.data.data.pendingStep != "0") {
          localStorage.setItem("ManagerId", action.payload.user.data.data.managerId);
          window.location.replace(
            "/default-register-complete-prcoess/" +
              action.payload.user.data.data.vendorId
          );
          toast.error("Please Make Sure Your All Details Are Saved");
        } else {
          if (action.payload.user.data.data.vendorType == "single") {
            state.verifyStatus = action.payload.user.data.data?.verifyStatus;
            localStorage.setItem(
              "outletId",
              action.payload.user.data.data.outletId
            );

            localStorage.setItem(
              "userData",
              JSON.stringify(action.payload?.user?.data?.data)
            );
          } else {
            state.verifyStatus = true;
            localStorage.setItem("outletId", "");
          }
          state.isLoggedIn = true;
          localStorage.setItem("isLoggedIn", true);
          state.vendorId = action.payload.user.data.data.vendorId;
          state.managerId = action.payload.user.data.data.managerId;
          state.user = action.payload.user.data.data.name;
          localStorage.setItem(
            "user",
            action.payload.user.data.data.accessToken
          );

          localStorage.setItem(
            "vendorId",
            action.payload.user.data.data.vendorId
          );
          localStorage.setItem(
            "vendorType",
            action.payload.user.data.data.vendorType
          );
          setTimeout(() => {
            window.location.replace("/dashboard");
          }, 1200);
        }
      }
    },
    [login.rejected]: (state, action) => {
      state.isLoggedIn = false;
      // localStorage.setItem("isLoggedIn", false)
    },
    [sendPasswordOtp.fulfilled]: (state, action) => {
      state.managerId = action.payload.data.managerId;
    },
    [sendPasswordOtp.rejected]: (state, action) => {
      state.managerId = "";
    },
    [register.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
    },
    [register.rejected]: (state, action) => {
      state.isLoggedIn = false;
    },
    [logout.fulfilled]: (state, action) => {
      console.log("logout123", state);
      state.isLoggedIn = false;
      state.user = null;
      state.verifyStatus = true;
    },
    [registerStepOne.fulfilled]: (state, action) => {
      state.managerId = action.payload.managerId;
    },
    [registerStepTwo.fulfilled]: (state, action) => {
      state.outletId = action.payload.outletId;
      state.vendorId = action.payload.vendorId;
      state.vendorType = action.payload.vendorType;
    },
    [registerStepFive.fulfilled]: (state, action) => {
      state.isBar = action.payload.isBar;
    },

    [getDesignation.fulfilled]: (state, action) => {
      state.designations = action.payload.data;
    },
    [getCountries.fulfilled]: (state, action) => {
      state.countries = action.payload.data;
    },
    [getCountries.rejected]: (state, action) => {
      state.countries = [];
    },

    [getStates.fulfilled]: (state, action) => {
      state.states = action.payload.data;
    },
    [getStates.rejected]: (state, action) => {
      state.states = [];
    },
    [getCities.fulfilled]: (state, action) => {
      state.cities = action.payload.data;
    },
    [getCities.rejected]: (state, action) => {
      state.cities = [];
    },
    [verifyAddress.fulfilled]: (state, action) => {
      state.address = action.payload.data;
    },
    [verifyAddress.rejected]: (state, action) => {
      state.address = {};
    },
    [getVendorCategories.fulfilled]: (state, action) => {
      state.vendorCategories = action.payload.data;
    },
    [getVendorCategories.rejected]: (state, action) => {
      state.vendorCategories = [];
    },
    [getAmenitiesCategories.fulfilled]: (state, action) => {
      state.amenitiesCategories = action.payload.data;
    },
    [getAmenitiesCategories.rejected]: (state, action) => {
      state.amenitiesCategories = [];
    },
    [getCuisineCategories.fulfilled]: (state, action) => {
      state.cuisineCategories = action.payload.data;
    },
    [getCuisineCategories.rejected]: (state, action) => {
      state.cuisineCategories = [];
    },
    [getGoodForCategories.fulfilled]: (state, action) => {
      state.goodForCategories = action.payload.data;
    },
    [getGoodForCategories.rejected]: (state, action) => {
      state.goodForCategories = [];
    },
    [getMealsCategories.fulfilled]: (state, action) => {
      state.mealsCategories = action.payload.data;
    },
    [getMealsCategories.rejected]: (state, action) => {
      state.mealsCategories = [];
    },
    [getParkingCategories.fulfilled]: (state, action) => {
      state.parkingCategories = action.payload.data;
    },
    [getParkingCategories.rejected]: (state, action) => {
      state.parkingCategories = [];
    },
    [getAtmosphereCategories.fulfilled]: (state, action) => {
      state.atmosphereCategories = action.payload.data;
    },
    [getAtmosphereCategories.rejected]: (state, action) => {
      state.atmosphereCategories = [];
    },
    [getProfileDetails.fulfilled]: (state, action) => {
      state.profile = action.payload.data;
    },
    [getSettingAddress.fulfilled]: (state, action) => {
      console.log(action.payload);
      state.settingsAddressDetails = action.payload.data;
    },
  },
});
const { reducer } = registerSlice;
export default reducer;
