import React, { useState, Fragment, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { upperCase } from "../../../constants";
import ExcelUploadModal from "./ExcelUploadModal";
import { addMenu, getMenuList } from "../../../redux/slices/Menu";
import { getCategories } from "../../../redux/slices/Registration";
import { FaInfoCircle } from "react-icons/fa";
import ReadOnlyRow from "./ReadOnlyRow";
import "../../../assets/css/menu.css";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { FaFileUpload } from "react-icons/fa";

import {
  Button,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import useRequest from "../../../hooks/useRequest";
import useRequest2 from "../../../hooks/useRequest2";
import { downloadExcel } from "../../../util/fn";
import menuListxlsx from "../../../util/menuListxlsx.xlsx";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
// import { nanoid } from "nanoid";
// import "./App.css";
// import data from "./mock-data.json";

const styles = {
  label: {
    display: "flex",
    maxWidth:"80px",
    width:'60px',
    marginTop:'10px',
    alignItems: "center",
    cursor: "pointer",
    padding: "10px",
    border: "1px solid #3ea99f",
    borderRadius: "5px",
  },
  icon: {
    marginRight: "10px",
    fontSize: "20px",
  },
  input: {
    display: "none",
  },
};
const MenuTable = () => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 5;
  // const { menuList } = useSelector((state) => state.menu);
  const { hashKey: outletId } = useParams();

  const [files, setFiles] = React.useState();
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const [addFormData, setAddFormData] = useState({
    cusineId: "",
    name: "",
    foodCategory: "",
    ingredients: "",
    description: "",
    fullPrice: "",
    status: "",
  });
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [loader, setLoader] = useState(false);
  const [itemOffset, setItemOffset] = useState(0);
  const [cusine, setCusine] = useState("");
  const [cusineName, setCusineName] = useState();
  const [foodType, setFoodType] = useState("");
  const [menuList, setMenuList] = useState([]);
  const [firstCuisne, setFirstCuisne] = useState("");
  const [pdfMenu, setPdf] = useState("");
  const [cuisineCategories, setCuisineCategories] = useState([]);
  // Fetch items from another resources.

  const { request, response } = useRequest();

  const { request: requestImportMenu, response: responseImportMenu } =
    useRequest();
  const { request: requestCategories, response: responseCategories } =
    useRequest2();
  const { request: requestPdf, response: responsePdf } = useRequest2();

  useEffect(() => {
    requestCategories("POST", "vendor/menu/getCuisineCategory", {
      outletId: outletId ? outletId : localStorage.getItem("outletId"),
    });
    requestPdf("POST", "vendor/menu/getPdfMenu", {
      outletId: outletId ? outletId : localStorage.getItem("outletId"),
    });
  }, []);

  useEffect(() => {
    if (responsePdf && responsePdf.status) {
      setPdf(responsePdf?.data);
    }
  }, [responsePdf]);

  console.log("pdfMenu", pdfMenu);
  useEffect(() => {
    if (responseCategories && responseCategories.status) {
      setCuisineCategories(responseCategories.data);
      // setFirstCuisne(responseCategories.data[0]?._id)
    }
  }, [responseCategories]);

  const handleAddFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    if (fieldName === "cuisine") {
      const selectedCusine = cuisineCategories.find((obj) => {
        if (obj._id == fieldValue) {
          return obj;
        }
      });

      if (selectedCusine && selectedCusine.name) {
        setCusineName(selectedCusine.name);
      } else {
        setCusineName();
      }

      setCusine(fieldValue);

      if (!fieldValue) {
        // if (foodType) {
        //   toast.error("Please Select filter cuisine.")
        // }
        // else {
        //   setCurrentItems([]);
        //   setMenuList([])
        //   setPageCount(0)
        // }
      } else {
        request("POST", "menu/getAllMenu", {
          outletId: localStorage.getItem("outletId")
            ? localStorage.getItem("outletId")
            : outletId,
          cusineId: fieldValue,
          foodType: foodType,
          per_page: itemsPerPage,
          page: page,
        });
      }
    } else {
      setFoodType(fieldValue);
      const newFormData = { ...addFormData };
      newFormData[fieldName] = fieldValue;
      setAddFormData(newFormData);
      if (fieldName === "foodType2") {
        // if (!cusine) {
        //   toast.error("Please Select filter cuisine.")
        // }
        // else {
        request("POST", "menu/getAllMenu", {
          outletId: localStorage.getItem("outletId")
            ? localStorage.getItem("outletId")
            : outletId,
          cusineId: cusine ? cusine : "",
          foodType: fieldValue,
          per_page: itemsPerPage,
          page: page,
        });
        // }
      }
    }
  };

  const handleAddFormSubmit = (event) => {
    event.preventDefault();
    const form = new FormData();
    form.append(
      "outletId",
      localStorage.getItem("outletId")
        ? localStorage.getItem("outletId")
        : outletId
    );
    for (const key in addFormData) {
      form.append(key, addFormData[key]);
    }
    form.append("currency", "inr");
    if (files) {
      for (let i = 0; i < files.length; i++) {
        form.append("image", files[i]);
      }
    }
    setLoader(true);
    dispatch(addMenu(form))
      .unwrap()
      .then((res) => {
        toast.success(res.message);

        if (cusine) {
          setLoader(false);

          request("POST", "menu/getAllMenu", {
            outletId: localStorage.getItem("outletId")
              ? localStorage.getItem("outletId")
              : outletId,
            cusineId: cusine,
            foodType: foodType,
            per_page: itemsPerPage,
            page: page,
          });
        }
        setLoader(false);

        const form = document.getElementById("menu_form");
        form.reset();
      })
      .catch((err) => {
        toast.error(err);
        setLoader(false);
      });
  };

  useEffect(() => {
    if (response && response.status == "success") {
      if (response?.data) {
        console.log("setTotalPages", response);
        setTotalPages(Math.ceil(response?.totalPage));
        setCurrentItems(response?.data);
        setLoader(false);

        // const endOffset = itemOffset + itemsPerPage;
        // setPageCount(Math.ceil(response?.data.length / itemsPerPage));
      }
      setMenuList(response);
    }
    // }, [response, itemOffset, itemsPerPage])
  }, [response]);

  const handlePageClick = (event) => {
    console.log("event.selected", event.selected + 1);
    setPage(event.selected + 1);
    // const newOffset = (event.selected * itemsPerPage) % menuList?.data.length;
    // setItemOffset(newOffset);
  };

  // const downloadExcelHandler = () => {
  //   let data = [{
  //     'Menu Items':'',
  //     'Category':'',
  //     'Ingredients':'',
  //     'Description':'',
  //     'Price':'',
  //     'Status':'',
  //     'Type':''
  //   }];
  //   downloadExcel(data, "ExcelFormat");
  // };

  const importMenu = () => {
    // if (!cusine) {
    //   toast.error("Please Select filter cuisine.");
    // }
  };
  useEffect(() => {
    if (responseImportMenu) {
      if (responseImportMenu.status == "success") {
        request("POST", "menu/getAllMenu", {
          outletId: localStorage.getItem("outletId")
            ? localStorage.getItem("outletId")
            : outletId,
          cusineId: cusine,
          foodType: foodType,
          per_page: itemsPerPage,
          page: page,
        });
        requestPdf("POST", "vendor/menu/getPdfMenu", {
          outletId: outletId ? outletId : localStorage.getItem("outletId"),
        });

        toast.success(responseImportMenu.message);
      }
    }
  }, [responseImportMenu]);

  useEffect(() => {
    if (foodType == "" && cusine == "") {
      request("POST", "menu/getAllMenu", {
        outletId: localStorage.getItem("outletId")
          ? localStorage.getItem("outletId")
          : outletId,
        cusineId: "",
        foodType: "",
        per_page: itemsPerPage,
        page: page,
      });
    }
    if (cusine) {
      request("POST", "menu/getAllMenu", {
        outletId: localStorage.getItem("outletId")
          ? localStorage.getItem("outletId")
          : outletId,
        cusineId: cusine,
        foodType: foodType,
        per_page: itemsPerPage,
        page: page,
      });
    }
  }, [page, cusine]);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Please Select cuisine from first dropdown and upload CSV/XLX file.
    </Tooltip>
  );

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <Row className="bg-clr" id="menu_form2">
      <Col md={12}>
        <div className="mt-4">
          <h4 className="menu-h2">Menu Items</h4>
          <OverlayTrigger
            placement="top"
            delay={{ show: 100, hide: 100 }}
            overlay={renderTooltip}
          >
            <span className="cursor-pointer">
              <FaInfoCircle fontSize={"20px"} onClick={handleShowModal} />
            </span>
          </OverlayTrigger>
        </div>
        <div>
          <span className="menu-heading">To Upload the Excel Menu</span>
        </div>
        <Row>
          <Col md={3} className="">
            <div>
              <div className="mt-2 mb-4">
                <span>Step 1</span>
                <a
                  className="excle-dowm text-center mt-1"
                  href={menuListxlsx}
                  download
                >
                  Download excel format
                </a>
                {/* <button type="button" onClick={downloadExcelHandler} className="excle-dowm"> Download excel format</button> */}
              </div>
              <div className="mt-2 mb-4">
                <span>Step 2</span>
                {cusineName ? (
                  <>
                    <span className="uploadInput m-0">
                      <input
                        type="file"
                        name="uploadfile"
                        id="uploadFile"
                        accept=".xlsx"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          const form = new FormData();
                          form.append(
                            "outletId",
                            localStorage.getItem("outletId")
                              ? localStorage.getItem("outletId")
                              : outletId
                          );
                          form.append("cusineId", cusine);
                          if (e.target.files && e.target.files[0]) {
                            form.append("image", e.target.files[0]);
                          }
                          setPage(1);
                          requestImportMenu(
                            "POST",
                            "menu/uploadExcelMenu",
                            form
                          );
                        }}
                      />
                      <label
                        className="excle-dowm m-0 text-center"
                        htmlFor="uploadFile"
                      >
                        Import Menu
                      </label>
                    </span>
                    <span id="uploadedfileName" />
                  </>
                ) : (
                  <>
                    <span className="uploadInput m-0">
                      <input
                        type="file"
                        name="uploadfile"
                        id="uploadFile"
                        accept=".xlsx"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          const form = new FormData();
                          form.append(
                            "outletId",
                            localStorage.getItem("outletId")
                              ? localStorage.getItem("outletId")
                              : outletId
                          );
                          form.append("cusineId", cusine);
                          if (e.target.files && e.target.files[0]) {
                            form.append("image", e.target.files[0]);
                          }
                          setPage(1);
                          requestImportMenu(
                            "POST",
                            "menu/uploadExcelMenu",
                            form
                          );
                        }}
                      />
                      <label
                        className="excle-dowm m-0 text-center"
                        htmlFor="uploadFile"
                      >
                        Import Menu
                      </label>
                    </span>
                    <span id="uploadedfileNamed" />
                  </>
                )}
              </div>
              {pdfMenu?.length == 0 ? (
                <div className="mt-2 mb-4">
                  <span>Step 3</span>

                  <>
                    <span className="uploadInput m-0">
                      <input
                        type="file"
                        name="uploadfile1"
                        id="uploadFile1"
                        multiple
                        style={{ display: "none" }}
                        onChange={(e) => {
                          e.stopPropagation();
                          const form = new FormData();
                          form.append(
                            "outletId",
                            localStorage.getItem("outletId")
                              ? localStorage.getItem("outletId")
                              : outletId
                          );
                          if (e.target.files) {
                            for (let i = 0; i < e.target.files.length; i++) {
                              form.append("image", e.target.files[i]);
                            }
                          }
                          setPage(1);
                          requestImportMenu("POST", "menu/uploadPdfMenu", form);
                        }}
                      />
                      <label
                        className="excle-dowm m-0 text-center"
                        htmlFor="uploadFile1"
                      >
                        Import Pdf
                      </label>
                    </span>
                    <span id="uploadedfileName1" />
                  </>
                </div>
              ) : (
                <div className="mt-2 mb-4">
                  <a
                    href={`${pdfMenu?.[0]?.src}`}
                    className="excle-dowm text-center"
                    target="_blank"
                  >
                    View Pdf
                  </a>
                </div>
              )}
            </div>
          </Col>

          <div className="col-md-3"></div>
          <div className="col-md-3"></div>
          <div className="col-md-3">
            <div>
              <span className="menu-heading">To View The Menu</span>
            </div>
            <div>
              <label htmlFor="validationCustom01" className="form-label">
                Filter By Cuisine
              </label>
              <div className="dropdown-group form-vendor-regi">
                <select
                  className="form-control"
                  id="validationCustom01"
                  name="cuisine"
                  onChange={(e) => {
                    setPage(1);
                    handleAddFormChange(e);
                  }}
                  defaultValue={addFormData?.cusineId}
                >
                  <option value="">Entire Menu</option>
                  {cuisineCategories.length > 0 &&
                    cuisineCategories.map((item) => {
                      return (
                        <option value={item?._id}>
                          {upperCase(item?.name)}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>

            <div className="mt-b">
              <label htmlFor="validationCustom01" className="form-label">
                Filter By Food type
              </label>
              <div className="dropdown-group form-vendor-regi">
                <select
                  className="form-control"
                  id="validationCustom01"
                  name="foodType2"
                  onChange={(e) => {
                    setPage(1);
                    handleAddFormChange(e);
                  }}
                  // value={addFormData.cusineId}
                >
                  <option value="">Please Select</option>
                  <option value="food">Food/Beverage</option>
                  <option value="beverage">Alc. Beverage</option>
                </select>
                {/* <div className="invalid-feedback">
                    Please Select a valid Designation.
                  </div> */}
              </div>
            </div>
          </div>
        </Row>
      </Col>
      <Col md={12}>
        <form className="border-bootom">
          <div className="menu">
            <div>
              <span className="menu-heading">To Upload the Menu Manually</span>
            </div>
            <form id="menu_form" onSubmit={handleAddFormSubmit}>
              <table className="menu-table mt-0" ref={tableRef}>
                <caption>Menus</caption>
                <thead>
                  <tr>
                    <th>Sr.</th>
                    <th>Cuisine</th>
                    <th>Menu Items</th>
                    <th>Category</th>
                    {/* <th>Ingredients</th> */}
                    <th>Course</th>
                    <th>Price</th>
                    <th>Picture</th>
                    <th>Status</th>
                    <th>Type</th>
                    <th>Enter/Edit/Delete</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="form-vendor-regi">
                    <td>0</td>
                    <td>
                      <select
                        // className="form-control"
                        name="cusineId"
                        required
                        onChange={(e) => {
                          setPage(1);
                          handleAddFormChange(e);
                        }}
                      >
                        <option value="">Select Cuisine</option>
                        {cuisineCategories.length > 0 &&
                          cuisineCategories.map((item) => {
                            return (
                              <option value={item?._id}>
                                {upperCase(item?.name)}
                              </option>
                            );
                          })}
                      </select>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="name"
                        required="required"
                        placeholder="Enter name..."
                        onChange={(e) => {
                          setPage(1);
                          handleAddFormChange(e);
                        }}
                      />
                    </td>
                    <td>
                      <select
                        // className="form-control"
                        name="foodCategory"
                        onChange={(e) => {
                          setPage(1);
                          handleAddFormChange(e);
                        }}
                        required
                      >
                        <option value="">Food Category</option>
                        <option value="veg">Veg</option>
                        <option value="egg">Egg</option>
                        <option value="nonveg">Non-Veg</option>
                      </select>
                    </td>
                    {/* <td>
                      <input
                        type="text"
                        name="ingredients"
                        required="required"
                        placeholder="Enter ingredients..."
                        onChange={(e) => {
                          setPage(1)
                          handleAddFormChange(e)
                        }}
                      />
                    </td> */}
                    <td>
                      {/* <input
                        type="text"
                        name="description"
                        required="required"
                        placeholder="Enter description..."
                        onChange={(e) => {
                          setPage(1)
                          handleAddFormChange(e)
                        }}
                      /> */}
                      <select
                        // className="form-control"
                        name="course"
                        onChange={(e) => {
                          setPage(1);
                          handleAddFormChange(e);
                        }}
                        required
                      >
                        <option value="">Select</option>
                        {/* <option value="food category">Food Category</option> */}
                        <option value="soups">Soups</option>
                        <option value="starters">Starters</option>
                        <option value="salads">Salads</option>
                        <option value="main course">Main course</option>
                        <option value="breads">Breads</option>
                        <option value="desserts">Desserts</option>
                        <option value="drinks">Drinks</option>
                      </select>
                    </td>
                    <td>
                      <input
                        type="number"
                        name="fullPrice"
                        required="required"
                        placeholder="Price"
                        min={1}
                        onChange={(e) => {
                          setPage(1);
                          handleAddFormChange(e);
                        }}
                      />
                    </td>
                    <td>
                      {/* <input
                        type="file"
                        name="image"
                        multiple
                        accepr="image/*"
                        placeholder="Select images..."
                        onChange={(e) => {
                          setFiles(e.target.files);
                        }}
                      /> */}

                      <label htmlFor="image-upload" style={styles.label}>
                        <FaFileUpload style={styles.icon} />
                        {/* <span>Select Images...</span> */}
                      </label>
                      <input
                        id="image-upload"
                        type="file"
                        name="image"
                        multiple
                        accept="image/*"
                        style={styles.input}
                        onChange={(e) => {
                          setFiles(e.target.files);
                        }}
                      />
                    </td>
                    <td>
                      <select
                        // className="form-control"
                        name="status"
                        onChange={(e) => {
                          setPage(1);
                          handleAddFormChange(e);
                        }}
                        required
                      >
                        <option value="">Select Status</option>
                        <option value="active">Available</option>
                        <option value="inactive">Not Available</option>
                      </select>
                    </td>
                    <td>
                      <select
                        // className="form-control"
                        name="foodType"
                        onChange={(e) => {
                          setPage(1);
                          handleAddFormChange(e);
                        }}
                        required
                      >
                        <option value="">Please Select</option>
                        <option value="food">Food/Beverage</option>
                        <option value="beverage">Alc. Beverage</option>
                      </select>
                    </td>
                    <td>
                      {loader ? (
                        <center>
                          <div
                            style={{
                              flex: 1,
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Spinner
                              animation="border"
                              className="d-flex justify-content-center"
                            />
                          </div>
                        </center>
                      ) : (
                        <button
                          className="ms-btn-icon-outline btn-square btn-success"
                          type="submit"
                        >
                          <i className="flaticon-tick-inside-circle mr-0"></i>
                        </button>
                      )}
                    </td>
                  </tr>
                  {currentItems &&
                    currentItems.map((menu, index) => (
                      <Fragment>
                        {
                          <ReadOnlyRow
                            menu={menu}
                            request={request}
                            cusine={cusine}
                            foodType={foodType}
                            per_page={itemsPerPage}
                            page={page}
                            index={
                              menuList?.data
                                ? menuList?.data.indexOf(menu)
                                : index
                            }
                          />
                        }
                      </Fragment>
                    ))}
                  {/* </form> */}
                </tbody>
              </table>
            </form>
            {menuList?.data && (
              <ReactPaginate
                nextLabel="Next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={totalPages}
                previousLabel="< Previous"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            )}
          </div>
          <ExcelUploadModal show={showModal} handleClose={handleCloseModal} />
        </form>
      </Col>
    </Row>
  );
};

export default MenuTable;
