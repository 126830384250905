import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import MenuApi from "../services/menuApis";

export const addMenu = createAsyncThunk(
    "menu/addMenu",
    async (props, thunkAPI) => {
        try {
            const response = await MenuApi.addMenuApi(props);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const getMenuList = createAsyncThunk(
    "menu/getMenuList",
    async (props, thunkAPI) => {
        try {
            const response = await MenuApi.getMenuListApi(props);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const getMenuDetails = createAsyncThunk(
    "menu/getMenuDetails",
    async (props, thunkAPI) => {
        try {
            const response = await MenuApi.getMenuDetailsApi(props);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const updateMenu = createAsyncThunk(
    "menu/updateMenu",
    async (props, thunkAPI) => {
        try {
            const response = await MenuApi.updateMenuApi(props);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const removeMenu = createAsyncThunk(
    "menu/removeMenu",
    async (props, thunkAPI) => {
        try {
            const response = await MenuApi.removeMenuApi(props);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
const initialState = {
    menuList: [],
    menuDetails: {}
}

const menuSlice = createSlice({
    name: "menu",
    initialState,
    extraReducers: {
        [getMenuList.fulfilled]: (state, action) => {
            state.menuList = action.payload;
        },
        [getMenuList.rejected]: (state, action) => {
            state.menuList = [];
        },
        [getMenuDetails.fulfilled]: (state, action) => {
            state.menuDetails = action.payload.data;
        },
        [getMenuDetails.rejected]: (state, action) => {
            state.menuDetails = {};
        },
        [updateMenu.fulfilled]: (state, action) => {
            state.menuList = action.payload.data;
        },
        [updateMenu.rejected]: (state, action) => {
            state.menuList = [];
        },
        [removeMenu.fulfilled]: (state, action) => {
            state.menuList = action.payload.data;
        },
        [removeMenu.rejected]: (state, action) => {
            state.menuList = [];
        }
    }
})
const { reducer } = menuSlice;
export default reducer;