import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import { getOutletDetail, setCommision } from "../../../redux/slices/Outlet";
const CommisionModal = (props) => {
    const dispatch = useDispatch();
    const { outletDetails } = useSelector((state) => state.outlet);
    const [sliderValue, setSliderValue] = useState(0);
    useEffect(() => {
        if (outletDetails?.commissionPoint) {
            setSliderValue(outletDetails?.commissionPoint)
        }
    }, [])
    return (
        <Modal
            show={props.setShowCommisionModal}
            onHide={props.handleShowCommisionModal}
            style={{ height: "90vh", maxHeight: "90vh", overflow: "hidden" }}
            scrollable
            size="lg"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Commision</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row style={{ marginBottom: "25px" }}>
                    <Col md={6}>
                        <label htmlFor="foodLicence" className="form-label customLabel">
                            Commision
                        </label>
                        <Slider
                            tooltip={true}
                            orientation="horizontal"

                            min={5}
                            max={100}
                            value={sliderValue}
                            onChange={(val) => setSliderValue(val)}
                        />
                        {sliderValue}
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleShowCommisionModal}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={() => {
                        dispatch(
                            setCommision({
                                commission: sliderValue,
                                outletId: props.outletId,
                            })
                        )
                            .unwrap()
                            .then(() => {
                                dispatch(getOutletDetail({ outletId: props.outletId })).unwrap().then(() => {
                                    toast.success("Commission set successfully");
                                    props.handleShowCommisionModal();
                                }).catch((err) => {
                                    toast.error(err);
                                })
                            })
                            .catch((err) => {
                                toast.error(err);
                                props.handleShowCommisionModal();
                            });
                    }}
                >
                    Yes
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
export default CommisionModal;