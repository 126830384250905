import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Button, Form, Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Breadcrumb from "../../components/Breadcrumb";
import { registerOutletStepThree } from "../../redux/slices/Outlet";
import { outletStepThreeSave } from "../../redux/slices/SaveOutletDetails";
import { API_URL } from "../../util/constant";
import { maxSize } from "../../constants";

const StepThree = ({ nextStep, handleNextStep, isEdit, outletId }) => {
  // const outletId = "6562f95f93920674274886a7";
  const dispatch = useDispatch();

  const { outletStepThree } = useSelector((state) => state.saveOutletDetails);
  const [loader, setLoader] = useState(false);
  const [touchedError, setTouchedError] = useState(false);
  const [textCount, setTextCount] = useState();
  const [displayImage, setDisplayImage] = useState({
    coverPicture: "",
    facadePicture: [],
    interiorsPicture: [],
    sittingPicture: [],
    teamStaffPicture: [],
    otherPicture: [],
  });
  const [displayImage2, setDisplayImage2] = useState({
    coverPicture: "",
    facadePicture: [],
    interiorsPicture: [],
    sittingPicture: [],
    teamStaffPicture: [],
    otherPicture: [],
  });
  const [storeImage, setStoreImage] = useState({
    coverPicture: [],
    facadePicture: [],
    interiorsPicture: [],
    sittingPicture: [],
    teamStaffPicture: [],
    otherPicture: [],
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
    setValue,
  } = useForm();

  useEffect(() => {
    register("coverPicture", { required: true });
    register("facadeImage", { required: true });
    register("interiorsImage", { required: false });
    register("uploadSitting", { required: false });
  }, []);

  useEffect(() => {
    if (outletStepThree) {
      const length = outletStepThree?.description?.length ?? "";
      setTextCount(length);
      setValue("description", outletStepThree?.description);

      let coverPictureBlobImage = "";
      let facadePictureBlobImage = [];
      let interiorsPictureBlobImage = [];
      let sittingPictureBlobImage = [];
      let teamStaffPictureBlobImage = [];
      let otherPictureBlobImage = [];

      let coverPictureStoreImage = "";
      let facadePictureStoreImage = [];
      let interiorsPictureStoreImage = [];
      let sittingPictureStoreImage = [];
      let teamStaffPictureStoreImage = [];
      let otherPictureStoreImage = [];

      console.log(outletStepThree);
      if (outletStepThree?.savedCoverPicture) {
        if (outletStepThree?.coverPicture?.length) {
          coverPictureBlobImage = `${API_URL}/viewImage/${outletStepThree?.coverPicture[0]}`;
          setError("coverPicture", "");
          setValue("coverPicture", outletStepThree?.coverPicture[0]);
          register("coverPicture", { required: false });
          setError("coverPicture", "");
        }
      } else {
        console.log(outletStepThree?.coverPicture);
        if (outletStepThree?.coverPicture?.length > 0) {
          if (outletStepThree?.coverPicture[0]) {
            const files = outletStepThree?.coverPicture;
            if (files) {
              coverPictureBlobImage = URL.createObjectURL(files[0]);
              coverPictureStoreImage = files;
              // setDisplayImage({...displayImage,coverPicture:URL.createObjectURL(files[0])});
              // setStoreImage({...storeImage,coverPicture:files});

              setError("coverPicture", "");
              setValue("coverPicture", files[0]);
              register("coverPicture", { required: false });
              setError("coverPicture", "");
            } else {
              setError("coverPicture", "");
              coverPictureBlobImage = "";
              coverPictureStoreImage = [];
              setValue("coverPicture", "");
              register("coverPicture", { required: true });
            }
          }
        }
      }

      if (outletStepThree?.savedfacadePicture) {
        if (outletStepThree?.facadeImage?.length) {
          facadePictureBlobImage = [
            `${API_URL}/viewImage/${outletStepThree?.facadeImage[0]}`,
          ];
          setError("facadeImage", "");
          setValue("facadeImage", outletStepThree?.facadeImage);
          register("facadeImage", { required: false });
          setError("facadeImage", "");
        }
      } else {
        if (outletStepThree?.facadeImage?.length > 0) {
          if (outletStepThree?.facadeImage[0]) {
            const files = outletStepThree?.facadeImage;
            if (files?.length > 0) {
              let facadeP = [];
              let facadeFile = [];

              console.log(
                "outletStepThree?.facadeImage",
                outletStepThree?.facadeImage[0]
              );
              // for (let i = 0; i < files.length; i++) {
              //   facadeP.push(URL?.createObjectURL(files[i]));
              //   facadeFile.push(files[i]);
              // }

              // facadePictureBlobImage = facadeP;
              // facadePictureStoreImage = files
              // setDisplayImage({...displayImage,facadePicture:facadeP});
              // setStoreImage({...storeImage,facadePicture:files});
              setError("facadeImage", "");
              setValue("facadeImage", facadeFile);
              register("facadeImage", { required: false });
              setError("facadeImage", "");
            } else {
              setError("facadePicture", "");
              facadePictureBlobImage = [];
              facadePictureStoreImage = [];
              setValue("facadeImage", []);
              register("facadeImage", { required: true });
            }
          }
        }
      }

      if (outletStepThree?.savedInteriorsPicture) {
        if (outletStepThree?.interiorsImage?.length) {
          for (let i = 0; i < outletStepThree?.interiorsImage?.length; i++) {
            interiorsPictureBlobImage.push(
              `${API_URL}/viewImage/${outletStepThree?.interiorsImage[i]}`
            );
          }

          setError("interiorsImage", "");
          setValue("interiorsImage", outletStepThree?.interiorsImage);
          register("interiorsImage", { required: false });
          setError("interiorsImage", "");
        }
      } else {
        if (outletStepThree?.interiorsImage?.length > 0) {
          if (outletStepThree?.interiorsImage[0]) {
            const files = outletStepThree?.interiorsImage;

            if (files?.length) {
              let interiorsP = [];
              let interiorsFile = [];

              for (let i = 0; i < files.length; i++) {
                interiorsP.push(URL.createObjectURL(files[i]));
                interiorsFile.push(files[i]);
              }

              interiorsPictureBlobImage = interiorsP;
              interiorsPictureStoreImage = files;
              // setDisplayImage({...displayImage,interiorsPicture:interiorsP});
              // setStoreImage({...storeImage,interiorsPicture:files});

              setError("interiorsImage", "");
              setValue("interiorsImage", interiorsFile);
              register("interiorsImage", { required: false });
            } else {
              setError("interiorsImage", "");
              interiorsPictureBlobImage = [];
              interiorsPictureStoreImage = [];
              setValue("interiorsImage", []);
              register("interiorsImage", { required: false });
            }
          }
        }
      }

      if (outletStepThree?.savedSittingPicture) {
        if (outletStepThree?.sittingImage?.length) {
          for (let i = 0; i < outletStepThree?.sittingImage?.length; i++) {
            sittingPictureBlobImage.push(
              `${API_URL}/viewImage/${outletStepThree?.sittingImage[i]}`
            );
          }

          setError("uploadSitting", "");
          setValue("uploadSitting", outletStepThree?.sittingImage);
          register("uploadSitting", { required: false });
          setError("uploadSitting", "");
        }
      } else {
        if (outletStepThree?.sittingImage?.length > 0) {
          if (outletStepThree?.sittingImage[0]) {
            const files = outletStepThree?.sittingImage;

            if (files?.length) {
              let sittingP = [];
              let sittingFile = [];

              for (let i = 0; i < files.length; i++) {
                sittingP.push(URL.createObjectURL(files[i]));
                sittingFile.push(files[i]);
              }

              sittingPictureBlobImage = sittingP;
              sittingPictureStoreImage = files;
              setError("uploadSitting", "");
              setValue("uploadSitting", sittingFile);
              register("uploadSitting", { required: false });
            } else {
              sittingPictureBlobImage = [];
              sittingPictureStoreImage = [];
              setError("uploadSitting", "");
              setValue("uploadSitting", []);
              register("uploadSitting", { required: false });
            }
          }
        }
      }

      if (outletStepThree?.savedStaffPicture) {
        if (outletStepThree?.staffImage?.length) {
          for (let i = 0; i < outletStepThree?.staffImage?.length; i++) {
            teamStaffPictureBlobImage.push(
              `${API_URL}/viewImage/${outletStepThree?.staffImage[i]}`
            );
          }

          setError("uploadStaff", "");
          setValue("uploadStaff", outletStepThree?.staffImage);
          register("uploadStaff", { required: false });
          setError("uploadStaff", "");
        }
      } else {
        if (outletStepThree?.staffImage?.length > 0) {
          if (outletStepThree?.staffImage[0]) {
            const files = outletStepThree?.staffImage;
            if (files?.length) {
              let teamsP = [];
              let teamsFile = [];

              for (let i = 0; i < files.length; i++) {
                teamsP.push(URL.createObjectURL(files[i]));
                teamsFile.push(files[i]);
              }

              teamStaffPictureBlobImage = teamsP;
              teamStaffPictureStoreImage = files;

              setValue("uploadStaff", teamsFile);
            } else {
              teamStaffPictureBlobImage = [];
              teamStaffPictureStoreImage = [];
              setValue("uploadStaff", []);
            }
          }
        }
      }

      if (outletStepThree?.savedOtherPicture) {
        if (outletStepThree?.image?.length) {
          for (let i = 0; i < outletStepThree?.image?.length; i++) {
            otherPictureBlobImage.push(
              `${API_URL}/viewImage/${outletStepThree?.image[i]}`
            );
          }

          setError("images", "");
          setValue("images", outletStepThree?.image);
          register("images", { required: false });
          setError("images", "");
        }
      } else {
        if (outletStepThree?.image?.length > 0) {
          if (outletStepThree?.image[0]) {
            const files = outletStepThree?.image;
            if (files?.length) {
              let otherP = [];
              let otherFile = [];

              for (let i = 0; i < files.length; i++) {
                otherP.push(URL.createObjectURL(files[i]));
                otherFile.push(files[i]);
              }
              otherPictureBlobImage = otherP;
              otherPictureStoreImage = files;

              setValue("images", otherFile);
            } else {
              otherPictureBlobImage = [];
              otherPictureStoreImage = [];
              setValue("images", []);
            }
          }
        }
      }

      // console.log({...displayImage, coverPicture:coverPictureBlobImage,
      //   facadePicture:facadePictureBlobImage,
      //   interiorsPicture:interiorsPictureBlobImage,
      //   sittingPicture:sittingPictureBlobImage,
      //   teamStaffPicture:teamStaffPictureBlobImage,
      //   otherPicture:otherPictureBlobImage})

      setDisplayImage2({
        ...displayImage,
        coverPicture: coverPictureBlobImage,
        facadePicture: facadePictureBlobImage,
        interiorsPicture: interiorsPictureBlobImage,
        sittingPicture: sittingPictureBlobImage,
        teamStaffPicture: teamStaffPictureBlobImage,
        otherPicture: otherPictureBlobImage,
      });

      setStoreImage({
        ...storeImage,
        coverPicture: coverPictureStoreImage,
        facadePicture: facadePictureStoreImage,
        interiorsPicture: interiorsPictureStoreImage,
        sittingPicture: sittingPictureStoreImage,
        teamStaffPicture: teamStaffPictureStoreImage,
        otherPicture: otherPictureStoreImage,
      });
      // setDisplayImage({...displayImage, coverPicture:coverPictureBlobImage,
      //   facadePicture:facadePictureBlobImage,
      //   interiorsPicture:interiorsPictureBlobImage,
      //   sittingPicture:sittingPictureBlobImage,
      //   teamStaffPicture:teamStaffPictureBlobImage,
      //   otherPicture:otherPictureBlobImage});
    }
  }, [outletStepThree]);

  const onSubmit = (data) => {
    const {
      description,
      coverPicture,
      facadeImage,
      interiorsImage,
      uploadSitting,
      uploadStaff,
      images,
    } = data;
    if (description?.length >= 50 && description?.length <= 200) {
      setLoader(true);
      const fd = new FormData();
      fd.append("aboutVendor", description);
      fd.append("outletId", outletId);

      let savedCoverPicture = true;
      let savedfacadePicture = true;
      let savedInteriorsPicture = true;
      let savedSittingPicture = true;
      let savedStaffPicture = true;
      let savedOtherPicture = true;

      let dispatchData;
      if (!displayImage2?.coverPicture) {
        if (coverPicture) {
          fd.append("coverPicture", coverPicture);
          dispatchData = {
            ...dispatchData,
            coverPicture: storeImage.coverPicture,
          };
        }
        savedCoverPicture = false;
      }

      if (!displayImage2?.facadePicture?.length) {
        if (facadeImage?.length) {
          for (let i = 0; i < facadeImage.length; i++) {
            fd.append("facadeImage", facadeImage[i]);
          }
          dispatchData = {
            ...dispatchData,
            facadeImage: storeImage.facadePicture,
          };
        }
        savedfacadePicture = false;
      }

      if (!displayImage2?.interiorsPicture?.length) {
        if (interiorsImage?.length) {
          for (let i = 0; i < interiorsImage.length; i++) {
            fd.append("interiorsImage", interiorsImage[i]);
          }
          dispatchData = {
            ...dispatchData,
            interiorsImage: storeImage.interiorsPicture,
          };
        }
        savedInteriorsPicture = false;
      }

      if (!displayImage2?.sittingPicture?.length) {
        if (uploadSitting?.length) {
          for (let i = 0; i < uploadSitting.length; i++) {
            fd.append("sittingImage", uploadSitting[i]);
          }
          dispatchData = {
            ...dispatchData,
            sittingImage: storeImage.sittingPicture,
          };
        }
        savedSittingPicture = false;
      }

      if (!displayImage2?.teamStaffPicture?.length) {
        if (uploadStaff?.length) {
          for (let i = 0; i < uploadStaff.length; i++) {
            fd.append("staffImage", uploadStaff[i]);
          }
          dispatchData = {
            ...dispatchData,
            staffImage: storeImage.teamStaffPicture,
          };
        }
        savedStaffPicture = false;
      }

      if (!displayImage2?.otherPicture?.length) {
        if (images?.length) {
          for (let i = 0; i < images.length; i++) {
            fd.append("otherImage", images[i]);
          }
          dispatchData = { ...dispatchData, image: storeImage.otherPicture };
        }
        savedOtherPicture = false;
      }

      dispatch(registerOutletStepThree(fd))
        .unwrap()
        .then((res) => {
          toast.success(res.message);
          dispatch(
            outletStepThreeSave({
              description: description,
              ...dispatchData,
              savedData: true,
              savedCoverPicture: true,
              savedfacadePicture: true,
              savedInteriorsPicture: true,
              savedSittingPicture: true,
              savedStaffPicture: true,
              savedOtherPicture: true,
              savedCoverPicture,
              savedfacadePicture,
              savedInteriorsPicture,
              savedSittingPicture,
              savedStaffPicture,
              savedOtherPicture,
            })
          );
          handleNextStep(4);
        })
        .catch((err) => {
          toast.error(err);
        });
    } else {
      setLoader(false);
      toast.error("Must be of minimum 50 and maximum 200 characters.");
    }
  };

  const handleCoverPicture = (files) => {
    setDisplayImage2({ ...displayImage2, coverPicture: "" });

    if (files?.length) {
      const file = files[0];
      const fileSize = file.size; // Size in bytes

      if (fileSize > maxSize) {
        toast.error(
          `File size exceeds maximum allowed size (${
            maxSize / 1024 / 1024
          } mb).`
        );

        return;
      }

      setDisplayImage({
        ...displayImage,
        coverPicture: URL.createObjectURL(file),
      });
      setStoreImage({ ...storeImage, coverPicture: files });
      setError("coverPicture", "");
      setValue("coverPicture", file);
      register("coverPicture", { required: false });
      setError("coverPicture", "");
    } else {
      setError("coverPicture", "");
      setDisplayImage({ ...displayImage, coverPicture: "" });
      setStoreImage({ ...storeImage, coverPicture: "" });
      setValue("coverPicture", "");
      register("coverPicture", { required: true });
    }
  };

  const handleFacadePicture = (file) => {
    console.log("file", file[0].size);
    setDisplayImage2({ ...displayImage2, facadePicture: [] });

    if (file[0]) {
      const fileSize = file[0].size.size; // Size in bytes
      const maxSize = 1024 * 1024; // 1 MB (example maximum size)

      if (fileSize > maxSize) {
        toast.error(
          `File size exceeds maximum allowed size (${
            maxSize / 1024 / 1024
          } mb).`
        );

        return; // Stop processing if file size exceeds the limit
      }

      const facadeP = URL.createObjectURL(file[0]);
      const facadeFile = file[0];

      setDisplayImage({ ...displayImage, facadePicture: [facadeP] });
      setStoreImage({ ...storeImage, facadePicture: [facadeFile] });
      setError("facadeImage", "");
      setValue("facadeImage", [facadeFile]);
      register("facadeImage", { required: false });
      setError("facadeImage", "");
    } else {
      setError("facadePicture", "Please Select an image.");
      setDisplayImage({ ...displayImage, facadePicture: [] });
      setStoreImage({ ...storeImage, facadePicture: [] });
      setValue("facadeImage", []);
      register("facadeImage", { required: true });
    }
  };

  const handleInteriorsPicture = (files) => {
    setDisplayImage2({ ...displayImage2, interiorsPicture: [] });

    if (files?.length > 0) {
      let interiorsP = [];
      let interiorsFile = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileSize = file.size;

        if (fileSize > maxSize) {
          // Display error message or take appropriate action
          toast.error(
            `File size exceeds maximum allowed size (${
              maxSize / 1024 / 1024
            } mb).`
          );

          return;
        }

        interiorsP.push(URL.createObjectURL(file));
        interiorsFile.push(file);
      }

      setDisplayImage({ ...displayImage, interiorsPicture: interiorsP });
      setStoreImage({ ...storeImage, interiorsPicture: files });

      setError("interiorsImage", "");
      setValue("interiorsImage", interiorsFile);
      register("interiorsImage", { required: false });
    } else {
      setError("interiorsImage", "Please Select at least one image.");
      setDisplayImage({ ...displayImage, interiorsPicture: [] });
      setStoreImage({ ...storeImage, interiorsPicture: [] });
      setValue("interiorsImage", []);
      register("interiorsImage", { required: true });
    }
  };

  const handleSittingPicture = (files) => {
    setDisplayImage2({ ...displayImage2, sittingPicture: [] });

    if (files?.length > 0) {
      let sittingP = [];
      let sittingFile = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileSize = file.size;

        if (fileSize > maxSize) {
          // Display error message or take appropriate action
          toast.error(
            `File size exceeds maximum allowed size (${
              maxSize / 1024 / 1024
            } mb).`
          );

          return;
        }

        sittingP.push(URL.createObjectURL(file));
        sittingFile.push(file);
      }

      setError("uploadSitting", "");
      setDisplayImage({ ...displayImage, sittingPicture: sittingP });
      setStoreImage({ ...storeImage, sittingPicture: files });
      setValue("uploadSitting", sittingFile);
      register("uploadSitting", { required: false });
    } else {
      setError("uploadSitting", "Please Select at least one image.");
      setDisplayImage({ ...displayImage, sittingPicture: [] });
      setStoreImage({ ...storeImage, sittingPicture: [] });
      setValue("uploadSitting", []);
      register("uploadSitting", { required: false });
    }
  };

  const handleTeamStaffPicture = (files) => {
    setDisplayImage2({ ...displayImage2, teamStaffPicture: [] });

    if (files?.length > 0) {
      let teamsP = [];
      let teamsFile = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileSize = file.size;

        if (fileSize > maxSize) {
          toast.error(
            `File size exceeds maximum allowed size (${
              maxSize / 1024 / 1024
            } mb).`
          );

          return;
        }

        teamsP.push(URL.createObjectURL(file));
        teamsFile.push(file);
      }

      // setError("uploadStaff", ""); // Clear any previous error
      setDisplayImage({ ...displayImage, teamStaffPicture: teamsP });
      setStoreImage({ ...storeImage, teamStaffPicture: files });
      setValue("uploadStaff", teamsFile);
    } else {
      // Handle case when no files are selected
      setDisplayImage({ ...displayImage, teamStaffPicture: [] });
      setStoreImage({ ...storeImage, teamStaffPicture: [] });
      setValue("uploadStaff", []);
    }
  };

  const handleOtherPicture = (files) => {
    setDisplayImage2({ ...displayImage2, otherPicture: [] });

    if (files?.length > 0) {
      let otherP = [];
      let otherFile = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileSize = file.size;

        if (fileSize > maxSize) {
          toast.error(
            `File size exceeds maximum allowed size (${
              maxSize / 1024 / 1024
            } mb).`
          );

          return;
        }

        otherP.push(URL.createObjectURL(file));
        otherFile.push(file);
      }

      // setError("images", ""); // Clear any previous error
      setDisplayImage({ ...displayImage, otherPicture: otherP });
      setStoreImage({ ...storeImage, otherPicture: files });
      setValue("images", otherFile);
    } else {
      // Handle case when no files are selected
      setDisplayImage({ ...displayImage, otherPicture: [] });
      setStoreImage({ ...storeImage, otherPicture: [] });
      setValue("images", []);
    }
  };

  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb
            title="Add/Edit Outlet"
            links={[
              { to: "/", name: "Home" },
              { to: "/total-outlets", name: "Outlets" },
            ]}
          />
        </div>
        <div className="col-md-12">
          <div className="ms-panel ms-panel-fh">
            <div className="ms-panel-header">
              <h6>{isEdit ? "Edit Outlet" : "Add Outlet"}</h6>
            </div>
            <div className="ms-panel-body">
              <form
                className="needs-validation"
                onSubmit={handleSubmit(onSubmit)}
                style={{ width: "100%" }}
              >
                {/* <p>Fill the Required Details of Person Incharge handling the Bookings and Guest Communications.</p> */}

                <div className="form-vendor-regi">
                  <div className="form-vendor">
                    <Row>
                      <Col md={12}>
                        <div className="form-group">
                          <label htmlFor="description">
                            Describe Your Restaurant (minimum of 50 characters
                            and max of 200 chars)
                            <span className="error">*</span>{" "}
                            {textCount ? `(${textCount})` : ""}
                          </label>
                          <div className="input-group">
                            <textarea
                              type="text"
                              // minLength={50}
                              // maxLength={200}
                              className={`form-control ${
                                touchedError
                                  ? errors.description?.type == "required" ||
                                    errors.description?.type == "pattern"
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                              id="description"
                              name="description"
                              {...register("description", {
                                required: true,
                                // pattern: /^[A-Za-z0-9 ]{50,200}$/gm,
                                // pattern: /^[A-Za-z0-9 !@#$%^&*()-_+=,'.]{50,200}$/gm,

                                // pattern: /^[A-Za-z ]{50,200}$/,
                                // pattern: /^[A-Za-z0-9 ]{50,200}$/gm,
                              })}
                              onChange={(e) => {
                                // formik.setFieldValue("description", action.target.value)
                                const length = e.target.value.length;
                                setTextCount(length);
                                setError("description", "");
                                setValue("description", e.target.value);
                              }}
                              placeholder="Description"
                            />

                            {errors.description?.type === "required" && (
                              <div className="invalid-feedback">
                                The description field is required.
                              </div>
                            )}
                            {errors.description?.type === "pattern" && (
                              <div className="invalid-feedback">
                                Must be of minimum 50 and maximum 200
                                characters.
                              </div>
                            )}
                          </div>
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="form-group ">
                          <label
                            htmlFor="foodLicence"
                            className="form-label customLabel"
                          >
                            Outlet Picture <span className="error">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              type="file"
                              id="coverPicture"
                              placeholder="Upload ID Proof"
                              name="coverPicture"
                              accept="image/*"
                              className={`uploadFile form-control ${
                                touchedError
                                  ? errors.coverPicture?.type === "required"
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                              onChange={(e) =>
                                handleCoverPicture(e?.target?.files)
                              }
                            />

                            <div>
                              {errors.coverPicture?.type === "required" && (
                                <div className="invalid-field">
                                  The cover picture field is required.
                                </div>
                              )}
                            </div>
                          </div>

                          {displayImage2?.coverPicture ? (
                            <div className="img-pos col-md-4">
                              <img
                                src={
                                  displayImage2?.coverPicture
                                    ? `${displayImage2?.coverPicture}`
                                    : ""
                                }
                                alt="preview"
                                style={{ width: "200px", height: "50px" }}
                              />
                            </div>
                          ) : displayImage?.coverPicture ? (
                            <div className="img-pos col-md-4">
                              <img
                                src={
                                  displayImage?.coverPicture
                                    ? `${displayImage?.coverPicture}`
                                    : ""
                                }
                                alt="preview"
                                style={{ width: "200px", height: "50px" }}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="form-group ">
                          <label htmlFor="facadeImage">
                            Outlet Façade <span className="error">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              type="file"
                              id="facadeImage"
                              placeholder="Upload Images"
                              name="facadeImage"
                              accept="image/*"
                              className={`uploadFile form-control ${
                                touchedError
                                  ? errors.facadeImage?.type === "required"
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                              style={{ color: "transparent" }}
                              // multiple

                              onChange={(e) =>
                                handleFacadePicture(e?.target?.files)
                              }
                            />
                            <div>
                              {errors.facadeImage?.type === "required" && (
                                <div className="invalid-field">
                                  The facade picture field is required.
                                </div>
                              )}
                            </div>
                          </div>

                          {displayImage2?.facadePicture?.length
                            ? displayImage2.facadePicture?.map((image) => (
                                <div className="img-pos col-md-4">
                                  <img
                                    src={image ? `${image}` : ""}
                                    alt="preview"
                                    style={{ width: "200px", height: "50px" }}
                                  />
                                </div>
                              ))
                            : displayImage?.facadePicture?.length
                            ? displayImage.facadePicture?.map((image) => (
                                <div className="img-pos col-md-4">
                                  <img
                                    src={image ? `${image}` : ""}
                                    alt="preview"
                                    style={{ width: "200px", height: "50px" }}
                                  />
                                </div>
                              ))
                            : ""}
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="form-group ">
                          <label htmlFor="interiorsImage">
                            Interiors / Décor
                          </label>
                          <div className="input-group">
                            <input
                              type="file"
                              id="interiorsImage"
                              placeholder="Upload Images"
                              name="interiorsImage"
                              accept="image/*"
                              className={`uploadFile form-control ${
                                touchedError
                                  ? errors.interiorsImage?.type === "required"
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                              style={{ color: "transparent" }}
                              multiple
                              onChange={(e) =>
                                handleInteriorsPicture(e?.target?.files)
                              }
                            />
                            <div>
                              {errors?.interiorsImage?.type === "required" && (
                                <div className="invalid-field">
                                  The interior picture field is required.
                                </div>
                              )}
                            </div>
                          </div>

                          {displayImage2?.interiorsPicture?.length
                            ? displayImage2.interiorsPicture.map((image) => (
                                <div className="img-pos col-md-4">
                                  <img
                                    src={image ? `${image}` : ""}
                                    alt="preview"
                                    style={{ width: "200px", height: "50px" }}
                                  />
                                </div>
                              ))
                            : displayImage?.interiorsPicture?.length
                            ? displayImage.interiorsPicture.map((image) => (
                                <div className="img-pos col-md-4">
                                  <img
                                    src={image ? `${image}` : ""}
                                    alt="preview"
                                    style={{ width: "200px", height: "50px" }}
                                  />
                                </div>
                              ))
                            : ""}
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="form-group ">
                          <label htmlFor="uploadSitting">
                            Sitting Arrangements
                          </label>
                          <div className="input-group">
                            <input
                              type="file"
                              id="uploadSitting"
                              placeholder="Upload Images"
                              name="uploadSitting"
                              accept="image/*"
                              className={`uploadFile form-control ${
                                touchedError
                                  ? errors?.uploadSitting?.type === "required"
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                              style={{ color: "transparent" }}
                              multiple
                              onChange={(e) =>
                                handleSittingPicture(e?.target?.files)
                              }
                            />
                            <div>
                              {errors?.uploadSitting?.type === "required" && (
                                <div className="invalid-field">
                                  The sitting picture field is required.
                                </div>
                              )}
                            </div>
                          </div>

                          {displayImage2?.sittingPicture?.length
                            ? displayImage2.sittingPicture.map((image) => (
                                <div className="img-pos col-md-4">
                                  <img
                                    src={image ? `${image}` : ""}
                                    alt="preview"
                                    style={{ width: "200px", height: "50px" }}
                                  />
                                </div>
                              ))
                            : displayImage?.sittingPicture?.length
                            ? displayImage.sittingPicture.map((image) => (
                                <div className="img-pos col-md-4">
                                  <img
                                    src={image ? `${image}` : ""}
                                    alt="preview"
                                    style={{ width: "200px", height: "50px" }}
                                  />
                                </div>
                              ))
                            : ""}
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="form-group ">
                          <label htmlFor="uploadStaff">Team / Staff</label>
                          <div className="input-group">
                            <input
                              type="file"
                              id="uploadStaff"
                              placeholder="Upload Images"
                              name="uploadStaff"
                              accept="image/*"
                              className={`uploadFile form-control`}
                              style={{ color: "transparent" }}
                              multiple
                              onChange={(e) =>
                                handleTeamStaffPicture(e?.target?.files)
                              }
                            />
                            <div></div>
                          </div>
                          {displayImage2?.teamStaffPicture?.length
                            ? displayImage2.teamStaffPicture.map((image) => (
                                <div className="img-pos col-md-4">
                                  <img
                                    src={image ? `${image}` : ""}
                                    alt="preview"
                                    style={{ width: "200px", height: "50px" }}
                                  />
                                </div>
                              ))
                            : displayImage?.teamStaffPicture?.length
                            ? displayImage.teamStaffPicture.map((image) => (
                                <div className="img-pos col-md-4">
                                  <img
                                    src={image ? `${image}` : ""}
                                    alt="preview"
                                    style={{ width: "200px", height: "50px" }}
                                  />
                                </div>
                              ))
                            : ""}
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="form-group ">
                          <label htmlFor="images">Other Image</label>
                          <div className="input-group">
                            <input
                              type="file"
                              id="images"
                              placeholder="Upload Images"
                              name="images"
                              accept="image/*"
                              className={`uploadFile form-control`}
                              style={{ color: "transparent" }}
                              multiple
                              onChange={(e) =>
                                handleOtherPicture(e?.target?.files)
                              }
                            />
                            <div></div>
                          </div>

                          {displayImage2?.otherPicture?.length
                            ? displayImage2.otherPicture.map((image) => (
                                <div className="img-pos col-md-4">
                                  <img
                                    src={image ? `${image}` : ""}
                                    alt="preview"
                                    style={{ width: "200px", height: "50px" }}
                                  />
                                </div>
                              ))
                            : displayImage?.otherPicture?.length
                            ? displayImage.otherPicture.map((image) => (
                                <div className="img-pos col-md-4">
                                  <img
                                    src={image ? `${image}` : ""}
                                    alt="preview"
                                    style={{ width: "200px", height: "50px" }}
                                  />
                                </div>
                              ))
                            : ""}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>

                {/* {isImageSelected && (
                <div className="error">{"please select all Images"}</div>
              )} */}
                <div className="row mt-4">
                  <div className="col-md-6">
                    <button
                      className="steps-btn float-left"
                      type="button"
                      onClick={() => {
                        handleNextStep(2);
                      }}
                    >
                      <i>&#8592;</i> Previous
                    </button>
                  </div>
                  <div className="col-md-6">
                    {loader ? (
                      <center>
                        <div
                          style={{
                            flex: 1,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Spinner
                            animation="border"
                            className="d-flex justify-content-center"
                          />
                        </div>
                      </center>
                    ) : (
                      <button
                        className="steps-btn float-right"
                        type="submit"
                        onClick={() => setTouchedError(true)}
                      >
                        Next
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepThree;
