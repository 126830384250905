import React, { Component } from 'react';
import Content from '../sections/Userprofile/Content';

class Userprofile extends Component {
    render() {
        return (
            <Content />
        );
    }
}

export default Userprofile;