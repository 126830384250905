import React,{useState, useEffect} from 'react'
import { Button, Col, Modal, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import loginLogo from "../assets/img/logo.png";
import useRequest from '../hooks/useRequest';

const ResetPassword = (props) => {
    const managerId = props?.location?.state?.managerId;

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError
    } = useForm();

    const dispatch                                     = useDispatch();
    const history                                       = useHistory();
    const { response, request }                         = useRequest();

    const [touchedError, setTouchedError]               = useState(false)
    const [isPasswordVisible, setIsPasswordVisible]     = useState({
                                                              newPassword:false,
                                                              confirmNewPassword:false
                                                          });

    useEffect(() => {
      if(!managerId){
        history.push("/login");
      }
    },[managerId])

    const onSubmit = (data) => {
      const { newPassword, confirmNewPassword } = data;

      if(newPassword != confirmNewPassword){
          setError("confirmNewPassword",{type:"manual"})
          return;
      }

      request("POST","generatePassword",{managerId,password:newPassword});
    };

    useEffect(() => {
      if(response){
        if(response.status == "success"){
          toast.success(response.message)
          history.push("/login")
        }
      }
    },[response])
  
    return (
        <div className="ms-body ms-primary-theme ms-logged-out">
            <main className="body-content">
            <div className="ms-content-wrapper ms-auth">
              <Row>
                  <Col md={5}>
                  <div className="ms-auth-bg" >
                  <img src={loginLogo} />
                  </div>
                  </Col>
                  <Col md={7}>
                  <div className="regi-form two-step">
                    <form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                      noValidate
                      
                    >
                      <h3>Reset Password</h3>
                      <p>Please enter Password to continue</p>

                      {/* password and repassword */}

                      <div className="form-row">
                        <div className="col-md-12 form-vendor-regi">
                          <label htmlFor="validationCustom04">Password<span className="error">*</span></label>
                          <div className="input-group">
                            <input type={isPasswordVisible.newPassword ? "text" : "password"} name="newPassword" id="newPassword" placeholder="New Password" 
                                  className={`form-control ${
                                    touchedError ? ( errors.newPassword ? "is-invalid" : "is-valid" ): "" 
                                  }`}
                                 {...register("newPassword", {
                                    required: true,
                                    pattern: /.{8,}/,
                                  })} />

                                  
                                <a href="javascript:void(0);" onClick={() => {
                                    const update = {...isPasswordVisible, newPassword : isPasswordVisible.newPassword ? false :true}
                                    setIsPasswordVisible(update)
                                    }}>
                                    {isPasswordVisible.newPassword? <i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}
                                </a>

                                {errors.newPassword?.type === "required" && (
                                    <div className="invalid-field">
                                        The password field is required.
                                    </div>
                                )}
                                 {errors.newPassword?.type === "pattern" && (
                                    <div className="invalid-field">
                                         Password must be 8 characters long.
                                    </div>
                                )}
                          </div>
                        </div>
                        <div className="col-md-12 form-vendor-regi">
                          <label htmlFor="validationCustom04">Re-Cofirm Password<span className="error">*</span></label>
                          <div className="input-group">
                            <input type={isPasswordVisible.confirmNewPassword ? "text" : "password"} name="confirmNewPassword" id="confirmNewPassword" placeholder="Confirm New Password" 
                                  className={`form-control ${
                                    touchedError ? ( errors.confirmNewPassword ? "is-invalid" : "is-valid" ): "" 
                                  }`}
                                 {...register("confirmNewPassword", {
                                    required: true,
                                    pattern: /.{8,}/,
                                  })} />

                                  
                                <a href="javascript:void(0);" onClick={() => {
                                    const update = {...isPasswordVisible, confirmNewPassword : isPasswordVisible.confirmNewPassword ? false :true}
                                    setIsPasswordVisible(update)
                                    }}>
                                    {isPasswordVisible.confirmNewPassword? <i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}
                                </a>
                                 {errors.confirmNewPassword?.type === "required" && (
                                    <div className="invalid-field">
                                        The re-confirm password field is required.
                                    </div>
                                )}
                                   {errors.confirmNewPassword?.type === "pattern" && (
                                    <div className="invalid-field">
                                         Re-confirm password must be 8 characters long.
                                    </div>
                                )}
                                 {errors.confirmNewPassword?.type === "manual" && (
                                    <div className="invalid-field">
                                        Password and re-confirm password does not match.
                                    </div>
                                )}
                          </div>
                          {/* <div className="error">{formik.errors.rePassword}</div> */}
                        </div>
                      </div>
                      <button
                        className="btn btn-primary mt-4 d-block w-100"
                        type="submit" onClick={() => setTouchedError(true)}
                      >
                        Reset
                      </button>
                      <p className="mb-0 mt-3 text-center">
                            Already have an account?{" "}
                            
                                <Link to="/login" className="btn-link">
                                    Login
                                </Link>
                            </p>
                    </form>
                  </div>
                </Col>
                </Row>
            </div>
            </main>
        </div>
    )
}

export default ResetPassword
