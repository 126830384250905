// import React, { useEffect, useState } from "react";
// import StepOne from "./StepOne";
// import StepTwo from "./StepTwo";
// import StepThree from "./StepThree";
// import StepFour from "./StepFour";
// import StepFive from "./StepFive";
// import StepSix from "./StepSix";

// const Registration = () => {
//   const [nextStep, setNextStep] = useState(2);
//   const [managerId, setManagerId] = useState('');

//   useEffect(() => {
//     const a = localStorage.getItem("pendingStep");
//     setNextStep(Number(a));
//   }, []);

//   const renderContent = () => {
//     switch (nextStep) {
//       case 1:
//         return (
//           <StepOne
//             setNextStep={setNextStep}
//             nextStep={nextStep}
//             handleNextStep={handleNextStep}
//             handleManagerId={handleManagerId}
//           />
//         );
//       case 2:
//         return (
//           <StepTwo
//             setNextStep={setNextStep}
//             nextStep={nextStep}
//             handleNextStep={handleNextStep}
//             managerId={managerId}
//           />
//         );
//       case 3:
//         return (
//           <StepThree
//             setNextStep={setNextStep}
//             nextStep={nextStep}
//             handleNextStep={handleNextStep}
//             managerId={managerId}
//           />
//         );
//       case 4:
//         return (
//           <StepFour
//             setNextStep={setNextStep}
//             nextStep={nextStep}
//             handleNextStep={handleNextStep}
//             managerId={managerId}
//           />
//         );
//       case 5:
//         return (
//           <StepFive
//             setNextStep={setNextStep}
//             nextStep={nextStep}
//             handleNextStep={handleNextStep}
//             managerId={managerId}
//           />
//         );
//       case 6:
//         return (
//           <StepSix
//             spendingSttNextStep={setNextStep}
//             nextStep={nextStep}
//             handleNextStep={handleNextStep}
//             managerId={managerId}
//           />
//         );
//       default:
//         return;
//     }
//   };
//   const handleNextStep = (val) => {
//     setNextStep(val);
//   };

//   const handleManagerId = (val) => {
//     setManagerId(val);
//   };
//   return (
//     <div className="ms-body ms-primary-theme ms-logged-out">
//       <main className="body-content">
//         {/* <Topnavigation /> */}
//         {renderContent()}
//       </main>
//     </div>
//   );
// };

// export default Registration;

import React, { useEffect, useState } from "react";
import StepOne from "../RegistrationSteps/StepOne";
import StepTwo from "../RegistrationSteps/StepTwo";
import StepThree from "../RegistrationSteps/StepThree";
import StepFour from "../RegistrationSteps/StepFour";
import StepFive from "../RegistrationSteps/StepFive";
import StepSix from "../RegistrationSteps/StepSix";

const Registration = () => {
  const [nextStep, setNextStep] = useState(1);
  const [managerId, setManagerId] = useState();

  const renderContent = () => {
    switch (nextStep) {
      case 1:
        return (
          <StepOne
            setNextStep={setNextStep}
            nextStep={nextStep}
            handleNextStep={handleNextStep}
            handleManagerId={handleManagerId}
            setManagerId={setManagerId}
          />
        );

      case 2:
        return (
          <StepTwo
            setNextStep={setNextStep}
            nextStep={nextStep}
            handleNextStep={handleNextStep}
            managerId={managerId}
          />
        );
      case 3:
        return (
          <StepThree
            setNextStep={setNextStep}
            nextStep={nextStep}
            handleNextStep={handleNextStep}
            managerId={managerId}
          />
        );
      case 4:
        return (
          <StepFour
            setNextStep={setNextStep}
            nextStep={nextStep}
            handleNextStep={handleNextStep}
            managerId={managerId}
          />
        );
      case 5:
        return (
          <StepFive
            setNextStep={setNextStep}
            nextStep={nextStep}
            handleNextStep={handleNextStep}
            managerId={managerId}
          />
        );
      case 6:
        return (
          <StepSix
            spendingSttNextStep={setNextStep}
            nextStep={nextStep}
            handleNextStep={handleNextStep}
            managerId={managerId}
          />
        );
      default:
        return;
    }
  };
  const handleNextStep = (val) => {
    setNextStep(val);
  };

  const handleManagerId = (val) => {
    setManagerId(val);
  };
  return (
    <div className="ms-body ms-primary-theme ms-logged-out">
      <main className="body-content">
        {/* <Topnavigation /> */}
        {renderContent()}
      </main>
    </div>
  );
};

export default Registration;
