import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Breadcrumb = ({ title, links }) => {
  const { verifyStatus } = useSelector(state => state.register);
  return (
    <nav aria-label="breadcrumb" style={{ display: !verifyStatus ? "none" : "" }}>
      <ol className="breadcrumb pl-0">
        {links.length > 0 &&
          links.map((link, index) => (
            <li key={index} className="breadcrumb-item">
              <Link to={link.to}><i className="material-icons">home</i> {link.name}{" "}</Link>
            </li>
          ))}
        <li className="breadcrumb-item active" aria-current="page">{title}</li>
      </ol>
    </nav >
  );
};

export default Breadcrumb;
