import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal, Col } from "react-bootstrap";

import { BASE_URL } from "../redux/services/apiPath";
import { toast } from "react-toastify";
import { downloadAllData, downloadExcel, downloadExcel1 } from "../util/fn";
const UpdateCountModal = (props) => {
  const [count, setCount] = useState("");
  const [token, setToken] = useState(localStorage.getItem("user"));

  useEffect(() => {
    setCount(props.count);
  }, [props.count]);
  const handleGetExcel = async () => {
    const data = {
      vendorId: localStorage.getItem("vendorId"),
      totalOutlet: count,
    };
    const res = await axios.post(
      `${BASE_URL}outlet/updateMaxOutlet`,

      data,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    if (res?.data?.status === "success") {
      toast.success("Updated");
      props.onHide();
    }
  };

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Count
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="row mb-5">
              <Col md={6}>
                <div className="startDate">
                  <input
                    type="text"
                    className="form-control"
                    id="count"
                    name="count"
                    placeholder="Eg: 5"
                    value={count}
                    onChange={(e) => {
                      setCount(e.target.value);
                    }}
                  />
                </div>
              </Col>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="excle-dowm w-25"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              handleGetExcel();
            }}
          >
            Update count
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UpdateCountModal;
