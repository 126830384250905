// import { useState, useEffect } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import axios from "axios";
// import { useHistory } from "react-router-dom";
// import { toast } from "react-toastify";

// // import { logout, updateLoading } from "../store/auth/action";
// import { API_URL2 } from "../util/constant";

// const BACKEND_URL = API_URL2;

// const useRequest = (notShowLoader, isSubmitting) => {
//   const [error, setError] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [response, setResponse] = useState(null);

//   const token = localStorage?.getItem('user')?.token;

//   const history = useHistory();
//   const dispatch = useDispatch();

// //   useEffect(() => {
// //     if (!notShowLoader) {
// //       dispatch(updateLoading({ loading }));
// //     }
// //   }, [loading]);

//   const startFetching = () => {
//     setResponse(null);
//     setLoading(true);
//     setError(null);
//   };

//   const clear = () => {
//     setResponse(null);
//     setError(null);
//   };

//   const fetchedData = () => {
//     setLoading(false);
//     setError(null);
//   };

//   const requestData = (method, url, data) => {
//     let config;

//     if (token) {
//       config = {
//         method,
//         url: `${BACKEND_URL}/${url}`,
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//         data,
//       };
//     } else {
//       config = {
//         method,
//         url: `${BACKEND_URL}/${url}`,
//         data,
//       };
//     }

//     startFetching();

//     axios(config)
//       .then((res) => {
//         fetchedData();
//         setResponse(res.data);
//       })
//       .catch((err) => {
//         fetchedData();
//         if (err.response) {
//           if (err.response.status === 401) {
//             // dispatch(logout());
//           } else if (err.response.status === 404) {
//             history.push("/404");
//           } else {
//             if (isSubmitting) {
//               setResponse(err.response.data);
//               setError(err.response.data);
//             } else {
//               toast.error(err.response.data.message);
//               setError(err.response.data.message);
//             }
//           }
//         } else if (err.request) {
//           toast.error("Slow Network Speed. Try Again later.");
//         } else {
//           toast.error("Oops!! Unusual error occurred");
//         }
//       });
//   };

//   return {
//     loading,
//     error,
//     request: requestData,
//     clear,
//     response,
//     setError,
//   };
// };

// export default useRequest;


import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

// import { logout, updateLoading } from "../store/auth/action";
import { API_URL2 } from "../util/constant";

const BACKEND_URL = API_URL2;

const useRequest = (notShowLoader, isSubmitting) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);

  let token;
  if(localStorage.getItem('user')){
    token = localStorage.getItem('user');
  }

  const history = useHistory();
  const dispatch = useDispatch();

//   useEffect(() => {
//     if (!notShowLoader) {
//       dispatch(updateLoading({ loading }));
//     }
//   }, [loading]);

  const startFetching = () => {
    setResponse(null);
    // setLoading(true);
    setError(null);
  };

  const clear = () => {
    setResponse(null);
    setError(null);
  };

  const fetchedData = () => {
    setLoading(false);
    setError(null);
  };

  const requestData = (method, url, data) => {
    let config;

    if (token) {
      config = {
        method,
        url: `${BACKEND_URL}/${url}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data,
      };
    } else {
      config = {
        method,
        url: `${BACKEND_URL}/${url}`,
        data,
      };
    }

    startFetching();

    axios(config)
      .then((res) => {
        fetchedData();
        setResponse(res.data);
      })
      .catch((err) => {
        fetchedData();
        if (err?.response) {
          if (err?.response?.status === 401) {
            // dispatch(logout());
          } else if (err?.response?.status === 404) {
            history.push("/404");
          } else {
            if (isSubmitting) {
              setResponse(err?.response?.data);
              setError(err.response.data);
            } else {
              toast.error(err?.response?.data?.message);
              setError(err?.response?.data?.message);
            }
          }
        } else if (err.request) {
          toast.error("Slow Network Speed. Try Again later.");
        } else {
          toast.error("Oops!! Unusual error occurred");
        }
      });
  };

  return {
    loading,
    error,
    request: requestData,
    clear,
    response,
    setError,
  };
};

export default useRequest;
