import React, { Component } from 'react';
import Gridcontent from '../sections/Discountgrid/Gridcontent'

class Discountgrid extends Component {
    render() {
        return (
            <>
                <Gridcontent />
            </>
        );
    }
}

export default Discountgrid;