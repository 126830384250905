import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { FaFilePdf } from "react-icons/fa6";

import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import loginLogo from "../../assets/img/logo.png";
import {
  stepOneSave,
  stepTwoSave,
  stepThreeSave,
  stepFourSave,
  stepFiveSave,
  stepSixSave,
} from "../../redux/slices/SaveDetails";
import { registerStepSix } from "../../redux/slices/Registration";
import { maxSize } from "../../constants";
import axios from "axios";

const StepSix = ({ nextStep, handleNextStep }) => {
  const [loader, setLoader] = useState(false);
  const [isPdf1, setPdf1] = useState(false);
  const [isPdf2, setPdf2] = useState(false);
  const [isPdf3, setPdf3] = useState(false);
  const [isPdf4, setPdf4] = useState(false);
  const [isPdf5, setPdf5] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { vendorId } = useSelector((state) => state.register);
  const { stepSix, stepTwo } = useSelector((state) => state.saveDetails);

  const [isBar, setIsBar] = useState(false);
  const [touchedError, setTouchedError] = useState(false);
  const [displayImage, setDisplayImage] = useState({
    foodLicence: "",
    barLicence: "",
    tanCertificate: "",
    gstCertificate: "",
    panCertificate: "",
  });
  const [storeImage, setStoreImage] = useState({
    foodLicence: [],
    barLicence: [],
    tanCertificate: [],
    gstCertificate: [],
    panCertificate: [],
  });
  console.log("storeImage", storeImage);
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
    setValue,
  } = useForm();

  useEffect(() => {
    register("foodLicence", { required: false });
    register("tanCertificate", { required: false });
    register("gstCertificate", { required: false });
    register("panCertificate", { required: false });
  }, []);

  const onSubmit = (data) => {
    setLoader(true);
    const {
      foodLicenceNumber,
      barLicenceNumber,
      tanCertificateNumber,
      gstCertificateNumber,
      panCertificateNumber,
      foodLicence,
      barLicence,
      tanCertificate,
      gstCertificate,
      panCertificate,
    } = data;
    localStorage.removeItem("services");

    let fd = new FormData();
    fd.append("foodLicence", foodLicence);
    fd.append("foodLicenceNumber", foodLicenceNumber);
    fd.append("barLicence", barLicence);
    fd.append("barLicenceNumber", barLicenceNumber);
    fd.append("gstCertificate", gstCertificate);
    fd.append("gstCertificateNumber", gstCertificateNumber);
    fd.append("tanCertificate", tanCertificate);
    fd.append("tanCertificateNumber", tanCertificateNumber);
    fd.append("panCertificate", panCertificate);
    fd.append("panCertificateNumber", panCertificateNumber);
    fd.append("vendorId", vendorId);

    dispatch(registerStepSix(fd))
      .unwrap()
      .then(async (res) => {
        // toast.success(res.message);
        const data = {
          mobile: "+91 " + res?.data?.mobile,
          status: "success",
        };
        await axios.post(
          `https://api.quickreply.ai/webhook/company/W7CmSYhFotkieadds_c/key/LdZ8ACsZEq5zPN2N7`,
          data
        );
        Swal.fire({
          title: "",
          html: `
    <p>Dear Restaurant Partner,</p>
    <p>Your Primary Registration has been <span style="color: #3ea99f;">Completed Successfully.</span></p>
    <p>To Get Live on App, Now Login Your Account And Complete <span style="color: #3ea99f;"> Bank Details, Menus, Best Discounts, Promotions, Reward Program</p>
 </span> `,
          icon: "success",
          confirmButtonText: "Login",
          confirmButtonColor: "#3ea99f",
        }).then(() => {
          dispatch(stepOneSave({}));
          dispatch(stepTwoSave({}));
          dispatch(stepThreeSave({}));
          dispatch(stepFourSave({}));
          dispatch(stepFiveSave({}));
          // dispatch(stepSixSave(fd));
          history.push("/login");
        });
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err);
      });
  };

  const handleFoodPicture = (files) => {
    if (files?.length) {
      const file = files[0];
      const fileSize = file.size;
      const pdfType = "application/pdf";
      const imageTypes = ["image/jpeg", "image/png"];

      // Check file type
      if (![pdfType, ...imageTypes].includes(file.type)) {
        toast.error(
          "Invalid file type. Please upload a PDF, JPEG, or PNG file."
        );
        return; // Stop processing if file type is not allowed
      }

      // Check file size
      if (fileSize > maxSize) {
        toast.error(
          `File size exceeds maximum allowed size (${
            maxSize / 1024 / 1024
          } MB).`
        );
        return;
      }

      const isPdf = file.type === pdfType;
      if (isPdf) {
        setPdf1(true);
      } else {
        setPdf1(false);
      }

      setDisplayImage({
        ...displayImage,
        foodLicence: URL.createObjectURL(file),
      });
      setStoreImage({ ...storeImage, foodLicence: files });

      setError("foodLicence", "");
      setValue("foodLicence", file);
      register("foodLicence", { required: false });
    } else {
      setError("foodLicence", "");
      setDisplayImage({ ...displayImage, foodLicence: "" });
      setStoreImage({ ...storeImage, foodLicence: "" });
      setValue("foodLicence", "");
      register("foodLicence", { required: false });
    }
  };

  const handleBarPicture = (files) => {
    if (files?.length) {
      const file = files[0];
      const fileSize = file.size; // Size in bytes
      const pdfType = "application/pdf";
      const imageTypes = ["image/jpeg", "image/png"];

      if (![pdfType, ...imageTypes].includes(file.type)) {
        toast.error(
          "Invalid file type. Please upload a PDF, JPEG, or PNG file."
        );
        return;
      }

      // Check file size
      if (fileSize > maxSize) {
        toast.error(
          `File size exceeds maximum allowed size (${
            maxSize / 1024 / 1024
          } MB).`
        );
        return;
      }

      const isPdf = file.type === pdfType;
      if (isPdf) {
        setPdf2(true);
      } else {
        setPdf3(false);
      }
      setDisplayImage({
        ...displayImage,
        barLicence: URL.createObjectURL(file),
      });
      setStoreImage({ ...storeImage, barLicence: file });
      setValue("barLicence", file);

      if (isBar) {
        register("barLicence", { required: false });
      }

      setError("barLicence", "");
    } else {
      setDisplayImage({ ...displayImage, barLicence: "" });
      setStoreImage({ ...storeImage, barLicence: "" });
      setValue("barLicence", "");
      setError("barLicence", "");

      if (isBar) {
        register("barLicence", { required: false });
      }
    }
  };

  const handleTanPicture = (files) => {
    if (files?.length) {
      const file = files[0];
      const fileSize = file.size; // Size in bytes
      // 1 MB (example maximum size)

      const pdfType = "application/pdf";
      const imageTypes = ["image/jpeg", "image/png"];

      // Check file type
      if (![pdfType, ...imageTypes].includes(file.type)) {
        toast.error(
          "Invalid file type. Please upload a PDF, JPEG, or PNG file."
        );
        return; // Stop processing if file type is not allowed
      }

      // Check file size
      if (fileSize > maxSize) {
        toast.error(
          `File size exceeds maximum allowed size (${
            maxSize / 1024 / 1024
          } MB).`
        );
        return;
      }

      const isPdf = file.type === pdfType;
      if (isPdf) {
        setPdf3(true);
      } else {
        setPdf3(false);
      }
      setDisplayImage({
        ...displayImage,
        tanCertificate: URL.createObjectURL(file),
      });
      setStoreImage({ ...storeImage, tanCertificate: file });

      setError("tanCertificate", "");
      setValue("tanCertificate", file);
      register("tanCertificate", { required: false });
    } else {
      setError("tanCertificate", "");
      setDisplayImage({ ...displayImage, tanCertificate: "" });
      setStoreImage({ ...storeImage, tanCertificate: "" });
      setValue("tanCertificate", "");
      register("tanCertificate", { required: false });
    }
  };

  const handleGstinPicture = (files) => {
    if (files?.length) {
      const file = files[0];
      const fileSize = file.size; // Size in bytes
      const pdfType = "application/pdf";
      const imageTypes = ["image/jpeg", "image/png"];

      // Check file type
      if (![pdfType, ...imageTypes].includes(file.type)) {
        toast.error(
          "Invalid file type. Please upload a PDF, JPEG, or PNG file."
        );
        return; // Stop processing if file type is not allowed
      }

      // Check file size
      if (fileSize > maxSize) {
        toast.error(
          `File size exceeds maximum allowed size (${
            maxSize / 1024 / 1024
          } MB).`
        );
        return;
      }

      const isPdf = file.type === pdfType;
      if (isPdf) {
        setPdf4(true);
      } else {
        setPdf4(false);
      }

      setDisplayImage({
        ...displayImage,
        gstCertificate: URL.createObjectURL(file),
      });
      setStoreImage({ ...storeImage, gstCertificate: files });

      setError("gstCertificate", "");
      setValue("gstCertificate", file);
      register("gstCertificate", { required: false });
    } else {
      setError("gstCertificate", "");
      setDisplayImage({ ...displayImage, gstCertificate: "" });
      setStoreImage({ ...storeImage, gstCertificate: "" });
      setValue("gstCertificate", "");
      register("gstCertificate", { required: false });
    }
  };

  const handlePanPicture = (files) => {
    if (files?.length) {
      const file = files[0];
      const fileSize = file.size; // Size in bytes
      const pdfType = "application/pdf";
      const imageTypes = ["image/jpeg", "image/png"];

      // Check file type
      if (![pdfType, ...imageTypes].includes(file.type)) {
        toast.error(
          "Invalid file type. Please upload a PDF, JPEG, or PNG file."
        );
        return; // Stop processing if file type is not allowed
      }

      // Check file size
      if (fileSize > maxSize) {
        toast.error(
          `File size exceeds maximum allowed size (${
            maxSize / 1024 / 1024
          } MB).`
        );
        return;
      }

      const isPdf = file.type === pdfType;
      if (isPdf) {
        setPdf5(true);
      } else {
        setPdf5(false);
      }

      setDisplayImage({
        ...displayImage,
        panCertificate: URL.createObjectURL(file),
      });
      setStoreImage({ ...storeImage, panCertificate: files });

      setError("panCertificate", "");
      setValue("panCertificate", file);
      register("panCertificate", { required: false });
    } else {
      setError("panCertificate", "");
      setDisplayImage({ ...displayImage, panCertificate: "" });
      setStoreImage({ ...storeImage, panCertificate: "" });
      setValue("panCertificate", "");
      register("panCertificate", { required: false });
    }
  };

  useEffect(() => {
    if (stepTwo) {
      if (
        stepTwo?.vendorSubCategory?.length > 0 &&
        stepTwo?.vendorSubCategory.find((obj) => obj.label == "Bar")
      ) {
        setIsBar(false);
        register("barLicence", { required: false });
      } else {
        setIsBar(false);
        register("barLicence", { required: false });
      }
    }
  }, [stepTwo]);
  return (
    <div className="ms-content-wrapper ms-auth">
      <div className="regi-section">
        <Row>
          <Col md={5}>
            <div className="ms-auth-bg">
              <img src={loginLogo} />
            </div>
          </Col>

          <Col md={7}>
            <div className="regi-form">
              <form
                className="needs-validation"
                onSubmit={handleSubmit(onSubmit)}
                style={{ width: "100%" }}
              >
                <h3>
                  Registration : Step <span>{nextStep} of 6</span>
                </h3>
                <p>Please enter information to continue</p>

                <div className="form-vendor-regi">
                  <div className="form-vendor">
                    <Row>
                      <Col md={6}>
                        <div className="form-group ">
                          <label
                            htmlFor="foodLicence"
                            className="form-label customLabel"
                          >
                            Food License Number
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              id="foodLicenceNumber"
                              placeholder="Enter Food License  Number"
                              name="foodLicenceNumber"
                              {...register("foodLicenceNumber", {
                                required: false,
                              })}
                              className={`form-control `}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div
                          className="form-group"
                          style={{
                            marginTop: "25px",
                          }}
                        >
                          <div className="input-group">
                            <input
                              type="file"
                              id="foodLicence"
                              placeholder="Upload ID Proof"
                              name="foodLicence"
                              // //accept="image/*"
                              className={`uploadFile form-control`}
                              onChange={(e) =>
                                handleFoodPicture(e?.target?.files)
                              }
                            />
                          </div>

                          {isPdf1 ? (
                            <FaFilePdf fontSize={"35px"} color="red" />
                          ) : (
                            displayImage?.foodLicence && (
                              <div className="img-pos col-md-4">
                                <img
                                  src={
                                    displayImage?.foodLicence
                                      ? `${displayImage?.foodLicence}`
                                      : ""
                                  }
                                  alt="preview"
                                  style={{ width: "200px", height: "50px" }}
                                />
                              </div>
                            )
                          )}
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="form-group">
                          <label
                            htmlFor="barLicenceNumber"
                            className="form-label customLabel"
                          >
                            Bar License (if registered into Bar category or
                            opted serving beverages and liquor) isBar
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              id="barLicenceNumber"
                              placeholder="Enter Bar License  Number"
                              name="barLicenceNumber"
                              {...register("barLicenceNumber", {
                                required: false,
                              })}
                              className={`form-control`}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div
                          className="form-group"
                          style={{
                            marginTop: "40px",
                          }}
                        >
                          <div className="input-group">
                            <input
                              type="file"
                              id="barLicence"
                              name="barLicence"
                              //accept="image/*"
                              className={`uploadFile form-control `}
                              onChange={(e) =>
                                handleBarPicture(e?.target?.files)
                              }
                            />
                          </div>

                          {isPdf2 ? (
                            <FaFilePdf fontSize={"35px"} color="red" />
                          ) : (
                            displayImage?.barLicence && (
                              <div className="img-pos col-md-4">
                                <img
                                  src={
                                    displayImage?.barLicence
                                      ? `${displayImage?.barLicence}`
                                      : ""
                                  }
                                  alt="preview"
                                  style={{ width: "200px", height: "50px" }}
                                />
                              </div>
                            )
                          )}
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="form-group">
                          <label
                            htmlFor="tanCertificateNumber"
                            className="form-label customLabel"
                          >
                            Company TAN Number
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              id="tanCertificateNumber"
                              placeholder="Enter TAN Number"
                              name="tanCertificateNumber"
                              {...register("tanCertificateNumber", {
                                required: false,
                              })}
                              className={`form-control 
                                `}
                            />
                          </div>
                        </div>
                      </Col>

                      <Col md={6}>
                        <div
                          className="form-group"
                          style={{
                            marginTop: "25px",
                          }}
                        >
                          <div className="input-group">
                            <input
                              type="file"
                              id="tanCertificate"
                              placeholder="Upload ID Proof"
                              name="tanCertificate"
                              //accept="image/*"
                              className={`uploadFile form-control `}
                              onChange={(e) =>
                                handleTanPicture(e?.target?.files)
                              }
                            />
                          </div>

                          {isPdf3 ? (
                            <FaFilePdf fontSize={"35px"} color="red" />
                          ) : (
                            displayImage?.tanCertificate && (
                              <div className="img-pos col-md-4">
                                <img
                                  src={
                                    displayImage?.tanCertificate
                                      ? `${displayImage?.tanCertificate}`
                                      : ""
                                  }
                                  alt="preview"
                                  style={{ width: "200px", height: "50px" }}
                                />
                              </div>
                            )
                          )}
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="form-group">
                          <label
                            htmlFor="gstCertificateNumber"
                            className="form-label customLabel"
                          >
                            Company Gstin Number{" "}
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              id="gstCertificateNumber"
                              placeholder="Enter GST Number"
                              name="gstCertificateNumber"
                              {...register("gstCertificateNumber", {
                                required: false,
                              })}
                              className={`form-control`}
                            />
                          </div>
                        </div>
                      </Col>

                      <Col md={6}>
                        <div
                          className="form-group"
                          style={{
                            marginTop: "25px",
                          }}
                        >
                          <div className="input-group">
                            <input
                              type="file"
                              id="gstCertificate"
                              placeholder="Upload ID Proof"
                              name="gstCertificate"
                              //accept="image/*"
                              className={`uploadFile form-control `}
                              onChange={(e) =>
                                handleGstinPicture(e?.target?.files)
                              }
                            />

                            <div></div>
                          </div>

                          {isPdf4 ? (
                            <FaFilePdf fontSize={"35px"} color="red" />
                          ) : (
                            displayImage?.gstCertificate && (
                              <div className="img-pos col-md-4">
                                <img
                                  src={
                                    displayImage?.gstCertificate
                                      ? `${displayImage?.gstCertificate}`
                                      : ""
                                  }
                                  alt="preview"
                                  style={{ width: "200px", height: "50px" }}
                                />
                              </div>
                            )
                          )}
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="form-group">
                          <label
                            htmlFor="panCertificateNumber"
                            className="form-label customLabel"
                          >
                            PAN
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              id="panCertificateNumber"
                              placeholder="Enter PAN Number"
                              name="panCertificateNumber"
                              {...register("panCertificateNumber", {
                                required: false,
                              })}
                              className={`form-control`}
                            />
                          </div>
                        </div>
                      </Col>

                      <Col md={6}>
                        <div
                          className="form-group"
                          style={{
                            marginTop: "25px",
                          }}
                        >
                          <div className="input-group">
                            <input
                              type="file"
                              id="panCertificate"
                              placeholder="Upload ID Proof"
                              name="panCertificate"
                              //accept="image/*"
                              className={`uploadFile form-control `}
                              onChange={(e) =>
                                handlePanPicture(e?.target?.files)
                              }
                            />
                          </div>

                          {isPdf5 ? (
                            <FaFilePdf fontSize={"35px"} color="red" />
                          ) : (
                            displayImage?.panCertificate && (
                              <div className="img-pos col-md-4">
                                <img
                                  src={
                                    displayImage?.panCertificate
                                      ? `${displayImage?.panCertificate}`
                                      : ""
                                  }
                                  alt="preview"
                                  style={{ width: "200px", height: "50px" }}
                                />
                              </div>
                            )
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <button
                      className="steps-btn float-left"
                      type="button"
                      onClick={() => handleNextStep(5)}
                    >
                      <i>&#8592;</i> Previous
                    </button>
                  </div>
                  <div className="col-md-6">
                    {loader ? (
                      <center>
                        <div
                          style={{
                            flex: 1,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Spinner
                            animation="border"
                            className="d-flex justify-content-center"
                          />
                        </div>
                      </center>
                    ) : (
                      <button
                        className="steps-btn"
                        type="submit"
                        onClick={() => setTouchedError(true)}
                      >
                        Register <i>&#8594;</i>
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default StepSix;
