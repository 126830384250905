import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import reportsApi from "../services/reportApis";
export const getBusinessReports = createAsyncThunk(
    "report/getBusinessReports",
    async (props, thunkAPI) => {
        try {
            const response = await reportsApi.getBusinessReportsApi(props);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const getBookingReports = createAsyncThunk(
    "report/getBookingReports",
    async (props, thunkAPI) => {
        try {
            const response = await reportsApi.getBookingReportsApi(props);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const getRevenueReports = createAsyncThunk(
    "report/getRevenueReports",
    async (props, thunkAPI) => {
        try {
            const response = await reportsApi.getRevenueReportsApi(props);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const getTrendsReports = createAsyncThunk(
    "report/getTrendsReports",
    async (props, thunkAPI) => {
        try {
            const response = await reportsApi.getTrendsReportsApi(props);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const addCompetitor = createAsyncThunk(
    "report/addCompetitor",
    async (props, thunkAPI) => {
        try {
            const response = await reportsApi.addCompetitorApi(props);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const getCompetitors = createAsyncThunk(
    "report/getCompetitors",
    async (props, thunkAPI) => {
        try {
            const response = await reportsApi.getCompetitorsApi(props);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
const reportsSlice = createSlice({
    name: "report",
    initialState: {
        businessReports: [],
        businessData: null,
        bookingReports: [],
        bookingData: null,
        revenueReports: [],
        revenueData: [],
        trendsReports: {}
    },
    extraReducers: {
        [getBusinessReports.fulfilled]: (state, action) => {
            state.businessData = action.payload;
            state.businessReports = action.payload?.data;
        },
        [getBusinessReports.rejected]: (state, action) => {
            state.businessReports = [];
            state.businessData = null;
        },
        [getBookingReports.fulfilled]: (state, action) => {
            state.bookingData = action.payload;
            console.log(action.payload?.data);
            state.bookingReports = action.payload?.data;
        },
        [getBookingReports.rejected]: (state, action) => {
            state.bookingReports = [];
            state.bookingData = null;
        },
        [getRevenueReports.fulfilled]: (state, action) => {
            state.revenueData = action.payload;
            state.revenueReports = action.payload.data;
        },
        [getRevenueReports.rejected]: (state, action) => {
            state.revenueReports = [];
            state.revenueData = null;
        },
        [getTrendsReports.fulfilled]: (state, action) => {
            state.trendsReports = action.payload.data;
        },
        [getTrendsReports.rejected]: (state, action) => {
            state.trendsReports = {};
        }
    }
}
);
const { reducer } = reportsSlice;
export default reducer;



