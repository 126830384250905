import React, { useState } from "react";
import Sidenavigation from "../layouts/Sidenavigation";
import StepOne from "./Auth/RegisterationSteps/StepOne";
import StepTwo from "./Auth/RegisterationSteps/StepTwo";
import StepThree from "./Auth/RegisterationSteps/StepThree";
import StepFour from "./Auth/RegisterationSteps/StepFour";
import StepFive from "./Auth/RegisterationSteps/StepFive";
import StepSix from "./Auth/RegisterationSteps/StepSix";
// import StepOne from './Auth/Steps/StepOne';
// import StepTwo from './Auth/Steps/StepTwo';
// import StepThree from './Auth/Steps/StepThree';
// import StepFive from "./Auth/Steps/StepFive";
// import StepFour from "./Auth/Steps/StepFour";

const Defaultregister = () => {
  const [nextStep, setNextStep] = useState(1);
  const renderContent = () => {
    switch (nextStep) {
      case 1:
        return <StepOne setNextStep={setNextStep} nextStep={nextStep} />;
      case 2:
        return <StepTwo setNextStep={setNextStep} nextStep={nextStep} />;
      case 3:
        return <StepThree setNextStep={setNextStep} nextStep={nextStep} />;
      case 4:
        return <StepFour setNextStep={setNextStep} nextStep={nextStep} />;
      case 5:
        return <StepFive setNextStep={setNextStep} nextStep={nextStep} />;
      case 6:
        return <StepSix setNextStep={setNextStep} nextStep={nextStep} />;
      default:
        return;
    }
  };
  return (
    <div className="ms-body ms-primary-theme ms-logged-out">
      <Sidenavigation />
      <main className="body-content">
        {/* <Topnavigation /> */}
        {renderContent()}
      </main>
    </div>
  );
};

export default Defaultregister;
