import React, { useState, useEffect } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import loginLogo from "../assets/img/logo.png";
import useRequest from "../hooks/useRequest";

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const dispatch = useDispatch();
  const history = useHistory();

  const [touchedError, setTouchedError] = useState(false);
  const [managerId, setManagerId] = useState("");

  const { response: responseSendPasswordOTP, request: requestSendPasswordOTP } =
    useRequest();
  const {
    response: responseverifyPasswordOTP,
    request: requestVerifyPasswordOTP,
  } = useRequest();

  const onSubmit = (data) => {
    const { email } = data;
    requestSendPasswordOTP("POST", "sendPasswordOtp", { email });
  };

  useEffect(() => {
    if (responseSendPasswordOTP) {
      if (responseSendPasswordOTP?.status == "success") {
        const managerID = responseSendPasswordOTP?.data?.managerId;
        setManagerId(managerID);
        Swal.fire({
          title: "Enter your otp",
          input: "text",
          inputLabel: "OTP",
          inputPlaceholder: "Enter otp here",
          inputAttributes: {
            maxlength: 8,
            autocapitalize: "off",
            autocorrect: "off",
            required: "true",
          },
          inputValidator: (value) => {
            if (value === "") {
              return "Please enter otp!";
            }
          },
          confirmButtonText: "Submit",
          confirmButtonColor: "#3ea99f",
        }).then((result) => {
          if (result.value === null) return false;

          if (result.isConfirmed) {
            const otp = result.value;
            requestVerifyPasswordOTP("POST", "verifyPasswordOtp", {
              managerId: managerID,
              otp: otp,
            });
          }
        });
        toast.success(responseSendPasswordOTP.message);
      }
    }
  }, [responseSendPasswordOTP]);

  useEffect(() => {
    if (responseverifyPasswordOTP) {
      if (responseverifyPasswordOTP.status == "success") {
        toast.success(responseverifyPasswordOTP.message);

        Swal.fire({
          icon: "success",
          title: "Good Job!",
          text: "otp verified",
          confirmButtonColor: "#3ea99f",
          confirmButtonText: "Next",
        }).then((res) => {
          if (res.isConfirmed) {
            history.push({
              pathname: "/reset-password",
              state: { managerId: managerId },
            });
          }
        });
      }
    }
  }, [responseverifyPasswordOTP]);
  return (
    <div className="ms-body ms-primary-theme ms-logged-out">
      <main className="body-content">
        <div className="ms-content-wrapper ms-auth">
          <Row>
            <Col md={5}>
              <div className="ms-auth-bg">
                <img src={loginLogo} />
              </div>
            </Col>
            <Col md={7}>
              <div className="regi-form two-step">
                <form
                  className="needs-validation"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <h3>Forgot Password</h3>
                  <p>Please enter your email number to continue</p>
                  <div className="mb-3 form-vendor-regi">
                    <label htmlFor="validationCustom08">
                      Email<span className="error">*</span>
                    </label>
                    <div className="input-group">
                      <input
                       type="email"
                        name="email"
                        id="email"
                        placeholder="Email"
                        className={`form-control ${
                          touchedError
                            ? errors.email
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        {...register("email", {
                          required: true,
                        })}
                      />

                      {errors.email?.type === "required" && (
                        <div className="invalid-field">
                          The email field is required.
                        </div>
                      )}
                    </div>
                  </div>

                  <button
                    className="btn btn-primary mt-4 d-block w-100"
                    type="submit"
                    onClick={() => setTouchedError(true)}
                  >
                    Verify
                  </button>
                  <p className="mb-0 mt-3 text-center">
                    Already have an account?{" "}
                    <Link to="/login" className="btn-link">
                      Login
                    </Link>
                  </p>
                </form>
              </div>
            </Col>
          </Row>
          <Modal
            className="modal-min"
            // show={this.state.show1}
            // onHide={this.handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body className="text-center">
              <button
                type="button"
                className="close"
                //   onClick={this.handleClose}
              >
                <span aria-hidden="true">×</span>
              </button>
              <i className="flaticon-secure-shield d-block" />
              <h1>Forgot Password?</h1>
              <p>Enter your email to recover your password</p>
              <form method="post">
                <div className="ms-form-group has-icon">
                  <input
                    type="text"
                    placeholder="Email Address"
                    className="form-control"
                    name="forgot-password"
                  />{" "}
                  <i className="material-icons">email</i>
                </div>
                <button type="submit" className="btn btn-primary shadow-none">
                  Reset Password
                </button>
              </form>
            </Modal.Body>
          </Modal>
        </div>
      </main>
    </div>
  );
};

export default ForgotPassword;
