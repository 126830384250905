// import React, { Component, useEffect } from "react";
// import { Bar as BarChart, HorizontalBar } from "react-chartjs-2";

// // Bar chart
// function chartData1(props) {

//     return {
//         labels: Object.keys(props.data),
//         datasets: [
//             {
//                 label: props.about,
//                 backgroundColor: "#3ea99f",
//                 data: Object.values(props.data),
//             },
//         ],
//     };
// }


// class Barchart extends Component {

//     constructor(props, context) {
//         super(props, context);
//         this.state = {
//             data1: chartData1(props),
//             open: true,
//             values: props.data ? Object.values(props.data) : []
//         };
//     }
//     render() {
//         return (
//             <div
//             // style={{ height: "50%", width: "50%" }}
//             >
//                 {this.props.isHoriz ?
//                     <HorizontalBar
//                         data={this.state.data1}
//                         options={{
//                             indexAxis: 'y',
//                             legend: { display: false },
//                             title: {
//                                 display: true,
//                                 text: this.props.about,
//                             },
//                         }}
//                     />
//                     :
//                     <BarChart

//                         data={this.state.data1}
//                         options={{
//                             indexAxis: 'y',
//                             legend: { display: false },
//                             title: {
//                                 display: true,
//                                 text: this.props.about,
//                             },
//                             scales: {
//                                 yAxes: [{
//                                     ticks: {
//                                         max: Math.max(...this.state.values),
//                                         min: 0,
//                                         //  stepSize: 5
//                                     }
//                                 }]
//                             },

//                         }}
//                     />
//                 }
//             </div>
//         );
//     }
// }

// export default Barchart;


import React, { useState, useEffect } from "react";
import { Bar as BarChart, HorizontalBar } from "react-chartjs-2";

// Bar chart
function chartData1(props) {
    return {
        labels: Object.keys(props.data),
        datasets: [
            {
                label: props.about,
                backgroundColor: "#3ea99f",
                data: Object.values(props.data),
            },
        ],
    };
}

const Barchart = (props) => {
    const [data1, setData1] = useState(chartData1(props));
    const [values, setValues] = useState(props.data ? Object.values(props.data) : []);

    useEffect(() => {
        setData1(chartData1(props));
        setValues(props.data ? Object.values(props.data) : []);
    }, [props]);

    console.log("value12", values)

    return (
        <div>
            {props.isHoriz ? (
                <HorizontalBar
                    data={data1}
                    options={{
                        indexAxis: 'y',
                        legend: { display: false },
                        title: {
                            display: true,
                            text: props.about,
                        },
                        scales: {
                            xAxes: [{
                                ticks: {
                                    precision: 0, // Set precision to 0 to display integer values only
                                    min: 0,
                                },
                            }],
                        },
                    }}
                />
            ) : (
                <BarChart
                    data={data1}
                    options={{
                        indexAxis: 'y',
                        legend: { display: false },
                        title: {
                            display: true,
                            text: props.about,
                        },
                        // scales: {
                        //     yAxes: [{
                        //         ticks: {
                        //             max: Math.max(...values),
                        //             min: 0,
                        //         },
                        //     }],
                        // },
                        scales: {
                            yAxes: [{
                                ticks: {
                                    precision: 0, // Set precision to 0 to display integer values only
                                    min: 0,
                                },
                            }],
                        },
                    }}
                />
            )}
        </div>
    );
};

export default Barchart;

