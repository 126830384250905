import React, { useEffect, useState } from "react";
import "datatables.net-bs4/js/dataTables.bootstrap4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import Barchart from "../../sections/Charts/Barchart";
import { useSelector, useDispatch } from "react-redux";
import { getTrendsReports } from "../../../redux/slices/Reports";
import { upperCase } from "../../../constants";
import { getAllOutlets } from "../../../redux/slices/Outlet";
import { Spinner } from "react-bootstrap";
import Breadcrumb from "../../Breadcrumb";

const Trends = () => {
  const [startDate, setstartDate] = useState("")
  const [outletIds, setOutletId] = useState("")
  const [endDate, setEndDate] = useState("")
  const { trendsReports } = useSelector((state) => state.report);
  const dispatch = useDispatch();
  const { outletList } = useSelector((state) => state.outlet);
  const [loader, setLoader] = useState(false);
  const [selectedOutlet, setSelectedOutlet] = useState({
    outletId: "",
  });
  useEffect(() => {
    dispatch(getAllOutlets({ vendorId: localStorage.getItem("vendorId") }));
    if (localStorage.getItem("vendorType") === "single") {
      dispatch(
        getTrendsReports({
          outletId: localStorage.getItem("outletId"),
          endDate: endDate,
          startDate: startDate
        })
      );
    }
  }, [dispatch, startDate, endDate]);
  const filterData = (e) => {
    setLoader(true);
    setOutletId(e.target.value)
    if (e.target.name === "outletId") {
      setSelectedOutlet({
        ...selectedOutlet,
        outletId: e.target.value,

      });
      dispatch(
        getTrendsReports({
          ...selectedOutlet,
          outletId: e.target.value,
          endDate: endDate,
          startDate: startDate
        })
      )
        .then((res) => {
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };
  const filterDatas = () => {
    setLoader(true);
    dispatch(
      getTrendsReports({
        ...selectedOutlet,
        outletId: outletIds,
        endDate: endDate,
        startDate: startDate
      })
    )
      .then((res) => {
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });

  };

  //initialize datatable
  return (
    <>
      <div className="ms-content-wrapper">
        <Breadcrumb
          title="Trends Reports"
          links={[
            { to: "/", name: "Home" },
          ]}
        />
        <div className="row">
          <div className="col-md-12">
            <div className="ms-panel">
              <div className="ms-panel-header">
                <h6>Trends Reports</h6>
                {localStorage.getItem("vendorType") === "multiple" && (
                  <div
                    className="outletDropdown"
                    style={{
                      padding: "0 30px 0",
                      display: "inline-block",
                      width: "100%",
                    }}
                  >
                    <div className="float-right d-flex">
                      <label
                        htmlFor="validationCustom01"
                        className="form-label mr-3 mt-2"
                      >
                        Select Outlet
                      </label>
                      <div className="dropdown-group form-vendor-regi">
                        <select
                          className="form-control"
                          id="validationCustom01"
                          name="outletId"
                          onChange={filterData}
                        >
                          <option value={0}>Please Select</option>
                          {outletList.length ? (
                            outletList.map((item) => {
                              return (
                                <option value={item.outletId}>
                                  {upperCase(item.outletName)}
                                </option>
                              );
                            })
                          ) : (
                            <option value={0}> Not found</option>
                          )}
                        </select>
                        {/* <div className="invalid-feedback">
                            Please Select a valid Designation.
                          </div> */}
                      </div>

                      <div className="custom-flex">
                        <div
                          className="outletDropdown"
                          style={{
                            padding: "0 ",
                            display: "flex",
                            justifyContent: " flex-end",
                            width: "100%",
                            float: "right",
                          }}
                        >
                          <label htmlFor="validationCustom01" className="form-label mt-2 mr-2 ml-2">
                            Start Date
                          </label>
                          <div className="dropdown-group form-vendor-regi">
                            <input type="date" onChange={(e) => {

                              setstartDate(e.target.value)

                            }}
                              value={startDate}
                            />
                          </div>
                        </div>
                        <div
                          className="outletDropdown"
                          style={{
                            padding: "0 ",
                            display: "flex",
                            justifyContent: " flex-end",
                            width: "100%",
                            float: "right",
                          }}
                        >
                          <label htmlFor="validationCustom01" className="form-label mt-2 mr-2">
                            End Date
                          </label>
                          <div className="dropdown-group form-vendor-regi">
                            <input type="date" onChange={(e) => {
                              setEndDate(e.target.value)
                            }}
                              value={endDate}
                            />
                          </div>
                        </div>

                        <div
                          className="outletDropdown"
                          style={{
                            padding: "0 ",
                            display: "flex",
                            justifyContent: " flex-end",
                            width: "100%",
                            float: "right",
                          }}>
                          <button type="button" onClick={filterDatas} className="excle-dowm px-2 m-0"> Search </button>
                        </div>
                      </div>


                    </div>

                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-xl-6 col-md-12">
                  <div className="ms-panel">
                    <div className="ms-panel-header">
                      <h6>Feedback</h6>
                    </div>
                    {trendsReports?.businessTrend && (
                      <div className="ms-panel">
                        {loader ? (
                          <div
                            style={{
                              flex: 1,
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Spinner
                              animation="border"
                              className="d-flex justify-content-center"
                            />
                          </div>
                        ) : (
                          selectedOutlet.outletId != "" && (
                            <Barchart
                              data={trendsReports?.businessTrend}
                              about={"Feedback"}
                            />
                          )
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-xl-6 col-md-12">
                  <div className="ms-panel">
                    <div className="ms-panel-header">
                      <h6>Cuisine</h6>
                    </div>
                    {trendsReports?.cuisineTrend && (
                      <div className="ms-panel">
                        {loader ? (
                          <Spinner
                            animation="border"
                            className="d-flex justify-content-center"
                          />
                        ) : (
                          selectedOutlet.outletId != "" && (
                            <Barchart
                              data={trendsReports?.cuisineTrend}
                              about={"Cuisine Trend"}
                            />
                          )
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-xl-6 col-md-12">
                  <div className="ms-panel">
                    <div className="ms-panel-header">
                      <h6>Dishes</h6>
                    </div>
                    {trendsReports?.dishesTrend && (
                      <div className="ms-panel">
                        {loader ? (
                          <Spinner
                            animation="border"
                            className="d-flex justify-content-center"
                          />
                        ) : (
                          selectedOutlet.outletId != "" && (
                            <Barchart
                              data={trendsReports?.dishesTrend}
                              about={"Dishes Trend"}
                              isHoriz={true}
                            />
                          )
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-xl-6 col-md-12">
                  <div className="ms-panel">
                    <div className="ms-panel-header">
                      <h6>Promotions</h6>
                    </div>
                    {trendsReports?.promotions && (
                      <div className="ms-panel">
                        <Barchart
                          data={trendsReports?.promotions}
                          about={"Promotions"}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-xl-6 col-md-12">
                  <div className="ms-panel">
                    <div className="ms-panel-header">
                      <h6>Session</h6>
                    </div>
                    {trendsReports?.sessionTrend && (
                      <div className="ms-panel">
                        <Barchart
                          data={trendsReports?.sessionTrend}
                          about={"Session"}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-xl-6 col-md-12">
                  <div className="ms-panel">
                    <div className="ms-panel-header">
                      <h6>Booking Trend</h6>
                    </div>
                    {trendsReports?.bookingTrend && (
                      <div className="ms-panel">
                        <Barchart
                          data={trendsReports?.bookingTrend}
                          about={"Booking Trend"}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Trends;

