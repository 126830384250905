import React from "react";

function ErrorComponent({ name }) {
    return (
        <>
            {name && (
                // <div>
                <span className="formerrror">{name}</span>
                // </div>
            )}
        </>
    );
}

export default ErrorComponent;
