import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { upperCase } from "../../../constants";
import { getDashboardData } from "../../../redux/slices/Dashboard";

import {
  getOrderDetails,
  getOrders,
  updateOrders,
  verifyOrder,
} from "../../../redux/slices/Orders";
import { getNewOrderFromSocket } from "../../../util/fn";
import axios from "axios";
import { BASE_URL } from "../../../redux/services/apiPath";
import authHeader from "../../../redux/services/authHeader";
import AudioFile from "../../../assets/audio/audio.mp3";
const Recentorder = ({ selectedOutlet }) => {
  const { dashboardData } = useSelector((state) => state.dashboard);
  const [dashboardData1, setDashboardData] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState("");

  useEffect(() => {
    const socket = getNewOrderFromSocket(selectedOutlet?.outletId);
    if (socket && filter == "") {
      console.log("socketsocketsocket------", socket);
      socket.on("newOrder", (data) => {
        if (data) {
          const selOutletId = selectedOutlet?.outletId;
          if (data.outletId == selOutletId) {
            dispatch(
              getDashboardData({
                outletId: selOutletId,
                days: filter,
              })
            )
              .then((res) => {
                // console.log(res)
              })
              .catch((err) => {
                //  console.log(err)
              });
          }
        }
      });

      socket.on("newOrderNotification", (data) => {
        console.log("newOrderNotification------", data);

        if (data) {
          toast.success(data);
          handlePlay();
        }
      });
    }

    return () => {
      socket.off("newOrderNotification");
    };

    return () => {
      socket.off("newOrder");
    };
  }, [selectedOutlet, filter]);

  const GetData = async () => {
    const res = await axios.post(
      `${BASE_URL}dashboard/getLatestBooking`,
      {
        outletId: selectedOutlet?.outletId,
        days: filter,
      },
      authHeader()
    );

    if (res?.data?.status == "success") {
      setDashboardData(res?.data?.data);
    }
  };
  useEffect(() => {
    if (filter != "") {
      GetData();
    }
  }, [filter, selectedOutlet]);

  const audio = new Audio(AudioFile);
  const handlePlay = async () => {
    try {
      await audio.play();
    } catch (error) {
      console.error("Error playing audio:", error);
    }
  };

  return (
    <div className="">
      <div className="float-right dropdown-group form-vendor-regi selct-boxx mb-2">
        {/* <audio src={AudioFile} controls autoPlay>
        Your browser does not support the audio element.
      </audio> */}
        <select
          className="form-control"
          onChange={(e) => {
            console.log("filter", e.target.value);
            setFilter(e.target.value);
          }}
          value={filter}
        >
          <option selected value={""}>
            Select Time
          </option>
          <option selected value={"0"}>
            Today
          </option>
          <option selected value={"1"}>
            Tomorrow
          </option>
          <option selected value={"7"}>
            Next Seven Days
          </option>
          <option selected value={"30"}>
            Next Thirty Days
          </option>
        </select>
        {/* // <button onClick={handlePlay}>paly</button> */}
      </div>
      {filter == "" ? (
        <div className="table-responsive mt-4">
          <table className="table table-hover thead-primary">
            <thead>
              <tr>
                <th scope="col">Sr. No</th>

                <th scope="col">Booking Number</th>
                <th scope="col">User Name</th>
                <th scope="col">User Phone Number</th>
                <th scope="col">Table Amount</th>
                <th scope="col">Menu Amount</th>
                <th scope="col">Booking Made On</th>

                <th scope="col">Date</th>
                <th scope="col">Person</th>
                <th scope="col">Booking For</th>
                <th scope="col">Order Status</th>
                <th scope="col">Is Verified</th>
                <th scope="col">Accept/Decline/View/Verify</th>
              </tr>
            </thead>
            <tbody>
              {dashboardData?.latestBooking?.length
                ? dashboardData?.latestBooking?.map((item, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>

                      <td>{item?.bookingNumber}</td>
                      <td>{item?.userName}</td>
                      <td>{item.userMobile}</td>
                      <td>{item?.tablePaidAmount}</td>
                      <td>{item?.menuPaidAmount}</td>
                      <td>{item?.bookingMadeOn}</td>
                      <td>{item.date}</td>
                      <td>{item.person}</td>
                      <td>{item.bookingType}</td>
                      {/* <td>
                        <span
                          className={
                            item.bookingLabel == "decline"
                              ? "badge badge-dark"
                              : item.bookingLabel == "completed"
                              ? "badge badge-success"
                              : item.bookingLabel == "accepted"
                              ? "badge badge-info"
                              : item.bookingLabel == "cancel"
                              ? "badge badge-danger"
                              : "badge badge-primary"
                          }
                        >
                          {upperCase(
                            item.bookingLabel == "cancel"
                              ? "Cancelled"
                              : item.bookingLabel == "completed" &&
                                item.isVerified == "no"
                              ? "Upcoming"
                              : item.bookingLabel
                          )}
                        </span>
                      </td> */}
                      <td>
                        <span
                          className={
                            item?.bookingLabel == "Pending"
                              ? "badge badge-primary"
                              : item?.bookingLabel == "Cancel" ||
                                item?.bookingLabel == "cancel"
                              ? "badge badge-danger"
                              : item?.bookingLabel == "completed" ||
                                item?.bookingLabel == "Completed"
                              ? "badge badge-completed"
                              : item?.bookingLabel == "Upcoming"
                              ? "badge badge-info"
                              : item?.bookingLabel == "accepted"
                              ? "badge badge-success"
                              : item?.bookingLabel == "decline"
                              ? "badge badge-danger"
                              : ""
                          }
                        >
                          {upperCase(
                            item.status == "Cancel" ? "Cancelled" : item.status
                          )}
                        </span>
                      </td>
                      <td>
                        <span
                          className={
                            item?.isVerified == "yes"
                              ? "badge badge-success"
                              : "badge badge-primary"
                          }
                        >
                          {upperCase(item?.isVerified)}
                        </span>
                      </td>
                      <td>
                        <button
                          type="button"
                          disabled={item?.status == "pending" ? false : true}
                          className="ms-btn-icon-outline btn-square btn-success"
                          onClick={() => {
                            Swal.fire({
                              title: "Accept Order",
                              showCancelButton: true,
                              confirmButtonText: "Ok",
                              showLoaderOnConfirm: true,
                            }).then(function (result) {
                              if (result.isConfirmed) {
                                dispatch(
                                  updateOrders({
                                    bookingId: item.bookingId,
                                    status: "accept",
                                  })
                                )
                                  .unwrap()
                                  .then(() => {
                                    dispatch(
                                      getDashboardData({
                                        outletId: selectedOutlet?.outletId,
                                        days: "1",
                                      })
                                    );
                                    Swal.fire({
                                      icon: "success",
                                      html: "Order status updated successfully",
                                    });
                                  })
                                  .catch(() => {
                                    Swal.fire({
                                      icon: "error",
                                      html: "Something went wrong",
                                    });
                                  });
                              }
                            });
                          }}
                        >
                          <i className="flaticon-tick-inside-circle mr-0"></i>
                        </button>
                        <button
                          type="button"
                          disabled={item?.status == "pending" ? false : true}
                          className="ms-btn-icon-outline btn-square btn-success ml-2"
                          onClick={() => {
                            Swal.fire({
                              title: "Decline Order",
                              showCancelButton: true,
                              confirmButtonText: "Ok",
                              showLoaderOnConfirm: true,
                            }).then(function (result) {
                              if (result.isConfirmed) {
                                dispatch(
                                  updateOrders({
                                    bookingId: item.bookingId,
                                    status: "decline",
                                  })
                                )
                                  .unwrap()
                                  .then(() => {
                                    dispatch(
                                      getDashboardData({
                                        outletId: selectedOutlet?.outletId,
                                        days: "1",
                                      })
                                    );
                                    Swal.fire({
                                      icon: "success",
                                      html: "Order status updated successfully",
                                    });
                                  })
                                  .catch(() => {
                                    Swal.fire({
                                      icon: "error",
                                      html: "Something went wrong",
                                    });
                                  });
                              }
                            });
                          }}
                        >
                          <i
                            className="flaticon-trash ms-delete-trigger mr-0"
                            style={{ color: "red" }}
                          ></i>
                        </button>
                        <button
                          disabled={
                            item?.status == "accepted" ||
                            item?.status == "completed"
                              ? false
                              : true
                          }
                          type="button"
                          className="ms-btn-icon-outline btn-square btn-info ml-2"
                          onClick={() => {
                            dispatch(
                              getOrderDetails({
                                bookingId: item.bookingId,
                              })
                            )
                              .unwrap()
                              .then(() => {
                                history.push(`/order-detail/${item.bookingId}`);
                              });
                          }}
                        >
                          <i className="flaticon-information mr-0"></i>
                        </button>
                        <button
                          disabled={
                            item?.isVerified == "no" &&
                            item?.status == "completed"
                              ? false
                              : true
                          }
                          type="button"
                          className="ms-btn-icon-outline btn-square btn-info ml-2"
                          onClick={() => {
                            Swal.fire({
                              title: "Enter order code",
                              input: "text",
                              inputLabel: "code",
                              inputPlaceholder: "Enter code here",
                              inputAttributes: {
                                maxlength: 8,
                                autocapitalize: "off",
                                autocorrect: "off",
                                required: "true",
                              },
                              inputValidator: (value) => {
                                if (value === "") {
                                  return "Please enter code!";
                                }
                              },
                              confirmButtonText: "Submit",
                              confirmButtonColor: "#3ea99f",
                            })
                              .then((r) => {
                                if (r.value === null) return false;

                                if (r.isConfirmed) {
                                  // console.log(r.value);

                                  dispatch(
                                    verifyOrder({
                                      bookingId: item.bookingId,
                                      secureCode: r.value,
                                    })
                                  )
                                    .unwrap()
                                    .then(() => {
                                      Swal.fire({
                                        icon: "success",
                                        title: "Order verified!",
                                        confirmButtonColor: "#3ea99f",
                                        confirmButtonText: "Ok",
                                      }).then((res) => {
                                        dispatch(
                                          getDashboardData({
                                            outletId: selectedOutlet?.outletId,
                                            days: "1",
                                          })
                                          // getOrders({
                                          //     outletId:
                                          //     selectedOutlet?.outletId,
                                          // })
                                        );
                                      });
                                    })
                                    .catch((err) => {
                                      toast.error("Invalid Code");
                                    });
                                }
                              })
                              .catch((err) => {
                                toast.error(err);
                              });
                          }}
                        >
                          <i className="flaticon-security mr-0"></i>
                        </button>
                      </td>
                    </tr>
                  ))
                : "No Data Found"}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="table-responsive mt-4">
          <table className="table table-hover thead-primary">
            <thead>
              <tr>
                <th scope="col">Sr. No</th>

                <th scope="col">Booking Number</th>
                <th scope="col">User Name</th>
                <th scope="col">User Phone Number</th>
                <th scope="col">Table Amount</th>
                <th scope="col">Menu Amount</th>
                <th scope="col">Booking Made On</th>

                <th scope="col">Date</th>
                <th scope="col">Person</th>
                <th scope="col">Booking For</th>
                <th scope="col">Order Status</th>
                <th scope="col">Is Verified</th>
                <th scope="col">Accept/Decline/View/Verify</th>
              </tr>
            </thead>
            <tbody>
              {dashboardData1?.length
                ? dashboardData1?.map((item, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>

                      <td>{item?.bookingNumber}</td>
                      <td>{item.userName}</td>
                      <td>{item.userMobile}</td>
                      <td>{item?.tablePaidAmount}</td>
                      <td>{item?.menuPaidAmount}</td>
                      <td>{item?.bookingMadeOn}</td>
                      <td>{item.date}</td>
                      <td>{item.person}</td>
                      <td>{item.bookingType}</td>
                      <td>
                        <span
                          className={
                            item?.bookingLabel == "Pending"
                              ? "badge badge-primary"
                              : item?.bookingLabel == "Cancel" ||
                                item?.bookingLabel == "cancel"
                              ? "badge badge-danger"
                              : item?.bookingLabel == "completed" ||
                                item?.bookingLabel == "Completed"
                              ? "badge badge-completed"
                              : item?.bookingLabel == "Upcoming"
                              ? "badge badge-info"
                              : item?.bookingLabel == "accepted"
                              ? "badge badge-success"
                              : item?.bookingLabel == "decline"
                              ? "badge badge-danger"
                              : ""
                          }
                        >
                          {upperCase(
                            item.status == "cancel"
                              ? "Cancelled"
                              : item.status == "completed" &&
                                item.isVerified == "no"
                              ? "Upcoming"
                              : item.status
                          )}
                        </span>
                      </td>
                      <td>
                        <span
                          className={
                            item?.isVerified == "yes"
                              ? "badge badge-success"
                              : "badge badge-primary"
                          }
                        >
                          {upperCase(item?.isVerified)}
                        </span>
                      </td>
                      <td>
                        <button
                          type="button"
                          disabled={item?.status == "pending" ? false : true}
                          className="ms-btn-icon-outline btn-square btn-success"
                          onClick={() => {
                            Swal.fire({
                              title: "Accept Order",
                              showCancelButton: true,
                              confirmButtonText: "Ok",
                              showLoaderOnConfirm: true,
                            }).then(function (result) {
                              if (result.isConfirmed) {
                                dispatch(
                                  updateOrders({
                                    bookingId: item.bookingId,
                                    status: "accept",
                                  })
                                )
                                  .unwrap()
                                  .then(() => {
                                    dispatch(
                                      getDashboardData({
                                        outletId: selectedOutlet?.outletId,
                                        days: "1",
                                      })
                                    );
                                    Swal.fire({
                                      icon: "success",
                                      html: "Order status updated successfully",
                                    });
                                  })
                                  .catch(() => {
                                    Swal.fire({
                                      icon: "error",
                                      html: "Something went wrong",
                                    });
                                  });
                              }
                            });
                          }}
                        >
                          <i className="flaticon-tick-inside-circle mr-0"></i>
                        </button>
                        <button
                          type="button"
                          disabled={item?.status == "pending" ? false : true}
                          className="ms-btn-icon-outline btn-square btn-success ml-2"
                          onClick={() => {
                            Swal.fire({
                              title: "Decline Order",
                              showCancelButton: true,
                              confirmButtonText: "Ok",
                              showLoaderOnConfirm: true,
                            }).then(function (result) {
                              if (result.isConfirmed) {
                                dispatch(
                                  updateOrders({
                                    bookingId: item.bookingId,
                                    status: "decline",
                                  })
                                )
                                  .unwrap()
                                  .then(() => {
                                    dispatch(
                                      getDashboardData({
                                        outletId: selectedOutlet?.outletId,
                                        days: "1",
                                      })
                                    );
                                    Swal.fire({
                                      icon: "success",
                                      html: "Order status updated successfully",
                                    });
                                  })
                                  .catch(() => {
                                    Swal.fire({
                                      icon: "error",
                                      html: "Something went wrong",
                                    });
                                  });
                              }
                            });
                          }}
                        >
                          <i
                            className="flaticon-trash ms-delete-trigger mr-0"
                            style={{ color: "red" }}
                          ></i>
                        </button>
                        <button
                          disabled={
                            item?.status == "accepted" ||
                            item?.status == "completed"
                              ? false
                              : true
                          }
                          type="button"
                          className="ms-btn-icon-outline btn-square btn-info ml-2"
                          onClick={() => {
                            dispatch(
                              getOrderDetails({
                                bookingId: item.bookingId,
                              })
                            )
                              .unwrap()
                              .then(() => {
                                history.push(`/order-detail/${item.bookingId}`);
                              });
                          }}
                        >
                          <i className="flaticon-information mr-0"></i>
                        </button>
                        <button
                          disabled={
                            item?.isVerified == "no" &&
                            item?.status == "completed"
                              ? false
                              : true
                          }
                          type="button"
                          className="ms-btn-icon-outline btn-square btn-info ml-2"
                          onClick={() => {
                            Swal.fire({
                              title: "Enter order code",
                              input: "text",
                              inputLabel: "code",
                              inputPlaceholder: "Enter code here",
                              inputAttributes: {
                                maxlength: 8,
                                autocapitalize: "off",
                                autocorrect: "off",
                                required: "true",
                              },
                              inputValidator: (value) => {
                                if (value === "") {
                                  return "Please enter code!";
                                }
                              },
                              confirmButtonText: "Submit",
                              confirmButtonColor: "#3ea99f",
                            })
                              .then((r) => {
                                if (r.value === null) return false;

                                if (r.isConfirmed) {
                                  // console.log(r.value);

                                  dispatch(
                                    verifyOrder({
                                      bookingId: item.bookingId,
                                      secureCode: r.value,
                                    })
                                  )
                                    .unwrap()
                                    .then(() => {
                                      Swal.fire({
                                        icon: "success",
                                        title: "Order verified!",
                                        confirmButtonColor: "#3ea99f",
                                        confirmButtonText: "Ok",
                                      }).then((res) => {
                                        dispatch(
                                          getDashboardData({
                                            outletId: selectedOutlet?.outletId,
                                            days: "1",
                                          })
                                          // getOrders({
                                          //     outletId:
                                          //     selectedOutlet?.outletId,
                                          // })
                                        );
                                      });
                                    })
                                    .catch((err) => {
                                      toast.error("Invalid Code");
                                    });
                                }
                              })
                              .catch((err) => {
                                toast.error(err);
                              });
                          }}
                        >
                          <i className="flaticon-security mr-0"></i>
                        </button>
                      </td>
                    </tr>
                  ))
                : "No Data Found"}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Recentorder;
