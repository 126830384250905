import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
  getCities,
  getCountries,
  getStates,
  registerStepFive,
  registerStepOne,
  verifyAddress,
  verifyOTP,
} from "../../../../redux/slices/Registration";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  stepFiveSave,
  stepTwoSave,
} from "../../../../redux/slices/SaveDetails";
import { toast } from "react-toastify";
import SimpleMap from "../../../GoogleMaps";
import { Button, Col, Modal, Row } from "react-bootstrap";

import loginLogo from "../../../../assets/img/logo.png";

const StepFive = ({ setNextStep, nextStep }) => {
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = React.useState(false);
  const { countries, states, cities, address, vendorId, outletId } =
    useSelector((state) => state.register);
  const [latLong, setLatLong] = React.useState({
    latitude: "",
    longitude: "",
  });
  const { stepFive } = useSelector((state) => state.saveDetails);
  const [initialValues, setInitialValues] = React.useState(
    stepFive
      ? stepFive
      : {
        country: "",
        state: "",
        city: "",
        address: "",
        street: "",
        landMark: "",
        pincode: "",
        // latitude: "12.912",
        // longitude: "77.912",
      }
  );

  const validationSchema = Yup.object().shape({
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
    address: Yup.string().required("Address is required"),
    landMark: Yup.string().required("LandMark is required"),
    street: Yup.string().required("Street is required"),
    pincode: Yup.string().required("Postal Code is required"),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      dispatch(stepFiveSave(values));
      handleAddressVerification();
    },
  });
  const handleShowOtpModal = async () => {
    setLatLong({
      latitude: address.lat ? address.lat : "",
      longitude: address.lng ? address.lng : "",
    });
    dispatch(
      registerStepFive({
        ...formik.values,
        ...latLong,
        vendorId: vendorId,
        outletId: outletId,
      })
    )
      .unwrap()
      .then((res) => {
        toast.success(res.message);
        setModalShow(false);
        setNextStep(6);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleAddressVerification = () => {
    dispatch(verifyAddress(formik.values))
      .unwrap()
      .then((res) => {
        setLatLong({
          latitude: res.data.lat,
          longitude: res.data.lng,
        });
        setModalShow(true);
        toast.success("Address Verified");
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  useEffect(() => {
    if (stepFive) {
      setInitialValues(stepFive);
    }
    dispatch(getCountries())
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, [dispatch, initialValues]);

  const onHandleChange = (e) => {
    formik.handleChange(e);
    if (e.target.name === "country") {
      dispatch(getStates(e.target.value)).then((res) => {
        // console.log(res);
      });
    } else if (e.target.name === "state") {
      dispatch(getCities(e.target.value)).then((res) => {
        // console.log(res);
      });
    }
  };
  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Confirm Your Address
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{SimpleMap(latLong)}</Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Change Address</Button>
          <Button onClick={handleShowOtpModal}>Confirm</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <div className="ms-content-wrapper ms-auth">
      <div className="regi-section">
        <Row>
          <Col md={5}>
            <div className="ms-auth-bg">
              <img src={loginLogo} />
            </div>
          </Col>
          <Col md={7}>
            <div className="form-vendor-regi">
              <div className="form-vendor">
                <form className="needs-validation">
                  <div className="regi-form">
                    <h3>
                       Registration : Step <span>{nextStep} of 6</span>
                    </h3>
                    <p>Please enter information to continue</p>
                    <div className="">
                      <label htmlFor="validationCustom03">Country<span className="error">*</span></label>
                      <div className="input-group">
                        <select
                          className="form-control"
                          name="country"
                          onChange={onHandleChange}
                          style={{
                            borderColor:
                              formik.errors.country && formik.touched.country
                                ? "red"
                                : "#3ea99f",
                          }}
                          value={formik.values.country}
                        >
                          <option>Select Country</option>
                          {countries.length > 0 ? (
                            countries.map((country) => {
                              return (
                                <option value={country.table_id}>
                                  {country.name}
                                </option>
                              );
                            })
                          ) : (
                            <option value={0}>No Country Found</option>
                          )}
                        </select>
                      </div>
                      <div className="error">{formik.errors.country}</div>
                    </div>

                    <div className="form-row">
                      <div className="col-md-6 ">
                        <label htmlFor="validationCustom03">State<span className="error">*</span></label>
                        <div className="input-group">
                          <select
                            className="form-control"
                            name="state"
                            aria-readonly={true}
                            onChange={onHandleChange}
                            value={formik.values.state}
                            style={{
                              borderColor:
                                formik.errors.state && formik.touched.state
                                  ? "red"
                                  : "#3ea99f",
                            }}
                          >
                            <option>Select State</option>
                            {states.length > 0 ? (
                              states.map((country) => {
                                return (
                                  <option value={country.table_id}>
                                    {country.name}
                                  </option>
                                );
                              })
                            ) : (
                              <option value={0}>No State Found</option>
                            )}
                          </select>
                        </div>
                        {formik.touched.state && (
                          <div className="error">{formik.errors.state}</div>
                        )}
                      </div>
                      <div className="col-md-6 ">
                        <label htmlFor="validationCustom03">City<span className="error">*</span></label>
                        <div className="input-group">
                          <select
                            className="form-control"
                            name="city"
                            onChange={onHandleChange}
                            value={formik.values.city}
                            style={{
                              borderColor:
                                formik.errors.city && formik.touched.city
                                  ? "red"
                                  : "#3ea99f",
                            }}
                          >
                            <option>Select City</option>
                            {cities.length > 0 ? (
                              cities.map((country) => {
                                return (
                                  <option value={country.table_id}>
                                    {country.name}
                                  </option>
                                );
                              })
                            ) : (
                              <option value={0}>No City Found</option>
                            )}
                          </select>
                        </div>
                        {formik.touched.city && (
                          <div className="error">{formik.errors.city}</div>
                        )}
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-12 ">
                        <label htmlFor="validationCustom04">Address<span className="error">*</span></label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            name="address"
                            onChange={formik.handleChange}
                            value={formik.values.address}
                            id="validationCustom04"
                            placeholder="Address"
                            style={{
                              borderColor:
                                formik.errors.address && formik.touched.address
                                  ? "red"
                                  : "#3ea99f",
                            }}
                          />
                          <div className="invalid-feedback">
                            Please provide address.
                          </div>
                        </div>
                        {formik.touched.address && (
                          <div className="error">{formik.errors.address}</div>
                        )}
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-12 ">
                        <label htmlFor="validationCustom04">
                          Nearest Land Mark<span className="error">*</span>
                        </label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            name="landMark"
                            onChange={formik.handleChange}
                            value={formik.values.landMark}
                            id="validationCustom04"
                            placeholder="Land Mark"
                            style={{
                              borderColor:
                                formik.errors.landMark &&
                                  formik.touched.landMark
                                  ? "red"
                                  : "#3ea99f",
                            }}
                          />
                          <div className="invalid-feedback">
                            Please provide Landmark.
                          </div>
                        </div>
                        {formik.touched.landMark && (
                          <div className="error">{formik.errors.landMark}</div>
                        )}
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-6 ">
                        <label htmlFor="validationCustom03">Street<span className="error">*</span></label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            name="street"
                            onChange={formik.handleChange}
                            value={formik.values.street}
                            id="validationCustom03"
                            placeholder="street"
                            style={{
                              borderColor:
                                formik.errors.street && formik.touched.street
                                  ? "red"
                                  : "#3ea99f",
                            }}
                          />
                          <div className="invalid-feedback">
                            Please provide street.
                          </div>
                        </div>
                        {formik.touched.street && (
                          <div className="error">{formik.errors.street}</div>
                        )}
                      </div>
                      <div className="col-md-6 ">
                        <label htmlFor="validationCustom04">Postal code<span className="error">*</span></label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            name="pincode"
                            onChange={formik.handleChange}
                            value={formik.values.pincode}
                            id="validationCustom04"
                            placeholder="Postal code"
                            style={{
                              borderColor:
                                formik.errors.pincode && formik.touched.pincode
                                  ? "red"
                                  : "#3ea99f",
                            }}
                          />
                          <div className="invalid-feedback">
                            Please provide postal code.
                          </div>
                        </div>
                        {formik.touched.pincode && (
                          <div className="error">{formik.errors.pincode}</div>
                        )}
                      </div>
                    </div>
                    {latLong.latitude && latLong.longitude && (
                      <div className="form-row">
                        <div className="col-md-6 ">
                          <label htmlFor="validationCustom03">Latitude</label>
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              name="latitude"
                              onChange={formik.handleChange}
                              value={latLong.latitude}
                              id="validationCustom03"
                              placeholder="Latitude"
                              disabled
                            />
                            <div className="invalid-feedback">
                              Please provide latitude.
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 ">
                          <label htmlFor="validationCustom04">Longitude</label>
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              name="longitude"
                              onChange={formik.handleChange}
                              value={latLong.longitude}
                              id="validationCustom04"
                              placeholder="Longitude"
                              disabled
                            />
                            <div className="invalid-feedback">
                              Please provide longitude.
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="row">
                      <div className="col-md-6">
                        <button
                          className="steps-btn float-left"
                          type="button"
                          onClick={() => setNextStep(4)}
                        >
                          <i>&#8592;</i> Previous
                        </button>
                      </div>
                      <div className="col-md-6">
                        <button
                          className="steps-btn"
                          type="submit"
                          onClick={formik.handleSubmit}
                        >
                          {"Verify Address"}
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* {latLong.latitude && SimpleMap(latLong)} */}
                  {latLong.latitude && (
                    <MyVerticallyCenteredModal
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    />
                  )}
                </form>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default StepFive;

