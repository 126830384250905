import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Button, Form, Modal, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import loginLogo from "../../assets/img/logo.png";
import useRequest from "../../hooks/useRequest";
import useRequest2 from "../../hooks/useRequest2";
import { maxSize, upperCase } from "../../constants";
import { stepOneSave } from "../../redux/slices/SaveDetails";
import { verifyOTP } from "../../redux/slices/Registration";

const StepOne = ({
  nextStep,
  handleNextStep,
  handleManagerId,
  setManagerId,
}) => {
  const { userId } = useParams();
  const { stepOne } = useSelector((state) => state.saveDetails);
  const dispatch = useDispatch();
  const { response: responseIdType, request: requestIdType } = useRequest2();
  const { response: responseDesignation, request: requestDesignation } =
    useRequest2();
  const { response: responseSaveStepOne, request: requestSaveStepOne } =
    useRequest();
  const { request, response } = useRequest();

  const [touchedError, setTouchedError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState({
    newPassword: false,
    confirmNewPassword: false,
  });
  const [idTypes, setIdTypes] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [displayImage, setDisplayImage] = useState("");
  const [storeImage, setStoreImage] = useState([]);
  const [idType, setIDType] = useState("");
  const [submitStepData, setSubmitStepData] = useState({});

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
    setValue,
  } = useForm();

  useEffect(() => {
    if (userId) {
      request("GET", `user/getRegisterDetail/${userId}`);
    }
  }, [userId]);

  useEffect(() => {
    if (response) {
      if (response.status == "success") {
        if (response?.data?.mobile) {
          setValue("mobile", response?.data?.mobile);
        }
        if (response?.data?.email) {
          setValue("email", response?.data?.email);
        }
        if (response?.data?.name) {
          setValue("name", response?.data?.name);
        }
      }
    }
  }, [response]);

  useEffect(() => {
    requestIdType("POST", "manageCategory/getCategorys", { type: "idProof" });
    requestDesignation("POST", "manageCategory/getCategorys", {
      type: "designation",
    });
    register("idImage", { required: true });
  }, []);

  useEffect(() => {
    if (responseIdType) {
      if (
        responseIdType?.status == true ||
        responseIdType?.status == "success"
      ) {
        setIdTypes(
          responseIdType?.data?.length > 0 ? responseIdType?.data : []
        );
      }
    }
  }, [responseIdType]);

  useEffect(() => {
    if (responseDesignation) {
      if (
        responseDesignation?.status == true ||
        responseDesignation?.status == "success"
      ) {
        setDesignation(
          responseDesignation?.data?.length > 0 ? responseDesignation?.data : []
        );
      }
    }
  }, [responseDesignation]);

  useEffect(() => {
    if (stepOne) {
      setValue("designation", stepOne?.designation ?? "");
      setValue("email", stepOne?.email ?? "");
      setValue("idNumber", stepOne?.idNumber ?? "");
      setValue("idImage", stepOne?.idImage ?? "");
      setValue("idType", stepOne?.idType ?? "");
      setValue("mobile", stepOne?.mobile ?? "");
      setValue("name", stepOne?.name ?? "");
      setValue("referBy", stepOne?.referBy ?? "");
      setValue("password", stepOne?.password ?? "");
      setValue("confirmNewPassword", stepOne?.password ?? "");

      if (stepOne?.idImage?.length > 0) {
        if (stepOne?.idImage[0]) {
          const files = stepOne?.idImage;
          if (files?.length) {
            // setDisplayImage(URL.createObjectURL(files[0]));
            // setStoreImage(files);

            setError("idImage", "");
            setValue("idImage", files[0]);
            register("idImage", { required: false });
          } else {
            setError("idImage", "");
            setDisplayImage("");
            setStoreImage([]);
            setValue("idImage", "");
            register("idImage", { required: true });
          }
        }
      }
    }
  }, [stepOne]);

  const onSubmit = (data) => {
    const {
      designation,
      name,
      idType,
      idNumber,
      idImage,
      email,
      password,
      mobile,
      confirmNewPassword,
      referBy,
    } = data;

    if (password != confirmNewPassword) {
      setError("confirmNewPassword", { type: "manual" });
      return;
    }
    setLoader(true);
    setSubmitStepData({
      designation,
      name,
      idType,
      idNumber,
      idImage: storeImage,
      email,
      password,
      mobile,
      referBy,
    });
    const fd = new FormData();
    fd.append("designation", designation);
    fd.append("name", name);
    fd.append("idType", idType);
    fd.append("idNumber", idNumber);
    fd.append("referBy", referBy);
    fd.append("email", email);
    fd.append("password", password);
    fd.append("mobile", mobile);

    if (displayImage) {
      if (idImage) {
        fd.append("idImage", idImage);
      }
    }
    requestSaveStepOne("POST", "registerStepOne", fd);
  };

  useEffect(() => {
    if (responseSaveStepOne) {
      if (
        responseSaveStepOne?.status == "success" ||
        responseSaveStepOne?.status == true
      ) {
        dispatch(stepOneSave(submitStepData));
        toast.success(responseSaveStepOne.message);
        const managerId = responseSaveStepOne?.managerId;
        localStorage.setItem("ManagerId", managerId);
        handleShowModal(managerId);
      } else {
        toast.error(responseSaveStepOne?.message);
        setLoader(false);
      }
    }
  }, [responseSaveStepOne]);

  const handleShowModal = async (managerId) => {
    Swal.fire({
      title: "OTP Sent",
      text: "An OTP has been sent to your registered email id.",
      input: "text",
      inputLabel: "OTP",
      inputPlaceholder: "Enter otp here",
      inputAttributes: {
        maxlength: 8,
        autocapitalize: "off",
        autocorrect: "off",
        required: "true",
      },
      inputValidator: (value) => {
        if (value === "") {
          return "Please enter otp!";
        }
      },
      confirmButtonText: "Submit",
      confirmButtonColor: "#3ea99f",
    })
      .then((r) => {
        if (r.value === null) return false;

        if (r.isConfirmed) {
          dispatch(
            verifyOTP({
              managerId: managerId,
              otpEmail: r.value,
            })
          )
            .unwrap()
            .then(() => {
              Swal.fire({
                icon: "success",
                title: "OTP verified",
                confirmButtonColor: "#3ea99f",
                confirmButtonText: "Next",
              }).then((res) => {
                if (res.isConfirmed) {
                  handleManagerId(managerId);
                  setManagerId(managerId);

                  handleNextStep(2);
                }
              });
            })
            .catch((err) => {
              setLoader(false);
              toast.error("Invalid OTPsss");
            });
        } else {
          setLoader(false);
        }
      })
      .catch((err) => {
        toast.error(err);
        setLoader(false);
      });
  };

  const handleImage = (event) => {
    event.preventDefault();

    const file = event.currentTarget.files[0];

    if (file) {
      const fileSize = file.size; // Size in bytes

      if (fileSize > maxSize) {
        toast.error(
          `File size exceeds maximum allowed size (${
            maxSize / 1024 / 1024
          } mb).`
        );

        return; // Stop processing if file size exceeds the limit
      }

      setDisplayImage(URL.createObjectURL(file));
      setStoreImage([file]);
      setError("idImage", "");
      setValue("idImage", file);
      register("idImage", { required: false });
    } else {
      setStoreImage([]);
      setError("idImage", "Please Select an image.");
      setDisplayImage("");
      setValue("idImage", "");
      register("idImage", { required: true });
    }
  };

  return (
    <div className="ms-content-wrapper ms-auth">
      <div className="regi-section">
        <Row>
          <Col md={5}>
            <div className="ms-auth-bg">
              <img src={loginLogo} />
            </div>
          </Col>
          <Col md={7}>
            <div className="regi-form">
              <form
                className="needs-validation"
                onSubmit={handleSubmit(onSubmit)}
                noValidate
              >
                <h3>
                  Registration : Step <span>{nextStep} of 6</span>
                </h3>
                <p>Please enter information to continue</p>
                <div className="form-vendor-regi">
                  <div className="form-vendor">
                    <Row>
                      <Col md={12}>
                        <label htmlFor="validationCustom01" class="form-label">
                          Please Introduce Yourself
                          <span className="error">*</span>
                        </label>
                        <div className="dropdown-group">
                          <select
                            id="designation"
                            name="designation"
                            className={`form-control ${
                              touchedError
                                ? errors.designation
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            {...register("designation", {
                              required: true,
                            })}
                          >
                            <option selected value="">
                              Select Designation
                            </option>

                            {designation.length > 0 ? (
                              designation.map((d) => {
                                return (
                                  <option
                                    value={d._id}
                                    selected={
                                      getValues("designation") == d._id
                                        ? "selected"
                                        : ""
                                    }
                                  >
                                    {upperCase(d.name)}
                                  </option>
                                );
                              })
                            ) : (
                              <option value={0}> Not found</option>
                            )}
                          </select>
                          {errors.designation?.type === "required" && (
                            <div className="invalid-select invalid-feedback">
                              The please introduce yourself field is required.
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col md={12}>
                        <div
                          style={{
                            marginTop: "20px",
                          }}
                        >
                          <label htmlFor="validationCustom03">
                            Name of Authorized Person
                            <span className="error">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              name="name"
                              id="name"
                              placeholder="Name of Authorized Person"
                              className={`form-control ${
                                touchedError
                                  ? errors.name
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                              {...register("name", {
                                required: true,
                              })}
                            />

                            {errors.name?.type === "required" && (
                              <div className="invalid-feedback">
                                The name of authorized person field is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col md={12}>
                        <label htmlFor="validationCustom04" class="form-label">
                          ID type<span className="error">*</span>
                        </label>
                        <div className="dropdown-group">
                          <select
                            id="idType"
                            name="idType"
                            className={`form-control ${
                              touchedError
                                ? errors.idType?.type === "required"
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            {...register("idType", {
                              required: true,
                            })}
                            onChange={(e) => {
                              setValue("idType", e.target.value);
                              setError("idType", "");
                              const selectedIdType = idTypes.find(
                                (type) => type._id == e.target.value
                              );
                              if (selectedIdType?._id) {
                                setIDType(selectedIdType?.name);
                              }
                            }}
                          >
                            <option selected disabled value="">
                              Select ID Type
                            </option>

                            {idTypes.length > 0 ? (
                              idTypes.map((idType) => {
                                return (
                                  <option
                                    value={idType._id}
                                    selected={
                                      getValues("idType") == idType._id
                                        ? "selected"
                                        : ""
                                    }
                                  >
                                    {upperCase(idType.name)}
                                  </option>
                                );
                              })
                            ) : (
                              <option value={0}> Not found</option>
                            )}
                          </select>
                          {errors.idType?.type === "required" && (
                            <div className="invalid-select invalid-feedback">
                              The ID type field is required.
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col md={12}>
                        <div
                          style={{
                            marginTop: "20px",
                          }}
                        >
                          <label htmlFor="validationCustom04">
                            ID Number<span className="error">*</span>
                          </label>
                          <div className="input-group">
                            {idType == "pan card" ? (
                              <>
                                <input
                                  type="text"
                                  name="idNumber"
                                  id="idNumber"
                                  placeholder="ID Number"
                                  className={`form-control ${
                                    touchedError
                                      ? errors.idNumber
                                        ? "is-invalid"
                                        : "is-valid"
                                      : ""
                                  }`}
                                  {...register("idNumber", {
                                    required: true,
                                    // pattern: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/gm,
                                  })}
                                />
                                {errors.idNumber?.type === "pattern" && (
                                  <div className="invalid-feedback">
                                    Please enter a valid pan number.
                                  </div>
                                )}
                              </>
                            ) : idType == "aadhar card" ? (
                              <>
                                <input
                                  type="text"
                                  name="idNumber"
                                  id="idNumber"
                                  placeholder="ID Number"
                                  className={`form-control ${
                                    touchedError
                                      ? errors.idNumber
                                        ? "is-invalid"
                                        : "is-valid"
                                      : ""
                                  }`}
                                  {...register("idNumber", {
                                    required: true,
                                    // pattern: /^[0-9]{12}$/gm,
                                  })}
                                />

                                {errors.idNumber?.type === "pattern" && (
                                  <div className="invalid-feedback">
                                    Please enter a valid aadhar number.
                                  </div>
                                )}
                              </>
                            ) : idType == "driving licence" ? (
                              <>
                                <input
                                  type="text"
                                  name="idNumber"
                                  id="idNumber"
                                  placeholder="ID Number"
                                  className={`form-control ${
                                    touchedError
                                      ? errors.idNumber
                                        ? "is-invalid"
                                        : "is-valid"
                                      : ""
                                  }`}
                                  {...register("idNumber", {
                                    required: true,
                                    // pattern:
                                    //   /^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/,
                                  })}
                                />
                                {errors.idNumber?.type === "pattern" && (
                                  <div className="invalid-feedback">
                                    Please enter a valid driving licence number.
                                  </div>
                                )}
                              </>
                            ) : (
                              <input
                                type="text"
                                name="idNumber"
                                id="idNumber"
                                placeholder="ID Number"
                                className={`form-control ${
                                  touchedError
                                    ? errors.idNumber
                                      ? "is-invalid"
                                      : "is-valid"
                                    : ""
                                }`}
                                {...register("idNumber", {
                                  required: true,
                                })}
                              />
                            )}

                            {errors.idNumber?.type === "required" && (
                              <div className="invalid-feedback">
                                The ID number field is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="form-group ">
                          <label
                            htmlFor="foodLicence"
                            className="form-label customLabel"
                          >
                            ID Proof<span className="error">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              type="file"
                              id="idImage"
                              placeholder="Upload ID Proof"
                              name="idImage"
                              accept="image/*"
                              className={`uploadFile form-control ${
                                touchedError
                                  ? errors.idImage?.type === "required"
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                              onChange={handleImage}
                            />

                            <div>
                              {errors.idImage?.type === "required" && (
                                <div className="invalid-field">
                                  The ID proof field is required.
                                </div>
                              )}
                            </div>
                          </div>

                          {displayImage && (
                            <div class="img-pos col-md-4">
                              <img
                                src={displayImage ? `${displayImage}` : ""}
                                alt="preview"
                                style={{ width: "200px", height: "50px" }}
                              />
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="form-group">
                          <label htmlFor="validationCustom03">
                            Email Address<span className="error">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              type="email"
                              name="email"
                              id="email"
                              placeholder="Email Address"
                              className={`form-control ${
                                touchedError
                                  ? errors.email
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                              {...register("email", {
                                required: true,
                                pattern:
                                  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                              })}
                            />

                            {errors.email?.type === "required" && (
                              <div className="invalid-feedback">
                                The email field is required.
                              </div>
                            )}
                            {errors.email?.type === "pattern" && (
                              <div className="invalid-feedback">
                                The email must be a valid email address.
                              </div>
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="form-group ">
                          <label htmlFor="validationCustom04">
                            Password<span className="error">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              type={
                                isPasswordVisible.newPassword
                                  ? "text"
                                  : "password"
                              }
                              name="password"
                              id="password"
                              placeholder="Password"
                              className={`form-control ${
                                touchedError
                                  ? errors.password
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              } no-transform`}
                              {...register("password", {
                                required: true,
                                pattern: /.{8,}/,
                              })}
                            />

                            <a
                              href="javascript:void(0);"
                              onClick={() => {
                                const update = {
                                  ...isPasswordVisible,
                                  newPassword: isPasswordVisible.newPassword
                                    ? false
                                    : true,
                                };
                                setIsPasswordVisible(update);
                              }}
                            >
                              {isPasswordVisible.newPassword ? (
                                <i className="fas fa-eye"></i>
                              ) : (
                                <i className="fas fa-eye-slash"></i>
                              )}
                            </a>

                            {errors.password?.type === "required" && (
                              <div className="invalid-feedback">
                                The password field is required.
                              </div>
                            )}
                            {errors.password?.type === "pattern" && (
                              <div className="invalid-feedback">
                                Password must be 8 characters long.
                              </div>
                            )}
                          </div>
                        </div>
                      </Col>

                      <Col md={12}>
                        <div className="form-group">
                          <label htmlFor="validationCustom04">
                            Re-Confirm Password<span className="error">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              type={
                                isPasswordVisible.confirmNewPassword
                                  ? "text"
                                  : "password"
                              }
                              name="confirmNewPassword"
                              id="confirmNewPassword"
                              placeholder="Confirm New Password"
                              className={`form-control ${
                                touchedError
                                  ? errors.confirmNewPassword
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              } no-transform`}
                              {...register("confirmNewPassword", {
                                required: true,
                                pattern: /.{8,}/,
                              })}
                            />

                            <a
                              href="javascript:void(0);"
                              onClick={() => {
                                const update = {
                                  ...isPasswordVisible,
                                  confirmNewPassword:
                                    isPasswordVisible.confirmNewPassword
                                      ? false
                                      : true,
                                };
                                setIsPasswordVisible(update);
                              }}
                            >
                              {isPasswordVisible.confirmNewPassword ? (
                                <i className="fas fa-eye"></i>
                              ) : (
                                <i className="fas fa-eye-slash"></i>
                              )}
                            </a>
                            {errors.confirmNewPassword?.type === "required" && (
                              <div className="invalid-feedback">
                                The re-confirm password field is required.
                              </div>
                            )}
                            {errors.confirmNewPassword?.type === "pattern" && (
                              <div className="invalid-feedback">
                                Re-confirm password must be 8 characters long.
                              </div>
                            )}
                            {errors.confirmNewPassword?.type === "manual" && (
                              <div className="invalid-feedback">
                                Password and re-confirm password does not match.
                              </div>
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="form-group ">
                          <label htmlFor="validationCustom04">
                            Phone<span className="error">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              type="number"
                              name="mobile"
                              id="mobile"
                              placeholder="Mobile"
                              className={`form-control ${
                                touchedError
                                  ? errors.mobile
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                              {...register("mobile", {
                                required: true,
                                pattern: /^[0-9]{10}$/gm,
                              })}
                            />

                            {errors.mobile?.type === "required" && (
                              <div className="invalid-feedback">
                                This field is required.
                              </div>
                            )}
                            {errors.mobile?.type === "pattern" && (
                              <div className="invalid-feedback">
                                Please enter a valid phone number.
                              </div>
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="form-group ">
                          <label htmlFor="validationCustom04">
                            Refer By<span className="error">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              name="referBy"
                              id="referBy"
                              {...register("referBy", {
                                required: false,
                              })}
                              placeholder="referBy"
                              className={`form-control ${
                                touchedError
                                  ? errors.referBy
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>

                <div className="form-row">{/* password and repassword */}</div>

                {loader ? (
                  <center>
                    <div
                      style={{
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Spinner
                        animation="border"
                        className="d-flex justify-content-center"
                      />
                    </div>
                  </center>
                ) : (
                  <button
                    className=" steps-btn"
                    type="submit"
                    onClick={() => {
                      // setLoader(true)
                      setTouchedError(true);
                    }}
                  >
                    Next <i>&#8594;</i>
                  </button>
                )}

                <p className="mb-0 mt-3 text-center">
                  Already have an account?{" "}
                  <Link to="/login" className="btn-link">
                    Login
                  </Link>
                </p>
              </form>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default StepOne;
