import React, { useState, useEffect } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import loginLogo from "../assets/img/logo.png";
import { getOutletDetail } from "../redux/slices/Outlet";
import { clearAllRegisterDetails } from "../redux/slices/SaveDetails";
import { login } from "../redux/slices/Registration";

import {
  outletStepFourSave,
  outletStepOneSave,
  outletStepThreeSave,
  outletStepTwoSave,
} from "../redux/slices/SaveOutletDetails";

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const dispatch = useDispatch();
  const history = useHistory();
  const [rememberMe, setRememberMe] = useState(0);
  const [rememberMeEmail, setRememberMeEmail] = useState("");
  const [rememberMePassword, setRememberMePassword] = useState("");
  const [touchedError, setTouchedError] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  useEffect(() => {
    setValue("email", localStorage.getItem("isRememberEmailVendor"));
    setValue("password", localStorage.getItem("isRememberPasswordVendor"));
    if (localStorage.getItem("isRememberMeVendor")) {
      setRememberMe(localStorage.getItem("isRememberMeVendor"));
    }
  }, []);

  const onSubmit = (data) => {
    const { email, password } = data;

    if (rememberMe) {
      setRememberMeEmail(email);
      setRememberMePassword(password);
    }
    dispatch(login({ email, password }))
      .unwrap()
      .then((resp) => {
        toast.success(resp.message);

        if (resp.user?.data?.data?.verifyStatus === false) {
          Swal.fire({
            icon: "warning",
            title: "Your account is pending please verify your outlet details",
            // text: "otp verified",
            confirmButtonColor: "#3ea99f",
            confirmButtonText: "Ok",
          }).then((res) => {
            if (res.isConfirmed) {
              dispatch(
                getOutletDetail({
                  outletId: localStorage.getItem("outletId"),
                })
              )
                .unwrap()
                .then((res) => {
                  dispatch(
                    outletStepOneSave({
                      vendorSubCategory: res.data.subCategory.map((item) => {
                        return {
                          value: item._id,
                          label: item.name,
                        };
                      }),
                      outletName: res.data.name,
                    })
                  );
                  dispatch(
                    outletStepTwoSave({
                      hoursData: res.data.openingHours,
                      cuisine: res.data.cuisineCategory.map((item) => item._id),
                      meals: res.data.mealsCategory.map((item) => item._id),
                      payment: res.data.paymentCategory.map((item) => item._id),
                      parking: res.data.parkingCategory.map((item) => item._id),
                      themes: res.data.themeType.map((item) => item._id),
                      otherAttractions: res.data.otherAttraction.map(
                        (item) => item._id
                      ),
                      moreFacilities: res.data.moreFacilities.map(
                        (item) => item._id
                      ),
                    })
                  );
                  dispatch(
                    outletStepThreeSave({
                      description: res.data.aboutVendor,
                    })
                  );
                  dispatch(
                    outletStepFourSave({
                      name: res.data.managerDetail.name,
                      email: res.data.managerDetail.email,
                      mobile: res.data.managerDetail.mobile,
                      password: res.data.managerDetail.password,
                      rePassword: res.data.managerDetail.password,
                    })
                  );
                  // window.location.reload();
                  history.push(
                    `/edit-outlet/${localStorage.getItem("outletId")}`
                  );
                })
                .catch((err) => {
                  // localStorage.clear("")
                  toast.error(err);
                });
            }
          });
        } else {
          let result = document.getElementById("rememberCheck").checked;
          if (result) {
            localStorage.setItem("isRememberEmailVendor", email);
            localStorage.setItem("isRememberPasswordVendor", password);
            localStorage.setItem("isRememberMeVendor", 1);
          } else {
            localStorage.setItem("isRememberEmailVendor", "");
            localStorage.setItem("isRememberPasswordVendor", "");
            localStorage.setItem("isRememberMeVendor", 0);
          }
        }
        // history.push("/dashboard");
      })
      .catch((err) => {
        // localStorage.clear("")
        return toast.error(err);
      });
  };
  return (
    <div className="ms-body ms-primary-theme ms-logged-out">
      <main className="body-content">
        <div className="ms-content-wrapper ms-auth">
          <Row>
            <Col md={5}>
              <div className="ms-auth-bg">
                <img src={loginLogo} />
              </div>
            </Col>
            <Col md={7}>
              <div className="regi-form two-step">
                <form
                  className="needs-validation"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <h3>Login to Account</h3>
                  <p>Please enter your email and password to continue</p>
                  <div className="form-vendor-regi">
                    <div className="form-vendor">
                      <label htmlFor="validationCustom08">
                        Email Address<span className="error">*</span>
                      </label>
                      <div className="input-group">
                        <input
                          type="email"
                          name="email"
                          id="email"
                          placeholder="Email Address"
                          className={`form-control ${
                            touchedError
                              ? errors.email
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}
                          {...register("email", {
                            required: true,
                            pattern:
                              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                          })}
                        />

                        {errors.email?.type === "required" && (
                          <div className="invalid-field">
                            The email field is required.
                          </div>
                        )}
                        {errors.email?.type === "pattern" && (
                          <div className="invalid-field">
                            The email must be a valid email address.
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="">
                      <label htmlFor="validationCustom09">
                        Password<span className="error">*</span>
                      </label>
                      <div className="input-group">
                        <input
                          type={isPasswordVisible ? "text" : "password"}
                          name="password"
                          id="password"
                          placeholder="Password"
                          className={`form-control ${
                            touchedError
                              ? errors.password
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          } no-transform`}
                          {...register("password", {
                            required: true,
                            pattern: /.{8,}/,
                          })}
                        />

                        <Link
                          to="#"
                          onClick={() => setIsPasswordVisible((prev) => !prev)}
                        >
                          {isPasswordVisible ? (
                            <i className="fas fa-eye"></i>
                          ) : (
                            <i className="fas fa-eye-slash"></i>
                          )}
                        </Link>
                        {errors.password?.type === "required" && (
                          <div className="invalid-field">
                            The password field is required.
                          </div>
                        )}
                        {errors.password?.type === "pattern" && (
                          <div className="invalid-field">
                            Password must be 8 characters long.
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="ms-checkbox-wrap">
                        <input
                          className="form-check-input"
                          id="rememberCheck"
                          checked={rememberMe == 0 ? "" : "checked"}
                          onChange={() => setRememberMe((prev) => !prev)}
                          type="checkbox"
                          defaultValue
                        />{" "}
                        <i className="ms-checkbox-check" />
                      </label>
                      <span> Remember Password </span>
                      <label className="d-block mt-3">
                        <Link to="/forgot-password" className="btn-link">
                          Forgot Password?
                        </Link>
                      </label>
                    </div>
                    <button
                      className=" steps-btn"
                      type="submit"
                      onClick={() => setTouchedError(true)}
                    >
                      Sign In
                    </button>
                    <p className="mb-0 mt-3 text-center">
                      Don't have an account?{" "}
                      <div>
                        <Link to="/default-register" className="btn-link">
                          Create Account
                        </Link>
                      </div>
                    </p>
                  </div>
                </form>
              </div>
            </Col>
          </Row>

          <Modal
            className="modal-min"
            // show={this.state.show1}
            // onHide={this.handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body className="text-center">
              <button
                type="button"
                className="close"
                //   onClick={this.handleClose}
              >
                <span aria-hidden="true">×</span>
              </button>
              <i className="flaticon-secure-shield d-block" />
              <h1>Forgot Password?</h1>
              <p>Enter your email to recover your password</p>
              <form method="post">
                <div className="ms-form-group has-icon">
                  <input
                    type="text"
                    placeholder="Email Address"
                    className="form-control"
                    name="forgot-password"
                  />{" "}
                  <i className="material-icons">email</i>
                </div>
                <button type="submit" className="btn btn-primary shadow-none">
                  Reset Password
                </button>
              </form>
            </Modal.Body>
          </Modal>
        </div>
      </main>
    </div>
  );
};

export default Login;
