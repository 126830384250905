import axios from "axios";
import apiPath, { BASE_URL } from "./apiPath";
import authHeader from "./authHeader";

const getOrdersApi = (props) => {
    return axios.post(BASE_URL + apiPath.getOrders, props, authHeader());
};
const updateOrdersApi = (props) => {
    return axios.post(BASE_URL + apiPath.updateOrder, props, authHeader());
}
const getOrderDetailsApi = (props) => {
    return axios.post(BASE_URL + apiPath.getOrderDetails, props, authHeader());
}
const verifyOrderApi = (props) => {
    return axios.post(BASE_URL + apiPath.verifyOrder, props, authHeader());
}
const OrdersApi = {
    getOrdersApi,
    updateOrdersApi,
    getOrderDetailsApi,
    verifyOrderApi
}

export default OrdersApi;