import React, { useState, Fragment, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../assets/css/menu.css";
import { toast } from "react-toastify";
import { addPromotion, getPromotionList } from "../../../redux/slices/Discount";
import ReactPaginate from "react-paginate";
import PromotionTableRow from "./PromotionTableRow";
import { Col, Row, Spinner } from "react-bootstrap";
import { upperCase } from "../../../constants";
// import { DownloadTableExcel } from "react-export-table-to-excel";
import { downloadExcel } from "../../../util/fn";
import ModalAdd from "../Discount/ModalAdd";

// import { nanoid } from "nanoid";
// import "./App.css";
// import data from "./mock-data.json";


const PromotionTable = () => {
  const { promotionList, promotionData } = useSelector(
    (state) => state.discount
  );
  const { outletList } = useSelector((state) => state.outlet);
  const tableRef = useRef(null);
  const [loader, setLoader] = useState(false)
  const itemsPerPage = 10;
  const dispatch = useDispatch();
  const [addFormData, setAddFormData] = useState({
    amount: "",
    title: "",
    description: "",
    discountType: "percent",
    discountCategory: "flat",
    startDate: "",
    endDate: "",
    image: "",
    startTime:"",
    status: "",
    endTime:""
  });

  const [image, setImage] = useState()
  const [currentItems, setCurrentItems] = useState(null);
  const [callback, setCallback] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [selectedOutlet, setSelectedOutlet] = useState({
    outletId: "",
    startDate: "",
    endDate: "",
  });
  const [overallData, setOverallData] = useState({ promotionData: promotionData?.totalPromotion, totalActive: promotionData?.totalActive, totalInactive: promotionData?.totalInactive });

  const [selectedOutletId, setSelectedOutletId] = useState(localStorage.getItem("outletId"))
  const [minEndDate, setMinEndDate] = useState();
  const [maxStartDate, setMaxStartDate] = useState();
  const [modal1, setModal1] = useState(false)

  // Fetch items from another resources.
  

  useEffect(() => {
    if (promotionList) {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(promotionList.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(promotionList.length / itemsPerPage));
    } else {
      setCurrentItems(null);
      setPageCount(0);
    }
  }, [itemOffset, itemsPerPage, promotionList, callback]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % promotionList.length;
    setItemOffset(newOffset);
  };
  const handleAddFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    if (fieldName != "image") {
      const fieldValue = event.target.value;
      const newFormData = { ...addFormData };
      newFormData[fieldName] = fieldValue;
      setAddFormData(newFormData);
    }

  };

  const handleAddFormSubmit = (event) => {
    event.preventDefault();
    //formdata
    const data = new FormData();
    setLoader(true)
    const addOutletId = selectedOutletId ? selectedOutletId : localStorage.getItem("outletId");
    data.append("outletId", addOutletId);
    // data.append("image", image);
    if (image && image.length > 0) {
      data.append("image", image[0]);
    }

    for (const key in addFormData) {

      if (key !== "rePassword" && key !== "other" && key !== "image") {
        data.append(key, addFormData[key]);

      }
    }
    if (addOutletId) {
      dispatch(addPromotion(data))
        .unwrap()
        .then((res) => {
          setLoader(false)
          toast.success(res.message);
          dispatch(
            getPromotionList({
              outletId: selectedOutletId ? selectedOutletId : localStorage.getItem("outletId"),
            })
          );
          const form = document.getElementById("menu_form");
          form.reset();
        })
        .catch((err) => {
          setLoader(false)
          toast.error(err);
        });
    } else {
      toast.error("Please Select outlet.");
    }

  };
  const filterData = (e) => {
    setSelectedOutletId(e.target.value)
    setSelectedOutlet({
      ...selectedOutlet,
      outletId: e.target.value,
    });

    if (e.target.value == '') {

      setOverallData({ promotionData: 0, totalActive: 0, totalInactive: 0 })
      setCurrentItems(null);
    } else {
      const startDate = document.getElementById('startDate').value;
      const endDate = document.getElementById('endDate').value;
      dispatch(
        getPromotionList({
          startDate,
          endDate,
          outletId: e.target.value,
        })
      );
    }
  };
  useEffect(() => {
    dispatch(
      getPromotionList({
        outletId: localStorage.getItem("outletId"),
      })
    );
    if (localStorage.getItem("outletId")) {
      setSelectedOutlet({
        ...selectedOutlet,
        outletId: localStorage.getItem("outletId"),
      });
    }
  }, [dispatch, callback]);

  const downloadExcelHandler = () => {
    let data = [];
    if (currentItems && currentItems.length > 0) {
      let count = 1;
      currentItems.forEach(obj => {
        data.push({
          'Sr.': count,
          'Price (Per Person)': obj?.price,
          'Discount': obj?.discountAmount,
          'Title': obj?.title,
          'Description': obj?.description,
          'Start Date': obj?.startDate,
          'End Date': obj?.endDate,
          // 'Image':`<img src="${obj?.image}" alt=""/>`,
          'Status': obj?.status,
        })
        count = count + 1;
      })
    } else {
      data.push({
        'Sr.': '',
        'Price (Per Person)': '',
        'Discount': '',
        'Title': '',
        'Description': '',
        'Start Date': '',
        'End Date': '',
        // 'Image':`<img src="${obj?.image}" alt=""/>`,
        'Status': '',
      })
    }
    downloadExcel(data, "Promotions");
  };

  const filterHandler = () => {
    const startDate = document.getElementById('startDate').value;
    const endDate = document.getElementById('endDate').value;
    dispatch(
      getPromotionList({
        startDate,
        endDate,
        outletId: selectedOutletId,
      })
    ).then((res) => {
      // paginateEffect(res.payload.data);
    });
  }

  const handleStartDate = (e) => {
    if (e.target.value) {
      setMinEndDate(e.target.value)
    } else {
      setMinEndDate("")
    }
  }

  const handleEndDate = (e) => {
    if (e.target.value) {
      setMaxStartDate(e.target.value)
    } else {
      setMaxStartDate("")
    }
  }

  useEffect(() => {
    setOverallData({ promotionData: promotionData?.totalPromotion, totalActive: promotionData?.totalActive, totalInactive: promotionData?.totalInactive })
  }, [promotionData, callback])

  useEffect(() => {
    setCurrentItems([])

    dispatch(
      getPromotionList({
        outletId: selectedOutletId ? selectedOutletId : localStorage.getItem("outletId"),
      })
    );
  }, [callback])

  console.log("callback", callback, currentItems)
  return (
    <form className="bg-clr" id="menu_form" onSubmit={handleAddFormSubmit}>
      <div className="menu">
        <Row>
          <Col md={6}>
            <div className="menu-selectiom">
              <h4 className="menu-h2">Promotions | Buffet</h4>
            </div>
          </Col>
          <Col md={6}>
            {localStorage.getItem("vendorType") === "multiple" && (
              <div
                className="outletDropdown"
                style={{
                  padding: "0 30px 0",
                  display: "inline-block",
                  width: "100%",
                }}
              >
                <div className="float-right d-flex">
                  <label
                    htmlFor="validationCustom01"
                    className="form-label mr-3 mt-2"
                  >
                    Select Outlet
                  </label>
                  <div className="dropdown-group form-vendor-regi">
                    <select
                      className="form-control"
                      id="validationCustom01"
                      name="outletId"
                      onChange={filterData}
                      value={selectedOutlet.outletId}
                    >
                      <option value={''}>Please Select</option>
                      {outletList.length ? (
                        outletList.map((item) => {
                          return (
                            <option value={item.outletId}>
                              {upperCase(item.outletName)}
                            </option>
                          );
                        })
                      ) : (
                        <option value={0}> Not found</option>
                      )}
                    </select>
                    {/* <div className="invalid-feedback">
                            Please Select a valid Designation.
                          </div> */}
                  </div>
                </div>
              </div>
            )}
          </Col>
        </Row>
        <Row className="px-3 mt-3 mb-3">
          <Col md={4}>
            <div className="startDate">
              {/* date picker input */}
              <label htmlFor="validationCustom01" className="form-label">
                Start Date
              </label>
              <input
                type="date"
                className="form-control"
                id="startDate"
                name="startDate"
                placeholder="Start Date"
                max={maxStartDate}
                onChange={handleStartDate}
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="endDate">
              <label htmlFor="validationCustom01" className="form-label">
                End Date
              </label>
              <input
                type="date"
                className="form-control"
                id="endDate"
                name="endDate"
                placeholder="End Date"
                min={minEndDate}
                onChange={handleEndDate}
              />
            </div>
          </Col>
          <Col md={2}>
            <button type="button" onClick={filterHandler} className="excle-dowm"> Filter </button>
          </Col>
          <Col md={2}>
            {/* <DownloadTableExcel
              filename="Promotions"
              sheet="Promotionss"
              currentTableRef={tableRef.current}
            >
              <button className="excle-dowm"> Export excel </button>
            </DownloadTableExcel> */}
            <button type="button" onClick={downloadExcelHandler} className="excle-dowm"> Export excel </button>
          </Col>
         
        </Row>
        <div className="dh-section mt-4">
          {/* <div className="row">
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                <div className="ms-card-body media">
                  <div className="media-body">
                    <span className="black-text">
                      <strong>Total Promotion</strong>
                    </span>
                    <h2>{overallData?.totalPromotion ?? 0}</h2>
                  </div>
                </div>

              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
              
                <div className="ms-card-body media">
                  <div className="media-body">
                    <span className="black-text">
                      <strong>Active Coupon</strong>
                    </span>
                    <h2>{overallData?.totalActive ?? 0}</h2>
                  </div>
                </div>
              
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                <div className="ms-card-body media">
                  <div className="media-body">
                    <span className="black-text">
                      <strong>InActive Coupon</strong>
                    </span>
                    <h2>{overallData?.totalInactive ?? 0}</h2>
                  </div>
                </div>
              
              </div>
            </div>
          </div> */}
          <div className='row'>
        {selectedOutletId&&  <Col md={2}>
            <button type="button" onClick={()=>setModal1(true)} className="excle-dowm"> Add Promotion </button>
          </Col>}
          </div>
        </div>
        <table className="menu-table mt-4" ref={tableRef}>
          <caption>Coupons</caption>
          <thead>
            <tr>
              <th>Sr.</th>
              <th>Price (Per Person)</th>
              <th>Discount (%)</th>

              <th>Type</th>
              <th>Title</th>
              <th>Description</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Image</th>
              <th>Status</th>
              <th>Enter/Update/Delete</th>
            </tr>
          </thead>
          <tbody>
           
            {/* <tr className="form-vendor-regi">
              <td>0</td>

              <td>
                <input
                  type="text"
                  name="amount"
                  required="required"
                  placeholder="Enter Amount..."
                  onChange={handleAddFormChange}
                />
              </td>
              <td>
                <input
                  type="number"
                  name="discount"
                  required="required"
                  placeholder="Enter Discount..."
                  onChange={handleAddFormChange}
                />
              </td>
              <td>
                <input
                  type="text"
                  name="title"
                  required="required"
                  placeholder="Enter title..."
                  onChange={handleAddFormChange}
                />
              </td>
              <td>
                <input
                  type="text"
                  name="description"
                  required="required"
                  placeholder="Enter description..."
                  onChange={handleAddFormChange}
                />
              </td>
              <td>
                <input
                  type="date"
                  name="startDate"
                  required="required"
                  placeholder="Enter Start Date..."
                  onChange={handleAddFormChange}
                />
              </td>
              <td>
                <input
                  type="date"
                  name="endDate"
                  required="required"
                  placeholder="Enter End Date..."
                  onChange={handleAddFormChange}
                />
              </td>
             
              <td>
                <input
                  type="time"
                  name="startTime"
                  required="required"
                  placeholder="Enter Start startTime..."
                  onChange={handleAddFormChange}
                />
              </td>
              <td>
                <input
                  type="time"
                  name="endTime"
                  required="required"
                  placeholder="Enter Start startTime..."
                  onChange={handleAddFormChange}
                />
              </td>
              <td>
                <input
                  type="file"
                  name="image"
                  placeholder="Select image..."
                  accept="image/*"
                  onChange={(e) => { setImage(e.target.files) }}
                />
              </td>
              <td>
                <select
                  // className="form-control"
                  name="status"
                  onChange={handleAddFormChange}
                  required
                >
                  <option value="">Select Status</option>
                  <option value="active">Active</option>
                  <option value="inactive">InActive</option>
                </select>
              </td>
              <td>
                {
                  loader ?
                    <center>
                      <div
                        style={{
                          flex: 1,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Spinner
                          animation="border"
                          className="d-flex justify-content-center"
                        />
                      </div>
                    </center> :
                    <button
                      className="ms-btn-icon-outline btn-square btn-success"
                      type="submit"
                    >
                      <i className="flaticon-tick-inside-circle mr-0"></i>
                    </button>
                }
              </td>
            </tr> */}
            {currentItems &&
              currentItems.map((coupon, index) => (
                <Fragment>
                  {
                    <PromotionTableRow
                      coupon={coupon}
                      index={promotionList?.indexOf(coupon)}
                      outlet={selectedOutlet.outletId}
                      setCallback={setCallback}
                      callback={callback}
                    />
                  }
                </Fragment>
              ))}
          </tbody>
        </table>
        {currentItems && (
          <ReactPaginate
            nextLabel="Next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="< Previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        )}
      </div>
      <ModalAdd
       data={''}
        handleClose={() => {
          setModal1(false)
          dispatch(
            getPromotionList({
              outletId: selectedOutletId ? selectedOutletId : localStorage.getItem("outletId"),
            })
          );
        }}
        show={modal1}
      
        outletId={selectedOutletId}
        setCallback={''}
        callback={''}
      />
    </form>
  );
};

export default PromotionTable;

