import React, { Component } from 'react';
import Sidenavigation from '../layouts/Sidenavigation';
import Topnavigation from '../layouts/Topnavigation';
import Gridcontent from '../sections/Menugrid/Gridcontent'
import Quickbar from '../layouts/Quickbar';

class Menugrid extends Component {
    render() {
        return (
           <Gridcontent/> 
        );
    }
}

export default Menugrid;