import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import dashboardApis from "../services/dashboardApis";
export const getDashboardData = createAsyncThunk(
    "dashboard/getDashboardData",
    async (props, thunkAPI) => {
        try {
            const response = await dashboardApis.getDashboardData(props);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
const initialState = {
    dashboardData: null,
}
const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    extraReducers: {
        [getDashboardData.fulfilled]: (state, action) => {
            state.dashboardData = action.payload.data;
        },
        [getDashboardData.rejected]: (state) => {
            state.dashboardData = null;
        }
    }
})

const { reducer } = dashboardSlice;
export default reducer;