// import React, { useEffect, useRef, useState } from "react";
// import { Col, Row, Spinner } from "react-bootstrap";
// import { toast } from "react-toastify";
// import { useDispatch, useSelector } from "react-redux";
// import { useForm } from "react-hook-form";
// import Select from "react-select";
// import loginLogo from "../../assets/img/logo.png";
// import useRequest2 from "../../hooks/useRequest2";
// import { upperCase } from "../../constants";
// import { registerStepTwo } from "../../redux/slices/Registration";
// import { stepTwoSave } from "../../redux/slices/SaveDetails";
// import useRequest from "../../hooks/useRequest";
// import { useParams } from "react-router-dom/cjs/react-router-dom.min";

// const StepTwo = ({ nextStep, handleNextStep }) => {
//   const dispatch = useDispatch();

//   const { userId } = useParams();
//   const [loader, setLoader] = useState(false);
//   const [managerId, setManagerId] = useState(localStorage.getItem("ManagerId"));
//   const { stepTwo } = useSelector((state) => state.saveDetails);
//   const { response: responseCategory, request: requestCategory } =
//     useRequest2();
//   const { request, response } = useRequest();
//   const { response: responseSubCategory, request: requestSubCategory } =
//     useRequest2();
//   const [touchedError, setTouchedError] = useState(false);
//   const [categories, setCategories] = useState([]);
//   const [subCategories, setSubCategories] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState("");

//   const {
//     register,
//     handleSubmit,
//     formState: { errors },
//     getValues,
//     setError,
//     setValue,
//   } = useForm();

//   useEffect(() => {
//     requestCategory("POST", "manageCategory/getCategorys", {
//       type: "vendorCategory",
//     });
//     requestSubCategory("POST", "manageCategory/getCategorys", {
//       type: "vendorSubCategory",
//     });
//   }, []);

//   useEffect(() => {
//     if (responseCategory) {
//       if (
//         responseCategory?.status == true ||
//         responseCategory?.status == "success"
//       ) {
//         setCategories(
//           responseCategory?.data?.length > 0 ? responseCategory?.data : []
//         );
//       }
//     }
//   }, [responseCategory]);

//   useEffect(() => {
//     if (responseSubCategory) {
//       if (
//         responseSubCategory?.status == true ||
//         responseSubCategory?.status == "success"
//       ) {
//         setSubCategories(
//           responseSubCategory?.data?.length > 0 ? responseSubCategory?.data : []
//         );
//       }
//     }
//   }, [responseSubCategory]);

//   useEffect(() => {
//     if (stepTwo) {
//       setSelectedCategory(stepTwo?.vendorCategory ?? "");
//       setValue("totalOutlet", stepTwo?.totalOutlet ?? "");
//       setValue("vendorCategory", stepTwo?.vendorCategory ?? "");
//       setValue("vendorName", stepTwo?.vendorName ?? "");
//       setValue("vendorSubCategory", stepTwo?.vendorSubCategory ?? "");
//       setValue("managerId", managerId ?? "");
//     }
//   }, [stepTwo]);
//   useEffect(() => {
//     if (userId) {
//       request("GET", `user/getRegisterDetail/${userId}`);
//     }
//   }, [userId]);

//   // useEffect(() => {
//   //   if (response) {
//   //     setManagerId(response?.data?.managerId);
//   //   }
//   // }, [response]);

//   const onSubmit = (data) => {
//     setLoader(true);
//     var getVendorID = "";
//     if (data?.vendorSubCategory) {
//       getVendorID = data?.vendorSubCategory?.map((val, i) => {
//         return val?.value;
//       });
//     }

//     dispatch(
//       registerStepTwo({
//         ...data,
//         vendorSubCategory: getVendorID,
//         managerId: managerId,
//       })
//     )
//       .unwrap()
//       .then((res) => {
//         toast.success(res.message);
//         dispatch(stepTwoSave(data));
//         if (data.vendorCategory === "62b429d59d1d55495a9056a9") {
//           handleNextStep(3);
//         } else {
//           handleNextStep(4);
//         }
//       })
//       .catch((err) => {
//         setLoader(false);
//         toast.error(err);
//       });
//   };
//   console.log("stepTwostepTwo", stepTwo);

//   return (
//     <div className="ms-content-wrapper ms-auth">
//       <div className="regi-section">
//         <Row>
//           <Col md={5}>
//             <div className="ms-auth-bg">
//               <img src={loginLogo} />
//             </div>
//           </Col>
//           <Col md={7}>
//             <div className="regi-form two-step">
//               <form
//                 className="needs-validation"
//                 onSubmit={handleSubmit(onSubmit)}
//                 noValidate
//               >
//                 <h3>
//                   Registration : Step <span>{nextStep} of 6</span>
//                 </h3>
//                 <p>Please enter information to continue</p>
//                 <div className="form-vendor-regi">
//                   <div className="form-vendor">
//                     <Row>
//                       <Col md={12}>
//                         <label htmlFor="vendorCategory">
//                           Select Your Category<span className="error">*</span>
//                         </label>
//                         <div className="dropdown-group">
//                           <select
//                             id="vendorCategory"
//                             name="vendorCategory"
//                             className={`form-control ${
//                               touchedError
//                                 ? errors.vendorCategory?.type === "required"
//                                   ? "is-invalid"
//                                   : "is-valid"
//                                 : ""
//                             }`}
//                             {...register("vendorCategory", {
//                               required: true,
//                             })}
//                             onChange={(e) => {
//                               if (e.target.value) {
//                                 setError("vendorCategory", "");
//                               } else {
//                                 setError("vendorCategory", {
//                                   type: "required",
//                                 });
//                               }
//                               setValue("vendorCategory", e.target.value);

//                               const selected = categories.find(
//                                 (cat) => cat._id == e.target.value
//                               );

//                               if (selected?._id) {
//                                 setSelectedCategory(selected?._id);
//                               } else {
//                                 setSelectedCategory("");
//                               }
//                               setValue("totalOutlet", "");
//                               setValue("vendorSubCategory", "");
//                             }}
//                           >
//                             <option value=""> Select Catgeory </option>

//                             {categories.length > 0 ? (
//                               categories.map((cat) => {
//                                 return (
//                                   <option
//                                     value={cat._id}
//                                     selected={
//                                       getValues("vendorCategory") == cat._id
//                                         ? "selected"
//                                         : ""
//                                     }
//                                   >
//                                     {upperCase(cat.name)}
//                                   </option>
//                                 );
//                               })
//                             ) : (
//                               <option value={0}> Categories not found</option>
//                             )}
//                           </select>
//                           {errors.vendorCategory?.type === "required" && (
//                             <div className="invalid-select invalid-feedback">
//                               The category field is required.
//                             </div>
//                           )}
//                         </div>
//                       </Col>

//                       {selectedCategory == "62b429b69d1d55495a9056a5" ||
//                       selectedCategory == "62b429c79d1d55495a9056a7" ? (
//                         <Col md={12}>
//                           <div
//                             style={{
//                               marginTop: "20px",
//                             }}
//                           >
//                             <label htmlFor="totalOutlet">
//                               Number of outlets<span className="error">*</span>
//                             </label>
//                             <div className="input-group">
//                               <input
//                                 type="text"
//                                 name="totalOutlet"
//                                 id="totalOutlet"
//                                 placeholder="Number of outlets"
//                                 className={`form-control ${
//                                   touchedError
//                                     ? errors.totalOutlet
//                                       ? "is-invalid"
//                                       : "is-valid"
//                                     : ""
//                                 }`}
//                                 {...register("totalOutlet", {
//                                   required: true,
//                                   pattern: /^[0-9]/gm,
//                                 })}
//                               />

//                               {errors.totalOutlet?.type === "required" && (
//                                 <div className="invalid-feedback">
//                                   The number of outlets field is required.
//                                 </div>
//                               )}

//                               {errors.totalOutlet?.type === "pattern" && (
//                                 <div className="invalid-feedback">
//                                   Please enter a valid number of outlets.
//                                 </div>
//                               )}
//                             </div>
//                           </div>
//                         </Col>
//                       ) : selectedCategory == "62b429d59d1d55495a9056a9" ? (
//                         <Col
//                           md={12}
//                           style={{
//                             marginTop: "20px",
//                           }}
//                         >
//                           <div>
//                             <label htmlFor="vendorSubCategory">
//                               Select Your Sub-Category
//                               <span className="error">*</span>
//                             </label>
//                             <div className="dropdown-group">
//                               <Select
//                                 isMulti
//                                 name="vendorSubCategory"
//                                 options={
//                                   subCategories.length > 0 &&
//                                   subCategories.map((c) => {
//                                     return {
//                                       value: c._id,
//                                       label: upperCase(c.name),
//                                     };
//                                   })
//                                 }
//                                 {...register("vendorSubCategory", {
//                                   required: true,
//                                 })}
//                                 defaultValue={getValues("vendorSubCategory")}
//                                 onChange={(action) => {
//                                   if (action?.length > 0) {
//                                     setError("vendorSubCategory", "");
//                                     setValue("vendorSubCategory", action);
//                                   } else {
//                                     setError("vendorSubCategory", {
//                                       type: "required",
//                                     });
//                                     setValue("vendorSubCategory", []);
//                                   }
//                                 }}
//                                 className={`basic-multi-select ${
//                                   touchedError
//                                     ? errors.vendorSubCategory
//                                       ? "is-invalid"
//                                       : "is-valid"
//                                     : ""
//                                 }`}
//                                 classNamePrefix="select"
//                               />
//                               {errors.vendorSubCategory?.type ===
//                                 "required" && (
//                                 <div className="invalid-select invalid-feedback">
//                                   The sub category field is required.
//                                 </div>
//                               )}
//                             </div>
//                           </div>
//                         </Col>
//                       ) : (
//                         ""
//                       )}

//                       <Col md={12}>
//                         <div
//                           style={{
//                             marginTop: "20px",
//                           }}
//                         >
//                           <label htmlFor="vendorName">
//                             Name of the Restaurent/Hotel
//                             <span className="error">*</span>
//                           </label>
//                           <div className="input-group">
//                             <input
//                               type="text"
//                               name="vendorName"
//                               id="vendorName"
//                               placeholder="Place Name"
//                               className={`form-control ${
//                                 touchedError
//                                   ? errors.vendorName
//                                     ? "is-invalid"
//                                     : "is-valid"
//                                   : ""
//                               }`}
//                               {...register("vendorName", {
//                                 required: true,
//                               })}
//                             />

//                             {errors.vendorName?.type === "required" && (
//                               <div className="invalid-feedback">
//                                 The name of Restaurent/Hotel field is required.
//                               </div>
//                             )}
//                           </div>
//                         </div>
//                       </Col>
//                     </Row>
//                   </div>
//                   {/* {formik.values.vendorCategory ===
//                     "62b429d59d1d55495a9056a9" && (
//                     <div className="">
//                       <div>
//                         <label htmlFor="validationCustom01">
//                           Select Your Sub-Category<span className="error">*</span>
//                         </label>
//                         <Select
//                           isMulti
//                           name="vendorSubCategory"
//                           value={formik.values.vendorSubCategory}
//                           options={
//                             vendorSubCategories.length > 0 &&
//                             vendorSubCategories.map((c) => {
//                               return { value: c._id, label: upperCase(c.name) };
//                             })
//                           }
//                           onChange={(action) => {
//                             formik.setFieldValue("vendorSubCategory", action);

//                           }}
//                           className="basic-multi-select"
//                           classNamePrefix="select"
//                         />
//                         <div>
//                           {isError && (
//                             <div className="error">
//                               Vendor sub categories are required
//                             </div>
//                           )}
//                         </div>

//                       </div>
//                     </div>
//                   )} */}
//                   {/* {(formik.values.vendorCategory ===
//                     "62b429b69d1d55495a9056a5" ||
//                     formik.values.vendorCategory ===
//                       "62b429c79d1d55495a9056a7") && (
//                     <div className="">
//                       <div>
//                         <label htmlFor="validationCustom02">
//                           Number of outlets<span className="error">*</span>
//                         </label>
//                         <div className="input-group">
//                           <input
//                             type="number"
//                             className="form-control"
//                             id="validationCustom02"
//                             name="totalOutlet"
//                             onChange={formik.handleChange}
//                             value={formik.values.totalOutlet}
//                             required
//                           />
//                           <div className="invalid-feedback">
//                             Please provide a valid Number.
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   )} */}
//                 </div>
//                 <div className="row">
//                   <div className="col-md-6">
//                     <button
//                       className="steps-btn float-left"
//                       type="button"
//                       onClick={() => handleNextStep(1)}
//                     >
//                       <i>&#8592;</i> Previous
//                     </button>
//                   </div>
//                   <div className="col-md-6">
//                     {loader ? (
//                       <center>
//                         <div
//                           style={{
//                             flex: 1,
//                             alignItems: "center",
//                             justifyContent: "center",
//                           }}
//                         >
//                           <Spinner
//                             animation="border"
//                             className="d-flex justify-content-center"
//                           />
//                         </div>
//                       </center>
//                     ) : (
//                       <button
//                         className="steps-btn"
//                         type="submit"
//                         onClick={() => setTouchedError(true)}
//                       >
//                         Next <i>&#8594;</i>
//                       </button>
//                     )}
//                   </div>
//                 </div>
//               </form>
//             </div>
//           </Col>
//         </Row>
//       </div>
//     </div>
//   );
// };

// export default StepTwo;

import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Select from "react-select";
import loginLogo from "../../assets/img/logo.png";
import useRequest2 from "../../hooks/useRequest2";
import { upperCase } from "../../constants";
import { registerStepTwo } from "../../redux/slices/Registration";
import { stepTwoSave } from "../../redux/slices/SaveDetails";
import useRequest from "../../hooks/useRequest";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const StepTwo = ({ nextStep, handleNextStep }) => {
  const dispatch = useDispatch();

  const { userId } = useParams();
  const [loader, setLoader] = useState(false);
  const [managerId, setManagerId] = useState(localStorage.getItem("ManagerId"));
  const { stepTwo } = useSelector((state) => state.saveDetails);
  const { response: responseCategory, request: requestCategory } =
    useRequest2();
  const { request, response } = useRequest();
  const { response: responseSubCategory, request: requestSubCategory } =
    useRequest2();
  const [touchedError, setTouchedError] = useState(false);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categoryType, setCAtegoryType] = useState("");
  const [outletNames, setOutletNames] = useState([]);

  useEffect(() => {
    if (response) {
      const outlets = response?.data?.totalOutlet ?? 1;
      setOutletNames(Array(outlets).fill(""));
    }
  }, [response]);

  const handleTotalOutletChange = (e) => {
    const totalOutlet = parseInt(e.target.value, 10) || 1;
    const namesArray = Array(totalOutlet).fill("");
    setOutletNames(namesArray);
    setValue("totalOutlet", totalOutlet);
  };

  const handleOutletNameChange = (index, value) => {
    const newOutletNames = [...outletNames];
    newOutletNames[index] = value;
    setOutletNames(newOutletNames);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
    setValue,
  } = useForm();

  useEffect(() => {
    requestCategory("POST", "manageCategory/getCategorys", {
      type: "vendorCategory",
    });
    requestSubCategory("POST", "manageCategory/getCategorys", {
      type: "vendorSubCategory",
    });
  }, []);

  useEffect(() => {
    if (responseCategory) {
      if (
        responseCategory?.status == true ||
        responseCategory?.status == "success"
      ) {
        setCategories(
          responseCategory?.data?.length > 0 ? responseCategory?.data : []
        );
      }
    }
  }, [responseCategory]);

  useEffect(() => {
    if (responseSubCategory) {
      if (
        responseSubCategory?.status == true ||
        responseSubCategory?.status == "success"
      ) {
        setSubCategories(
          responseSubCategory?.data?.length > 0 ? responseSubCategory?.data : []
        );
      }
    }
  }, [responseSubCategory]);

  useEffect(() => {
    if (userId) {
      request("GET", `user/getRegisterDetail/${userId}`);
    }
  }, [userId]);

  // useEffect(() => {
  //   if (response) {
  //     setManagerId(response?.data?.managerId);
  //   }
  // }, [response]);

  // const onSubmit = (data) => {
  //   setLoader(true);
  //   var getVendorID = "";
  //   if (data?.vendorSubCategory) {
  //     getVendorID = data?.vendorSubCategory?.map((val, i) => {
  //       return val?.value;
  //     });
  //   }

  //   dispatch(
  //     registerStepTwo({
  //       ...data,
  //       vendorSubCategory: getVendorID,
  //       outletName: outletNames,
  //       managerId: managerId,
  //     })
  //   )
  //     .unwrap()
  //     .then((res) => {
  //       let datass = {
  //         ...data,
  //         outletName: outletNames,
  //         vendorType: categoryType,
  //       };
  //       toast.success(res.message);
  //       dispatch(stepTwoSave(datass));
  //       if (datass.categoryType === "single") {
  //         handleNextStep(3);
  //       } else {
  //         handleNextStep(4);
  //       }
  //     })
  //     .catch((err) => {
  //       setLoader(false);
  //       toast.error(err);
  //     });
  // };

  const onSubmit = (data) => {
    if (categoryType == "multiple") {
      let isValid = true;
      for (let index = 0; index < outletNames.length; index++) {
        const name = outletNames[index];
        if (!name) {
          toast.error("Please Fill All Fields");
          isValid = false;
          break; // Stop the loop if any field is empty
        }
      }

      if (!isValid) {
        setLoader(false);
        return;
      }

      // setLoader(true);
      var getVendorID = "";
      if (data?.vendorSubCategory) {
        getVendorID = data?.vendorSubCategory?.map((val, i) => {
          return val?.value;
        });
      }

      dispatch(
        registerStepTwo({
          ...data,
          vendorSubCategory: getVendorID,
          outletName: outletNames,
          managerId: managerId,
        })
      )
        .unwrap()
        .then((res) => {
          let datass = {
            ...data,
            outletName: outletNames,
            vendorType: categoryType,
          };
          toast.success(res.message);
          dispatch(stepTwoSave(datass));
        
          if (datass.vendorType === "single") {
            handleNextStep(3);
          } else {
            handleNextStep(4);
          }
        })
        .catch((err) => {
          setLoader(false);
          toast.error(err);
        });
    } else {
      setLoader(true);
      var getVendorID = "";
      if (data?.vendorSubCategory) {
        getVendorID = data?.vendorSubCategory?.map((val, i) => {
          return val?.value;
        });
      }

      dispatch(
        registerStepTwo({
          ...data,
          vendorSubCategory: getVendorID,
          outletName: outletNames,
          managerId: managerId,
        })
      )
        .unwrap()
        .then((res) => {
          let datass = {
            ...data,
            outletName: outletNames,
            vendorType: categoryType,
          };
          toast.success(res.message);
          dispatch(stepTwoSave(datass));
        

          if (datass.vendorType === "single") {
            handleNextStep(3);
          } else {
            handleNextStep(4);
          }
        })
        .catch((err) => {
          setLoader(false);
          toast.error(err);
        });
    }
  };

  useEffect(() => {
    if (stepTwo) {
      setSelectedCategory(stepTwo?.vendorCategory ?? "");
      setValue("totalOutlet", parseInt(stepTwo?.totalOutlet) ?? "");
      setValue("vendorCategory", stepTwo?.vendorCategory ?? "");
      setValue("vendorName", stepTwo?.vendorName ?? "");
      setValue("vendorSubCategory", stepTwo?.vendorSubCategory ?? "");
      // setValue("vendorSubCategory", stepTwo?.vendorSubCategory ?? "");
      setCAtegoryType(stepTwo.vendorType);

      setValue("managerId", managerId ?? "");
      // setValue("outletName", outletNames ?? "");
      setOutletNames(stepTwo?.outletName);
    }
  }, [stepTwo]);

  useEffect(() => {
    setOutletNames(stepTwo?.outletName);
  }, [selectedCategory, stepTwo]);
  console.log("stepTwo", stepTwo, subCategories);
  return (
    <div className="ms-content-wrapper ms-auth">
      <div className="regi-section">
        <Row>
          <Col md={5}>
            <div className="ms-auth-bg">
              <img src={loginLogo} />
            </div>
          </Col>
          <Col md={7}>
            <div className="regi-form two-step">
              <form
                className="needs-validation"
                onSubmit={handleSubmit(onSubmit)}
                noValidate
              >
                <h3>
                  Registration : Step <span>{nextStep} of 6</span>
                </h3>
                <p>Please enter information to continue</p>
                <div className="form-vendor-regi">
                  <div className="form-vendor">
                    <Row>
                      <Col md={12}>
                        <label htmlFor="vendorCategory">
                          Select Your Category<span className="error">*</span>
                        </label>
                        <div className="dropdown-group">
                          <select
                            id="vendorCategory"
                            name="vendorCategory"
                            className={`form-control ${
                              touchedError
                                ? errors.vendorCategory?.type === "required"
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            {...register("vendorCategory", {
                              required: true,
                            })}
                            onChange={(e) => {
                              if (e.target.value) {
                                setError("vendorCategory", "");
                              } else {
                                setError("vendorCategory", {
                                  type: "required",
                                });
                              }
                              setValue("vendorCategory", e.target.value);

                              const selected = categories.find(
                                (cat) => cat._id == e.target.value
                              );

                              if (selected?._id) {
                                setSelectedCategory(selected?._id);
                                setCAtegoryType(selected.vendorType);
                              } else {
                                setSelectedCategory("");
                              }
                              setValue("totalOutlet", "");
                              setValue("vendorSubCategory", "");
                            }}
                          >
                            <option value=""> Select Catgeory </option>

                            {categories.length > 0 ? (
                              categories.map((cat) => {
                                return (
                                  <option
                                    value={cat._id}
                                    selected={
                                      getValues("vendorCategory") == cat._id
                                        ? "selected"
                                        : ""
                                    }
                                  >
                                    {upperCase(cat.name)}
                                  </option>
                                );
                              })
                            ) : (
                              <option value={0}> Categories not found</option>
                            )}
                          </select>
                          {errors.vendorCategory?.type === "required" && (
                            <div className="invalid-select invalid-feedback">
                              The category field is required.
                            </div>
                          )}
                        </div>
                      </Col>

                      <Col md={12}>
                        <div
                          style={{
                            marginTop: "20px",
                          }}
                        >
                          <label htmlFor="vendorName">
                            Name of the {categoryType=='multiple' ? "Hotel" :'Restaurent'}
                            <span className="error">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              name="vendorName"
                              id="vendorName"
                              placeholder="Place Name"
                              className={`form-control ${
                                touchedError
                                  ? errors.vendorName
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                              {...register("vendorName", {
                                required: true,
                              })}
                            />

                            {errors.vendorName?.type === "required" && (
                              <div className="invalid-feedback">
                                The name of Restaurent/Hotel field is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </Col>
                      {categoryType == "multiple" ? (
                        <Col md={12}>
                          <div
                            style={{
                              marginTop: "20px",
                            }}
                          >
                            <label htmlFor="totalOutlet">
                              Number of outlets<span className="error">*</span>
                            </label>
                            {/* <div className="input-group">
                              <input
                                type="text"
                                name="totalOutlet"
                                id="totalOutlet"
                                placeholder="Number of outlets"
                                className={`form-control ${
                                  touchedError
                                    ? errors.totalOutlet
                                      ? "is-invalid"
                                      : "is-valid"
                                    : ""
                                }`}
                                {...register("totalOutlet", {
                                  required: true,
                                  pattern: /^[0-9]/gm,
                                })}
                              />

                              {errors.totalOutlet?.type === "required" && (
                                <div className="invalid-feedback">
                                  The number of outlets field is required.
                                </div>
                              )}

                              {errors.totalOutlet?.type === "pattern" && (
                                <div className="invalid-feedback">
                                  Please enter a valid number of outlets.
                                </div>
                              )}
                            </div> */}
                            <div className="input-group">
                              <select
                                id="numberSelect"
                                name="number"
                                placeholder="Number of outlets"
                                className={`form-control ${
                                  touchedError
                                    ? errors.totalOutlet
                                      ? "is-invalid"
                                      : "is-valid"
                                    : ""
                                }`}
                                {...register("totalOutlet", {
                                  required: true,
                                })}
                                onChange={(e) => {
                                  handleTotalOutletChange(e);
                                }}
                              >
                                <option value={""} disabled selected>
                                  --Select outlet number--
                                </option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                              </select>

                              {errors.totalOutlet?.type === "required" && (
                                <div className="invalid-feedback">
                                  The number of outlets field is required.
                                </div>
                              )}

                              {errors.totalOutlet?.type === "pattern" && (
                                <div className="invalid-feedback">
                                  Please enter a valid number of outlets.
                                </div>
                              )}
                            </div>
                          </div>

                          <div
                            className={
                              outletNames?.length > 0 ? "form-fields" : ""
                            }
                          >
                            {outletNames?.map((name, index) => (
                              <div>
                                <label>
                                  Name of the Restaurant {index + 1}{" "}
                                  <span className="error">*</span>
                                </label>
                                <div
                                  className="input-group"
                                  style={{
                                    marginTop: "20px",
                                  }}
                                  key={index}
                                >
                                  <input
                                    type="text"
                                    id={`vendorName${index}`}
                                    name={`vendorName${index}`}
                                    placeholder="Enter name of the restaurant"
                                    className={`form-control ${
                                      touchedError
                                        ? errors[`vendorName${index}`]
                                          ? "is-invalid"
                                          : "is-valid"
                                        : ""
                                    }`}
                                    value={name}
                                    onChange={(e) =>
                                      handleOutletNameChange(
                                        index,
                                        e.target.value
                                      )
                                    }
                                    required
                                  />
                                  {errors[`vendorName${index}`]?.type ==
                                    "required" && (
                                    <div className="invalid-feedback">
                                      The name of the restaurant/hotel field is
                                      required.
                                    </div>
                                  )}

                                  {errors[`vendorName${index}`]?.type ==
                                    "pattern" && (
                                    <div className="invalid-feedback">
                                      The name of the restaurant/hotel format is
                                      invalid.
                                    </div>
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        </Col>
                      ) : categoryType == "single" ? (
                        <Col
                          md={12}
                          style={{
                            marginBottom: "20px",
                          }}
                        >
                          <div>
                            <label htmlFor="vendorSubCategory">
                              Select Your Sub-Category
                              <span className="error">*</span>
                            </label>
                            <div className="dropdown-group">
                              <Select
                                isMulti
                                name="vendorSubCategory"
                                options={
                                  subCategories.length > 0 &&
                                  subCategories.map((c) => {
                                    return {
                                      value: c._id,
                                      label: upperCase(c.name),
                                    };
                                  })
                                }
                                {...register("vendorSubCategory", {
                                  required: true,
                                })}
                                defaultValue={getValues("vendorSubCategory")}
                                onChange={(action) => {
                                  if (action?.length > 0) {
                                    setError("vendorSubCategory", "");
                                    setValue("vendorSubCategory", action);
                                  } else {
                                    setError("vendorSubCategory", {
                                      type: "required",
                                    });
                                    setValue("vendorSubCategory", []);
                                  }
                                }}
                                className={`basic-multi-select ${
                                  touchedError
                                    ? errors.vendorSubCategory
                                      ? "is-invalid"
                                      : "is-valid"
                                    : ""
                                }`}
                                classNamePrefix="select"
                              />
                              {errors.vendorSubCategory?.type ===
                                "required" && (
                                <div className="invalid-select invalid-feedback">
                                  The sub category field is required.
                                </div>
                              )}
                            </div>
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}
                    </Row>
                  </div>
                  {/* {formik.values.vendorCategory ===
                    "62b429d59d1d55495a9056a9" && (
                    <div className="">
                      <div>
                        <label htmlFor="validationCustom01">
                          Select Your Sub-Category<span className="error">*</span>
                        </label>
                        <Select
                          isMulti
                          name="vendorSubCategory"
                          value={formik.values.vendorSubCategory}
                          options={
                            vendorSubCategories.length > 0 &&
                            vendorSubCategories.map((c) => {
                              return { value: c._id, label: upperCase(c.name) };
                            })
                          }
                          onChange={(action) => {
                            formik.setFieldValue("vendorSubCategory", action);

                          }}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                        <div>
                          {isError && (
                            <div className="error">
                              Vendor sub categories are required
                            </div>
                          )}
                        </div>

                      </div>
                    </div>
                  )} */}
                  {/* {(formik.values.vendorCategory ===
                    "62b429b69d1d55495a9056a5" ||
                    formik.values.vendorCategory ===
                      "62b429c79d1d55495a9056a7") && (
                    <div className="">
                      <div>
                        <label htmlFor="validationCustom02">
                          Number of outlets<span className="error">*</span>
                        </label>
                        <div className="input-group">
                          <input
                            type="number"
                            className="form-control"
                            id="validationCustom02"
                            name="totalOutlet"
                            onChange={formik.handleChange}
                            value={formik.values.totalOutlet}
                            required
                          />
                          <div className="invalid-feedback">
                            Please provide a valid Number.
                          </div>
                        </div>
                      </div>
                    </div>
                  )} */}
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <button
                      className="steps-btn float-left "
                      type="button"
                      onClick={() => handleNextStep(1)}
                    >
                      <i>&#8592;</i> Previous
                    </button>
                  </div>
                  <div className="col-md-6">
                    {loader ? (
                      <center>
                        <div
                          style={{
                            flex: 1,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Spinner
                            animation="border"
                            className="d-flex justify-content-center"
                          />
                        </div>
                      </center>
                    ) : (
                      <button
                        className="steps-btn"
                        type="submit"
                        onClick={() => setTouchedError(true)}
                      >
                        Next <i>&#8594;</i>
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default StepTwo;
