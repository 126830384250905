import React, { Component } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "./Breadcrumb";
import $ from "jquery";

// Images
import customerimg1 from "../../../assets/img/foodtech/customer-1.jpg";
import { useSelector } from "react-redux";

// Array

const Content = () => {
  // Ratings
  const addstars = (e) => {
    var elem = e.target,
      parentTask = elem.closest(".ms-rating-item");
    $(parentTask).prevAll().removeClass("rated");
    $(parentTask).addClass("rated");
    $(parentTask).nextAll().addClass("rated");
  };
  const rating = [1, 2, 3, 4, 5];
  const getRating = (ratings, item) => {
    if (ratings >= item) {
      return true;
    } else {
      return false;
    }
  };

  const { outletReviews } = useSelector((state) => state.outlet);
  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-12">
          <Breadcrumb />
        </div>
      </div>
      <div className="ms-content-wrapper">
        <div className="row">
          {/* Recent Support Tickets */}
          <div className="col-xl-12 col-md-12">
            <div className="ms-panel ms-panel-fh">
              <div className="ms-panel-body p-0">
                <ul className="ms-list ms-feed ms-twitter-feed ms-recent-support-tickets">
                  {outletReviews.map((item, i) => (
                    <li key={i} className="ms-list-item">
                      {/* <Link to="/customer-review" className="media clearfix"> */}
                      <img
                        src={customerimg1}
                        className="ms-img-round ms-img-small"
                        alt="This is another feature"
                      />
                      <div className="media-body">
                        <div className="d-flex justify-content-between">
                          <div className="new">
                            <h5 className="ms-feed-user mb-0">{item.name}</h5>
                            <h6 className="ml-4 mb-0 text-red">{item.tag}</h6>
                          </div>
                          <ul className="ms-star-rating rating-fill rating-circle ratings-new">
                            {rating.map((i) => {
                              return (
                                <li
                                  className={`ms-rating-item ${
                                    getRating(item.rating, i) ? "rated" : ""
                                  }`}
                                  onClick={addstars}
                                >
                                  {" "}
                                  <i className="material-icons">star</i>
                                </li>
                              );
                            })}
                          </ul>
                        </div>{" "}
                        <span className="my-2 d-block">
                          {" "}
                          <i className="material-icons">date_range</i>{" "}
                          {item.date}
                        </span>
                        <p className="d-block">{item.comment}</p>
                        <div className="d-flex justify-content-between align-items-end">
                          <div className="ms-feed-controls">
                            {" "}
                            <span>
                              <i className="material-icons">chat</i> {item.chat}{" "}
                            </span>
                            <span>
                              <i className="material-icons">attachment</i>{" "}
                              {item.attachment}{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* </Link> */}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
