import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Breadcrumb from '../../Breadcrumb'
// import { Formik } from 'formik'
import Showformerror from "../../ErrorComponent"
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios'
import { BASE_URL } from '../../../redux/services/apiPath'
import authHeader from '../../../redux/services/authHeader'
import { toast } from 'react-toastify'
import ReactPaginate from 'react-paginate'

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    number: Yup.string().required('Number is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    desc: Yup.string().required('Description is required'),
});

const Content = () => {
    const [totalPages, setTotalPages] = useState(1)
    const [page, setPage] = useState(1)
    const [limit] = useState(10)
    const [currentItems, setList] = useState([])

    const handleSubmit = async (values, { resetForm }) => {
        const data = {
            name: values.name,
            mobile: values.number?.toString(),
            email: values.email,
            description: values.desc,
            vendorId: localStorage.getItem("vendorId") ? localStorage.getItem("vendorId") : ""
        }
        const res = await axios.post(BASE_URL + 'user/saveContactUs', data, authHeader());
        if (res.status == "200") {
            GetList()
            resetForm()
            toast.success(res?.data?.message)
        }
        else {
            toast.error("Something Went Wrong")
        }
    }


    const GetList = async () => {

        const res = await axios.get(BASE_URL + `user/getContactUs?per_page=${limit}&page=${page}&vendorId=${localStorage.getItem("vendorId")}`, authHeader());

        if (res.status == "200") {
            setList(res?.data?.data)
            setTotalPages(res?.data?.totalPage)
        }
        else {
            toast.error("Something Went Wrong")
        }
    }


    useEffect(() => {
        GetList()
    }, [page])



    const handlePageClick = (event) => {

        setPage(event.selected + 1)

    };

    console.log("currentItems", currentItems)

    return (
        <div>
            <div className='ms-content-wrapper'>
                <Breadcrumb
                    title="Help & Support"
                    links={[
                        { to: "/", name: "Home" },
                    ]}
                />
                <Container>
                    <Row>
                        <Col md={4}>
                            <div className='support-sect'>
                                <div className='img-icon'>
                                    <img src="https://cdn-icons-png.flaticon.com/512/104/104069.png?w=740&t=st=1663089367~exp=1663089967~hmac=1a1c0044b27b4a71b10263a45916271721ff9d9a144dae08e6b89aea87943975" alt='icon' />
                                </div>
                                <div className='detail'>
                                    <h3>Email</h3>
                                    <Link>Info@sortyfy.com</Link>
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className='support-sect'>
                                <div className='img-icon'>
                                    <img src="https://cdn-icons-png.flaticon.com/512/1180/1180109.png?w=740&t=st=1663089407~exp=1663090007~hmac=c0435c545bd2caec43447126c8fdb3fc60ee684c0f841c6b3a308eb93ddb4f42" alt='icon' />
                                </div>
                                <div className='detail'>
                                    <h3>Address</h3>
                                    <Link>Jaipur Rajashtan</Link>
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className='support-sect'>
                                <div className='img-icon'>
                                    <img src="https://cdn-icons-png.flaticon.com/512/1239/1239342.png?w=740&t=st=1663089489~exp=1663090089~hmac=969f4c0ec0d0578b4cbc16a7c9e82b7cae6c128907cd336d2ad6c8d2d6a40397" alt='icon' />
                                </div>
                                <div className='detail'>
                                    <h3>Contact</h3>
                                    <Link>+91 9509446022</Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className='form-admin support-form'>

                        <Formik
                            initialValues={{
                                name: '',
                                number: '',
                                email: '',
                                desc: ''
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting }) => (
                                <form onSubmit={handleSubmit}>


                                    <Row >
                                        <Col md={12} >
                                            <div className='heading-cont'>
                                                <h4>Contact Us</h4>
                                            </div>
                                        </Col>
                                        <Col md={6} className="m-0">
                                            <div className='form-group m-0 p-0'>
                                                <input type='text' placeholder='Name'
                                                    name='name'
                                                    onChange={handleChange}
                                                    value={values.name}
                                                />
                                                <Showformerror
                                                    name={errors.name && touched.name && errors.name}
                                                />
                                            </div>

                                        </Col>

                                        <Col md={6} className="m-0">
                                            <div className='form-group m-0 p-0'>
                                                <input type='number' placeholder='Number'
                                                    name='number'
                                                    onChange={handleChange}
                                                    value={values.number}
                                                />
                                            </div>
                                            <Showformerror
                                                name={errors.number && touched.number && errors.number}
                                            />

                                        </Col>
                                        <Col md={6} className="m-0">
                                            <div className='form-group m-0 p-0'>
                                                <input type='Email' placeholder='Email'
                                                    name='email'
                                                    onChange={handleChange}
                                                    value={values.email}
                                                />
                                            </div>
                                            <Showformerror
                                                name={errors.email && touched.email && errors.email}
                                            />
                                        </Col>
                                        <Col md={6} className="m-0">
                                            <div className='form-group m-0 p-0'>
                                                <textarea placeholder='desc'
                                                    name='desc'
                                                    onChange={handleChange}
                                                    value={values.desc}
                                                ></textarea>
                                            </div>
                                            <Showformerror
                                                name={errors.desc && touched.desc && errors.desc}
                                            />

                                        </Col>
                                        <Col md={3} className="m-0 p-0">
                                            <div className='submit-btn text-left p-0'>
                                                <button className="excle-dowm m-0" type='submit'> Send Query </button>
                                            </div>
                                        </Col>
                                    </Row>
                                </form>
                            )}
                        </Formik>

                    </div>

                    <div className='support-sect'>

                        <div className="row">
                            <div className="col-md-12">
                                <table className="contact-ablr mt-0" >

                                    <thead>
                                        <tr>

                                            <th>Name</th>
                                            <th>Number</th>
                                            <th>Email</th>
                                            <th>Description</th>

                                        </tr>
                                    </thead>
                                    <tbody>

                                        {currentItems &&
                                            currentItems.map((menu, index) => (
                                                <tr>
                                                    <td>
                                                        {menu.name}
                                                    </td>
                                                    <td>
                                                        {menu.mobile}
                                                    </td>
                                                    <td>
                                                        {menu.email}
                                                    </td>
                                                    <td>
                                                        {menu.description}

                                                    </td>
                                                </tr>
                                            ))}
                                        {/* </form> */}
                                    </tbody>
                                </table>

                            </div>


                        </div>
                        <div className="col-md-12 mt-2">
                            {
                                currentItems?.length && (
                                    <ReactPaginate
                                        nextLabel="Next >"
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={3}
                                        marginPagesDisplayed={2}
                                        pageCount={totalPages}
                                        previousLabel="< Previous"
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakLabel="..."
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        renderOnZeroPageCount={null}
                                    />
                                )
                            }
                        </div>
                    </div>


                </Container>


            </div>


        </div>
    )
}

export default Content