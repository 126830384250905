import React, { useEffect, useState } from "react";
import { Bar, HorizontalBar } from 'react-chartjs-2';
import { toast } from "react-toastify";
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { upperCase } from "../constants";
import { addCompetitor, getCompetitors } from "../redux/slices/Reports";
import { MdDelete } from "react-icons/md";
import { getAllOutlet, getAllOutlets } from "../redux/slices/Outlet";
import Breadcrumb from "./Breadcrumb";
import axios from "axios";
import { BASE_URL } from "../redux/services/apiPath";
import { Col, Row } from "react-bootstrap";
import authHeader from "../redux/services/authHeader";
import SelectCompitetor from "./SelectCompitetor";

const Competitor = () => {
  const [outLetID, setOutLetId] = useState("")
  const [fromDate, setFromDate] = useState("")
  const [toDate, setToDate] = useState("")
  const [showgraph, setShowGraph] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [tabledata, setTableData] = useState([])
  const [maxCheck, setMaxCheck] = useState(0)
  const [competionData, setCompetionData] = useState({})
  const [competionData1, setCompetionData1] = useState({})
  const [competionData2, setCompetionData2] = useState({})
  const dispatch = useDispatch();
  const [isError, setIserror] = React.useState(false);
  const [recallAPI, setRecall] = React.useState(false);
  const [selectedOutlet, setSelectedOutlet] = React.useState({
    outletId: localStorage.getItem("outletId"),
  });
  const { outletList } = useSelector((state) => state.outlet);
  // const { allOutlet } = useSelector((state) => state.outlet);

  const [areas, setArea] = useState("")
  const [selectedCompetitor, setSelectedCompetitor] = useState([])
  const [allOutlets, setAllOutlets] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    setError,
    clearErrors,
    control
  } = useForm();

  // const formik = useFormik({
  //   initialValues: {
  //     competitor: [],
  //     outletName: "",
  //   },
  //   validationSchema,
  //   onSubmit: (values) => {
  //     if (!values.competitor.length) {
  //       setIserror(true);
  //       return;
  //     } else {
  //       setIserror(false);
  //       let competitorValues = [];
  //       for (const key in values.competitor) {
  //         competitorValues.push(values.competitor[key].value);
  //       }
  //       dispatch(
  //         addCompetitor({
  //           outletId: selectedOutlet.outletId,
  //           competitor: values.competitor,
  //         })
  //       )
  //         .unwrap()
  //         .then((res) => {
  //           // formik.setFieldValue("competitor", []);
  //           toast.success(res.message);
  //         })
  //         .catch((err) => {
  //           toast.error(err);
  //         });
  //     }
  //   },
  // });
  // const validation = () => {
  //   var forms = document.getElementsByClassName("needs-validation");
  //   Array.prototype.filter.call(forms, function (form) {
  //     form.addEventListener(
  //       "submit",
  //       function (event) {
  //         if (form.checkValidity() === false) {
  //           event.preventDefault();
  //           event.stopPropagation();
  //         }
  //         form.classList.add("was-validated");
  //       },
  //       false
  //     );
  //   });
  // };



  const [token, setToken] = useState(localStorage.getItem("user"))

  // useEffect(() => {
  //   register("competitor", { required: true })

  //   if (allOutlet && allOutlet.length > 0) {

  //     let newAllOutlets = [];
  //     allOutlet.forEach(obj => {
  //       newAllOutlets.push({
  //         label: obj.name,
  //         value: obj._id
  //       })
  //     })
  //     setAllOutlets(newAllOutlets);
  //   }
  // }, [allOutlet])

  const onSubmit = (selectedCompetitors) => {
    setShowModal(false)
    dispatch(
      addCompetitor({
        outletId: outLetID,
        competitor: selectedCompetitors,
        // pincode: pincode
      })
    )
      .unwrap()
      .then((res) => {
        // formik.setFieldValue("competitor", []);
        toast.success(res.message);

        dispatch(
          getCompetitors({
            outletId: outLetID,
            // pincode:pincode
          })
        )
          .unwrap()
          .then((res) => {

            if (res && res.data) {
              setShowGraph(true)
              setTableData(res?.data)
              let allCompetitors = [];
              res.data.forEach(obj => {


                setSelectedCompetitor(obj.competitor);
                if (obj.competitor) {
                  obj.competitor.forEach(obj2 => {
                    allCompetitors.push(obj2.value)
                  })
                }
              })
              setValue('competitor', allCompetitors)
            }
          }

            // setValue("competitor",res?.data[0]?.competitor)
          );
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  useEffect(() => {
    dispatch(getAllOutlets({ vendorId: localStorage.getItem("vendorId") }));
    dispatch(getAllOutlet());
    if (localStorage.getItem("vendorType") === "single") {
      dispatch(
        getCompetitors({
          outletId: localStorage.getItem("outletId"),
        })
      )
        .unwrap()
        .then((res) => {
          setValue("competitor", res?.data[0]?.competitor)
        }

        );
    }



  }, [dispatch]);


  const getCompetitor = async (pincode) => {
    const datas = {
      outletId: outLetID,
      area: pincode
    }
    const res = await axios.post(`${BASE_URL}user/getOutletByArea`, datas, {
      headers: {
        "Authorization": "Bearer " + token
      }

    })

    if (res.data?.status == "success") {
      let newAllOutlets = [];
      res.data.data.forEach(obj => {
        newAllOutlets.push({
          label: obj.name,
          value: obj._id,
          address: obj?.vendorId?.address
        });
      });
      setAllOutlets(newAllOutlets);
      setShowModal(true)
    }
    else {
      toast.error("Vendor Not Found")
    }
  }
  const handleSearch = () => {

    const outletId = getValues('outletId');
    const pincode = getValues('pincode');


    if (outletId) {
      setSelectedOutlet({
        ...selectedOutlet,
        outletId: outletId,
      });

      if (pincode) {
        getCompetitor(pincode)
        // dispatch(getAllOutlets({ vendorId: localStorage.getItem("vendorId"), area: pincode }));
      }


    }
  }

  const handleCompetitor = (event) => {
    if (event) {
      // console.log(event)
      let pSearch = [];
      for (var i = 0; i < event.length; i++) {
        pSearch.push(event[i].value);
      }
      setSelectedCompetitor(event);
      setValue('competitor', pSearch);
    }
  };





  useEffect(() => {
    if (showgraph) {

      // getCompetitorData()
      dispatch(
        getCompetitors({
          outletId: outLetID,
          // pincode:pincode
        })
      )
        .unwrap()
        .then((res) => {

          if (res && res.data) {
            setShowGraph(true)
            setTableData(res?.data)
            let allCompetitors = [];
            res.data.forEach(obj => {

              // console.log(obj.competitor)
              setSelectedCompetitor(obj.competitor);
              if (obj.competitor) {
                obj.competitor.forEach(obj2 => {
                  allCompetitors.push(obj2.value)
                })
              }
            })
            setValue('competitor', allCompetitors)
          }
        }

          // setValue("competitor",res?.data[0]?.competitor)
        );
    }
  }, [showgraph, fromDate, toDate, recallAPI])



  // RecallAPI

  const DeleteComp = async (id) => {

    const res = await axios.post(BASE_URL + `user/removeCompetiter`, { id: id })
    if (res.status == "200") {
      setShowGraph(true)
      setRecall(!recallAPI)
    }
    else {
      toast.error("Something Went Wrong")
    }
  }

  return (
    <>
      <div className="ms-content-wrapper">
        <Breadcrumb
          title="Competitor"
          links={[
            { to: "/", name: "Home" },
          ]}
        />
        <div className="row">
          <div className="col-md-12">
            <div className="ms-panel ms-panel-fh">
              <div className="ms-panel-header">
                <h6>Add Competitors </h6>
              </div>
              <div className="ms-panel-body">
                <form
                  className="needs-validation"
                  // onSubmit={handleSubmit(onSubmit)}
                  noValidate
                >
                  <div className="form-row">
                    {/* {localStorage.getItem("vendorType") === "multiple" && ( */}
                    <>
                      <div className="col-xl-5 col-lg-5 col-md-5">
                        <div className="form-vendor-regi">
                          <div className="">
                            <div>
                              <label htmlFor="outletId">Select Outlet<span className="error">*</span></label>
                              <select
                                id="outletId"
                                name="outletId"
                                {...register("outletId", { required: true })}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={
                                  (e) => {
                                    setOutLetId(e.target.value)

                                    dispatch(
                                      getCompetitors({
                                        outletId: e.target.value,
                                        // pincode:pincode
                                      })
                                    )
                                      .unwrap()
                                      .then((res) => {

                                        if (res && res.data) {
                                          setMaxCheck(res?.maxCompetitor)
                                          setTableData(res?.data)
                                          let allCompetitors = [];
                                          res.data.forEach(obj => {

                                            // console.log(obj.competitor)
                                            setSelectedCompetitor(obj.competitor);
                                            if (obj.competitor) {
                                              obj.competitor.forEach(obj2 => {
                                                allCompetitors.push(obj2.value)
                                              })
                                            }
                                          })
                                          setValue('competitor', allCompetitors)
                                        }
                                      }

                                        // setValue("competitor",res?.data[0]?.competitor)
                                      );
                                    clearErrors("outletId")
                                    setShowGraph(false)
                                    setSelectedCompetitor([])
                                    setValue('competitor', "")
                                  }
                                }
                              >
                                <option value={""}>Please Select</option>
                                {outletList.length ? (
                                  outletList.map((item) => {
                                    return (
                                      <option value={item.outletId}>
                                        {upperCase(item.outletName)}
                                      </option>
                                    );
                                  })
                                ) : (
                                  <option value={0}> Not found</option>
                                )}
                              </select>
                              {errors.outletId?.type === "required" && (
                                <div className="error">
                                  This field is required.
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-5 col-lg-5 col-md-5">
                        <div className="form-vendor-regi">
                          <div className="">
                            <div>
                              <label htmlFor="pincode">Enter Competitor Area<span className="error">*</span></label>
                              {/* <label htmlFor="pincode">Pincode<span className="error">*</span></label> */}
                              <input
                                type="text"
                                className="form-control"
                                id="pincode"
                                name="pincode"
                                {...register("pincode", { required: true })}
                                placeholder="Enter Competitor"
                                onChange={
                                  (e) => {
                                    setArea(e.target.value)
                                    clearErrors("pincode")
                                    setSelectedCompetitor([])
                                    setValue('competitor', "")
                                  }
                                }
                              />

                              {errors.pincode?.type === "required" && (
                                <div className="error">
                                  This field is required.
                                </div>
                              )}
                              {/* {errors.pincode?.type === "pattern" && (
                                <div className="error">
                                  Please enter valid pincode.
                                </div>
                              )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-2 col-md-2">
                        <button type="button" onClick={handleSearch} className="excle-dowm">Search Competitors</button>
                      </div>
                    </>
                    {/* )} */}
                  </div>
                  {/* <div className="form-row">
                    <div className="col-xl-5 col-lg-5 col-md-5">
                      <div className="form-vendor-regi">
                        <div className="">
                          <div>
                            <label htmlFor="competitor"> Select Your Competitors<span className="error">*</span></label>

                            <Select
                              isMulti
                              name="competitor"
                              options={allOutlets}
                              value={selectedCompetitor}
                              onChange={handleCompetitor}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              isOptionDisabled={() => selectedCompetitor?.length >= 5}
                            />

                            {errors.competitor?.type === "required" && (
                              <div className="error">
                                This field is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="form-vendor-regi">
                        <button
                          className="btn btn-primary"
                          type="submit"

                        >
                          Save
                        </button>

                      </div>
                    </div>

                  </div> */}

                </form>


              </div>

              {

                <div className="border-new">
                  <Row>



                    {/* <Col md={12}>
                      <div className="date-filter">
                        <div className="date-ui mx-5">
                          <span>From Date</span>
                          <input
                            type="date"
                            className="form-control"
                            onChange={(e) => {
                              setFromDate(e.target.value)
                            }}
                            value={fromDate}
                          />
                        </div>
                        <div className="date-ui mr-3 " >
                          <span> To Date</span>
                          <input
                            type="date"
                            className="form-control"
                            onChange={(e) => {
                              setToDate(e.target.value)
                            }}
                            value={toDate}
                          />
                        </div>

                      </div>
                    </Col> */}

                    <Col md={12}>

                      {/* <Barchart1
                        isHoriz={false} // Example prop value
                        about="Revenue Graph" // Example prop value
                        data={competionData2} // Example prop value
                      /> */}

                      <div className="mx-5" >
                        <table
                          className="table  thead-primary w-80"
                        >
                          <thead>
                            <tr>
                              <th>Competitors</th>
                              <th>Address</th>
                              <th>Actions</th>
                            </tr>
                          </thead>

                          <tbody>
                            {tabledata?.map((val, i) => (

                              <tr key={i}>
                                <td>{val?.name}</td>
                                <td>{val?.address}</td>
                                {/* <td></td> */}
                                <td>
                                  <div className="mx-2 cursor-pointer" onClick={(e) => {
                                    e.preventDefault()
                                    setShowModal(false)
                                    DeleteComp(val._id)
                                  }}>
                                    <MdDelete />
                                  </div>
                                </td>
                              </tr>
                            ))

                            }
                          </tbody>
                        </table>
                      </div>
                    </Col>
                    {/* <Col md={6}>

                      <Barchart1
                        isHoriz={false} // Example prop value
                        about="FootFall Graph" // Example prop value
                        data={competionData} // Example prop value
                      />
                    </Col> */}
                    {/* <Col md={6}>

                      <Barchart1
                        isHoriz={false} // Example prop value
                        about="Per Person Revenue Graph" // Example prop value
                        data={competionData1} // Example prop value
                      />
                    </Col> */}


                  </Row>
                </div>
              }
              {/* <Barchart1 /> */}
            </div>
          </div>
        </div>
        <SelectCompitetor
          show={showModal}
          callBack={(selectedCompetitor) => {
            onSubmit(selectedCompetitor)
          }}
          onHide={() => {
            setShowModal(false)
          }}
          maxCheck={maxCheck}
          allOutlets={allOutlets}
        />
      </div>
    </>

  );
};

export default Competitor;

