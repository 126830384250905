import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { login } from "../../../redux/slices/Registration";
import loginLogo from "../../../assets/img/logo.png";
import { useFormik } from "formik";
import { clearAllRegisterDetails } from "../../../redux/slices/SaveDetails";
import Swal from "sweetalert2";
import { getOutletDetail } from "../../../redux/slices/Outlet";
import {
  outletStepFourSave,
  outletStepOneSave,
  outletStepThreeSave,
  outletStepTwoSave,
} from "../../../redux/slices/SaveOutletDetails";
const Login = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [auth, setAuth] = useState({
    email: "",
    password: "",
  });
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Must be a valid email")
      .max(255)
      .required("Email is required"),
    password: Yup.string().required("No password provided."),
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: (values) => {
      console.log("values",values)
      dispatch(login(values))
        .unwrap()
        .then((resp) => {

          toast.success(resp.message);
          if (resp.user?.data?.data?.verifyStatus === false) {
            Swal.fire({
              icon: "warning",
              title:
                "Your account is pending please verify your outlet details",
              // text: "otp verified",
              confirmButtonColor: "#3ea99f",
              confirmButtonText: "Ok",
            }).then((res) => {
              if (res.isConfirmed) {
                dispatch(
                  getOutletDetail({
                    outletId: localStorage.getItem("outletId"),
                  })
                )
                  .unwrap()
                  .then((res) => {
                    dispatch(
                      outletStepOneSave({
                        vendorSubCategory: res.data.subCategory.map((item) => {
                          return {
                            value: item._id,
                            label: item.name,
                          };
                        }),
                        outletName: res.data.name,
                      })
                    );
                    dispatch(
                      outletStepTwoSave({
                        hoursData: res.data.openingHours,
                        cuisine: res.data.cuisineCategory.map(
                          (item) => item._id
                        ),
                        meals: res.data.mealsCategory.map((item) => item._id),
                        payment: res.data.paymentCategory.map(
                          (item) => item._id
                        ),
                        parking: res.data.parkingCategory.map(
                          (item) => item._id
                        ),
                        themes: res.data.themeType.map((item) => item._id),
                        otherAttractions: res.data.otherAttraction.map(
                          (item) => item._id
                        ),
                        moreFacilities: res.data.moreFacilities.map(
                          (item) => item._id
                        ),
                      })
                    );
                    dispatch(
                      outletStepThreeSave({
                        description: res.data.aboutVendor,
                      })
                    );
                    dispatch(
                      outletStepFourSave({
                        name: res.data.managerDetail.name,
                        email: res.data.managerDetail.email,
                        mobile: res.data.managerDetail.mobile,
                        password: res.data.managerDetail.password,
                        rePassword: res.data.managerDetail.password,
                      })
                    );
                    // window.location.reload();
                    history.push(
                      `/edit-outlet/${localStorage.getItem("outletId")}`
                    );
                  })
                  .catch((err) => {
                    toast.error(err);
                  });
              }
            });
          }
          // history.push("/dashboard");
        })
        .catch((err) => {
          toast.error(err);
        });
    },
  });
  const validation = () => {
    // var forms = document.getElementsByClassName("needs-validation");
    // Array.prototype.filter.call(forms, function (form) {
    //   form.addEventListener(
    //     "submit",
    //     function (event) {
    //       alert("a");
    //       if (form.checkValidity() === false) {
    //         event.preventDefault();
    //         event.stopPropagation();
    //       } else {
    //         alert("a");
    //         dispatch(login(auth.email, auth.password))
    //           .unwrap()
    //           .then((res) => {
    //             toast.success(res.message);
    //             history.push("/dashboard");
    //           })
    //           .catch((err) => {
    //             toast.error(err.message);
    //           });
    //       }
    //       form.classList.add("was-validated");
    //     },
    //     false
    //   );
    // });
  };
  const handleChange = (e) => {
    setAuth({
      ...auth,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <div className="ms-content-wrapper ms-auth">
      <Row>
        <Col md={5}>
          <div className="ms-auth-bg">
            <img src={loginLogo} />
          </div>
        </Col>
        <Col md={7}>
          <div className="regi-form two-step">
            <form className="needs-validation" onSubmit={formik.handleSubmit}>
              <h3>Login to Account</h3>
              <p>Please enter your email and password to continue</p>
              <div className="form-vendor-regi">
                <div className="form-vendor">
                  <label htmlFor="validationCustom08">
                    Email Address<span className="error">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="email"
                      className="form-control"
                      id="validationCustom08"
                      placeholder="Email Address"
                      name="email"
                      onChange={formik.handleChange}
                      style={{
                        borderColor:
                          formik.errors.email && formik.touched.email
                            ? "red"
                            : "#3ea99f",
                      }}
                      required
                    />
                    <div className="invalid-feedback">
                      Please provide a valid email.
                    </div>
                  </div>
                </div>
                <div className="">
                  <label htmlFor="validationCustom09">
                    Password<span className="error">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type={isPasswordVisible ? "text" : "password"}
                      className="form-control "
                      id="validationCustom09"
                      placeholder="Password"
                      name="password"
                      onChange={formik.handleChange}
                      style={{
                        borderColor:
                          formik.errors.password && formik.touched.password
                            ? "red"
                            : "#3ea99f",
                      }}
                      required
                    />
                    <a
                      href="javascript:void(0);"
                      onClick={() => setIsPasswordVisible((prev) => !prev)}
                    >
                      {isPasswordVisible ? (
                        <i className="fas fa-eye"></i>
                      ) : (
                        <i className="fas fa-eye-slash"></i>
                      )}
                    </a>

                    <div className="invalid-feedback">
                      Please provide a password.
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="ms-checkbox-wrap">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue
                    />{" "}
                    <i className="ms-checkbox-check" />
                  </label>{" "}
                  <span> Remember Password </span>
                  <label className="d-block mt-3">
                    <Link to="/forgot-password" className="btn-link">
                      Forgot Password?
                    </Link>
                  </label>
                </div>
                <button
                  className=" steps-btn"
                  type="button"
                  onClick={formik.handleSubmit}
                >
                  Sign In
                </button>
                <p className="mb-0 mt-3 text-center">
                  Don't have an account?{" "}
                  <div
                    className="btn-link"
                    // to="/default-register"
                    onClick={() => {
                      dispatch(clearAllRegisterDetails());
                      // .unwrap()
                      // .then(() => {
                      history.push("/default-register");
                      // });
                    }}
                  >
                    Create Account
                  </div>
                </p>
              </div>
            </form>
          </div>
        </Col>
      </Row>

      <Modal
        className="modal-min"
        // show={this.state.show1}
        // onHide={this.handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="text-center">
          <button
            type="button"
            className="close"
            //   onClick={this.handleClose}
          >
            <span aria-hidden="true">×</span>
          </button>
          <i className="flaticon-secure-shield d-block" />
          <h1>Forgot Password?</h1>
          <p>Enter your email to recover your password</p>
          <form method="post">
            <div className="ms-form-group has-icon">
              <input
                type="text"
                placeholder="Email Address"
                className="form-control"
                name="forgot-password"
              />{" "}
              <i className="material-icons">email</i>
            </div>
            <button type="submit" className="btn btn-primary shadow-none">
              Reset Password
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Login;
