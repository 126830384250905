import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    stepOne: null,
    stepTwo: null,
    stepThree: null,
    stepFour: null,
    stepFive: null,
    stepSix: null,
}

const saveDetailsSlice = createSlice({
    name: 'saveDetails',
    initialState,
    reducers: {
        stepOneSave(state, action) {
            state.stepOne = action.payload
        },
        stepTwoSave(state, action) {
            state.stepTwo = action.payload
        },
        stepThreeSave(state, action) {
            state.stepThree = action.payload
        },
        stepFourSave(state, action) {
            state.stepFour = action.payload
        },
        stepFiveSave(state, action) {
            state.stepFive = action.payload
        },
        stepSixSave(state, action) {
            state.stepSix = action.payload
        },
        clearAllRegisterDetails(state) {
            state.stepOne = null;
            state.stepTwo = null;
            state.stepThree = null;
            state.stepFour = null;
            state.stepFive = null;
            state.stepSix = null;
        }
    }
})

export const { stepOneSave,
    stepTwoSave,
    stepThreeSave,
    stepFourSave,
    stepFiveSave, stepSixSave,
    clearAllRegisterDetails } = saveDetailsSlice.actions


export default saveDetailsSlice.reducer