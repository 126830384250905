import React, { useState, useEffect, useRef } from "react";
import "datatables.net-bs4/js/dataTables.bootstrap4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { getRevenueReports } from "../../../redux/slices/Reports";
import { getAllOutlets } from "../../../redux/slices/Outlet";
import { upperCase } from "../../../constants";
import { Col, Container, Row } from "react-bootstrap";
import { DownloadTableExcel } from "react-export-table-to-excel";
import ReactPaginate from "react-paginate";

const Revenue = () => {
  const { revenueReports, revenueData } = useSelector((state) => state.report);
  const dispatch = useDispatch();
  const itemsPerPage = 10;
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const paginateEffect = (rep) => {
    if (revenueReports.length) {
      const endOffset = itemOffset + itemsPerPage;
      console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      setCurrentItems(rep.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(revenueReports.length / itemsPerPage));
    }
  };
  useEffect(() => {
    if (revenueReports.length) {
      const endOffset = itemOffset + itemsPerPage;
      console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      setCurrentItems(revenueReports.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(revenueReports.length / itemsPerPage));
    }
  }, [itemOffset, itemsPerPage, revenueReports]);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % revenueReports.length;
    setItemOffset(newOffset);
  };
  const tableRef = useRef(null);

  const [selectedOutlet, setSelectedOutlet] = useState({
    outletId: "",
    startDate: "",
    endDate: "",
  });
  const { outletList } = useSelector((state) => state.outlet);
  useEffect(() => {
    dispatch(getAllOutlets({ vendorId: localStorage.getItem("vendorId")}));
    if (localStorage.getItem("vendorType") === "single") {
      dispatch(
        getRevenueReports({
          outletId: localStorage.getItem("outletId"),
        })
      );
    }
  }, [dispatch]);
  const filterData = (e) => {
    if (e.target.name === "outletId") {
      setSelectedOutlet({
        ...selectedOutlet,
        outletId: e.target.value,
      });
      dispatch(
        getRevenueReports({
          ...selectedOutlet,
          outletId: e.target.value,
        })
      ).then((res) => {
        paginateEffect(res.payload.data);
      });
    }
    if (e.target.name === "startDate" || e.target.name === "endDate") {
      setSelectedOutlet({
        ...selectedOutlet,
        [e.target.name]: e.target.value,
      });
      if (selectedOutlet.outletId !== "") {
        dispatch(
          getRevenueReports({
            ...selectedOutlet,
            [e.target.name]: e.target.value,
          })
        ).then((res) => {
          paginateEffect(res.payload.data);
        });
      } else {
        dispatch(
          getRevenueReports({
            ...selectedOutlet,
            [e.target.name]: e.target.value,
            outletId: localStorage.getItem("outletId"),
          })
        ).then((res) => {
          paginateEffect(res.payload.data);
        });
      }
    }
  };
  //initialize datatable
  return (
    <>
      <div className="ms-content-wrapper">
        <div className="row">
          <div className="col-md-12">
            <div className="ms-panel">
              <div className="ms-panel-header">
                <div className="date-filter">
                  <Row>
                    <Col md={6}>
                      <div>
                        <h6>Revenue Reports</h6>
                      </div>
                    </Col>
                    <Col md={6}>
                      {localStorage.getItem("vendorType") === "multiple" && (
                        <div
                          className="outletDropdown"
                          style={{
                            padding: "0 30px 0",
                            display: "inline-block",
                            width: "100%",
                          }}
                        >
                          <div className="float-right d-flex">
                            <label
                              htmlFor="validationCustom01"
                              className="form-label mr-3 mt-2"
                            >
                              Select Outlet
                            </label>
                            <div className="dropdown-group form-vendor-regi">
                              <select
                                className="form-control"
                                id="validationCustom01"
                                name="outletId"
                                onChange={filterData}
                              >
                                <option value={0}>Please Select</option>
                                {outletList.length ? (
                                  outletList.map((item) => {
                                    return (
                                      <option value={item.outletId}>
                                        {upperCase(item.outletName)}
                                      </option>
                                    );
                                  })
                                ) : (
                                  <option value={0}> Not found</option>
                                )}
                              </select>
                              {/* <div className="invalid-feedback">
                            Please Select a valid Designation.
                          </div> */}
                            </div>
                          </div>
                        </div>
                      )}
                    </Col>
                    <Col md={5}>
                      <div className="startDate">
                        {/* date picker input */}
                        <label htmlFor="validationCustom01" className="form-label">
                          Start Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="startDate"
                          name="startDate"
                          placeholder="Start Date"
                          onChange={filterData}
                        />
                      </div>
                    </Col>
                    <Col md={5}>
                      <div className="endDate">
                        <label htmlFor="validationCustom01" className="form-label">
                          End Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="endDate"
                          name="endDate"
                          placeholder="End Date"
                          onChange={filterData}
                        />
                      </div>
                    </Col>
                    <Col md={2}>
                      <DownloadTableExcel
                        filename="Revenue Reports"
                        sheet="Revenue Reports"
                        currentTableRef={tableRef.current}
                      >
                        <button
                          className="excle-dowm"
                          disabled={currentItems?.length > 0}
                        >
                          {" "}
                          Export excel{" "}
                        </button>
                      </DownloadTableExcel>
                    </Col>
                  </Row>
                </div>

                <Container>
                  <div className="dh-section mt-4">
                    <div className="row">
                      <div className="col-xl-3 col-lg-6 col-md-6">
                        <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                          <div className="ms-card-body media">
                            <div className="media-body">
                              <span className="black-text">
                                <strong>Total Booking</strong>
                              </span>
                              <h2>0</h2>
                            </div>
                          </div>
                          {/* <LineChart data={this.state.data1} options={options} /> */}
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-6 col-md-6">
                        <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                          <div className="ms-card-body media">
                            <div className="media-body">
                              <span className="black-text">
                                <strong>Total Guest</strong>
                              </span>
                              <h2>0</h2>
                            </div>
                          </div>
                          {/* <LineChart data={this.state.data3} options={options} /> */}
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-6 col-md-6">
                        <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                          <div className="ms-card-body media">
                            <div className="media-body">
                              <span className="black-text">
                                <strong>Total Revenue</strong>
                              </span>
                              <h2>{revenueData?.totalRevenue}</h2>
                            </div>
                          </div>
                          {/* <LineChart data={this.state.data2} options={options} /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </Container>

                <Row>
                  <Col md={12}>
                    <div className="table-responsive">
                      <table
                        className="table table-hover thead-primary"
                        ref={tableRef}
                      >
                        <thead>
                          <tr>
                            <th scope="col">Order ID</th>
                            <th scope="col">Guest Name</th>
                            <th scope="col">Date</th>
                            <th scope="col">Time</th>
                            <th scope="col">Total Billing</th>
                            <th scope="col">Payment Status</th>
                            <th scope="col">Feedback</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentItems?.length ? (
                            currentItems.map((report, index) => {
                              return (
                                <tr key={index}>
                                  <td>{report.orderId}</td>
                                  <td>{report.userName}</td>
                                  <td>{report.date}</td>
                                  <td>{report.time}</td>
                                  <td> {report.totalBilling}</td>
                                  <td>
                                    <span
                                      className={
                                        report.paymentStatus === "decline"
                                          ? "badge badge-dark"
                                          : report.paymentStatus === "accepted"
                                          ? "badge badge-success"
                                          : "badge badge-primary"
                                      }
                                    >
                                      {report.paymentStatus}{" "}
                                    </span>
                                  </td>
                                  <td> {report.feedback}</td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="7">No Reports Found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {currentItems?.length ? (
                        <ReactPaginate
                          nextLabel="next >"
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={3}
                          marginPagesDisplayed={2}
                          pageCount={pageCount}
                          previousLabel="< previous"
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          containerClassName="pagination"
                          activeClassName="active"
                          renderOnZeroPageCount={null}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Revenue;

