export const PORT = "https://api.sortyfy.com";

export const API_URL = "https://api.sortyfy.com/api/vendor";
export const API_URL2 = "https://api.sortyfy.com/api";

export const upperCaseByWord = (str) => {
  if (str) {
    const words = str.split(" ");

    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0]?.toUpperCase() + words[i]?.substr(1) + " ";
    }
    return words;
  }
  return str;
};

export const daysData = [
  {
    day: "Monday",
    fromTime: "",
    toTime: "",
    fullDay: false,
  },
  {
    day: "Tuesday",
    fromTime: "",
    toTime: "",
    fullDay: false,
  },
  {
    day: "Wednesday",
    fromTime: "",
    toTime: "",
    fullDay: false,
  },
  {
    day: "Thursday",
    fromTime: "",
    toTime: "",
    fullDay: false,
  },
  {
    day: "Friday",
    fromTime: "",
    toTime: "",
    fullDay: false,
  },
  {
    day: "Saturday",
    fromTime: "",
    toTime: "",
    fullDay: false,
  },
  {
    day: "Sunday",
    fromTime: "",
    toTime: "",
    fullDay: false,
  },
];

export const trimStringWithEllipsis = (str, maxLength) => {
  if (str.length <= maxLength) {
    return str;
  }
  return str.slice(0, maxLength) + "...";
};
