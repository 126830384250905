import React, { useEffect, useRef, useState } from "react";
import "datatables.net-bs4/js/dataTables.bootstrap4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import $ from "jquery";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { upperCase } from "../../../constants";
import { getAllOutlets } from "../../../redux/slices/Outlet";
import { useDispatch, useSelector } from "react-redux";
import { getPendingList } from "../../../redux/slices/Finance";
import { Col, Container, Row } from "react-bootstrap";
import ReactPaginate from "react-paginate";

const Pending = () => {
  const dispatch = useDispatch();

  const { pendingList, pendingData } = useSelector((state) => state.finance);
  const itemsPerPage = 10;
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const paginateEffect = (rep) => {
    if (pendingList.length) {
      const endOffset = itemOffset + itemsPerPage;
      console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      setCurrentItems(rep.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(pendingList.length / itemsPerPage));
    }
  };
  useEffect(() => {
    if (pendingList.length) {
      const endOffset = itemOffset + itemsPerPage;
      console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      setCurrentItems(pendingList.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(pendingList.length / itemsPerPage));
    }
  }, [itemOffset, itemsPerPage, pendingList]);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % pendingList.length;
    setItemOffset(newOffset);
  };
  const tableRef = useRef(null);

  const [selectedOutlet, setSelectedOutlet] = useState({
    outletId: "",
    startDate: "",
    endDate: "",
  });
  const { outletList } = useSelector((state) => state.outlet);
  useEffect(() => {
    dispatch(getAllOutlets({ vendorId: localStorage.getItem("vendorId") }));
    if (localStorage.getItem("vendorType") === "single") {
      dispatch(
        getPendingList({
          outletId: localStorage.getItem("outletId"),
        })
      );
    }
  }, [dispatch]);
  const filterData = (e) => {
    if (e.target.name === "outletId") {
      setSelectedOutlet({
        ...selectedOutlet,
        outletId: e.target.value,
      });
      dispatch(
        getPendingList({
          ...selectedOutlet,
          outletId: e.target.value,
        })
      ).then((res) => {
        paginateEffect(res.payload.data);
      });
    }
    if (e.target.name === "startDate" || e.target.name === "endDate") {
      setSelectedOutlet({
        ...selectedOutlet,
        [e.target.name]: e.target.value,
      });
      if (selectedOutlet.outletId !== "") {
        dispatch(
          getPendingList({
            ...selectedOutlet,
            [e.target.name]: e.target.value,
          })
        ).then((res) => {
          paginateEffect(res.payload.data);
        });
      } else {
        dispatch(
          getPendingList({
            ...selectedOutlet,
            [e.target.name]: e.target.value,
            outletId: localStorage.getItem("outletId"),
          })
        ).then((res) => {
          paginateEffect(res.payload.data);
        });
      }
    }
  };
  return (
    <>
      <div className="ms-content-wrapper">
        <div className="row">
          <div className="col-md-12">
            <div className="ms-panel">
              <div className="ms-panel-header">
                <div className="date-filter">
                  <Row>
                    <Col md={6}>
                      <div>
                        <h6>Pending Reports</h6>
                      </div>
                    </Col>
                    <Col md={6}>
                      {localStorage.getItem("vendorType") === "multiple" && (
                        <div
                          className="outletDropdown"
                          style={{
                            padding: "0 30px 0",
                            display: "inline-block",
                            width: "100%",
                          }}
                        >
                          <div className="float-right d-flex">
                            <label
                              htmlFor="validationCustom01"
                              className="form-label mr-3 mt-2"
                            >
                              Select Outlet
                            </label>
                            <div className="dropdown-group form-vendor-regi">
                              <select
                                className="form-control"
                                id="validationCustom01"
                                name="outletId"
                                onChange={filterData}
                              >
                                <option value={0}>Please Select</option>
                                {outletList.length ? (
                                  outletList.map((item) => {
                                    return (
                                      <option value={item.outletId}>
                                        {upperCase(item.outletName)}
                                      </option>
                                    );
                                  })
                                ) : (
                                  <option value={0}> Not found</option>
                                )}
                              </select>
                              {/* <div className="invalid-feedback">
                            Please Select a valid Designation.
                          </div> */}
                            </div>
                          </div>
                        </div>
                      )}
                    </Col>
                    <Col md={5}>
                      <div className="startDate">
                        {/* date picker input */}
                        <label htmlFor="validationCustom01" className="form-label">
                          Start Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="startDate"
                          name="startDate"
                          placeholder="Start Date"
                          onChange={filterData}
                        />
                      </div>
                    </Col>
                    <Col md={5}>
                      <div className="endDate">
                        <label htmlFor="validationCustom01" className="form-label">
                          End Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="endDate"
                          name="endDate"
                          placeholder="End Date"
                          onChange={filterData}
                        />
                      </div>
                    </Col>
                    <Col md={2}>
                      <DownloadTableExcel
                        filename="Pending Reports"
                        sheet="Pending Reports"
                        currentTableRef={tableRef.current}
                      >
                        <button className="excle-dowm"> Export excel </button>
                      </DownloadTableExcel>
                    </Col>
                  </Row>
                </div>

                <Container>
                  <div className="dh-section mt-4">
                    <div className="row">
                      <div className="col-xl-3 col-lg-6 col-md-6">
                        <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                          {/* <span className="ms-chart-label bg-black"><i className="material-icons">arrow_upward</i> 3.2%</span> */}
                          <div className="ms-card-body media">
                            <div className="media-body">
                              <span className="black-text">
                                <strong>Pending Payment</strong>
                              </span>
                              <h2>₹{pendingData?.pendingPayment}</h2>
                            </div>
                          </div>
                          {/* <LineChart data={this.state.data1} options={options} /> */}
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-6 col-md-6">
                        <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                          {/* <span className="ms-chart-label bg-red"><i className="material-icons">arrow_downward</i> 4.5%</span> */}
                          <div className="ms-card-body media">
                            <div className="media-body">
                              <span className="black-text">
                                <strong>Total Billing</strong>
                              </span>
                              <h2>{pendingData?.totalBilling}</h2>
                            </div>
                          </div>
                          {/* <LineChart data={this.state.data2} options={options} /> */}
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-6 col-md-6">
                        <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                          <span className="ms-chart-label bg-black">
                            <i className="material-icons">arrow_upward</i> 12.5%
                          </span>
                          <div className="ms-card-body media">
                            <div className="media-body">
                              <span className="black-text">
                                <strong>New Users</strong>
                              </span>
                              <h2>0</h2>
                            </div>
                          </div>
                          {/* <LineChart data={this.state.data3} options={options} /> */}
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-6 col-md-6">
                        <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                          <span className="ms-chart-label bg-red">
                            <i className="material-icons">arrow_upward</i> 9.5%
                          </span>
                          <div className="ms-card-body media">
                            <div className="media-body">
                              <span className="black-text">
                                <strong>Total Orders</strong>
                              </span>
                              <h2>0</h2>
                            </div>
                          </div>
                          {/* <LineChart data={this.state.data4} options={options} /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </Container>

                <Row>
                  <Col md={12}>
                    <div className="table-responsive">
                      <table
                        className="table table-hover thead-primary"
                        ref={tableRef}
                      >
                        <thead>
                          <tr>
                            <th scope="col">Total Bookings</th>
                            <th scope="col">Total Guest</th>
                            <th scope="col">Date</th>
                            <th scope="col">Total Billing</th>
                            <th scope="col">Pending Payment</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentItems?.length ? (
                            currentItems.map((report, index) => {
                              return (
                                <tr key={index}>
                                  <td>{report.totalBooking}</td>
                                  <td>{report.TotalGuest}</td>
                                  <td>{report.date}</td>
                                  <td> {report.totalBilling}</td>
                                  <td> {report.pendingPayment}</td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="7">No Reports Found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {currentItems?.length ? (
                        <ReactPaginate
                          nextLabel="next >"
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={3}
                          marginPagesDisplayed={2}
                          pageCount={pageCount}
                          previousLabel="< previous"
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          containerClassName="pagination"
                          activeClassName="active"
                          renderOnZeroPageCount={null}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pending;

