import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Button, Form, Modal, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import Breadcrumb from "../../components/Breadcrumb";
import useRequest from "../../hooks/useRequest";
import useRequest2 from "../../hooks/useRequest2";
import { upperCase } from "../../constants";
import {
  clearAllRegisterDetails,
  stepOneSave,
} from "../../redux/slices/SaveDetails";
import { logout, verifyOTP } from "../../redux/slices/Registration";
import {
  clearAllDetails,
  outletStepFourSave,
} from "../../redux/slices/SaveOutletDetails";
import { registerOutletStepFour } from "../../redux/slices/Outlet";

const StepFour = ({ handleNextStep, handleManagerId, isEdit, outletId }) => {
  const history = useHistory();
  const { userId } = useParams();
  const { verifyStatus } = useSelector((state) => state.register);
  const { outletStepFour } = useSelector((state) => state.saveOutletDetails);

  const dispatch = useDispatch();
  const { request, response } = useRequest();

  const [touchedError, setTouchedError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState({
    newPassword: false,
    confirmNewPassword: false,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
    setValue,
  } = useForm();

  useEffect(() => {
    if (userId) {
      request("GET", `user/getRegisterDetail/${userId}`);
    }
  }, [userId]);

  useEffect(() => {
    if (response) {
      if (response.status == "success") {
        if (response?.data?.mobile) {
          setValue("mobile", response?.data?.mobile);
        }
        if (response?.data?.email) {
          setValue("email", response?.data?.email);
        }
        if (response?.data?.name) {
          setValue("name", response?.data?.name);
        }
      }
    }
  }, [response]);

  useEffect(() => {
    if (outletStepFour) {
      setValue("designation", outletStepFour?.designation ?? "");
      setValue("email", outletStepFour?.email ?? "");
      setValue("mobile", outletStepFour?.mobile ?? "");
      setValue("name", outletStepFour?.name ?? "");
      setValue("password", outletStepFour?.password ?? "");
      setValue("confirmNewPassword", outletStepFour?.password ?? "");
    }
  }, [outletStepFour]);

  const onSubmit = (data) => {
    setLoader(true);
    dispatch(
      registerOutletStepFour({
        ...data,
        outletId: outletId,
      })
    )
      .unwrap()
      .then((res) => {
        toast.success(res.message);
        if (res?.status == "success" || res?.status == true) {
          dispatch(outletStepFourSave(data));

          if (!verifyStatus) {
            dispatch(logout());
            // localStorage.clear();
            localStorage.removeItem("isLoggedIn");
            localStorage.removeItem("outletId");
            localStorage.removeItem("vendorId");
            localStorage.removeItem("vendorType");
            localStorage.removeItem("user");
            localStorage.removeItem("services");
            localStorage.clear();
          } else {
            // setLoader(true)
            dispatch(clearAllDetails());
            history.push("/total-outlets");
          }
        }
      })
      .catch((err) => {
        setLoader(false);

        toast.error(err);
      });
  };

  const handleShowModal = async (managerId) => {
    Swal.fire({
      title: "Enter your email otp",
      input: "text",
      inputLabel: "OTP",
      inputPlaceholder: "Enter otp here",
      inputAttributes: {
        maxlength: 8,
        autocapitalize: "off",
        autocorrect: "off",
        required: "true",
      },
      inputValidator: (value) => {
        if (value === "") {
          return "Please enter otp!";
        }
      },
      confirmButtonText: "Submit",
      confirmButtonColor: "#3ea99f",
    })
      .then((r) => {
        if (r.value === null) return false;

        if (r.isConfirmed) {
          dispatch(
            verifyOTP({
              managerId: managerId,
              otpEmail: r.value,
            })
          )
            .unwrap()
            .then(() => {
              Swal.fire({
                icon: "success",
                title: "Good Job!",
                text: "OTP verified",
                confirmButtonColor: "#3ea99f",
                confirmButtonText: "Next",
              }).then((res) => {
                if (res.isConfirmed) {
                  handleManagerId(managerId);
                  handleNextStep(2);
                }
              });
            })
            .catch((err) => {
              toast.error("Invalid OTP");
            });
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb
            title="Add/Edit Outlet"
            links={[
              { to: "/", name: "Home" },
              { to: "/total-outlets", name: "Outlets" },
            ]}
          />
        </div>
        <div className="col-md-12">
          <div className="ms-panel ms-panel-fh">
            <div className="ms-panel-header">
              <h6>{isEdit ? "Edit Outlet" : "Add Outlet"}</h6>
            </div>
            <div className="ms-panel-body">
              <form
                className="needs-validation"
                onSubmit={handleSubmit(onSubmit)}
                noValidate
              >
                <p>
                  Fill the Required Details of Person Incharge handling the
                  Bookings and Guest Communications.
                </p>
                <div className="form-vendor-regi">
                  <div className="form-vendor">
                    <Row>
                      <Col md={12}>
                        <div
                          style={{
                            marginTop: "20px",
                          }}
                        >
                          <label htmlFor="validationCustom03">
                            Name<span className="error">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              name="name"
                              id="name"
                              placeholder="Name"
                              className={`form-control ${
                                touchedError
                                  ? errors.name
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                              {...register("name", {
                                required: true,
                              })}
                            />

                            {errors.name?.type === "required" && (
                              <div className="invalid-feedback">
                                The name field is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </Col>

                      <Col md={12}>
                        <div className="form-group">
                          <label htmlFor="validationCustom03">
                            Email Address<span className="error">*</span>
                          </label>
                          <div className="input-group">
                            <input
                             type="email"
                              name="email"
                              id="email"
                              placeholder="Email Address"
                              className={`form-control ${
                                touchedError
                                  ? errors.email
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                              {...register("email", {
                                required: true,
                                pattern:
                                  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                              })}
                            />

                            {errors.email?.type === "required" && (
                              <div className="invalid-feedback">
                                The email field is required.
                              </div>
                            )}
                            {errors.email?.type === "pattern" && (
                              <div className="invalid-feedback">
                                The email must be a valid email address.
                              </div>
                            )}
                          </div>
                        </div>
                      </Col>

                      <Col md={12}>
                        <div className="form-group ">
                          <label htmlFor="validationCustom04">
                            Password<span className="error">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              type={
                                isPasswordVisible.newPassword
                                  ? "text"
                                  : "password"
                              }
                              name="password"
                              id="password"
                              placeholder="Password"
                              className={`form-control ${
                                touchedError
                                  ? errors.password
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                              {...register("password", {
                                required: true,
                                pattern: /.{8,}/,
                              })}
                            />

                            <a
                              href="javascript:void(0);"
                              onClick={() => {
                                const update = {
                                  ...isPasswordVisible,
                                  newPassword: isPasswordVisible.newPassword
                                    ? false
                                    : true,
                                };
                                setIsPasswordVisible(update);
                              }}
                            >
                              {isPasswordVisible.newPassword ? (
                                <i className="fas fa-eye"></i>
                              ) : (
                                <i className="fas fa-eye-slash"></i>
                              )}
                            </a>

                            {errors.password?.type === "required" && (
                              <div className="invalid-feedback">
                                The password field is required.
                              </div>
                            )}
                            {errors.password?.type === "pattern" && (
                              <div className="invalid-feedback">
                                Password must be 8 characters long.
                              </div>
                            )}
                          </div>
                        </div>
                      </Col>
                      {!isEdit && (
                        <>
                          <Col md={12}>
                            <div className="form-group">
                              <label htmlFor="validationCustom04">
                                Re-Confirm Password
                                <span className="error">*</span>
                              </label>
                              <div className="input-group">
                                <input
                                  type={
                                    isPasswordVisible.confirmNewPassword
                                      ? "text"
                                      : "password"
                                  }
                                  name="confirmNewPassword"
                                  id="confirmNewPassword"
                                  placeholder="Confirm New Password"
                                  className={`form-control ${
                                    touchedError
                                      ? errors.confirmNewPassword
                                        ? "is-invalid"
                                        : "is-valid"
                                      : ""
                                  }`}
                                  {...register("confirmNewPassword", {
                                    required: true,
                                    pattern: /.{8,}/,
                                  })}
                                />

                                <a
                                  href="javascript:void(0);"
                                  onClick={() => {
                                    const update = {
                                      ...isPasswordVisible,
                                      confirmNewPassword:
                                        isPasswordVisible.confirmNewPassword
                                          ? false
                                          : true,
                                    };
                                    setIsPasswordVisible(update);
                                  }}
                                >
                                  {isPasswordVisible.confirmNewPassword ? (
                                    <i className="fas fa-eye"></i>
                                  ) : (
                                    <i className="fas fa-eye-slash"></i>
                                  )}
                                </a>
                                {errors.confirmNewPassword?.type ===
                                  "required" && (
                                  <div className="invalid-feedback">
                                    The re-confirm password field is required.
                                  </div>
                                )}
                                {errors.confirmNewPassword?.type ===
                                  "pattern" && (
                                  <div className="invalid-feedback">
                                    Re-confirm password must be 8 characters
                                    long.
                                  </div>
                                )}
                                {errors.confirmNewPassword?.type ===
                                  "manual" && (
                                  <div className="invalid-feedback">
                                    Password and re-confirm password does not
                                    match.
                                  </div>
                                )}
                              </div>
                            </div>
                          </Col>
                        </>
                      )}

                      <Col md={12}>
                        <div className="form-group ">
                          <label htmlFor="validationCustom04">
                            Phone<span className="error">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              name="mobile"
                              id="mobile"
                              placeholder="Phone"
                              className={`form-control ${
                                touchedError
                                  ? errors.mobile
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                              {...register("mobile", {
                                required: true,
                                pattern: /^[0-9]{10}$/gm,
                              })}
                            />

                            {errors.mobile?.type === "required" && (
                              <div className="invalid-feedback">
                                This field is required.
                              </div>
                            )}
                            {errors.mobile?.type === "pattern" && (
                              <div className="invalid-feedback">
                                Please enter a valid phone number.
                              </div>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>

                <div className="form-row">{/* password and repassword */}</div>

                <div className="row mt-4">
                  <div className="col-md-6">
                    <button
                      className="steps-btn float-left"
                      type="button"
                      onClick={() => handleNextStep(3)}
                    >
                      <i>&#8592;</i> Previous
                    </button>
                  </div>
                  <div className="col-md-6">
                    {loader ? (
                      <center>
                        <div
                          style={{
                            flex: 1,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Spinner
                            animation="border"
                            className="d-flex justify-content-center"
                          />
                        </div>
                      </center>
                    ) : (
                      <button
                        className="steps-btn float-right"
                        type="submit"
                        onClick={() => setTouchedError(true)}
                      >
                        Next
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepFour;
