import React, { Component } from 'react';
import Gridcontent from '../sections/Discountgrid/Gridcontent'
import PromotionTable from './Promotions/PromotionTable';
import Breadcrumb from '../Breadcrumb';

class Promotions extends Component {
    render() {
        return (
            <>
                <div className="ms-content-wrapper">
                {localStorage.getItem("vendorType") === "single" ? (
                    <Breadcrumb
                    title="Promotions"
                    links={[
                    { to: "/", name: "Home" },
                    // { to: "/total-outlets", name: "Outlets" },
                    ]}
                />
                   ):(
                    <Breadcrumb
                        title="Promotions | Buffet"
                        links={[
                        { to: "/", name: "Home" },
                        { to: "/total-outlets", name: "Outlets" },
                        ]}
                    />
                   )}
                   
                    <div className="row">
                        <div className="col-md-12">
                            <PromotionTable />
                        </div>
                    </div>
                </div>
              
            </>
        );
    }
}

export default Promotions;