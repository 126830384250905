import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
// Import your Redux actions
import { toast } from 'react-toastify';
import { blockUser, getOutletVistors } from '../../../redux/slices/Outlet';

const BlockUserModal = (props) => {

  const dispatch = useDispatch();
  const [reason, setReason] = useState('');


  const handleBlockUser = () => {
    if (!reason) {
      toast.error("Please enter a reason!");
      return;
    }
    dispatch(
      blockUser({
        userId: props?.userId,
        reason: reason,
        outletId: props.selectedOutlet.outletId,
      })
    ).unwrap()
      .then((res) => {
        toast.success("Blocked!");
        dispatch(
          getOutletVistors({
            outletId: props.selectedOutlet.outletId,
          })
        );
        props.handleClose();
        setReason("")
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  return (
    <>


      <Modal {...props}>
        <Modal.Header closeButton>
          <Modal.Title>Enter reason for block</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formBasicReason">
            <Form.Label>Reason</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter reason here"
              value={reason}
              onChange={handleReasonChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {
            props.handleClose()
          }}>
            Close
          </Button>
          <Button variant="primary" onClick={handleBlockUser}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BlockUserModal;
