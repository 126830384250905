import React, { Component } from "react";

import img1 from "../../../assets/img/foodtech/food-5.jpg";
import img2 from "../../../assets/img/foodtech/food-2.jpg";
import img3 from "../../../assets/img/foodtech/food-4.jpg";
import img4 from "../../../assets/img/foodtech/food-3.jpg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const trendfood = [
  {
    photo: img1,
    title: "Meat Stew",
    price: "$25.00",
    order: "15",
    income: "$175",
    resposnivecls: "col-xl-3 col-lg-6 col-md-6 col-sm-6 mb-lg-20",
  },
  {
    photo: img2,
    title: "Pancake",
    price: "$50.00",
    order: "75",
    income: "$275",
    resposnivecls: "col-xl-3 col-lg-6 col-md-6 col-sm-6 mb-lg-20",
  },
  {
    photo: img3,
    title: "Burger",
    price: "$45.00",
    order: "85",
    income: "$575",
    resposnivecls: "col-xl-3 col-lg-6 col-md-6 col-sm-6",
  },
  {
    photo: img4,
    title: "Saled",
    price: "$85.00",
    order: "175",
    income: "$775",
    resposnivecls: "col-xl-3 col-lg-6 col-md-6 col-sm-6",
  },
];

const truncateString = (str, maxLength) => {
  if (str?.length > maxLength) {
    return str?.substring(0, maxLength) + "...";
  } else {
    return str;
  }
};
const renderTooltip = (text) => (
  <Tooltip id="tooltip-full-string">{text}</Tooltip>
);

const Trendingorder = (props) => {
  return (
    <div className="ms-panel">
      <div className="ms-panel-header">
        <h6>Trending {props.name}</h6>
      </div>
      <div className="ms-panel-body">
        <div className="row">
          {props.data.map((item, i) => (
            <div key={i} className={item.resposnivecls}>
              <div
                className={
                  props.name == "Dishes" ? "ms-card no-margin" : "no-margin"
                }
                style={{ marginLeft: "30px" }}
              >
                {item.image && (
                  <div
                    className="ms-card-img"
                    // style={{ height: "125px", width: "186px" }}
                  >
                    <img src={item.image} alt="card_img" />
                  </div>
                )}

                <div className="ms-card-body">
                  <div className="ms-card-heading-title">
                    {/* <h6>{item.name}</h6> */}
                    <h6>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={renderTooltip(item.name)}
                      >
                        <span>
                          {props?.name == "Dishes"
                            ? truncateString(item.name, 10)
                            : truncateString(item.name, 100)}
                        </span>
                      </OverlayTrigger>
                    </h6>
                    <span className="green-text">
                      <strong>
                        {" "}
                        {props?.name == "Dishes" && `₹`} {item.price}
                      </strong>
                    </span>
                  </div>
                  {props.name == "Dishes" && (
                    <div className="ms-card-heading-title">
                      <p>
                        Orders{" "}
                        <span className="red-text">{item?.totalOrder}</span>
                      </p>
                      <p>
                        Revenue{" "}
                        <span className="red-text">
                          {item.totalSale ? "₹" + item.totalSale : ""}
                        </span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Trendingorder;
