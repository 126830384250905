import React, { useState, useEffect, useRef } from "react";
import "datatables.net-bs4/js/dataTables.bootstrap4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import { useDispatch, useSelector } from "react-redux";
import { getBusinessReports } from "../../../redux/slices/Reports";
import { getAllOutlets } from "../../../redux/slices/Outlet";
import { upperCase } from "../../../constants";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { Col, Container, Row } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { downloadExcel } from "../../../util/fn";
import Breadcrumb from "../../Breadcrumb";
import useRequest from "../../../hooks/useRequest";
import LineChart from "./charts/LineChart";
import PipeChart from "./charts/PipeChart";
// import LineChartWithVerticalBar from "./charts/LineChartWithVerticalBar";
import StackedLineAreaColumnChart from "./charts/StackedLineAreaColumnChart";
import VerticalBarChart from "./charts/VerticalBarChart";
import PieChart from "./charts/PieChart";
import ExcelDateModal from "../../ExcelDateModal";
import StackedLineAreaColumnChart1 from "./charts/StackedLineAreaColumnChart1";

const BusinessReports = () => {
  const dispatch = useDispatch();
  // const { businessReports, businessData } = useSelector(
  //   (state) => state.report
  // );
  const { request: requestReport, response: responseReport } = useRequest(true);
  const itemsPerPage = 10;
  const [currentItems, setCurrentItems] = useState(null);
  const [getExcelOfSelected, setExcelModal] = useState(false);
  const [outletName, setOutletName] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [overallData, setOverallData] = useState({ totalBooking: 0, totalPerson: 0, totalRevenue: 0 });

  // const paginateEffect = (rep) => {
  //   if (businessReports?.length) {
  //     const endOffset = itemOffset + itemsPerPage;
  //     console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  //     setCurrentItems(rep.slice(itemOffset, endOffset));
  //     setPageCount(Math.ceil(businessReports.length / itemsPerPage));
  //   }else{
  //     setCurrentItems(null);
  //     setPageCount(0);
  //   }
  // };

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % currentItems.length;
    setItemOffset(newOffset);
  };

  const tableRef = useRef(null);

  const [selectedOutlet, setSelectedOutlet] = useState({
    outletId: "",
    startDate: "",
    endDate: "",
  });
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedOutletId, setSelectedOutletId] = useState(localStorage.getItem("outletId"))
  const [minEndDate, setMinEndDate] = useState();
  const [maxStartDate, setMaxStartDate] = useState();
  const { outletList } = useSelector((state) => state.outlet);

  useEffect(() => {
    dispatch(getAllOutlets({ vendorId: localStorage.getItem("vendorId") }));

    if (localStorage.getItem("vendorType") === "single") {
      requestReport("POST", "report/businessReport", { outletId: localStorage.getItem("outletId") })

      // dispatch(
      //   getBusinessReports({
      //     outletId: localStorage.getItem("outletId"),
      //   })
      // );
    }
  }, [dispatch]);

  const filterData = (e) => {
    setSelectedOutletId(e.target.value)
    setOutletName(
      outletList.find((el) =>
        el.outletId == e.target.value ? el.outletName : null
      )
    );
    setSelectedOutlet({
      ...selectedOutlet,
      outletId: e.target.value,
    });
    if (e.target.value == '') {
      setCurrentItems(null);
      setOverallData({ totalBooking: 0, totalPerson: 0, totalRevenue: 0 });
    } else {
      if (e.target.name === "outletId") {


        // const startDate = document.getElementById('startDate').value;
        // const endDate = document.getElementById('endDate').value;

        const status = document.getElementById('status').value;
        requestReport("POST", "report/businessReport", {
          status,
          startDate,
          endDate,
          outletId: e.target.value,
        })

        // dispatch(
        //   getBusinessReports({
        //     status,
        //     startDate,
        //     endDate,
        //     outletId: e.target.value,
        //   })
        // );
      }
    }
  };

  const downloadExcelHandler = () => {
    let data = [];
    if (currentItems && currentItems.length > 0) {
      let count = 1;
      currentItems.forEach(obj => {
        data.push({
          'Sr.': count,
          'Order ID': obj?.orderId,
          'Guest Name': obj?.userName,
          'Total Guests': obj?.totalGuest,
          'Date & Time': obj?.date,
          'Total Billing': obj?.totalBilling,
          'Discount Offered': obj?.discount,
          'Commission Accrued': obj?.commission
        })
        count = count + 1;
      })
    } else {
      data.push({
        'Sr.': "",
        'Order ID': "",
        'Guest Name': "",
        'Total Guests': "",
        'Date & Time': "",
        'Total Billing': "",
        'Discount Offered': "",
        'Commission Accrued': ""
      })
    }
    downloadExcel(data, "Business Reports");
  };

  const filterHandler = () => {
    const startDate = document.getElementById('startDate').value;
    const endDate = document.getElementById('endDate').value;
    const status = document.getElementById('status').value;

    requestReport("POST", "report/businessReport", {
      startDate,
      endDate,
      status,
      outletId: selectedOutletId,
    })

    // dispatch(
    //   getBusinessReports({
    //     startDate,
    //     endDate,
    //     status,
    //     outletId: selectedOutletId,
    //   })
    // );
  }

  const handleStartDate = (e) => {
    if (e.target.value) {
      setMinEndDate(e.target.value)
    } else {
      setMinEndDate("")
    }
  }

  const handleEndDate = (e) => {
    if (e.target.value) {
      setMaxStartDate(e.target.value)
    } else {
      setMaxStartDate("")
    }
  }

  useEffect(() => {
    if (responseReport) {
      if (responseReport.status || responseReport.status == "success") {
        setOverallData({
          ...overallData,
          totalBooking: responseReport?.totalBooking ?? 0,
          totalPerson: responseReport?.totalPerson ?? 0,
          totalRevenue: responseReport?.totalRevenue ?? 0
        })

        if (responseReport?.data) {
          console.log("DataTUshar", responseReport)
          // const endOffset = itemOffset + itemsPerPage;
          setCurrentItems(responseReport.data);
          // setPageCount(Math.ceil(responseReport.data.length / itemsPerPage));
        } else {
          setCurrentItems([]);
          setPageCount(0);
        }
      }
    }

  }, [itemOffset, itemsPerPage, responseReport])




  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
    // Calculate end date with 7-day difference
    const newEndDate = new Date(e.target.value);
    newEndDate.setDate(newEndDate.getDate() + 7);
    setEndDate(newEndDate.toISOString().split('T')[0]);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  //initialize datatable
  return (
    <>
      <div className="ms-content-wrapper">
        <Breadcrumb
          title="Business Reports"
          links={[
            { to: "/", name: "Home" },
          ]}
        />
        <div className="row">


          <div className="ms-panel">
            <div className="ms-panel-header">
              <h6>
                Business Reports{" "}
                {outletName ? `( ${outletName?.outletName} )` : ""}
              </h6>
            </div>
            <div className="ms-panel-body">


              <Row>
                <Col md={12}>
                  <div className="">
                    <div className="row">
                      {/* <div className=" col-md-3">
                        <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                          <div className="ms-card-body media">
                            <div className="media-body">
                              <span className="black-text">
                                <strong>Total Booking</strong>
                              </span>
                              <h2>{overallData.totalBooking}</h2>
                            </div>
                          </div>

                        </div>
                      </div> */}
                      {/* <div className=" col-md-3">
                        <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                        
                          <div className="ms-card-body media">
                            <div className="media-body">
                              <span className="black-text">
                                <strong>Total Person</strong>
                              </span>
                              <h2>{overallData.totalPerson}</h2>
                            </div>
                          </div>
                       
                        </div>
                      </div> */}
                      {/* <div className=" col-md-3">
                        <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                          <div className="ms-card-body media">
                            <div className="media-body">
                              <span className="black-text">
                                <strong>Total Revenue</strong>
                              </span>
                              <h2>{overallData.totalRevenue}</h2>
                            </div>
                          </div>
                      
                        </div>
                      </div> */}
                      {localStorage.getItem("vendorType") === "multiple" && (
                        <Col md={3}>
                          <div
                            className="outletDropdown"
                            style={{
                              padding: "0 30px 0",
                              display: "inline-block",
                              width: "100%",
                            }}
                          >
                            <div className="float-right d-flex">
                              <label
                                htmlFor="validationCustom01"
                                className="form-label mr-3 mt-2"
                              >
                                Select Outlet
                              </label>
                              <div className="dropdown-group form-vendor-regi">
                                <select
                                  className="form-control"
                                  id="validationCustom01"
                                  name="outletId"
                                  onChange={filterData}
                                >
                                  <option value={''}>Please Select</option>
                                  {outletList.length ? (
                                    outletList.map((item) => {
                                      return (
                                        <option value={item.outletId}>
                                          {upperCase(item.outletName)}
                                        </option>
                                      );
                                    })
                                  ) : (
                                    <option value={0}> Not found</option>
                                  )}
                                </select>
                                {/* <div className="invalid-feedback">
                            Please Select a valid Designation.
                          </div> */}
                              </div>
                            </div>
                          </div>
                        </Col>
                      )}

                    </div>
                    <div className="row mb-5">
                      <Col md={3}>
                        <div className="startDate">
                          <label htmlFor="startDate" className="form-label">
                            Start Date
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            id="startDate"
                            name="startDate"
                            placeholder="Start Date"
                            value={startDate}
                            onChange={handleStartDateChange}
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="endDate">
                          <label htmlFor="endDate" className="form-label">
                            End Date
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            id="endDate"
                            name="endDate"
                            placeholder="End Date"
                            disabled
                            value={endDate}
                            onChange={handleEndDateChange}
                            min={startDate} // Set minimum date as the selected start date
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="endDate">
                          <label htmlFor="validationCustom01" className="form-label">
                            Status
                          </label>
                          <select
                            className="form-control"
                            id="status"
                            name="status"
                          // onChange={filterData}
                          >
                            <option value={""}>Please Select</option>
                            <option value={"accepted"}>Accepted</option>
                            <option value={"pending"}>Pending</option>
                            <option value={"decline"}>Decline</option>
                          </select>
                        </div>
                      </Col>
                      <Col md={2}>
                        <button type="button" onClick={filterHandler} className="excle-dowm"> Filter </button>
                      </Col>
                      <Col md={2}>
                        {/* <DownloadTableExcel
                        filename="Business Reports"
                        sheet="Business Reports"
                        currentTableRef={tableRef.current}
                      >
                        <button
                          className="excle-dowm"
                          disabled={!(currentItems?.length > 0)}
                        >
                          {" "}
                          Export excel{" "}
                        </button>
                      </DownloadTableExcel> */}
                        <button type="button" onClick={() => {
                          setExcelModal(true)
                        }} className="excle-dowm"> Export excel </button>
                        {/* <button type="button" onClick={downloadExcelHandler} className="excle-dowm"> Export excel </button> */}
                      </Col>
                    </div>


                  </div>
                </Col>

                {/* table 1 */}
                <Col md={12}>
                  {/* <div className="table-responsive">
                    <table
                      className="table table-hover thead-primary"
                      ref={tableRef}
                    >
                      <thead>
                        <tr>
                          <th scope="col">Order ID</th>
                          <th scope="col">Guest Name</th>
                          <th scope="col">Total Guests</th>
                          <th scope="col">Date & Time</th>
                          <th scope="col">Total Billing</th>
                          <th scope="col">Discount Offered</th>
                          <th scope="col">Commission Accrued</th>
                          <th scope="col">Payment Status</th>

                        </tr>
                      </thead>
                      <tbody>
                        {currentItems?.length ? (
                          currentItems.map((report, index) => {
                            return (
                              <tr key={index}>
                                <td>{report.orderId}</td>
                                <td>{report.userName}</td>
                                <td>{report.totalGuest}</td>
                                <td>{report.date}</td>
                                <td> {report.totalBilling}</td>
                                <td> {report?.discount}</td>
                                <td> {report?.commission}</td>
                                <td>
                                  {" "}
                                  <span
                                    className={
                                      report.paymentStatus === "decline"
                                        ? "badge badge-dark"
                                        : report.paymentStatus === "accepted"
                                          ? "badge badge-success"
                                          : "badge badge-primary"
                                    }
                                  >
                                    {report.paymentStatus}{" "}
                                  </span>
                                </td>
                              
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td style={{ textAlign: "center" }} colSpan="8">No Reports Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {currentItems?.length ? (
                      <ReactPaginate
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        previousLabel="< previous"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                      />
                    ) : (
                      ""
                    )}
                  </div> */}
                  <h4>Foot Fall Report</h4>

                  <div className="table-responsive">
                    <table
                      className="table table-hover thead-primary"
                      ref={tableRef}
                    >
                      <thead>
                        <tr>
                          <th scope="col">S.No</th>
                          <th scope="col">Date of Booking</th>
                          <th scope="col">Total Available Covers</th>
                          <th scope="col">Total Number of Guest</th>
                          <th scope="col">Cover Occcupancy %</th>


                        </tr>
                      </thead>
                      <tbody>
                        {currentItems?.footFallReport?.length ? (
                          currentItems?.footFallReport?.map((report, index) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{report?.dateOfBooking}</td>
                                <td>{report?.availableCovers}</td>
                                <td>{report?.totalGuest}</td>
                                <td>{report?.occupancy}</td>


                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td style={{ textAlign: "center" }} colSpan="8">No Reports Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {/* {currentItems?.footFallReport?.length ? (
                      <ReactPaginate
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        previousLabel="< previous"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                      />
                    ) : (
                      ""
                    )} */}
                  </div>
                </Col>
                {currentItems?.footFallReport && <Col md={6}>
                  <LineChart data={currentItems?.footFallReport} data1={""} data2={""} />
                </Col>
                }
                {currentItems?.footFallReport && <Col md={6}>
                  <PipeChart data={currentItems?.footFallReport} />
                </Col>}

                {/* TAble 2 */}
                <Col md={6}>
                  <div className="mt-2">
                    <h4>Revenue Report </h4>

                    <div className="table-responsive">
                      <table
                        className="table table-hover thead-primary"
                        ref={tableRef}
                      >
                        <thead>
                          <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">Date of Booking</th>
                            <th scope="col">Total Revenue</th>



                          </tr>
                        </thead>
                        <tbody>
                          {currentItems?.revenueReport?.length ? (
                            currentItems?.revenueReport?.map((report, index) => {
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{report?.dateOfBooking}</td>
                                  <td>{report?.totalRevenue}</td>

                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td style={{ textAlign: "center" }} colSpan="8">No Reports Found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {/* {currentItems?.revenueReport?.length ? (
                        <ReactPaginate
                          nextLabel="next >"
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={3}
                          marginPagesDisplayed={2}
                          pageCount={pageCount}
                          previousLabel="< previous"
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          containerClassName="pagination"
                          activeClassName="active"
                          renderOnZeroPageCount={null}
                        />
                      ) : (
                        ""
                      )} */}
                    </div>
                  </div>
                </Col>
                {currentItems?.revenueReport && <Col md={6}>
                  <LineChart data={""} data2={""} data1={currentItems?.revenueReport} /></Col>}
                {/*  TAble 3*/}

                <Col md={6}>
                  <div className="mt-2">
                    <h4>Avg. Per Person Revenue Report </h4>

                    <div className="table-responsive">
                      <table
                        className="table table-hover thead-primary"
                        ref={tableRef}
                      >
                        <thead>
                          <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">Date of Booking</th>
                            <th scope="col">Avg. Per Person Sales</th>



                          </tr>
                        </thead>
                        <tbody>
                          {currentItems?.perPersonRevenueReport?.length ? (
                            currentItems?.perPersonRevenueReport?.map((report, index) => {
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{report?.dateOfBooking}</td>
                                  <td>{report?.averagePPSale}</td>

                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td style={{ textAlign: "center" }} colSpan="8">No Reports Found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {/* {currentItems?.perPersonRevenueReport?.length ? (
                        <ReactPaginate
                          nextLabel="next >"
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={3}
                          marginPagesDisplayed={2}
                          pageCount={pageCount}
                          previousLabel="< previous"
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          containerClassName="pagination"
                          activeClassName="active"
                          renderOnZeroPageCount={null}
                        />
                      ) : (
                        ""
                      )} */}
                    </div>
                  </div>
                </Col>
                {currentItems?.perPersonRevenueReport && <Col md={6}>
                  <LineChart data={""} data1={""} data2={currentItems?.perPersonRevenueReport} />
                </Col>}


                {/* TAble 4 */}

                <Col md={12}>
                  <div className="mt-2">
                    <h4>Business Forcast Report </h4>

                    <div className="table-responsive">
                      <table
                        className="table table-hover thead-primary"
                        ref={tableRef}
                      >
                        <thead>
                          <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">Date of Booking</th>
                            <th scope="col">Bookings</th>
                            <th scope="col">Pax</th>
                            <th scope="col">Revenue</th>
                            <th scope="col">Avg. PP Re.</th>



                          </tr>
                        </thead>
                        <tbody>
                          {currentItems?.businessForcastReport?.length ? (
                            currentItems?.businessForcastReport?.map((report, index) => {
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{report?.dateOfBooking}</td>
                                  <td>{report?.booking}</td>
                                  <td>{report?.pax}</td>
                                  <td>{report?.revenue}</td>
                                  <td>{report?.avgPPRevenue}</td>

                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td style={{ textAlign: "center" }} colSpan="8">No Reports Found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {/* {currentItems?.businessForcastReport?.length ? (
                        <ReactPaginate
                          nextLabel="next >"
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={3}
                          marginPagesDisplayed={2}
                          pageCount={pageCount}
                          previousLabel="< previous"
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          containerClassName="pagination"
                          activeClassName="active"
                          renderOnZeroPageCount={null}
                        />
                      ) : (
                        ""
                      )} */}
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  {currentItems?.businessForcastReport &&
                    // <LineChart  data={currentItems?.businessForcastReport} />
                   <StackedLineAreaColumnChart data={currentItems?.businessForcastReport} />
                   }
                </Col>
                <Col md={6}>
                  {currentItems?.businessForcastReport &&
                    // <LineChart  data={currentItems?.businessForcastReport} />
                   <StackedLineAreaColumnChart1 data={currentItems?.businessForcastReport} />
                   }
                </Col>




                {/* Table 5 */}

                <Col md={6}>
                  <div className="mt-2">
                    <h4>Time Trend-Dinner </h4>

                    <div className="table-responsive">
                      <table
                        className="table table-hover thead-primary"
                        ref={tableRef}
                      >
                        <thead>
                          <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">Date of Booking</th>
                            <th scope="col">8:00 PM - 9:00 PM</th>
                            <th scope="col">9:00 PM - 10:00 PM</th>
                            <th scope="col">10:00 PM - 11:00 PM</th>
                            <th scope="col">11:00 PM - 12:00 PM</th>
                            <th scope="col">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentItems?.timeTrendDinner?.length ? (
                            currentItems?.timeTrendDinner?.map((report, index) => {
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{report?.dateOfBooking}</td>
                                  <td>{report?.['8to9']}</td>
                                  <td>{report?.['9to10']}</td>
                                  <td>{report?.['10to11']}</td>
                                  <td>{report?.['11to12']}</td>
                                  <td>{report?.total}</td>

                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td style={{ textAlign: "center" }} colSpan="8">No Reports Found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {/* {currentItems?.timeTrendDinner?.length ? (
                        <ReactPaginate
                          nextLabel="next >"
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={3}
                          marginPagesDisplayed={2}
                          pageCount={pageCount}
                          previousLabel="< previous"
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          containerClassName="pagination"
                          activeClassName="active"
                          renderOnZeroPageCount={null}
                        />
                      ) : (
                        ""
                      )} */}
                    </div>
                  </div>
                </Col>
                {currentItems?.timeTrendDinner &&
                  <>
                    <Col md={6}><VerticalBarChart data={currentItems?.timeTrendDinner} data2={""} /></Col>
                    {/* <Col md={6}><PieChart data={currentItems?.timeTrendDinner} /></Col> */}
                  </>
                }

                {/* Table 6 */}

                <Col md={6}>
                  <div className="mt-2">
                    <h4>Time Trend-Lunch </h4>

                    <div className="table-responsive">
                      <table
                        className="table table-hover thead-primary"
                        ref={tableRef}
                      >
                        <thead>
                          <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">Date of Booking</th>
                            <th scope="col">12:00 Hrs - 13:00 Hrs</th>
                            <th scope="col">13:00 Hrs - 14:00 Hrs</th>
                            <th scope="col">14:00 Hrs - 15:00 Hrs</th>
                            <th scope="col">15:00 Hrs - 16:00 Hrs</th>

                            <th scope="col">Total</th>



                          </tr>
                        </thead>
                        <tbody>
                          {currentItems?.timeTrendLunch?.length ? (
                            currentItems?.timeTrendLunch?.map((report, index) => {
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{report?.dateOfBooking}</td>
                                  <td>{report?.['12to13']}</td>
                                  <td>{report?.['13to14']}</td>
                                  <td>{report?.['14to15']}</td>
                                  <td>{report?.['15to16']}</td>
                                  <td>{report?.total}</td>

                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td style={{ textAlign: "center" }} colSpan="8">No Reports Found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {/* {currentItems?.length ? (
                        <ReactPaginate
                          nextLabel="next >"
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={3}
                          marginPagesDisplayed={2}
                          pageCount={pageCount}
                          previousLabel="< previous"
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          containerClassName="pagination"
                          activeClassName="active"
                          renderOnZeroPageCount={null}
                        />
                      ) : (
                        ""
                      )} */}
                    </div>
                  </div>
                </Col>
                {currentItems?.timeTrendLunch && <Col md={6}>
                  <VerticalBarChart data={currentItems?.timeTrendLunch} />
                </Col>}

              </Row>
            </div>
          </div>
          <ExcelDateModal
            show={getExcelOfSelected}
            // callBack={(selectedCompetitor) => {
            //   onSubmit(selectedCompetitor)
            // }}
            onHide={() => {
              setExcelModal(false)
            }}
            selectedOutletId={selectedOutletId}
          // allOutlets={allOutlets}
          />

        </div>
      </div >
    </>
  );
};

export default BusinessReports;

