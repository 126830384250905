import React, { useEffect, useState } from "react";
import Breadcrumb from "../../Breadcrumb";
import { ProgressBar } from "react-bootstrap";
import { getRevenueReports } from "../../../redux/slices/Reports";
import { upperCase } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import $ from "jquery";
import {
  getAllOutlets,
  getOutletRating,
  sendFeedBackApi,
} from "../../../redux/slices/Outlet";
import { toast } from "react-toastify";
import Barchart from "../Charts/Barchart";
import Groupedbarchart from "../Charts/Groupedbarchart";
import FeedbackChats from "../Customer-review/FeedbackChats";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import ImageModal from "../Modals/ImageModal";
import { trimStringWithEllipsis } from "../../../util/constant";

const Content = () => {
  const [startDate, setstartDate] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [imageModal, setImageModal] = useState(false);
  const [endDate, setEndDate] = useState("");
  const dispatch = useDispatch();
  const [selectedOutlet, setSelectedOutlet] = useState({
    outletId: localStorage.getItem("outletId"),
  });
  const itemsPerPage = 3;
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [feedback, setFeedbacks] = useState([]);

  const rating = [1, 2, 3, 4, 5];
  const [feedBack, setFeedback] = useState("");
  const { outletList, outletRatings } = useSelector((state) => state.outlet);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [responseIndex, setResponseIndex] = useState(false);
  const handleShowReviewModal = (ind) => {
    setResponseIndex(ind);
    setShowReviewModal(!showReviewModal);
  };
  const [npsGraph, setNpsGraph] = useState();
  useEffect(() => {
    if (selectedOutlet?.outletId) {
      if (outletRatings && outletRatings.npsGraph) {
        setNpsGraph(outletRatings.npsGraph);
      }

      const endOffset = itemOffset + itemsPerPage;
      // console.log("outletRatings", outletRatings?.userReview)

      setCurrentItems(outletRatings?.userReview?.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(outletRatings?.userReview?.length / itemsPerPage));
    } else {
      setNpsGraph();
      setCurrentItems([]);
      setPageCount(0);
    }
  }, [itemOffset, itemsPerPage, outletRatings?.userReview]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % outletRatings?.userReview?.length;
    setItemOffset(newOffset);
  };
  const getRating = (ratings, item) => {
    if (ratings >= item) {
      return true;
    } else {
      return false;
    }
  };
  const handleFeedbackChange = (e, id, userId, index) => {
    document.getElementById("error" + index).style.display = "none";
    setFeedback({
      ...feedBack,
      feedback: e.target.value,
      reviewId: id,
      toUserId: userId,
      index: index,
    });
  };
  const sendFeedback = (index) => {
    if (selectedOutlet.outletId !== "") {
      if (feedBack?.feedback) {
        dispatch(
          sendFeedBackApi({
            outletId: selectedOutlet?.outletId,
            reviewId: feedBack?.reviewId,
            message: feedBack?.feedback,
            toUserId: feedBack?.toUserId,
          })
        )
          .unwrap()
          .then(() => {
            dispatch(
              getOutletRating({
                outletId: selectedOutlet.outletId,
                endDate: endDate,
                startDate: startDate,
              })
            );
            toast.success("Reply sent successfully");
            document.getElementById("reply").value = "";
          })
          .catch((err) => {
            toast.error(err);
          });
      } else {
        document.getElementById("error" + index).style.display = "block";
      }
    } else {
      toast.error("Please Select the outlet first");
    }
  };
  useEffect(() => {
    dispatch(getAllOutlets({ vendorId: localStorage.getItem("vendorId") }));
    let outletID = "";
    if (localStorage.getItem("vendorType") === "single") {
      dispatch(
        getOutletRating({
          outletId: localStorage.getItem("outletId"),
          endDate: endDate,
          startDate: startDate,
        })
      );
    } else {
      if (localStorage.getItem("outletId")) {
        outletID = localStorage.getItem("outletId");
      }
      dispatch(
        getOutletRating({
          outletId: outletID,
          endDate: endDate,
          startDate: startDate,
        })
      );
    }
  }, [dispatch]);

  const filterDatas = () => {
    let outletID = "";
    if (localStorage.getItem("vendorType") === "single") {
      dispatch(
        getOutletRating({
          outletId: selectedOutlet.outletId,
          endDate: endDate,
          startDate: startDate,
        })
      );
    } else {
      dispatch(
        getOutletRating({
          outletId: selectedOutlet.outletId,
          endDate: endDate,
          startDate: startDate,
        })
      );
    }
  };

  useEffect(() => {
    if (outletRatings?.feedback) {
      const ratingsFloat = {};
      for (const key in outletRatings?.feedback) {
        ratingsFloat[key] = parseFloat(outletRatings?.feedback[key]);
      }
      console.log("dddd", ratingsFloat);
      setFeedback(ratingsFloat);
    }
  }, [outletRatings?.feedback]);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props.message}
    </Tooltip>
  );
  return (
    <div className="ms-content-wrapper ">
      <Breadcrumb title="Feedback" links={[{ to: "/", name: "Home" }]} />
      <div className="form-admin">
        <div className="row">
          <div className="col-md-6">
            <div className="d-flex">
              {localStorage.getItem("vendorType") === "multiple" && (
                <div
                  className="outletDropdown"
                  style={{
                    padding: "0 ",
                    display: "flex",
                    // justifyContent: " flex-end",
                    width: "100%",
                    // float: "right",
                  }}
                >
                  <label
                    htmlFor="validationCustom01"
                    className="form-label mt-2 mr-2"
                  >
                    Select Outlet
                  </label>
                  <div className="dropdown-group form-vendor-regi">
                    <select
                      className="form-control  mb-2"
                      id="validationCustom01"
                      name="outletId"
                      value={selectedOutlet?.outletId}
                      onChange={(e) => {
                        setFeedback("");
                        setSelectedOutlet({
                          ...selectedOutlet,
                          outletId: e.target.value,
                        });
                        dispatch(
                          getOutletRating({
                            ...selectedOutlet,
                            outletId: e.target.value,
                            endDate: endDate,
                            startDate: startDate,
                          })
                        )
                          .then((res) => {
                            //  console.log('res',res)
                          })
                          .catch((err) => {
                            // console.log(err);
                          });
                      }}
                    >
                      <option value={""}>Please Select</option>
                      {outletList.length ? (
                        outletList.map((item) => {
                          return (
                            <option value={item.outletId}>
                              {upperCase(item.outletName)}
                            </option>
                          );
                        })
                      ) : (
                        <option value={0}> Not found</option>
                      )}
                    </select>
                    {/* <div className="invalid-feedback">
                            Please Select a valid Designation.
                          </div> */}
                  </div>
                </div>
              )}
              {/* <div
                style={{
                  padding: "0 ",
                  display: "flex",
                  // justifyContent: " flex-end",
                  width: "100%",
                  // float: "right",

                }}
              >
                <label htmlFor="validationCustom01" className="form-label mt-2 mr-2">
                  Filter
                </label>
                <div className="dropdown-group form-vendor-regi">
                  <select
                    className="form-control  mb-2"
                    id="validationCustom01"
                    name="filter"
                    onChange={(e) => {
                      dispatch(
                        getOutletRating({
                          outletId: selectedOutlet.outletId,
                          filter: e.target.value,
                          endDate: endDate,
                          startDate: startDate
                        })
                      );
                    }}
                  >
                    <option value={0}>Please Select</option>
                    <option value={"7"}>One week</option>
                    <option value={"15"}>15 Days</option>
                    <option value={"30"}>01 Month</option>
                    <option value={"90"}>01 Quarter</option>
                    <option value={"180"}>06 Months</option>
                    <option value={"365"}>01 Year</option>
                  </select>
                </div>
              </div> */}
            </div>
          </div>

          <div className="col-md-6">
            <div className="d-flex">
              <div
                className="outletDropdown"
                style={{
                  padding: "0 ",
                  display: "flex",
                  // justifyContent: " flex-end",
                  width: "100%",
                  // float: "right",
                }}
              >
                <label
                  htmlFor="validationCustom01"
                  className="form-label mt-2 mr-2"
                >
                  Start Date
                </label>
                <div className="dropdown-group form-vendor-regi">
                  <input
                    type="date"
                    onChange={(e) => {
                      setstartDate(e.target.value);
                    }}
                    value={startDate}
                  />
                </div>
              </div>
              <div
                className="outletDropdown"
                style={{
                  padding: "0 ",
                  display: "flex",
                  // justifyContent: " flex-end",
                  width: "100%",
                  // float: "right",
                }}
              >
                <label
                  htmlFor="validationCustom01"
                  className="form-label mt-2 mr-2"
                >
                  End Date
                </label>
                <div className="dropdown-group form-vendor-regi">
                  <input
                    type="date"
                    onChange={(e) => {
                      setEndDate(e.target.value);
                    }}
                    value={endDate}
                  />
                </div>
              </div>
              <div>
                <button
                  type="button"
                  onClick={filterDatas}
                  className="excle-dowm px-2 m-0"
                >
                  {" "}
                  Search{" "}
                </button>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="ms-panel">
              <div className="ms-panel-header">
                <h6>Rating </h6>
              </div>
              {outletRatings?.rating && (
                <div className="ms-panel">
                  <Barchart data={outletRatings?.rating} about={"Ratings"} />
                </div>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="ms-panel">
              <div className="ms-panel-header">
                <h6>Net Promoter Score (NPS)</h6>
              </div>
              {npsGraph && (
                <div className="ms-panel">
                  <Groupedbarchart
                    data={npsGraph}
                    about={"NPS"}
                    isHoriz={false}
                    totalReview={outletRatings?.totalReview}
                  />
                </div>
              )}
            </div>
          </div>
          {feedBack && (
            <div className="col-md-4">
              <div className="ms-panel">
                <Barchart data={feedBack} about={"Feedback"} />
              </div>
            </div>
          )}
          {/* <div className="col-md-6"></div> */}

          {currentItems
            ? currentItems.map((i, ind) => {
                return (
                  <div className="col-md-12">
                    <div className="comment-section">
                      <div className="user-imnage-name ">
                        <div className="name-img-wrap">
                          <div className="user-imahs ">
                            <img src={i?.userImage} />
                          </div>
                          <div className="usera-name">
                            <div className="d-flex align-items-center">
                              <h6>{i?.name}</h6>
                              <div className="fil-star ml-2">
                                <ul className="ms-star-rating rating-bordered rating-circle replce-star">
                                  {rating &&
                                    rating.map((ind) => {
                                      return (
                                        <li
                                          className={`ms-rating-item ${
                                            getRating(i.rating, ind)
                                              ? "rated"
                                              : ""
                                          }`}
                                        >
                                          {" "}
                                          <i className="material-icons">star</i>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </div>
                            </div>
                            <div className="">
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 100, hide: 100 }}
                                overlay={renderTooltip({ message: i?.message })}
                              >
                                <sapn className="user-text">
                                  {trimStringWithEllipsis(i?.message, 80)}
                                </sapn>
                              </OverlayTrigger>
                            </div>
                          </div>
                        </div>
                        <div className="date">
                          <h6>Comment Date: {i?.date}</h6>
                        </div>
                      </div>

                      <div className="siplays my-2  2">
                        <div>
                          {i.uploadedPictures.map((vali) => {
                            return (
                              <div
                                className="img-upload"
                                onClick={(e) => {
                                  setImageUrl(vali);
                                  setImageModal(true);
                                }}
                              >
                                {/* <Link to={vali} target="_blank"> */}
                                <img src={vali} />
                                {/* </Link> */}
                              </div>
                            );
                          })}
                        </div>
                        <div>
                          {i.reviewResponse.length > 0 && (
                            <button
                              className="mt-0 excle-dowm"
                              onClick={() => {
                                handleShowReviewModal(i.reviewId);
                              }}
                              style={{
                                width: "165px",
                                marginRight: "7px",
                                // padding:"50px"
                              }}
                            >
                              All Replies
                            </button>
                          )}
                        </div>
                      </div>

                      <div
                        className={
                          i.reviewResponse.length == 0 ? "rply-btn mt-3" : ""
                        }
                      >
                        {i.reviewResponse.length == 0 ? (
                          <input
                            type="text"
                            id="reply"
                            style={{ width: "75%" }}
                            onChange={(e) => {
                              handleFeedbackChange(
                                e,
                                i?.reviewId,
                                i?.userId,
                                ind
                              );
                            }}
                            placeholder="Reply to comment"
                          />
                        ) : (
                          ""
                        )}

                        <span
                          className={`error`}
                          id={`error${ind}`}
                          style={{ display: "none" }}
                        >
                          Please enter comment.
                        </span>

                        {i.reviewResponse.length == 0 && (
                          <button
                            className="mt-0 excle-dowm"
                            style={{
                              width: "165px",
                              // marginRight: "200px"
                            }}
                            onClick={() => sendFeedback(ind)}
                          >
                            Reply
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })
            : ""}
        </div>
        {showReviewModal && (
          <FeedbackChats
            handleShowReviewModal={handleShowReviewModal}
            setReviewModal={setShowReviewModal}
            index={responseIndex}
            selectedOutlet={selectedOutlet}
          />
        )}

        <ImageModal
          imageUrl={imageUrl}
          onHide={() => {
            setImageModal(false);
          }}
          show={imageModal}
        />

        {/* <div className="row">
          {outletRatings?.overAllRating
            ? Object.keys(outletRatings?.overAllRating).map((keyName, i) => (
              <div className="col-md-6">
                <div className="ms-panel" key={i}>
                  <div className="ms-panel-body">
                    <div className="row">
                      <div className="col-xl-6 col-md-6 col-sm-6">
                        <h6 className="star-name">{keyName}</h6>
                      </div>
                      <div className="col-xl-6 col-md-6 col-sm-6">
                        <ul className="ms-star-rating rating-bordered rating-circle">
                          {rating.map((r) => {
                            return (
                              <li
                                className={`ms-rating-item ${getRating(
                                  outletRatings?.feedback[keyName],
                                  r
                                )
                                  ? "rated"
                                  : ""
                                  }`}
                              >
                                {" "}
                                <i className="material-icons">star</i>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
            : ""}
        </div> */}
        {currentItems && (
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        )}
      </div>
    </div>
  );
};

export default Content;
