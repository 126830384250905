import React from "react";
import { Col, Row } from "react-bootstrap";
import Breadcrumb from "./Breadcrumb";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { upperCase } from "../../../constants";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updateSettingsDocument } from "../../../redux/slices/Registration";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const UpdateDocument = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    licence: Yup.mixed().required("File is required"),
    licenceNumber: Yup.mixed().required("File is required"),
  });
  const formik = useFormik({
    initialValues: {
      licenceNumber: location?.state?.number || "",
      licence: location?.state?.src || "",
    },
    validationSchema,
    onSubmit: (values) => {
      let data = new FormData();
      data.append(`vendorId`, localStorage.getItem("vendorId"));
      data.append(`${location?.state?.key}`, values.licence);
      data.append(`${location?.state?.key}Number`, values.licenceNumber);
      dispatch(updateSettingsDocument(data))
        .unwrap()
        .then((res) => {
          // toast.success(res.message);
          Swal.fire({
            title: "Success",
            text: res.message,
            icon: "success",
            confirmButtonText: "OK",
            confirmButtonColor: "#00bcd4",
          }).then(() => {
            // setNextStep(1);
          });
        })
        .catch((err) => {
          toast.error(err);
        });
    },
  });
  const renderFilePreview = (src, name) => {
    return (
      <div className="img-pos col-md-4" style={{ marginBottom: "35px" }}>
        <img
          src={typeof src === "string" ? src : URL.createObjectURL(src)}
          alt="preview"
          // style={{ width: "250px", height: "150px" }}
          onError={(e) => {
            e.target.src = "";
            e.target.alt = name;
          }}
        />
      </div>
    );
  };

  const renderFile = () => {
    if (formik.values.licence) {
      return (
        <>
          {renderFilePreview(
            formik.values.licence,
            formik.values.licenceNumber
          )}
        </>
      );
    }
  };
  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb
            title="update-document"
            links={[
              { to: "/", name: "Home" },
              { to: "/settings/documents", name: "Documents" },
            ]}
          />
          {/* <Favorder/> */}
          <div className="col-12">
            <div className="ms-panel">
              <div className="ms-panel-header">
                <h6> Document </h6>
              </div>
              <Row>
                <Col md={12}>
                  <div
                    className="form-vendor-regi"
                    style={{
                      padding: "30px 60px 50px",
                    }}
                  >
                    <form className="needs-validation">
                      <div className="form-row">
                        <div className="col-md-12 ">
                          <label
                            htmlFor="foodLicence"
                            className="form-label customLabel"
                          >
                            {location?.state?.key
                              ? upperCase(location?.state?.key)
                              : ""}
                            <span className="error">*</span>
                          </label>
                          <div className="row">
                            <div className="col-md-6 ">
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="validationCustom03"
                                  placeholder="Enter Licence number"
                                  name="licenceNumber"
                                  style={{
                                    borderColor:
                                      formik.errors.licenceNumber && formik.touched.licenceNumber
                                        ? "red"
                                        : "#3ea99f",
                                  }}
                                  onChange={(e) => {
                                    formik.handleChange(e);
                                  }}
                                  value={formik.values.licenceNumber}
                                  required
                                />
                                {formik?.errors?.licenceNumber && (
                                <div style={{"display":"block"}} className="invalid-feedback">
                                  This field is required.
                                </div>)}
                              </div>
                            </div>
                            <div className="col-md-6 ">
                              <div className="input-group">
                                <input
                                  type="file"
                                  className="uploadFile"
                                  id="foddFile"
                                  placeholder="Upload Food Licence"
                                  name="licence"
                                 accept="image/*,application/pdf"
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      "licence",
                                      e.currentTarget.files[0]
                                    );
                                  }}
                        
                                />
                                <div>
                                  {formik.errors.licence &&
                                    formik.touched.licence && (
                                      <div className="error">
                                        {formik.errors.licence}
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* {renderFile()} */}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <button
                            className="steps-btn"
                            type="button"
                            onClick={formik.handleSubmit}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UpdateDocument;
