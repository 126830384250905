import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  FormControl,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import "../../Auth/Steps/styles.css";
import loginLogo from "../../../../assets/img/logo.png";
import {
  getCategories,
  registerStepThree,
} from "../../../../redux/slices/Registration";
import { toast } from "react-toastify";
import {
  stepFourSave,
  stepThreeSave,
} from "../../../../redux/slices/SaveDetails";
import { upperCase } from "../../../../constants";

const amenitiesData = {
  payment: [
    "Credit Cards",
    "Discover",
    "Visa",
    "American Express",
    "Cash Only",
  ],
  parkingCategory: [
    "Street Parking",
    "Valet Parking",
    "Validated Parking",
    "Parking Available",
  ],
  otherAttraction: [
    "Beach",
    "Waterfront",
    "Full Bar",
    "Jazz Bar",
    "Sports Bar",
  ],
};

const daysData = [
  {
    day: "Monday",
    fromTime: "",
    toTime: "",
    fullDay: false,
  },
  {
    day: "Tuesday",
    fromTime: "",
    toTime: "",
    fullDay: false,
  },
  {
    day: "Wednesday",
    fromTime: "",
    toTime: "",
    fullDay: false,
  },
  {
    day: "Thursday",
    fromTime: "",
    toTime: "",
    fullDay: false,
  },
  {
    day: "Friday",
    fromTime: "",
    toTime: "",
    fullDay: false,
  },
  {
    day: "Saturday",
    fromTime: "",
    toTime: "",
    fullDay: false,
  },
  {
    day: "Sunday",
    fromTime: "",
    toTime: "",
    fullDay: false,
  },
];

const StepThree = ({ setNextStep, nextStep }) => {
  const [showCuisineModal, setShowCuisineModal] = useState(false);
  const [showHoursModal, setShowHoursModal] = useState(false);
  const [hoursData, setHoursData] = useState(daysData);
  const [searchCuisine, setSearchCuisine] = useState("");
  const { stepThree } = useSelector((state) => state.saveDetails);
  const [initialValues, setInitialValues] = React.useState(
    stepThree
      ? stepThree
      : {
        cuisine: [],
        meals: [],
        payment: [],
        parking: [],
        moreFacilities: [],
        otherAttractions: [],
        themes: [],
      }
  );
  const dispatch = useDispatch();
  const {
    cuisineCategories,
    mealsCategories,
    parkingCategories,
    otherAttractions,
    themes,
    payments,
    moreFacilities,
    outletId,
  } = useSelector((state) => state.register);
  const validationSchema = Yup.object().shape({
    cuisine: Yup.array()
      .min(1, "Please Select at least one cuisine")
      .max(10, "Please Select a maximum of 10 cuisines"),
    meals: Yup.array().min(1, "at least one meal required"),
    payment: Yup.array().min(1, "at least one payment method required"),
    parking: Yup.array().min(1, "at least one parking selection required"),
    themes: Yup.array().min(1, "at least one must be selected"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      dispatch(
        stepThreeSave({
          ...values,
          hoursData: hoursData,
        })
      );
      dispatch(
        registerStepThree({
          ...values,
          outletId: outletId,
          openingHoures: hoursData,
        })
      )
        .unwrap()
        .then((res) => {
          toast.success(res.message);
          setNextStep(4);
        })
        .catch((err) => {
          toast.error(err);
        });
    },
  });

  const handleShowCuisine = () => setShowCuisineModal(!showCuisineModal);
  const handleShowHours = () => setShowHoursModal(!showHoursModal);

  const handleHoursData = (e, index, day) => {
    // console.log('2',e.target.checked,e.target.value,day)
    // let newHoursData = hoursData;
    // newHoursData.find((obj,i) => {
    //   if(obj.day == day){
    //     console.log('obj',obj)
    //       if(e.target.name == "fullDay"){
    //         if(e.target.checked){
    //           obj.fromTime = '00:00';
    //           obj.toTime = '23:59';
    //           obj.fullDay = true;
    //         }else{
    //           obj.fromTime = '';
    //           obj.toTime = '';
    //           obj.fullDay = false;
    //         }
    //       }else{
    //         obj.fullDay = false;
    //         if(e.target.name == 'fromTime'){
    //           obj.fromTime = e.target.value;
    //         }else if(e.target.name == 'toTime'){
    //           obj.toTime = e.target.value;
    //         }
    //       }
    //   }
    // })
    // console.log('newHoursData',newHoursData)
    // setHoursData(newHoursData)
    setHoursData(
      hoursData.map((item, i) => {
        if (i === index) {
          if (item.day == day) {
            if (e.target.name == "fullDay") {
              if (e.target.checked) {

                return {
                  ...item,
                  ['fromTime']: '00:00',
                  ['toTime']: '23:59',
                  ['fullDay']: true,
                };

              } else {
                return {
                  ...item,
                  ['fromTime']: '',
                  ['toTime']: '',
                  ['fullDay']: false,
                };
              }
            } else {
              // item.fullDay = false;

              if (e.target.name == 'fromTime') {
                return {
                  ...item,
                  ['fromTime']: e.target.value,
                  ['fullDay']: false,
                };
              } else if (e.target.name == 'toTime') {
                return {
                  ...item,
                  ['toTime']: e.target.value,
                  ['fullDay']: false,
                };
              }
            }
          }
        }
        return item;
      })
    );
  };

  const renderCuisineData = () => {

    // return cuisineCategories
    //   .filter((item) =>
    //     item.name.toLowerCase().includes(searchCuisine.toLowerCase())
    //   )
    //   .map((value, index) => {
    //     return (
    //       <div className="col-md-4" key={index}>
    //         <label>
    //           <input
    //             type="checkbox"
    //             name="cuisine"
    //             value={value._id}
    //             defaultChecked={
    //               formik.values.cuisine.includes(value._id) ||
    //               initialValues.cuisine.includes(value._id)
    //             }
    //             onChange={(e) => {
    //               if (e.target.checked) {
    //                 formik.setFieldValue("cuisine", [
    //                   ...formik.values.cuisine,
    //                   e.target.value,
    //                 ]);
    //               } else {
    //                 formik.setFieldValue(
    //                   "cuisine",
    //                   formik.values.cuisine.filter(
    //                     (item) => item !== e.target.value
    //                   )
    //                 );
    //               }
    //             }}
    //           />
    //           &nbsp;{value.name}
    //         </label>
    //       </div>
    //     );
    //   });
    return (
      <div className="row">
        <div className="col-md-4">
          <h6 className="greencolor">
            General Cuisines
          </h6>
          <div>
            {cuisineCategories
              .filter((item) =>
                item.name.toLowerCase().includes(searchCuisine.toLowerCase())
              ).filter((items) => items.category == "general cuisines"
              ).map((value, index) => {
                return (
                  <>
                    <div className="w-100" key={index}>
                      <label>
                        <input
                          type="checkbox"
                          name="cuisine"
                          value={value._id}
                          defaultChecked={
                            formik.values.cuisine.includes(value._id) ||
                            initialValues.cuisine.includes(value._id)
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              formik.setFieldValue("cuisine", [
                                ...formik.values.cuisine,
                                e.target.value,
                              ]);
                            } else {
                              formik.setFieldValue(
                                "cuisine",
                                formik.values.cuisine.filter(
                                  (item) => item !== e.target.value
                                )
                              );
                            }
                          }}
                        />
                        &nbsp;{upperCase(value.name)}
                      </label>
                    </div>


                  </>

                );
              })}
          </div>
        </div>
        <div className="col-md-4">
          <h6 className="greencolor">
            Indian Cuisines
          </h6>
          <div>
            {cuisineCategories
              .filter((item) =>
                item.name.toLowerCase().includes(searchCuisine.toLowerCase())
              ).filter((items) => items.category == "indian cuisines"
              ).map((value, index) => {
                return (
                  <>
                    <div className="w-100" key={index}>
                      <label>
                        <input
                          type="checkbox"
                          name="cuisine"
                          value={value._id}
                          defaultChecked={
                            formik.values.cuisine.includes(value._id) ||
                            initialValues.cuisine.includes(value._id)
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              formik.setFieldValue("cuisine", [
                                ...formik.values.cuisine,
                                e.target.value,
                              ]);
                            } else {
                              formik.setFieldValue(
                                "cuisine",
                                formik.values.cuisine.filter(
                                  (item) => item !== e.target.value
                                )
                              );
                            }
                          }}
                        />
                        &nbsp;{upperCase(value.name)}
                      </label>
                    </div>


                  </>

                );
              })}
          </div>
        </div>

        <div className="col-md-4">
          <h6 className="greencolor">
            International Cuisines
          </h6>
          <div>
            {cuisineCategories
              .filter((item) =>
                item.name.toLowerCase().includes(searchCuisine.toLowerCase())
              ).filter((items) => items.category == "international cuisines"
              ).map((value, index) => {
                return (
                  <>
                    <div className="w-100" key={index}>
                      <label>
                        <input
                          type="checkbox"
                          name="cuisine"
                          value={value._id}
                          defaultChecked={
                            formik.values.cuisine.includes(value._id) ||
                            initialValues.cuisine.includes(value._id)
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              formik.setFieldValue("cuisine", [
                                ...formik.values.cuisine,
                                e.target.value,
                              ]);
                            } else {
                              formik.setFieldValue(
                                "cuisine",
                                formik.values.cuisine.filter(
                                  (item) => item !== e.target.value
                                )
                              );
                            }
                          }}
                        />
                        &nbsp;{upperCase(value.name)}
                      </label>
                    </div>


                  </>

                );
              })}
          </div>
        </div>
        <div className="col-md-4">
          <h6 className="greencolor">
            European Cuisines
          </h6>
          <div>
            {cuisineCategories
              .filter((item) =>
                item.name.toLowerCase().includes(searchCuisine.toLowerCase())
              ).filter((items) => items.category == "european cuisines"
              ).map((value, index) => {
                return (
                  <>
                    <div className="w-100" key={index}>
                      <label>
                        <input
                          type="checkbox"
                          name="cuisine"
                          value={value._id}
                          defaultChecked={
                            formik.values.cuisine.includes(value._id) ||
                            initialValues.cuisine.includes(value._id)
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              formik.setFieldValue("cuisine", [
                                ...formik.values.cuisine,
                                e.target.value,
                              ]);
                            } else {
                              formik.setFieldValue(
                                "cuisine",
                                formik.values.cuisine.filter(
                                  (item) => item !== e.target.value
                                )
                              );
                            }
                          }}
                        />
                        &nbsp;{upperCase(value.name)}
                      </label>
                    </div>


                  </>

                );
              })}
          </div>
        </div>

      </div>
    )
  }

  const renderHoursData = () => {
    return hoursData.map((valueObj, index) => {
      const { day, fromTime, toTime, fullDay } = valueObj;
      return (
        <div>
          <h5>{day}</h5>
          {!fullDay && (
            <>
              <div
                style={{
                  color: "purple",
                  marginTop: "20px",
                  marginBottom: "10px",
                }}
              >
                Add open hours
              </div>
              <input
                type="time"
                name="fromTime"
                value={fromTime}
                style={{ marginBottom: "20px" }}
                onChange={(e) => {
                  handleHoursData(e, index, day)
                }}
              />
              <input
                type="time"
                name="toTime"
                value={toTime}
                style={{ margin: "10px" }}
                onChange={(e) => handleHoursData(e, index, day)}
              />
            </>
          )}
          <br />
          <div>
            <label>
              <input
                type="checkbox"
                name="fullDay"
                defaultChecked={fullDay}
                onChange={(e) => handleHoursData(e, index, day)}
              />
              &nbsp;Open 24 Hours
            </label>
          </div>
          <hr />
        </div>
      );
    });
  };

  const renderCuisineModal = () => {
    return (
      <Modal
        show={showCuisineModal}
        onHide={handleShowCuisine}
        style={{ height: "90vh", maxHeight: "90vh", overflow: "hidden" }}
        scrollable
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add cuisines</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">&#128269;</InputGroup.Text>
            <FormControl
              placeholder="search cuisine"
              aria-label="search cuisine"
              aria-describedby="basic-addon1"
              onChange={(e) => setSearchCuisine(e.target.value)}
            />
          </InputGroup>
          <div className="row" style={{ margin: "auto" }}>
            {renderCuisineData()}
          </div>
          {formik.errors.cuisine && formik.touched.cuisine && (
            <div className="error">{formik.errors.cuisine}</div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleShowCuisine}>
            Close
          </Button>
          <Button variant="primary" onClick={handleShowCuisine}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const renderOpenHoursModal = () => {
    return (
      <Modal
        show={showHoursModal}
        onHide={handleShowHours}
        style={{ height: "90vh", maxHeight: "90vh", overflow: "hidden" }}
        scrollable
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Add open hours
            <br />
            <span style={{ fontSize: "1rem" }}>
              Days without hours added will display as closed.
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{renderHoursData()}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleShowHours}>
            Close
          </Button>
          <Button variant="primary" onClick={handleShowHours}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const renderMeals = () => {
    return mealsCategories.map((value, index) => {
      return (
        <div className="col-md-4">
          <label>
            <input
              type="checkbox"
              value={value._id}
              name="meals"
              defaultChecked={
                formik.values.meals.includes(value._id) ||
                initialValues.meals.includes(value._id)
              }
              onChange={(e) => {
                formik.setFieldValue("meals", [
                  ...formik.values.meals,
                  e.target.value,
                ]);
              }}
            />
            &nbsp;{value.name}
          </label>
        </div>
      );
    });
  };
  const renderPayment = () => {
    return payments.map((value, index) => {
      return (
        <div className="col-md-4">
          <label>
            <input
              type="checkbox"
              value={value._id}
              name="payment"
              defaultChecked={
                formik.values.payment.includes(value._id) ||
                initialValues.payment.includes(value._id)
              }
              onChange={(e) => {
                formik.setFieldValue("payment", [
                  ...formik.values.payment,
                  e.target.value,
                ]);
              }}
            />
            &nbsp;{value.name}
          </label>
        </div>
      );
    });
  };
  const renderParking = () => {
    return parkingCategories.map((value, index) => {
      return (
        <div className="col-md-4">
          <label>
            <input
              type="checkbox"
              value={value._id}
              name="parking"
              defaultChecked={
                formik.values.parking.includes(value._id) ||
                initialValues.parking.includes(value._id)
              }
              onChange={(e) => {
                formik.setFieldValue("parking", [
                  ...formik.values.parking,
                  e.target.value,
                ]);
              }}
            />
            &nbsp;{value.name}
          </label>
        </div>
      );
    });
  };

  const renderGoodFor = () => {
    return themes.map((value, index) => {
      return (
        <div className="col-md-4">
          <label>
            <input
              type="checkbox"
              value={value._id}
              name="themes"
              defaultChecked={
                formik.values.themes.includes(value._id) ||
                initialValues.themes.includes(value._id)
              }
              onChange={(e) => {
                formik.setFieldValue(`themes`, [
                  ...formik.values.themes,
                  e.target.value,
                ]);
              }}
            />
            &nbsp;{value.name}
          </label>
        </div>
      );
    });
  };
  const renderOtherAttractions = () => {
    return otherAttractions.map((value, index) => {
      return (
        <div className="col-md-4">
          <label>
            <input
              type="checkbox"
              value={value._id}
              name="otherAttractions"
              defaultChecked={
                formik.values.otherAttractions.includes(value._id) ||
                initialValues.otherAttractions.includes(value._id)
              }
              onChange={(e) => {
                formik.setFieldValue(`otherAttractions`, [
                  ...formik.values.otherAttractions,
                  e.target.value,
                ]);
              }}
            />
            &nbsp;{value.name}
          </label>
        </div>
      );
    });
  };
  const renderMoreFacilities = () => {
    return moreFacilities.map((value, index) => {
      return (
        <div className="col-md-4">
          <label>
            <input
              type="checkbox"
              value={value._id}
              name="moreFacilities"
              defaultChecked={
                formik.values.moreFacilities.includes(value._id) ||
                initialValues.moreFacilities.includes(value._id)
              }
              onChange={(e) => {
                formik.setFieldValue(`moreFacilities`, [
                  ...formik.values.moreFacilities,
                  e.target.value,
                ]);
              }}
            />
            &nbsp;{value.name}
          </label>
        </div>
      );
    });
  };

  useEffect(() => {
    dispatch(getCategories("cuisine"));
    dispatch(getCategories("theme"));
    dispatch(getCategories("otherAttraction"));
    dispatch(getCategories("meals"));
    dispatch(getCategories("parking"));
    dispatch(getCategories("payment"));
    dispatch(getCategories("moreFacilities"));
    if (stepThree) {
      setInitialValues(stepThree);
      setHoursData(stepThree.hoursData);
    }
  }, [dispatch, initialValues]);

  return (
    <div className="ms-content-wrapper ms-auth">
      <div className="regi-section">
        <Row>
          <Col md={5}>
            <div className="ms-auth-bg">
              <img src={loginLogo} />
            </div>
          </Col>
          <Col md={7}>
            <div className="regi-form three-staps">
              <form
                className="needs-validation"
                onSubmit={formik.handleSubmit}
                style={{ width: "100%" }}
              >
                <h3>
                  Registration : Step  <span>{nextStep}/5</span>
                </h3>
                <div className="" style={{ marginTop: "20px" }}>
                  <div className=" ">
                    <h6>What type of cuisine does this place serve?<span className="error">*</span></h6>
                    {/* <span className="text-muted">Select 10 max</span> */}
                    <br />
                    <button
                      className="cuisines-btn steps-btn float-none mt-4"
                      style={{ borderRadius: "20px" }}
                      type="button"
                      onClick={handleShowCuisine}
                    >
                      {formik.values.cuisine.length > 0
                        ? "Edit cuisines"
                        : "Add cuisines"}
                    </button>
                    {/* cuisine modal */}
                    {renderCuisineModal()}
                    {formik.errors.cuisine && formik.touched.cuisine && (
                      <div className="error">{formik.errors.cuisine}</div>
                    )}
                    <div
                      style={{
                        // display: "flex",
                        flexDirection: "row",
                        // flexWrap: "wrap",
                        justifyContent: "space-between",
                        marginTop: "20px",
                      }}
                    >
                      {formik.values.cuisine && (
                        <div className="row">
                          {formik.values.cuisine.map((value, index) => {
                            return (
                              <div className="col-md-4" key={index}>
                                <span
                                  style={{
                                    backgroundColor: "#f5f5f5",
                                    borderRadius: "20px",
                                    padding: "5px",
                                    margin: "5px",
                                    fontSize: "1.0rem",
                                    fontWeight: "bold",
                                    color: "#000",
                                  }}
                                >
                                  {
                                    cuisineCategories.filter(
                                      (item) => item._id === value
                                    )[0].name
                                  }
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-row" style={{ marginTop: "20px" }}>
                  <div className="col-md-12 ">
                    <h6>What hours is this place open?</h6>
                    <button
                      className="btn btn-outline-primary font-weight-bold"
                      style={{ borderRadius: "20px" }}
                      type="button"
                      onClick={handleShowHours}
                    >
                      Add open hours
                    </button>
                    {/* open hours modal */}
                    {renderOpenHoursModal()}
                  </div>
                </div>
                <div
                  style={{
                    // display: "flex",
                    flexDirection: "row",
                    // flexWrap: "wrap",
                    justifyContent: "space-between",
                    marginTop: "20px",
                  }}
                >
                  {hoursData.length > 0 && (
                    <div className="row">
                      {hoursData.map((value, index) => {
                        return value.fullDay ||
                          (value.fromTime && value.toTime) ? (
                          <div className="col-md-4" key={index}>
                            <span
                              style={{
                                backgroundColor: "#f5f5f5",
                                borderRadius: "20px",
                                padding: "5px",
                                margin: "5px",
                                fontSize: "1.0rem",
                                fontWeight: "bold",
                                color: "#000",
                              }}
                            >
                              <br />
                              {value.day}
                              <br />
                              {value.fromTime !== ""
                                ? `( ${value.fromTime} to ${value.toTime} )`
                                : ""}
                            </span>
                          </div>
                        ) : (
                          ""
                        );
                      })}
                    </div>
                  )}
                </div>
                <div className="form-row" style={{ marginTop: "20px" }}>
                  <div className="col-md-12 ">
                    <h6>What meals does this restaurant serve?<span className="error">*</span></h6>
                    <div className="row">{renderMeals()}</div>
                    {formik.errors.meals && formik.touched.meals && (
                      <div className="error">{formik.errors.meals}</div>
                    )}
                  </div>
                </div>
                <div className="form-row" style={{ marginTop: "20px" }}>
                  <div className="col-md-12 ">
                    <h6>Payment method<span className="error">*</span></h6>
                    <div className="row">{renderPayment()}</div>
                    {formik.errors.payment && formik.touched.payment && (
                      <div className="error">{formik.errors.payment}</div>
                    )}
                  </div>
                </div>
                <div className="form-row" style={{ marginTop: "20px" }}>
                  <div className="col-md-12 ">
                    <h6>Parking<span className="error">*</span></h6>
                    <div className="row">{renderParking()}</div>
                    {formik.errors.parking && formik.touched.parking && (
                      <div className="error">{formik.errors.parking}</div>
                    )}
                  </div>
                </div>
                <div className="form-row" style={{ marginTop: "20px" }}>
                  <div className="col-md-12 ">
                    <h6>Themes<span className="error">*</span></h6>
                    <div className="row">{renderGoodFor()}</div>
                    {formik.errors.themes && formik.touched.themes && (
                      <div className="error">{formik.errors.themes}</div>
                    )}
                  </div>
                </div>
                <div className="form-row" style={{ marginTop: "20px" }}>
                  <div className="col-md-12 ">
                    <h6>Other Attractions</h6>
                    <div className="row">{renderOtherAttractions()}</div>
                    {formik.errors.otherAttractions &&
                      formik.touched.otherAttractions && (
                        <div className="error">
                          {formik.errors.otherAttractions}
                        </div>
                      )}
                  </div>
                </div>
                <div className="form-row" style={{ marginTop: "20px" }}>
                  <div className="col-md-12 ">
                    <h6>More Facilities</h6>
                    <div className="row">{renderMoreFacilities()}</div>
                    {formik.errors.moreFacilities &&
                      formik.touched.moreFacilities && (
                        <div className="error">
                          {formik.errors.moreFacilities}
                        </div>
                      )}
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-6">
                    <button
                      className="steps-btn float-left"
                      type="button"
                      onClick={() => setNextStep(2)}
                    >
                      <i>&#8592;</i> Previous
                    </button>
                  </div>
                  <div className="col-md-6">
                    <button className="steps-btn" type="submit">
                      Next<i>&#8594;</i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default StepThree;

