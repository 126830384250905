import Right from "../../../assets/img/double-right.svg";
import "./App.css";
import React, { useCallback, useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import "bootstrap/dist/css/bootstrap.min.css";
import Breadcrumb from "../../Breadcrumb";
// Images
import customerimg1 from "../../../assets/img/foodtech/customer-1.jpg";
import customerimg2 from "../../../assets/img/foodtech/customer-2.jpg";
import { getAllReviews, getOutletDetail } from "../../../redux/slices/Outlet";
import { useHistory, useParams } from "react-router-dom";
import { getOrders } from "../../../redux/slices/Orders";
import RewardModal from "./RewardModal";
import CommisionModal from "./CommisionModal";
import {Link} from "react-router-dom"
// import { image_url } from "../../../redux/services/apiPath";
const
  Detailcontent = () => {
    const { hashKey } = useParams();
    const [outletDetails, setOutletDetails] = useState()
    const [details, setDetails] = useState({});
    const dispatch = useDispatch();
    const history = useHistory();
    const [showRewardModal, setShowRewardModal] = useState(false);
    const [showCommisionModal, setShowCommisionModal] = useState(false);
    const handleShowRewardModal = () => setShowRewardModal(!showRewardModal);
    const handleShowCommisionModal = () =>
      setShowCommisionModal(!showCommisionModal);
    const rating = [1, 2, 3, 4, 5];
    const getRating = (ratings, item) => {
      if (ratings >= item) {
        return true;
      } else {
        return false;
      }
    };
    if (
      outletDetails === null &&
      localStorage.getItem("vendorType") == "multiple"
    ) {
      history.push("/total-outlets");
      window.location.reload();
    }
   

    useEffect(() => {
      // alert("useEffect");
      // if (!outletDetails.name) {
      //   history.push("/total-outlets");
      // }

      // if (localStorage.getItem("vendorType") == "single") {
      //   dispatch(getOutletDetail({ outletId: localStorage.getItem("outletId") }))
      //     .unwrap()
      //     .then((res) => {
      //       setOutletDetails(res.data);
      //       history.push(`/view-outlet/${localStorage.getItem("outletId")}`);
      //     })
      //     .catch((err) => {
      //       toast.error(err);
      //     });
      // } else {
      if (hashKey) {
        dispatch(getOutletDetail({ outletId: hashKey }))
          .unwrap()
          .then((res) => {
            setOutletDetails(res.data);
          })
          .catch((err) => {
            toast.error(err);
          });
      } else {
        history.push(`/total-outlets`);
      }
      // }
    }, [dispatch, history, showRewardModal, showCommisionModal]);

    const [currentImage, setCurrentImage] = useState(0);
    const [docViewerIsOpen, setDocViewerIsOpen] = useState(false);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    let outletPhotos = outletDetails
      ? JSON.parse(JSON.stringify(outletDetails.images))
      : [];
    let documentPhotos = outletDetails
      ? JSON.parse(JSON.stringify(outletDetails.documents))
      : [];
    const openLightbox = useCallback((event, { photo, index }) => {
      setCurrentImage(index);
      setViewerIsOpen(true);
    }, []);

    const openDocLightbox = useCallback((event, { photo, index }) => {
      setCurrentImage(index);
      setDocViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
      setCurrentImage(0);
      setViewerIsOpen(false);
    };
    const closeDocLightbox = () => {
      setCurrentImage(0);
      setDocViewerIsOpen(false);
    };

    return (
      <div className="ms-content-wrapper product-detail-wrapper">
        <div className="row">
          <div className="col-md-12">
            {localStorage.getItem("vendorType") == "multiple" &&
              <Breadcrumb
                title="Outlet Detail"
                links={[
                  { to: "/", name: "Home" },
                  { to: "/total-outlets", name: "Outlets" },
                ]}
              />}
          </div>
          <div className="new-product-class">
            <div className="hotel-detail">
              <Container>
                <Row>
                  <Col onMouseDown={12}>
                    <div className="hotel-title">
                      <h2>{outletDetails?.name}</h2>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>


            <Row>
              <Col md={6}>
                <div className="about-detail mt-4">
                  {/* {localStorage.getItem("vendorType") === "single" && ( */}
                  <div className="new mt-4 mb-4">

                    {showRewardModal && (
                      <RewardModal
                        handleShowRewardModal={handleShowRewardModal}
                        setShowRewardModal={setShowRewardModal}
                        outletId={outletDetails?.outletId}
                      />
                    )}
                    {showCommisionModal && (
                      <CommisionModal
                        outletId={outletDetails?.outletId}
                        setShowCommisionModal={setShowCommisionModal}
                        handleShowCommisionModal={handleShowCommisionModal}
                      />
                    )}
                  </div>
                  {/* )} */}
                  <Container>
                    <Row>
                      <Col md={12}>
                        <div className="about">
                          <h3>About Hotel</h3>
                          <p>{outletDetails?.aboutVendor}</p>
                        </div>
                      </Col>

                      {outletDetails?.amenities.map((item, index) => {
                        return (
                          <Col md={12} key={index}>
                            <div className="cat-list">
                              <h3>{item.name}</h3>
                              <ul>
                                {item.features.map((i, ind) => {
                                  return (
                                    <li>
                                      <i key={ind}>
                                        {" "}
                                        <img src={Right} />
                                      </i>{" "}
                                      {i}
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          </Col>
                        );
                      })}
                      <Col md={12}>
                        <div className="cat-list">
                          <div className="d-flex">
                            <h3>Commission Percent</h3><i className="fas fa-edit mx-2 reward-icon" onClick={handleShowCommisionModal}></i>
                          </div>
                          <p>{outletDetails?.commissionPoint} %</p>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="cat-list">
                              <Link to={`/reward-page/${hashKey}`}>
                          <div className="d-flex">
                            <h3>
                              Reward Partner Points
                              </h3>
                            <i className="fas fa-edit mx-2 reward-icon" ></i>
                          </div>
                              </Link>
                          <p>{outletDetails?.isReward}</p>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="promtion-list">
                          <h3>Discount</h3>
                          {/* <ul >
                      {outletDetails?.outletDiscount?.map((val, i) => (
                        <li key={i}>
                          <div className="list-flex">
                            <b>{val.description}</b> <span className="mx-3"> Discount:<b>{val?.discount}</b> </span> <span className="mx-3">    From {val?.startDate} to {val?.endDate}</span>
                          </div>
                        </li>
                      ))} */}

                          {/* </ul> */}
                          <table
                            className="table  thead-primary w-80"

                          >
                            <thead>
                              <tr>
                                <th>Description</th>
                                <th>Discount</th>
                                <th>From Date</th>
                                <th>To Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              {outletDetails?.outletDiscount?.map((val, i) => (
                                <tr key={i}>
                                  <td>{val.description}</td>
                                  <td>{val?.discount}</td>
                                  <td>{val?.startDate}</td>
                                  <td>{val?.endDate}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>

                        </div>
                      </Col>

                    </Row>
                    <Row>
                      {/* <Col md={6}>
                      <div className="hilight">
                        <h3>Outlet Gallery</h3>

                        {outletPhotos && (
                          <div className="hotel-img">
                            <Gallery
                              photos={outletPhotos}
                              onClick={openDocLightbox}
                            />
                          </div>
                        )}


                        <ModalGateway>
                          {docViewerIsOpen ? (
                            <Modal onClose={closeDocLightbox}>
                              <Carousel
                                currentindex={currentImage}
                                views={outletPhotos.map((x) => ({
                                  ...x,
                                  srcset: x.src,
                                }))}
                              />
                            </Modal>
                          ) : null}
                        </ModalGateway>
                      </div>
                    </Col> */}

                      {/* <Col md={6}>
                      <div className="hilight">
                        <h3>Document Gallery</h3>

                        {documentPhotos && (
                          <div className="hotel-img">
                            <Gallery photos={documentPhotos} onClick={openLightbox} />
                          </div>
                        )}


                        <ModalGateway>
                          {viewerIsOpen ? (
                            <Modal onClose={closeLightbox}>
                              <Carousel
                                currentindex={currentImage}
                                views={documentPhotos.map((x) => ({
                                  ...x,
                                  srcset: x.src,
                                }))}
                              />
                            </Modal>
                          ) : null}
                        </ModalGateway>
                      </div>
                    </Col> */}


                    </Row>
                  </Container>
                </div>

              </Col>
              <Col md={6}>
                {outletDetails?.bannerImage && (
                  <div>
                    <section className="slider-section mt-4">

                      <div className="prdouc-img">
                        <img
                          className=""
                          src={outletDetails?.bannerImage}
                          alt="First slide"
                        />
                      </div>


                    </section>

                    <div className="promtion-list newmanrgin">
                      <h3>Promotion</h3>
                      {/* <ul >
                      {outletDetails?.outletPromotion?.map((val, i) => (
                        <li key={i}>
                          <div className="list-flex">
                            <b>{val.description}</b> <span className="mx-3">Discount: <b>{val?.discount}</b> </span> <span className="mx-3">    From {val?.startDate} to {val?.endDate}</span>

                          </div>
                        </li>
                      ))}

                    </ul> */}
                      {/* <ul>{outletDetails?.outletPromotion?.promotion}</ul> */}

                      <table
                        className="table  thead-primary w-80 mb-5"

                      >
                        <thead>
                          <tr>
                            <th>Description</th>
                            <th>Discount</th>
                            <th>Price</th>
                            <th>From Date</th>
                            <th>To Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {outletDetails?.outletPromotion?.map((val, i) => (
                            <tr key={i}>
                              <td>{val.description}</td>
                              <td>{val?.discount}</td>
                              <td>{val?.price}</td>
                              <td>{val?.startDate}</td>
                              <td>{val?.endDate}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>


                    <div className="hilight mt-5">
                      <h3>Document Gallery</h3>

                      {documentPhotos && (
                        <div className="hotel-img">
                          <Gallery photos={documentPhotos} onClick={openDocLightbox} />
                        </div>
                      )}


                      <ModalGateway>
                        {docViewerIsOpen ? (
                          <Modal onClose={closeDocLightbox}>
                            <Carousel
                              currentindex={currentImage}
                              views={documentPhotos.map((x) => ({
                                ...x,
                                srcset: x.src,
                              }))}
                            />
                          </Modal>
                        ) : null}
                      </ModalGateway>
                    </div>

                    <div className="hilight-outlet ">
                      <h3>Outlet Gallery</h3>

                      {outletPhotos && (
                        <div className="hotel-img">
                          <Gallery photos={outletPhotos} onClick={openLightbox} />
                        </div>
                      )}


                      <ModalGateway>
                        {viewerIsOpen ? (
                          <Modal onClose={closeLightbox}>
                            <Carousel
                              currentindex={currentImage}
                              views={outletPhotos.map((x) => ({
                                ...x,
                                srcset: x.src,
                              }))}
                            />
                          </Modal>
                        ) : null}
                      </ModalGateway>
                    </div>




                  </div>

                )}
              </Col>
            </Row>

          </div>
        </div>
      </div>
    );
  };

export default Detailcontent;
