import React, { Component } from 'react';
import { Accordion, Card } from "react-bootstrap";

class Withgap extends Component {
    render() {
        return (
            <div className="ms-panel-body">
                <Accordion defaultActiveKey="0" className="has-gap ms-accordion-chevron">
                    <Card>
                        <Accordion.Collapse className="collapseparent" eventKey="0">
                            <Card.Body>
                                If your guest makes any changes, a notification automatically pops up on your dashboard, accordingly you plan the services for the guests.
                                </Card.Body>
                        </Accordion.Collapse>
                        <Accordion.Toggle as={Card.Header} eventKey="0">
                            <span> If guests make changes in the booking / menu </span>
                        </Accordion.Toggle>
                    </Card>
                    <Card>
                        <Accordion.Collapse className="collapseparent" eventKey="1">
                            <Card.Body>
                            <ul>
                            <li>
                                If the guest cancels the booking, a notification automatically pops up on your dashboard, accordingly you can plan to host other guests.
                            </li>
                            <li>  
                                If it happens – plans change! If a guest needs to cancel their reservation, we’re here to help you with a quick rebound.
                            </li>
                               </ul>
                                </Card.Body>
                        </Accordion.Collapse>
                        <Accordion.Toggle as={Card.Header} eventKey="1">
                            <span> If your guest cancels  </span>
                        </Accordion.Toggle>
                    </Card>
                    <Card>
                        <Accordion.Collapse className="collapseparent" eventKey="2">
                            <Card.Body>
                                <ul>
                                <li>We’ll notify you immediately.</li>
                                <li>The unblock the reservation blocks.</li>
                                <li>We’ll coordinate payouts and refunds (If it is after & against the cancellation policy)</li>
                                </ul>
                                <p><b>Cancellation payouts</b></p>
                                <p>If you’re owed a payout, you’ll receive it 24 hours after the guest cancellation time.</p>
                                <p><b>Cancellation Policy:</b></p>
                                <ol>
                                <li>Table Booking: 3 Hrs. before the time of booking. </li>
                                <li>Menu Booking: 4 Hrs. before the time of booking </li>
                                <li>Menu Reduction:  3 Hrs. before the time of booking </li>
                                <li>Menu Amendment: 3 Hrs. before the time of booking  </li>
                                </ol>
                                <p><b>Cancellations after Arriving. </b></p>
                                <span>If a guest cancels after they’ve already arrived: -</span>
                                <ol>
                                    <li><b>Cancellation without any Reasons</b>
                                        <ul>
                                        <li>They must vacate your outlet immediately, and the terms of the cancellation policy will still apply. The payment as per the cancellation policy will made to the outlet.
                                        </li>
                                        </ul>
                                    </li>
                                    <li><b>Cancellation with the valid Reasons</b>
                                        <ul>
                                        <li>If the guest is cancelling the booking with the real and genuine reasons with the candid evidence, then the Sortyfy will evaluate the case inconspicuously and the decision will depend on case-to-case basis and sole discretion of the Sortyfy’s Management decision. If the guest’s reason was found justified, then the entire money can be refunded to the guest without any deductions.
                                        </li>
                                        </ul>
                                    </li>
                                </ol>
                                <p><b>Few Justified Reasons:</b></p>
                                <ol>
                                    <li>Table was not booked as per the booking. </li>
                                    <li>Guest had to wait long to get the table as per booking details.</li>
                                    <li>Food was not ready as per the booking. </li>
                                    <li>Food took very long time to be served</li>
                                    <li>Guest booking was not recognized</li>
                                    <li>Staff Misbehaved </li>
                                    <li>No Parking available </li>
                                    <li>Safety & Security Reasons (Insecure Place)</li>
                                    <li>Unhygienic Conditions </li>
                                    <li>Served different food from the booked items. </li>
                                    <li>Provoking the guest to book direct</li>
                                </ol>
                                </Card.Body>
                        </Accordion.Collapse>
                        <Accordion.Toggle as={Card.Header} eventKey="2">
                            <span> When a guest cancels </span>
                        </Accordion.Toggle>
                    </Card>
                    <Card>
                        <Accordion.Collapse className="collapseparent" eventKey="3">
                            <Card.Body>
                                Changing the cancellation policy is the sole discretion of Sortyfy only. It may very to City, Starte or country wise as per the local conditions. The custom-made policy is not permissible. The outlet has to agree with the policies to get registered in sortyfy.
                            </Card.Body>
                        </Accordion.Collapse>
                        <Accordion.Toggle as={Card.Header} eventKey="3">
                            <span> Changing your cancellation policy </span>
                        </Accordion.Toggle>
                    </Card>
                    <Card>
                        <Accordion.Collapse className="collapseparent" eventKey="4">
                            <Card.Body>
                                Note: Changes to the cancellation policy are only applied to future reservations and don’t affect any pending or confirmed reservations.
                            </Card.Body>
                        </Accordion.Collapse>
                        <Accordion.Toggle as={Card.Header} eventKey="4">
                            <span> If Sortyfy change cancellation policy?  </span>
                        </Accordion.Toggle>
                    </Card>
                    <Card>
                        <Accordion.Collapse className="collapseparent" eventKey="5">
                            <Card.Body>
                                Note: Changes to the cancellation policy are only applied to future reservations and don’t affect any pending or confirmed reservations.
                            </Card.Body>
                        </Accordion.Collapse>
                        <Accordion.Toggle as={Card.Header} eventKey="5">
                            <span> If Sortyfy change cancellation policy?  </span>
                        </Accordion.Toggle>
                    </Card>
                    <Card>
                        <Accordion.Collapse className="collapseparent" eventKey="6">
                            <Card.Body>
                                Guests who cancel will receive an automatic refund if the cancellation policy permits it. Even if it doesn’t, they may qualify for a refund under our Rebooking and Refund Policy, or if there’s an extenuating circumstance (This depends on case-to-case basis and sole discretion of the Sortyfy’s Management decision)
                            </Card.Body>
                        </Accordion.Collapse>
                        <Accordion.Toggle as={Card.Header} eventKey="6">
                            <span> Guest refunds  </span>
                        </Accordion.Toggle>
                    </Card>
                    <Card>
                        <Accordion.Collapse className="collapseparent" eventKey="7">
                            <Card.Body>
                                <p>If guest cancels before the cancellation window, they’ll be automatically refunded according to the cancellation policy.</p>
                                <p>In case, If the guest is owed a refund, that amount is deducted from future payouts until the full amount is collected. To find your updated payout amount, go to your Finance / Transaction history.</p>
                            </Card.Body>
                        </Accordion.Collapse>
                        <Accordion.Toggle as={Card.Header} eventKey="7">
                            <span> Your Payouts if a guest cancels  </span>
                        </Accordion.Toggle>
                    </Card>
                    <Card>
                        <Accordion.Collapse className="collapseparent" eventKey="8">
                            <Card.Body>
                                <p>If you are hosting the guest, you need to put the OTP (Given by the guest) on your vendor panel to confirm the rendered services, accordingly the payment will be released to you on the same day before the closing of the day. </p>
                                <p>If this is the future hosting, the payment will be released the same way as mentioned above (After the reservation is materialized and confirming the OTP in the system).</p>
                            </Card.Body>
                        </Accordion.Collapse>
                        <Accordion.Toggle as={Card.Header} eventKey="8">
                            <span> What about your payment?   </span>
                        </Accordion.Toggle>
                    </Card>
                    <Card>
                        <Accordion.Collapse className="collapseparent" eventKey="9">
                            <Card.Body>
                                <p>If the guest was not up to your hosting standards, if you faced the issues like, Payment Problems, misbehaving, abusive, very loud, fighting, blackmailing or illegal activities etc. then there are couple of options as below: -</p>
                                <ol>
                                <li>You can block the guest. You can go to the setting on your dashboard and click on block guest. Insert all the required details and block that particular guests. </li>
                                <li>Also, you can raise your concern to our feedback portal and Sortyfy will take the appropriate steps to avoid this in future.  </li>
                                </ol>
                            </Card.Body>
                        </Accordion.Collapse>
                        <Accordion.Toggle as={Card.Header} eventKey="9">
                            <span> Was the guest not up to your hosting standards?    </span>
                        </Accordion.Toggle>
                    </Card>
                    <Card>
                        <Accordion.Collapse className="collapseparent" eventKey="10">
                            <Card.Body>
                                <p>Because cancellations or changes from outlet in the guest bookings disrupt guests' plans and impact confidence in the Sortyfy community, try to host all reservations and deliver as per the booking requirements.</p>
                                <p>If you can’t and you want to cancel or change, but know that if you do cancel then penalties are involved things like a cancellation fee or a blocked calendar or blocked account temporary or permanently.</p>
                            </Card.Body>
                        </Accordion.Collapse>
                        <Accordion.Toggle as={Card.Header} eventKey="10">
                            <span> If you make changes    </span>
                        </Accordion.Toggle>
                    </Card>
                    <Card>
                        <Accordion.Collapse className="collapseparent" eventKey="11">
                            <Card.Body>
                                <p>Airbnb guests look forward to their trips, but we understand there are times when you may need to cancel. When you do, we recommend you also send your guest a message as soon as possible.</p>
                                <p><b>To Cancel a reservation:</b></p>
                                <ol>
                                    <li>Go to dashboard and check the pending bookings.  </li>
                                    <li>Check All reservations and select the reservation you want to cancel/reject.</li>
                                    <li>Tap Cancel/reject booking.</li>
                                    <li>Select your answers to the questions that follow and tap Next.</li>
                                    <li>Review and tap Cancel reservation</li>
                                </ol>
                                <p>You may not be able to cancel active reservations or already confirmed bookings or those for which arrival is scheduled to occur within 4 hours. In those cases, you’ll need to contact us directly (Only in case of emergency).</p>
                            </Card.Body>
                        </Accordion.Collapse>
                        <Accordion.Toggle as={Card.Header} eventKey="11">
                            <span> How a host cancels or rejects the booking     </span>
                        </Accordion.Toggle>
                    </Card>

                    <Card>
                        <Accordion.Collapse className="collapseparent" eventKey="12">
                            <Card.Body>
                                <p>Cancelling a guest's reservation can have serious implications. so we may apply penalties if the cancellation does not meet certain extenuating circumstances.</p>                            
                                
                            </Card.Body>
                        </Accordion.Collapse>
                        <Accordion.Toggle as={Card.Header} eventKey="12">
                            <span> Cancellation penalties     </span>
                        </Accordion.Toggle>
                    </Card>

                    <Card>
                        <Accordion.Collapse className="collapseparent" eventKey="13">
                            <Card.Body>
                                <p>Although cancellations/ejections by Hosts are rare, and some cancellations are beyond a Host’s control, cancellations by Hosts can disrupt guest plans and undermine confidence in Sortyfy. For those reasons, if the Host cancels/reject the confirmedbooking, or if the Host is found to be responsible for a cancellation/rejection, Sortyfymay impose fees and other consequences. </p>                            
                                <p>The fees and other consequences set out in this Policy are intended to reflect the costs and other impacts of these cancellations on guests.</p>
                                <p>We will waive the fees and, in some cases, the other consequences, if the Host cancels because of Extenuating Circumstances or certain valid reasons beyond the Host’s control.</p>
                            </Card.Body>
                        </Accordion.Collapse>
                        <Accordion.Toggle as={Card.Header} eventKey="13">
                            <span> Host Cancellation Policy     </span>
                        </Accordion.Toggle>
                    </Card>

                    <Card>
                        <Accordion.Collapse className="collapseparent" eventKey="14">
                            <Card.Body>
                            <ol>
                            <li>If a Host cancels a confirmed reservation, or if the Host is found to be responsible for a cancellation under this Policy, we will impose fees subject to a minimum cancellation fee of Rs.500 or The commission is based on the reservation amount, whichever is higher, when the reservation is canceled.</li>
                            <li>If the reservation is denied 4 hours or less before guest arrival time, or after Arrival, the fee is 50% of the reservation amount.</li>
                            </ol>
                            <p>Cancellation fees are typically withheld from the next payout(s) to the Host as provided in the Payments Terms of Service. In addition to the fees and consequences set out in this Policy, Hosts who cancel, or are found responsible for a cancellation, will not receive a payout for the canceled reservation, or, if the payout has already been made, then the amount of the payout will be withheld from the next payout(s). </p>                            
                                
                            </Card.Body>
                        </Accordion.Collapse>
                        <Accordion.Toggle as={Card.Header} eventKey="14">
                            <span> Cancellation fees     </span>
                        </Accordion.Toggle>
                    </Card>

                    <Card>
                        <Accordion.Collapse className="collapseparent" eventKey="15">
                            <Card.Body>
                            
                            <p>A Host may be responsible for a cancellation when it occurs because of conditions in the Listing that are grossly and materially different from how the Listing was described at the time of booking. In these cases, the Host will be subject to the fees and other consequences laid out in this Policy, regardless of who initiates the cancellation. Examples may include: Gross Listing inaccuracies that materially disrupt a guest experience, like advertising a live band when no live band is available for the guests. </p>                            
                                
                            </Card.Body>
                        </Accordion.Collapse>
                        <Accordion.Toggle as={Card.Header} eventKey="15">
                            <span> When a Host is found responsible for a cancellation    </span>
                        </Accordion.Toggle>
                    </Card>

                    <Card>
                        <Accordion.Collapse className="collapseparent" eventKey="16">
                            <Card.Body>
                            
                            <p>If a Host cannot honor a reservation—regardless of the reason—it’s their responsibility to cancel in a timely manner to allow their guest time to adjust their plans. A Host may not encourage the guest to cancel the reservation. </p>                            
                            <p>Providing false statements or materials in connection with this Policy violates our Terms of Service and may result in account termination and other consequences.</p>  
                            <p>This Policy applies to cancellations that occur on or after the effective date. Any right that guests or Hosts may have to initiate legal action remains unaffected. Any changes to this Policy will be made in accordance with our Terms of Service. </p>
                            </Card.Body>
                        </Accordion.Collapse>
                        <Accordion.Toggle as={Card.Header} eventKey="16">
                            <span> Other things to consider    </span>
                        </Accordion.Toggle>
                    </Card>

                    <Card>
                        <Accordion.Collapse className="collapseparent" eventKey="17">
                            <Card.Body>
                            
                            <p>Disclosing safety information to guests Make sure your listings are updated to accurately reflect the safety features included so that our guests know what to expect.</p>                            
                            
                            </Card.Body>
                        </Accordion.Collapse>
                        <Accordion.Toggle as={Card.Header} eventKey="17">
                            <span>Preparing your listing with safety essential  </span>
                        </Accordion.Toggle>
                    </Card>

                    <Card>
                        <Accordion.Collapse className="collapseparent" eventKey="18">
                            <Card.Body>
                            
                            <p>Safety is one of our top priorities. To help prevent surprises and ensure transparency, we ask that Hosts welcome guests responsibly by completing all safety and accessibility sections.</p>                            
                            
                            </Card.Body>
                        </Accordion.Collapse>
                        <Accordion.Toggle as={Card.Header} eventKey="18">
                            <span>Safety information on listings</span>
                        </Accordion.Toggle>
                    </Card>

                    <Card>
                        <Accordion.Collapse className="collapseparent" eventKey="19">
                            <Card.Body>
                            
                            <p>When you first become a Host, you’ll need to make sure you have a payout method set up for your account. It might take a little while for it to get fully set up, so we recommend doing it as soon as possible. </p>                            
                            
                            </Card.Body>
                        </Accordion.Collapse>
                        <Accordion.Toggle as={Card.Header} eventKey="19">
                            <span>Payout set-up</span>
                        </Accordion.Toggle>
                    </Card>
                    <Card>
                        <Accordion.Collapse className="collapseparent" eventKey="20">
                            <Card.Body>
                            
                            <p>It’s based on the country and method you selected when you first added your payout method. </p>
                            <p>Be aware that you can’t change a payout method’s currency once you’ve added it.</p>                            
                            
                            </Card.Body>
                        </Accordion.Collapse>
                        <Accordion.Toggle as={Card.Header} eventKey="20">
                            <span>How do you choose your payout currency? </span>
                        </Accordion.Toggle>
                    </Card>

                    <Card>
                        <Accordion.Collapse className="collapseparent" eventKey="21">
                            <Card.Body>
                            
                            <p>You may want to set up more than one payout method. You can designate one as your default, or you can even split payouts between 2 payout methods.</p>                            
                            
                            </Card.Body>
                        </Accordion.Collapse>
                        <Accordion.Toggle as={Card.Header} eventKey="21">
                            <span>Setting your default payout method  </span>
                        </Accordion.Toggle>
                    </Card>

                    <Card>
                        <Accordion.Collapse className="collapseparent" eventKey="22">
                            <Card.Body>
                            
                            <p>You’ll find your current default payout method noted next to the payout method type. Tap Profile  > Payments and payouts > Payout methods </p>                            
                            
                            </Card.Body>
                        </Accordion.Collapse>
                        <Accordion.Toggle as={Card.Header} eventKey="22">
                            <span>Setting your default payout method  </span>
                        </Accordion.Toggle>
                    </Card>

                    <Card>
                        <Accordion.Collapse className="collapseparent" eventKey="23">
                            <Card.Body>
                            
                            <p>To get paid, you can't remove your default payout method.</p>                            
                            <p>Tap Profile  > Payments and payouts > Payout methods</p>
                            <p>Next to the (non-default) method you'd like to remove, tap Edit >Remove</p>
                            </Card.Body>
                        </Accordion.Collapse>
                        <Accordion.Toggle as={Card.Header} eventKey="23">
                            <span>Removing a payout method</span>
                        </Accordion.Toggle>
                    </Card>

                    <Card>
                        <Accordion.Collapse className="collapseparent" eventKey="24">
                            <Card.Body>
                            <ol>
                            <li>Tap Profile </li>
                            <li>Under Settings, tap Payments and payouts</li>
                            <li>Under Hosting, tap Payout methods</li>
                            <li>Tap Add payout method</li>
                            <li>Choose your billing country/region</li>
                            <li>Select your payout method and tap Continue</li>
                            <li>Add the requested info, then tap Next</li>
                            </ol>
                            
                            </Card.Body>
                        </Accordion.Collapse>
                        <Accordion.Toggle as={Card.Header} eventKey="24">
                            <span>How to add the payout method </span>
                        </Accordion.Toggle>
                    </Card>
                </Accordion>
            </div>
        );
    }
}

export default Withgap;