import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

const SelectCompetitor = (props) => {
    const { allOutlets, maxCheck, callBack } = props;
    const [selectedCompetitors, setSelectedCompetitors] = useState([]);

    const handleCheckboxChange = (value, label) => {
        setSelectedCompetitors([...selectedCompetitors, { label, value }]);
    };

    const handleSaveClick = () => {
        callBack(selectedCompetitors);
        setSelectedCompetitors([]);
    };

    const handleToggleCheckbox = (value) => {
        const isChecked = selectedCompetitors.some(comp => comp.value === value);
        if (isChecked) {
            setSelectedCompetitors(selectedCompetitors.filter(comp => comp.value !== value));
        } else if (selectedCompetitors.length < maxCheck) {
            handleCheckboxChange(value);
        } else {
            toast.error(`You Cannot Select More Than ${maxCheck} Competitor`);
        }
    };

    return (
        <div>
            <Modal
                {...props}
                size="lg"
                fullScreen
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Select Competitor
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table className="table table-hover thead-primary">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Name</th>
                                <th>Address</th>
                            </tr>
                        </thead>
                        <tbody>
                            {allOutlets && allOutlets.map((val, i) => (
                                <tr key={i}>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={selectedCompetitors.some(comp => comp.value === val.value)}
                                            onChange={() => handleToggleCheckbox(val.value)}
                                        />
                                    </td>
                                    <td>{val.label}</td>
                                    <td>{val.address}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <button className="excle-dowm w-25" onClick={handleSaveClick}>Save</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default SelectCompetitor;
