import React, { useEffect, useState } from "react";
import Breadcrumb from "../../Breadcrumb";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import axios from "axios";
import { API_URL } from "../../../util/constant";
import { upperCase } from "../../../constants";
import { toast } from "react-toastify";
import { setRewardPoint, getOutletDetail } from "../../../redux/slices/Outlet";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
function RewardPage() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [outletList, setOutetData] = useState([]);
  const [selectedoutlet, setSelectedOutlet] = useState([]);

  const [selectedoutletId, setSelectedOutletId] = useState("");

  const getOutetId = async () => {
    const res = await axios.post(
      `${API_URL}/outlet/getAllOutlet`,
      { vendorId: localStorage.getItem("vendorId") },
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user"),
        },
      }
    );
    if (res?.data?.status == "success") {
      setOutetData(res?.data?.data);
      let Id = pathname.split("page/")[1];
      if (Id) {
        let filterdata = res?.data?.data?.filter(
          (val) => val.outletId == Id
        )[0];
        console.log("filterdata", filterdata);
        setSelectedOutlet(filterdata);
        setSelectedOutletId(filterdata.outletId);
      } else {
        setSelectedOutlet(res?.data?.data[0]);
        setSelectedOutletId(res?.data?.data[0]?.outletId);
      }
    }
  };
  useEffect(() => {
    getOutetId();
  }, []);

  console.log("selectedoutlet", selectedoutlet?.isReward);
  return (
    <div>
      <div className="ms-content-wrapper product-detail-wrapper">
        <div className="row">
          <div className="col-md-12">
            {
              <Breadcrumb
                title="Reward"
                links={[
                  { to: "/", name: "Home" },
                  // { to: "/total-outlets", name: "Outlets" },
                ]}
              />
            }
          </div>
        </div>
        <div>
          <Row>
            <Col md={12}>
              <div className="">
                <h5>Reward point partner</h5>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6}></Col>
            {/* {outletList?.length > 1 && ( */}
            <Col md={6}>
              <div className="float-right d-flex">
                <label htmlFor="outletId" className="form-label mr-3 mt-2">
                  Select Outlet
                </label>

                <div className="dropdown-group form-vendor-regi">
                  <select
                    className="form-control"
                    Id="outletId"
                    name="outletId"
                    onChange={(e) => {
                      setSelectedOutletId(e.target.value);
                    }}
                    value={selectedoutletId}
                  >
                    <option value={""} disabled>
                      Please Select
                    </option>
                    {outletList?.length ? (
                      outletList?.map((item) => {
                        return (
                          <option value={item.outletId}>
                            {upperCase(item.outletName)}
                          </option>
                        );
                      })
                    ) : (
                      <option value={0}> Not found</option>
                    )}
                  </select>
                </div>
              </div>
            </Col>
            {/* )} */}

            <Col md={7}>
              <div>
                <div className="mt-2 mx-2">
                  <h5>
                    5% reward point will be offered as reward loyalty program :
                    <span
                      className={
                        selectedoutlet?.isReward == "Active" ? "green" : "red"
                      }
                    >
                      {" "}
                      {selectedoutlet?.isReward == "Active"
                        ? "Active"
                        : "Deactive"}
                    </span>
                  </h5>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div>
                {selectedoutlet?.isReward == "Active" && (
                  <Button
                    variant="secondary"
                    onClick={() => {
                      dispatch(
                        setRewardPoint({
                          status: "false",
                          outletId: selectedoutletId,
                        })
                      )
                        .unwrap()
                        .then(() => {
                          dispatch(
                            getOutletDetail({ outletId: selectedoutletId })
                          )
                            .unwrap()
                            .then(() => {
                              toast.dismiss();
                              toast.success(
                                "Reward partner points Deactivated successfully"
                              );
                              getOutetId();
                            })
                            .catch((err) => {
                              toast.dismiss();

                              toast.error(err);
                            });
                        })
                        .catch((err) => {
                          toast.dismiss();

                          toast.error(err);
                        });
                    }}
                    className="red-btn"
                  >
                    Deactivate
                  </Button>
                )}
                {selectedoutlet?.isReward == "Inactive" && (
                  <Button
                    variant="primary"
                    className="mx-4"
                    onClick={() => {
                      dispatch(
                        setRewardPoint({
                          status: "true",
                          outletId: selectedoutletId,
                        })
                      )
                        .unwrap()
                        .then(() => {
                          dispatch(
                            getOutletDetail({ outletId: selectedoutletId })
                          )
                            .unwrap()
                            .then(() => {
                              toast.dismiss();

                              toast.success(
                                "Reward partner points activated successfully"
                              );
                              getOutetId();
                            })
                            .catch((err) => {
                              toast.dismiss();

                              toast.error(err);
                            });
                        })
                        .catch((err) => {
                          toast.dismiss();

                          toast.error(err);
                        });
                    }}
                  >
                    Activate
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </div>
        <hr />
        <Container>
          <Row>
            <Col>
              <p>
                <b> Dear Partner,</b>
              </p>
              <p>
                You are advised to be a partner in the Sortyfy Rewards Program.
                This will give your business a number of benefits to enhance the
                restaurant sale and more:
              </p>
              <ul className="icon-ul">
                <li>Increase the footfall of the new clients</li>
                <li>Enhance your market visibility</li>
                <li>Improve the loyalty of your guests to your restaurant</li>
                <li>Helps in increasing the revenues</li>
                <li>Increases the referral business</li>
                <li>Will give you the global reach</li>
                <li>
                  Will make your restaurant placement on Sortyfy higher & better
                </li>
                <li>You become the preferred Sortyfy Partner</li>
                <li>
                  You will be shown frequently in the recommended restaurants
                  list
                </li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5>Example: Earned Reward Points</h5>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Amount</th>
                    <th>Reward Points %</th>
                    <th>Points</th>
                    <th>Value Per Point</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>100</td>
                    <td>5%</td>
                    <td>5</td>
                    <td>0.2</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <td>5000</td>
                    <td>5%</td>
                    <td>250</td>
                    <td>0.2</td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <td>7000</td>
                    <td>5%</td>
                    <td>350</td>
                    <td>0.2</td>
                    <td>70</td>
                  </tr>
                  <tr>
                    <td>10000</td>
                    <td>5%</td>
                    <td>500</td>
                    <td>0.2</td>
                    <td>100</td>
                  </tr>
                  <tr>
                    <td>15000</td>
                    <td>5%</td>
                    <td>750</td>
                    <td>0.2</td>
                    <td>150</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5>Usage OR Redemption</h5>
              <ul className="icon-ul">
                <li>
                  Guest will earn reward points in each transaction in any
                  restaurant in India.
                </li>
                <li>
                  The Points will be earned as per the Example given above
                  table.
                </li>
                <li>
                  Guest will be able to redeem the earned points in any Reward
                  point activated partner restaurants.
                </li>
                <li>
                  The amount accrued as per the points will be used by the guest
                  for the final billing.
                </li>
                <li>
                  Guests can use a part or the full accrued amount to settle the
                  final bills.
                </li>
                <li>
                  The accrued and selected amount will be deducted from the
                  final bills.
                </li>
                <li>
                  The summary of the bills and used reward points will be always
                  available to view for guest and restaurant both.
                </li>
              </ul>
              <p className="textss">
                So let’s become the Partner together in the Reward Program and
                lets be always ahead in the business.
              </p>
              <p className="textss"> Welcome to Sortyfy</p>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default RewardPage;
