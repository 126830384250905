import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import Preloader from "./components/layouts/Preloader";
import Dashboard from "./components/pages/Dashboard";
import Accordions from "./components/pages/Accordions";
import Addproduct from "./components/pages/Addproduct";
import Alerts from "./components/pages/Alerts";
import Animations from "./components/pages/Animations";
import Badges from "./components/pages/Badges";
import Basictables from "./components/pages/Basictables";
import Breadcrumbs from "./components/pages/Breadcrumbs";
import Buttons from "./components/pages/Buttons";
import Cards from "./components/pages/Cards";
import Chartjs from "./components/pages/Chartjs";
import Chat from "./components/pages/Chat";
import Cropper from "./components/pages/Cropper";
import Customerreview from "./components/pages/Customerreview";
import Datatables from "./components/pages/Datatables";
import Draggables from "./components/pages/Draggables";
import Email from "./components/pages/Email";
import Flaticons from "./components/pages/Flaticons";
import Fontawesome from "./components/pages/Fontawesome";
import Formelements from "./components/pages/Formelements";
import Formlayouts from "./components/pages/Formlayouts";
import Formvalidation from "./components/pages/Formvalidation";
import Formwizard from "./components/pages/Formwizard";
import Googlemaps from "./components/pages/Googlemaps";
import Invoicedetail from "./components/pages/Invoicedetail";
import Invoicelist from "./components/pages/Invoicelist";
import Materialize from "./components/pages/Materialize";
import Menucatalogue from "./components/pages/Menucatalogue";
import Menugrid from "./components/pages/Menugrid";
import Menulist from "./components/pages/Menulist";
import Modals from "./components/pages/Modals";
import Googlechart from "./components/pages/Googlechart";
import Orders from "./components/pages/Orders";
import Pagination from "./components/pages/Pagination";
import Preloaders from "./components/pages/Preloaders";
import Productdetail from "./components/pages/Productdetail";
import Progressbars from "./components/pages/Progressbars";
import Rangeslider from "./components/pages/Rangeslider";
import Rating from "./components/pages/Rating";
import Support from "./components/pages/Support";
import Restaurantlist from "./components/pages/Restaurantlist";
import Sales from "./components/pages/Sales";
import Sliders from "./components/pages/Sliders";
import Socialactivity from "./components/pages/Socialactivity";
import Sweetalerts from "./components/pages/Sweetalerts";
import Tabs from "./components/pages/Tabs";
import Toast from "./components/pages/Toast";
import Todolist from "./components/pages/Todolist";
import Tour from "./components/pages/Tour";
import Typography from "./components/pages/Typography";
import Vectormaps from "./components/pages/Vectormaps";
import Widgets from "./components/pages/Widgets";
import Clientmanagement from "./components/pages/Clientmanagement";
import Comingsoon from "./components/pages/Comingsoon";
import Defaultlogin from "./components/pages/Defaultlogin";
import VendorRegistration from "./components/pages/VendorRegistration";
import Defaultregister from "./components/pages/Defaultregister";
import Error from "./components/pages/Error";
import Faq from "./components/pages/Faq";
import Invoice from "./components/pages/Invoice";
import Lockscreen from "./components/pages/Lockscreen";
import Modallogin from "./components/pages/Modallogin";
import Modalregister from "./components/pages/Modalregister";
import Portfolio from "./components/pages/Portfolio";
import Stockmanagement from "./components/pages/Stockmanagement";
import Userprofile from "./components/pages/Userprofile";
import Webanalytics from "./components/pages/Webanalytics";
// import ForgotPassword2 from './components/pages/ForgotPassword';
import EditMenu from "./components/pages/Menu/EditMenu";
// import AddOutlet from './components/pages/AddOutlet';
import TotalOutlets from "./components/pages/TotalOutlets";
// import EditOutlet from './components/pages/EditOutlet';
import { useDispatch, useSelector } from "react-redux";
import OrderDetail from "./components/pages/OrderDetail";
import Discountgrid from "./components/pages/Discountgrid";
import Finance from "./components/pages/Finance/Finance";
import Reports from "./components/pages/Reports/Reports";
import BlockingAccount from "./components/pages/BlockingAccount/BlockingAccount";
import Promotion from "./components/pages/Promotions";
import Promotions from "./components/pages/Promotions";
import { getAllReviews } from "./redux/slices/Outlet";
import Competitor from "./components/Competitor";
import Sidenavigation from "./components/layouts/Sidenavigation";
import Quickbar from "./components/layouts/Quickbar";
import Topnavigation from "./components/layouts/Topnavigation";
import ManagerProfile from "./components/pages/Settings/ManagerProfile";
import Documents from "./components/pages/Settings/Documents";
import Address from "./components/pages/Settings/Address";
import UpdateDocument from "./components/pages/Settings/UpdateDocument";

import Login from "./Pages/Login";
import ForgotPassword from "./Pages/ForgotPassword";
import ResetPassword from "./Pages/ResetPassword";
import Registration from "./Pages/RegistrationSteps/Registration.js";
import ProcesComplete from "./Pages/ProcessComplete/ProcesComplete.js";
import AddOutlet2 from "./Pages/AddOutlet";
import EditOutlet2 from "./Pages/EditOutlet";
import ManagerForm from "./components/pages/Settings/ManagerForm";
import SecurityManageMent from "./components/pages/SecureityManagemnt/Securitymanagement";
import BankDetails from "./components/pages/Settings/BankDetails";
import ThankyouPage from "./components/pages/Auth/ThankyouPage.jsx";
import SendLink from "./components/pages/Auth/SendLink.js";

import { getNewOrderFromSocket } from "./util/fn.js";

import AudioFile from "./assets/audio/audio.mp3";

import { toast } from "react-toastify";
import RewardPage from "./components/sections/Productsdetail/RewardPage.js";
import PDFGenerator from "./Pages/PDFGenerator.js";
import checkMou from "./Pages/CheckMou.js";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
const LoggednRoutes = () => (
  <Router>
    <Switch>
      <Route path="/login" component={Login} />

      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/login-2" component={Defaultlogin} />
      <Route path="/reset-password" component={ResetPassword} />

      <Route path="/vendor-registration" component={VendorRegistration} />
      <Route path="/send-link" component={SendLink} />
      <Route path="/thankyou" component={ThankyouPage} />
      {/* <Route path="/forgot-password-2" component={ForgotPassword2} /> */}

      <Route path="/default-register/:userId" component={Registration} />
      <Route path="/default-register" component={Registration} />
      <Route
        path="/default-register-complete-prcoess/:userId"
        component={ProcesComplete}
      />

      {/* <Route path="/registration" component={Defaultregister} /> */}

      <Redirect from="*" to="/login" />
    </Switch>
  </Router>
);
const OutletEditRoutes = () => (
  <Router>
    <Switch>
      {/* <Route path="/edit-outlet/:hashKey" component={EditOutlet} /> */}
      {/* <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
        <main className="body-content">
          <Topnavigation />
          <Sidenavigation />

          <Route path="/edit-outlet/:hashKey" component={EditOutlet} />
          <Preloader />
        </main>
      </div> */}
      <Route path="/login" component={Defaultlogin} />
      <Redirect from="*" to="/login" />
    </Switch>
  </Router>
);

function App() {
  let OuteletID = localStorage.getItem("outletId");
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [paathname, setPathName] = useState("");
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const { verifyStatus } = useSelector((state) => state.register);
  const audio = new Audio(AudioFile);
  const handlePlay = async () => {
    try {
      await audio.play();
    } catch (error) {
      console.error("Error playing audio:", error);
    }
  };
  // Get Order Notification
  const socket = getNewOrderFromSocket(OuteletID);
  useEffect(() => {
    if (socket) {
      // socket.on("newOrderAlert", (data) => {
      //   if (data) {
      //     const selOutletId = OuteletID;
      //     if (data.outletId == selOutletId) {
      //       dispatch(
      //         getDashboardData({
      //           outletId: selOutletId,
      //           days: filter,
      //         })
      //       )
      //         .then((res) => {
      //           // console.log(res)
      //         })
      //         .catch((err) => {
      //           //  console.log(err)
      //         });
      //     }
      //   }
      // });

      socket.on("newOrderAlert", (data) => {
        if (data) {
          handlePlay();
          toast.success(data);
        }
      });
    }
  }, [socket]);
  useEffect(() => {
    setPathName(pathname);
  }, [pathname]);

  return verifyStatus === false ? (
    <OutletEditRoutes />
  ) : isLoggedIn || localStorage.getItem("isLoggedIn") ? (
    <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
      {paathname === "/checkMou" ? "" : <Sidenavigation />}
      <main className="body-content">
        {paathname === "/checkMou" ? "" : <Topnavigation />}

        {/* <Router basename={'/templatemonster/react/foodtech'}> */}
        <Preloader />
        <Switch>
          <Route path="/checkMou" component={checkMou} />

          <Route path="/dashboard" component={Dashboard} />
          <Route path="/accordions" component={Accordions} />
          <Route path="/add-product" component={Addproduct} />
          <Route path="/edit-menu/:hashKey" component={EditMenu} />
          <Route path="/edit-menu/:hashKey/:id" component={EditMenu} />
          <Route path="/reward-page" component={RewardPage} />
          <Route path="/reward-page/:Id" component={RewardPage} />
          <Route path="/get-mou" component={PDFGenerator} />

          <Route path="/bank-account" component={BankDetails} />
          <Route path="/alerts" component={Alerts} />
          {/* <Route path="/animations" component={Animations} />
          <Route path="/badges" component={Badges} />
          <Route path="/basic-tables" component={Basictables} />
          <Route path="/breadcrumbs" component={Breadcrumbs} />
          <Route path="/buttons" component={Buttons} />
          <Route path="/cards" component={Cards} />
          <Route path="/chartjs" component={Chartjs} />
          <Route path="/chat" component={Chat} />
          <Route path="/cropper" component={Cropper} /> */}
          <Route
            path="/customer-list"
            onEnter={() => {
              dispatch(
                getAllReviews({
                  outletId: localStorage.getItem("outletId"),
                })
              );
            }}
            component={Customerreview}
          />
          <Route path="/customer-review/:hashKey" component={Customerreview} />
          <Route path="/data-tables" component={Datatables} />
          <Route path="/invoice/billing" component={Finance} />
          {/* <Route path="/invoice/payment" component={Finance} />
        <Route path="/invoice/pending" component={Finance} /> */}
          <Route path="/reports/business-reports" component={Reports} />
          <Route path="/reports/bookings" component={Reports} />
          <Route path="/reports/revenue" component={Reports} />
          <Route path="/reports/trends" component={Reports} />
          <Route path="/blocking-account" component={BlockingAccount} />
          <Route path="/draggables" component={Draggables} />
          <Route path="/email" component={Email} />
          <Route path="/flaticons" component={Flaticons} />
          <Route path="/fontawesome" component={Fontawesome} />
          <Route path="/form-elements" component={Formelements} />
          <Route path="/form-layouts" component={Formlayouts} />
          <Route path="/form-validation" component={Formvalidation} />
          <Route path="/form-wizard" component={Formwizard} />
          <Route path="/google-maps" component={Googlemaps} />
          <Route path="/invoice-detail" component={Invoicedetail} />
          <Route path="/invoice-list" component={Invoicelist} />
          <Route path="/materialize" component={Materialize} />
          <Route path="/menu-catalogue" component={Menucatalogue} />

          <Route
            path={
              localStorage.getItem("vendorType") === "multiple"
                ? "/menu-grid/:hashKey"
                : "/menu-grid"
            }
            component={Menugrid}
          />
          <Route path="/menu-list" component={Menulist} />
          <Route path={"/discount-grid"} component={Discountgrid} />
          <Route path={"/promotion-grid"} component={Promotions} />
          {/* <Route path="/add-outlet" component={AddOutlet} /> */}
          {/* <Route path="/edit-outlet/:hashKey" component={EditOutlet} /> */}
          <Route path="/view-outlet/:hashKey" component={Productdetail} />

          {localStorage.getItem("vendorType") !== "single" && (
            <Route path="/total-outlets" component={TotalOutlets} />
          )}
          <Route path="/modals" component={Modals} />
          <Route path="/google-chart" component={Googlechart} />
          {/* <Route exact  path={localStorage.getItem("vendorType") === "multiple" ? "/orders/:hashKey" : "/orders"} component={(props) => <Orders getNewOrderFromSocket={getNewOrderFromSocket} />} /> */}

          <Route
            exact
            path={
              localStorage.getItem("vendorType") === "multiple"
                ? "/orders/:hashKey"
                : "/orders"
            }
            component={Orders}
          />

          <Route
            path="/order-detail/:hashKey/:lastID"
            component={OrderDetail}
          />
          <Route path="/order-detail/:hashKey" component={OrderDetail} />
          <Route path="/pagination" component={Pagination} />
          <Route path="/preloaders" component={Preloaders} />
          <Route path="/product-detail" component={Productdetail} />
          <Route path="/progress-bars" component={Progressbars} />
          <Route path="/range-slider" component={Rangeslider} />
          <Route
            path="/rating"
            component={Rating}
            onEnter={() => {
              dispatch(
                getAllReviews({
                  outletId: localStorage.getItem("outletId"),
                })
              );
            }}
          />
          <Route path="/support" component={Support} />

          <Route path="/restaurant-list" component={Restaurantlist} />
          <Route path="/sales" component={Sales} />
          <Route path="/sliders" component={Sliders} />
          <Route path="/social-activity" component={Socialactivity} />
          <Route path="/sweet-alerts" component={Sweetalerts} />
          <Route path="/tabs" component={Tabs} />
          <Route path="/toast" component={Toast} />
          <Route path="/todo-list" component={Todolist} />
          <Route path="/tour" component={Tour} />
          <Route path="/typography" component={Typography} />
          <Route path="/vector-maps" component={Vectormaps} />
          <Route path="/widgets" component={Widgets} />
          <Route path="/client-management" component={Clientmanagement} />
          <Route path="/coming-soon" component={Comingsoon} />

          <Route path="/error" component={Error} />
          <Route path="/faq" component={Faq} />
          <Route path="/competitor" component={Competitor} />
          <Route path="/invoice" component={Invoice} />
          <Route path="/lock-screen" component={Lockscreen} />
          <Route path="/modal-login" component={Modallogin} />
          <Route path="/modal-register" component={Modalregister} />
          <Route path="/portfolio" component={Portfolio} />
          <Route path="/stock-management" component={Stockmanagement} />
          <Route path="/user-profile" component={Userprofile} />
          <Route path="/web-analytics" component={Webanalytics} />
          <Route path="/settings/manager-profile" component={ManagerProfile} />
          <Route path="/settings/managers-profile" component={ManagerForm} />
          <Route path="/security-management" component={SecurityManageMent} />
          <Route path="/settings/documents" component={Documents} />
          <Route path="/settings/address" component={Address} />
          <Route
            path="/settings/document-detail/:hashKey"
            component={UpdateDocument}
          />
          <Route path="/add-outlet" component={AddOutlet2} />
          <Route path="/edit-outlet/:hashKey" component={EditOutlet2} />
          <Redirect from="*" to="/dashboard" />
        </Switch>
      </main>
      <Quickbar />
    </div>
  ) : (
    <>
      <LoggednRoutes />
    </>
  );
}

export default App;
