import axios from 'axios';
import React, { useState } from 'react';
import { Modal, Col } from 'react-bootstrap';

import { toast } from 'react-toastify';

const ImageModal = (props) => {
  

    return (
        <div>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Image
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='w-100 p-5'>
                       

                   <img src={props.imageUrl} />

                    </div>
                </Modal.Body>
              
            </Modal>
        </div>
    );
}

export default ImageModal;
