import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import loginLogo from "../../../assets/img/logo.png";
import Select from "react-select";
import { Link } from "react-router-dom";

const ThankyouPage = () => {

                  return (
                                    <div className="ms-content-wrapper ms-auth">
                                                      <Row>
                                                                        <Col md={5}>
                                                                                          <div className="ms-auth-bg">
                                                                                                            <img src={loginLogo} />
                                                                                          </div>
                                                                        </Col>
                                                                        <Col md={7}>
                                                                                          <div className="thankyoupopup">
                                                                                                            <div className='thanktou'>
                                                                                                                              <img
                                                                                                                                                src="https://www.nicepng.com/png/detail/61-618868_thank-you-wax-seal-style-stamp-icon.png"
                                                                                                                              />
                                                                                                            </div>
                                                                                                            <h2>Sucess</h2>
                                                                                                            <h3>Your account has been successfully registered!</h3>
                                                                                                            <h3>Please wait for admin approval to be verified.</h3>
                                                                                                            <Link
                                                                                                                              to={'/login'}
                                                                                                                              className=" steps-btn"

                                                                                                            >
                                                                                                                              Login
                                                                                                            </Link>
                                                                                          </div>
                                                                        </Col>
                                                      </Row>

                                    </div>
                  );
};

export default ThankyouPage;

