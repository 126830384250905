import * as XLSX from "xlsx";
import io from "socket.io-client";
import { PORT } from "./constant";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export const downloadExcel = (data, name) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, `${name}.xlsx`);
};

// export const downloadExcel1 = (dataArray, namesArray, headingsArray) => {
//     const workbook = XLSX.utils.book_new();

//     dataArray.forEach((data, index) => {
//         // Add an empty row before each table
//         data.unshift({});
//         data.unshift({});

//         // Add table name as the first row
//         data.unshift({ [namesArray[index]]: "" });

//         // Calculate the start row of the table
//         const startRow = index === 0 ? 3 : dataArray[index - 1].length + 6;

//         const worksheet = XLSX.utils.json_to_sheet(data, { header: headingsArray[index] });

//         // Add the worksheet with appropriate start row
//         XLSX.utils.book_append_sheet(workbook, worksheet, namesArray[index], { origin: `A${startRow}` });
//     });

//     XLSX.writeFile(workbook, "MultiTableReport.xlsx");
// };


export const downloadExcel1 = (dataArray, namesArray, headingsArray) => {
    const workbook = XLSX.utils.book_new();

    dataArray.forEach((data, index) => {
        // Calculate the start row of the table
        const startRow = index === 0 ? 0 : dataArray[index - 1].length + 2;

        const worksheet = XLSX.utils.json_to_sheet(data, { header: headingsArray[index] });

        // Add the worksheet with appropriate start row
        XLSX.utils.book_append_sheet(workbook, worksheet, namesArray[index], { origin: `A${startRow}` });
    });

    XLSX.writeFile(workbook, "MultiTableReport.xlsx");
};

export const downloadAllData = ( footFallData, revenueReport, perPersonRevenueReport, businessForcastReport, timeTrendLunch, timeTrendDinner ) => {
    console.log("ssssssss",footFallData, revenueReport, perPersonRevenueReport, businessForcastReport, timeTrendLunch, timeTrendDinner)
    const workbook = new ExcelJS.Workbook();

    const addWorksheetWithData = (workbook, data, sheetName) => {
        const worksheet = workbook.addWorksheet(sheetName);
        data.forEach((row) => {
            worksheet.addRow(row);
        });
    }

    addWorksheetWithData(workbook, footFallData, "footFallData");
    addWorksheetWithData(workbook, revenueReport, "revenueReport");
    addWorksheetWithData(workbook, perPersonRevenueReport, "perPersonRevenueReport");
    addWorksheetWithData(workbook, businessForcastReport, "businessForcastReport");
    addWorksheetWithData(workbook, timeTrendLunch, "timeTrendLunch");
    addWorksheetWithData(workbook, timeTrendDinner, "timeTrendDinner");

    workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer]), "all_data.xlsx");
    });
}


export const getNewOrderFromSocket = (outletId) => {

    const socket = io.connect(PORT, { reconnect: true, query: `outletId=${outletId}` });
    return socket;
};