import axios from "axios";
import apiPath, { BASE_URL } from "./apiPath";
import authHeader from "./authHeader";
const addMenuApi = (props) => {
    return axios.post(BASE_URL + apiPath.addMenu, props, authHeader());
};
const getMenuListApi = (props) => {
    return axios.post(BASE_URL + apiPath.getMenuList, props, authHeader());
};
const getMenuDetailsApi = (props) => {
    return axios.post(BASE_URL + apiPath.getMenuDetails, props, authHeader());
};
const updateMenuApi = (props) => {
    return axios.post(BASE_URL + apiPath.updateMenu, props, authHeader());
};
const removeMenuApi = (props) => {
    return axios.post(BASE_URL + apiPath.removeMenu, props, authHeader());
};

const MenuApi = {
    addMenuApi,
    getMenuListApi,
    updateMenuApi,
    removeMenuApi,
    getMenuDetailsApi

}

export default MenuApi;