import React from "react";
import ReactDOM from "react-dom";
import App from "./App";


import { BrowserRouter } from "react-router-dom";

// Css
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./assets/vendors/iconic-fonts/font-awesome/css/all.min.css";
import "./assets/vendors/iconic-fonts/flat-icons/flaticon.css";
import "./assets/vendors/iconic-fonts/cryptocoins/cryptocoins.css";
import "./assets/vendors/iconic-fonts/cryptocoins/cryptocoins-colors.css";
import "./assets/css/animate.min.css";
import "./assets/css/style.css";
import { Provider } from "react-redux";
import { persistor, store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
ReactDOM.render(
  <BrowserRouter>
    <ToastContainer />
    <Provider store={store}>
      {/* <PersistGate loading={null} persistor={null}> */}
      <App />
      {/* </PersistGate> */}
    </Provider>
  </BrowserRouter>,
  document.getElementById("sortyfy")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

