import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

const StackedLineAreaColumnChart = ({ data }) => {

    const [columns, setColumn] = useState([])
    const [area, setArea] = useState([])
    const [paxdata, setPax] = useState([])
    const [labels,setLables] = useState([])
    useEffect(() => {
        if (data) {
            const columnData = [];
            const areaData = [];
            const lineData = [];

            // Iterate over each object in the array
            data.forEach(item => {
                // Extract data for each type based on conditions
                columnData.push(item.booking);
                areaData.push(Number(item.revenue));
                lineData.push(item.pax);
            })
            setColumn(columnData)
            setArea(areaData)
            setPax(lineData)
            
            let s  = data?.map((val)=>{
                return  val.dateOfBooking
            })
            setLables(s)

        }

    }, [data])
  
  
    var options = {
        chart: {
          type: "line"
        },
        
      
        xaxis: {

           
            categories: labels ? labels : [],
          
        }
      };
      var series = [
        
        {
          name: "Revenue",
          data: area
        }
      ];

    return <Chart options={options} series={series}  type="line" height={350} />;
};

export default StackedLineAreaColumnChart;
