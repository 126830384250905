import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    outletStepOne: null,
    outletStepTwo: null,
    outletStepThree: null,
    outletStepFour: null,
}

const saveOutletDetailsSlice = createSlice({
    name: 'saveOutletDetails',
    initialState,
    reducers: {
        outletStepOneSave(state, action) {
            state.outletStepOne = action.payload
        },
        outletStepTwoSave(state, action) {
            state.outletStepTwo = action.payload
        },
        outletStepThreeSave(state, action) {
            state.outletStepThree = action.payload
        },
        outletStepFourSave(state, action) {
            state.outletStepFour = action.payload
        },
        clearAllDetails(state) {
            state.outletStepOne = null
            state.outletStepTwo = null
            state.outletStepThree = null
            state.outletStepFour = null
        }
    }
})

export const { outletStepOneSave,
    outletStepTwoSave,
    outletStepThreeSave,
    outletStepFourSave,
    clearAllDetails } = saveOutletDetailsSlice.actions

export default saveOutletDetailsSlice.reducer