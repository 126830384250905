import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const ExcelUploadModal = ({ show, handleClose }) => {

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Upload Excel File</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ol>



                    <li>      Download Excel Format to Insert Menu. </li>
                    <li>          Fill the Menu Details in The Excel Sheet as required. </li>
                    <li>  Don’t Change the Format </li>
                    <li>   Upload File. </li>
                    <li>   After Up-loading the File, Update the menu with Pictures. </li>
                    <li>   Choose the appropriate Picture One by One for each dish. </li>
                    <li> Check the Entire Menu again. </li>
                    <li>  For any changes – Push the Edit Button on the particular Dish. </li>
                    <li>  Please Check – Prices should be same as per the hotel Menu.
                    </li>
                </ol>

            </Modal.Body>

        </Modal >
    );
};

export default ExcelUploadModal;
