import React from 'react';
import { API_URL } from '../../../util/constant';
import { Carousel } from 'react-bootstrap'

const MenuSlider = (props) => {
  
    return (
        props?.images?.length == 1 ? (
            props?.images?.length>0 && props?.images.map(
                (image, index) => {
                    return (
                        props.isEdit === false ?
                                <img className="d-block w-100" src={`${API_URL}/viewImage/${image}`} alt="First slide" />
                                :
                                <img src={URL.createObjectURL(image)} alt="First slide" />
                        
                    )
                }
            )
        ): props?.images?.length >0 ? (
            <Carousel id="imagesSlider" className="ms-image-slider carousel slide" data-ride="carousel" indicators={false}>
                {props?.images?.length>0 && props?.images.map((image, index) => {
                    return (
                        <Carousel.Item key={index}>
                            {props.isEdit === false ?
                            <img className="d-block w-100" src={`${API_URL}/viewImage/${image}`} alt="First slide" />
                            :
                            <img src={URL.createObjectURL(image)} alt="First slide" />}
                        </Carousel.Item>          
                    )
                })}
            </Carousel>
        ):""
    );
}


export default MenuSlider;