import axios from "axios";
import apiPath, { BASE_URL, CAT_URL } from "./apiPath";
const register = (props) => {
  return axios.post(BASE_URL + apiPath.register, props);
};
const getProfileDetailsApi = (props) => {
  return axios.post(BASE_URL + apiPath.getProfileDetails, props);
};
const getProfileSettingsDetailsApi = (props) => {
  return axios.get(`${BASE_URL + apiPath.getProfileSettingsDetails}?vendorId=${props?.vendorId}`);
};
const getSettingAddress = (props) => {
  return axios.get(`${BASE_URL + apiPath.getSettingAddress}?vendorId=${props?.vendorId}`);
};
const updateSettingAddress = (props) => {
  return axios.post(BASE_URL + apiPath.updateSettingAddress, props);
};
const updateProfileApi = (props) => {
  return axios.post(BASE_URL + apiPath.updateProfile, props);
};
const updateSettingsProfileApi = (props) => {
  return axios.post(BASE_URL + apiPath.settingsProfile, props);
};
const updateSettingsDocument = (props) => {
  return axios.post(BASE_URL + apiPath.updateSettingsDocument, props);
};
const registerStepOneApi = (props) => {
  return axios.post(BASE_URL + apiPath.registerStepOne, props);
};
const registerStepTwoApi = (props) => {
  return axios.post(BASE_URL + apiPath.registerStepTwo, props);
};
const registerStepThreeApi = (props) => {
  return axios.post(BASE_URL + apiPath.registerStepThree, props);
};
const registerStepFourApi = (props) => {
  return axios.post(BASE_URL + apiPath.registerStepFour, props);
};
const registerStepFiveApi = (props) => {
  return axios.post(BASE_URL + apiPath.registerStepFive, props);
};
const registerStepSixApi = (props) => {
  return axios.post(BASE_URL + apiPath.registerStepSix, props);
};
const getDesignationApi = () => {
  return axios.get("http://43.204.100.255:4000/api/categoryType/" + apiPath.getDesignation);
};
const getCountriesApi = () => {
  return axios.get(BASE_URL + apiPath.getCountries);
};
const getStatesApi = (countryId) => {
  return axios.post(BASE_URL + apiPath.getStates, {
    countryId: countryId
  });
};
const getCitiesApi = (stateId) => {
  return axios.post(BASE_URL + apiPath.getCities, {
    stateId: stateId
  });
}
const getCategoriesApi = (type) => {
  return axios.post(CAT_URL, {
    type: type
  });
}
const verifyAddressApi = (props) => {
  return axios.post(BASE_URL + apiPath.verifyAddress, props);
}
const sendPasswordOtpApi = (props) => {
  return axios.post(BASE_URL + apiPath.sendPasswordOtp, props);
}
const generatePasswordApi = (props) => {
  return axios.post(BASE_URL + apiPath.generatePassword, props);
}
const verifyOTPApi = (props) => {
  return axios.post(BASE_URL + apiPath.verifyOTP, props);
}
const verifyPasswordOTPApi = (props) => {
  return axios.post(BASE_URL + apiPath.verifyPasswordOTP, props);
}
const getVendorCategoriesApi = () => {
  return axios.get("http://43.204.100.255:4000/api/categoryType/getVendorCategory");
}
const getAmenitiesCategoriesApi = () => {
  return axios.get("http://43.204.100.255:4000/api/categoryType/" + apiPath.getAmenitiesCategories);
}
const getCuisineCategoriesApi = () => {
  return axios.get("http://43.204.100.255:4000/api/categoryType/" + apiPath.getCuisineCategories);
}
const getGoodForCategoriesApi = () => {
  return axios.get("http://43.204.100.255:4000/api/categoryType/" + apiPath.getGoodForCategories);
}
const getMealsCategoriesApi = () => {
  return axios.get("http://43.204.100.255:4000/api/categoryType/" + apiPath.getMealsCategories);
}
const getParkingCategoriesApi = () => {
  return axios.get("http://43.204.100.255:4000/api/categoryType/" + apiPath.getParkingCategories);
}
const getAtmosphereCategoriesApi = () => {
  return axios.get("http://43.204.100.255:4000/api/categoryType/" + apiPath.getAtmosphereCategories);
}
const login = (props) => {

  return axios.post(BASE_URL + apiPath.login, props
  );
}


const logout = () => {
  localStorage.removeItem("user");
};
const RegisterApi = {
  register,
  getProfileDetailsApi,
  updateProfileApi,
  login,
  logout,
  registerStepOneApi,
  registerStepTwoApi,
  registerStepThreeApi,
  registerStepFourApi,
  registerStepFiveApi,
  registerStepSixApi,
  getCategoriesApi,
  getDesignationApi,
  getCountriesApi,
  getStatesApi,
  getCitiesApi,
  verifyAddressApi,
  verifyOTPApi,
  verifyPasswordOTPApi,
  getVendorCategoriesApi,
  getAmenitiesCategoriesApi,
  getCuisineCategoriesApi,
  getGoodForCategoriesApi,
  getMealsCategoriesApi,
  getParkingCategoriesApi,
  getAtmosphereCategoriesApi,
  sendPasswordOtpApi,
  generatePasswordApi,
  updateSettingsProfileApi,
  getProfileSettingsDetailsApi,
  getSettingAddress,
  updateSettingAddress,
  updateSettingsDocument
};
export default RegisterApi;
