import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { findInArray } from "../../../constants";
import {
  getMenuDetails,
  getMenuList,
  removeMenu,
} from "../../../redux/slices/Menu";
import { getCategories } from "../../../redux/slices/Registration";
import axios from "axios";
import { API_URL } from "../../../util/constant";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const ReadOnlyRow = (props) => {
  const { hashKey } = useParams();
  const token = localStorage.getItem("user");
  const history = useHistory();
  const { cuisineCategories } = useSelector((state) => state.register);

  const dispatch = useDispatch();
  const handleEditClick = (menuId) => {
    dispatch(
      getMenuDetails({
        menuId: menuId,
      })
    )
      .unwrap()
      .then((res) => {
        history.push(`/edit-menu/${menuId}/${hashKey}`);
      })
      .catch((err) => {
        toast.error(err);
      });
  };
  const handleDeleteClick = (menuId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          removeMenu({
            menuId: menuId,
          })
        )
          .unwrap()
          .then((res) => {
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
            props.request("POST", "menu/getAllMenu", {
              outletId: localStorage.getItem("outletId"),
              cusineId: props.cusine,
              foodType: props.foodType,
            });
          });
      }
    });
  };
  useEffect(() => {
    dispatch(getCategories("cuisine"));
  }, [dispatch]);

  const UpdateImage = async (files, id) => {
    const formdata = new FormData();
    formdata.append("menuId", id);
    // Append all files to FormData
    files.forEach((file) => {
      formdata.append("image", file);
    });
    const response = await axios.post(
      `${API_URL}/menu/updateMenuImage`,
      formdata,

      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    if (response?.data?.status == "success") {
      props.request("POST", "menu/getAllMenu", {
        outletId: localStorage.getItem("outletId"),
        cusineId: props.cusine,
        foodType: props.foodType,
        page: props?.page,
        per_page: props?.per_page,
      });
    }
  };
  return (
    <tr>
      <td>{props.index + 1}</td>
      {/* <td>
        {cuisineCategories.length &&
          findInArray(cuisineCategories, props.menu.cusineId)}
      </td> */}
      <td>{props.menu?.cuisine}</td>
      <td>{props.menu.name}</td>
      <td>{props.menu.foodCategory}</td>
      {/* <td>{props.menu.ingredients}</td> */}
      <td>{props.menu.course}</td>
      <td>{props.menu?.fullPrice}</td>
      <td>
        {props.menu?.image[0] ? (
          <img src={props.menu?.image[0]} alt="" />
        ) : (
          <input
            type="file"
            name="image"
            multiple
            accepr="image/*"
            placeholder="Select images..."
            // onChange={(e) => {
            //   UpdateImage([...e.target.files], props.menu._id)
            // }}
            onChange={(e) => {
              const files = [...e.target.files];

              UpdateImage(files, props.menu._id);
              {
                /* Passing individual file */
              }
            }}
          />
        )}
      </td>
      <td>{props.menu.status}</td>
      <td>
        {props.menu?.foodType != "" && props.menu?.foodType == "food"
          ? "Food/Beverage"
          : "Alc. Beverage"}
      </td>

      <td>
        <button
          className="menu-button"
          // type="button"
          onClick={() => {
            handleEditClick(props.menu._id);
          }}
        >
          Edit
        </button>
        <button
          type="button"
          className="menu-button"
          onClick={() => handleDeleteClick(props.menu._id)}
        >
          Delete
        </button>
      </td>
    </tr>
  );
};

export default ReadOnlyRow;
