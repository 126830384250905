import axios from "axios";
import apiPath, { BASE_URL } from "./apiPath";
import authHeader from "./authHeader";
const getDashboardData = (props) => {
    return axios.post(BASE_URL + apiPath.getDashboardData, props, authHeader());
};

const dashboardApis = {
    getDashboardData,
}

export default dashboardApis;