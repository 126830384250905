import React, { useEffect } from "react";
import { Field, useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { stepTwoSave } from "../../../../redux/slices/SaveDetails";
import {
  getCategories,
  registerStepTwo,
  verifyOTP,
} from "../../../../redux/slices/Registration";
import { toast } from "react-toastify";
import loginLogo from "../../../../assets/img/logo.png";
import Select from "react-select";
import { Col, Row, Button, Form, Modal } from "react-bootstrap";
import { upperCase } from "../../../../constants";
import SelectField from "../../../CustomSelect";
const StepTwo = ({ setNextStep, nextStep }) => {
  const dispatch = useDispatch();
  const { stepTwo } = useSelector((state) => state.saveDetails);
  const { managerId } = useSelector((state) => state.register);
  const [isError, setIserror] = React.useState(false);
  const { vendorCategories, vendorSubCategories } = useSelector(
    (state) => state.register
  );
  const [initialValues, setInitialValues] = React.useState(
    stepTwo
      ? stepTwo
      : {
          vendorCategory: "",
          vendorSubCategory: [],
          totalOutlet: "",
          vendorName: "",
        }
  );
  const validationSchema = Yup.object().shape({
    vendorCategory: Yup.string().required("vendorType is required"),
    // vendorSubCategory: Yup.array().required("vendorType is required"),
    vendorName: Yup.string().required("Place Nmae is required"),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (
        !values.vendorSubCategory.length &&
        values.vendorCategory === "62b429d59d1d55495a9056a9"
      ) {
        formik.setFieldValue("totalOutlet", "");
        setIserror(true);
        return;
      } else {
        setIserror(false);
        dispatch(stepTwoSave(values));
        let subCategoryValues = [];
        for (const key in values.vendorSubCategory) {
          subCategoryValues.push(values.vendorSubCategory[key].value);
        }
        dispatch(
          registerStepTwo({
            ...values,
            managerId: managerId,
            vendorSubCategory: subCategoryValues,
          })
        )
          .unwrap()
          .then((res) => {
            toast.success(res.message);
            if (values.vendorCategory === "62b429d59d1d55495a9056a9") {
              setNextStep(3);
            } else {
              setNextStep(4);
            }
          })
          .catch((err) => {
            toast.error(err);
          });
      }
    },
  });
  const validation = () => {
    var forms = document.getElementsByClassName("needs-validation");
    Array.prototype.filter.call(forms, function (form) {
      form.addEventListener(
        "submit",
        function (event) {
          if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
          }
          form.classList.add("was-validated");
        },
        false
      );
    });
  };
  useEffect(() => {
    if (stepTwo) {
      setInitialValues(stepTwo);
    }
    dispatch(getCategories("vendorCategory"));
    dispatch(getCategories("vendorSubCategory"));
  }, [dispatch, initialValues]);
  return (
    <div className="ms-content-wrapper ms-auth">
      <div className="regi-section">
        <Row>
          <Col md={5}>
            <div className="ms-auth-bg">
              <img src={loginLogo} />
            </div>
          </Col>
          <Col md={7}>
            <div className="regi-form two-step">
              <form
                className="needs-validation"
                onSubmit={formik.handleSubmit}
                noValidate
              >
                <h3>
                    Registration : Step <span>{nextStep} of 6</span>
                </h3>
                <p>Please enter information to continue</p>
                <div className="form-vendor-regi">
                  <div className="form-vendor">
                    <div className="form-group">
                      <label htmlFor="validationCustom01">
                        Select Your Category<span className="error">*</span>
                      </label>
                      <div className="dropdown-group">
                        <select
                          className="form-control"
                          name="vendorCategory"
                          onChange={formik.handleChange}
                          value={formik.values.vendorCategory}
                          required
                        >
                          <option>Select Category</option>

                          {vendorCategories.length > 0 ? (
                            vendorCategories.map((country) => {
                              return (
                                <option value={country._id}>
                                  {" "}
                                  {upperCase(country.name)}
                                </option>
                              );
                            })
                          ) : (
                            <option value={0}>Categories not found</option>
                          )}
                           <div className="invalid-feedback">
                            Please provide a valid category.
                          </div>
                        </select>
                      </div>
                    </div>
                  </div>
                  {formik.values.vendorCategory ===
                    "62b429d59d1d55495a9056a9" && (
                    <div className="">
                      <div>
                        <label htmlFor="validationCustom01">
                          Select Your Sub-Category<span className="error">*</span>
                        </label>
                        <Select
                          isMulti
                          name="vendorSubCategory"
                          value={formik.values.vendorSubCategory}
                          options={
                            vendorSubCategories.length > 0 &&
                            vendorSubCategories.map((c) => {
                              return { value: c._id, label: upperCase(c.name) };
                            })
                          }
                          onChange={(action) => {
                            formik.setFieldValue("vendorSubCategory", action);
                            // formik.values.vendorSubCategory = action;
                          }}
                          // onChange={formik.handleChange}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                        <div>
                          {isError && (
                            // formik.touched.vendorSubCategory &&
                            <div className="error">
                              Vendor sub categories are required
                            </div>
                          )}
                        </div>
                        {/* <Field
                        component={SelectField}
                        name="vendorSubCategory"
                        options={
                          vendorSubCategories.length > 0 &&
                          vendorSubCategories.map((c) => {
                            return { value: c._id, label: upperCase(c.name) };
                          })
                        }
                      /> */}
                      </div>
                    </div>
                  )}
                  {(formik.values.vendorCategory ===
                    "62b429b69d1d55495a9056a5" ||
                    formik.values.vendorCategory ===
                      "62b429c79d1d55495a9056a7") && (
                    <div className="">
                      <div>
                        <label htmlFor="validationCustom02">
                          Number of outlets<span className="error">*</span>
                        </label>
                        <div className="input-group">
                          <input
                            type="number"
                            className="form-control"
                            id="validationCustom02"
                            name="totalOutlet"
                            onChange={formik.handleChange}
                            value={formik.values.totalOutlet}
                            required
                          />
                          <div className="invalid-feedback">
                            Please provide a valid Number.
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="">
                    <div>
                      <label htmlFor="validationCustom03">
                        Name of the Restaurent/Hotel<span className="error">*</span>
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          id="validationCustom03"
                          placeholder="Place Name"
                          name="vendorName"
                          onChange={formik.handleChange}
                          value={formik.values.vendorName}
                          required
                        />
                        <div className="invalid-feedback">
                          Please provide a valid Name.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <button
                      className="steps-btn float-left"
                      type="button"
                      onClick={() => setNextStep(1)}
                    >
                      <i>&#8592;</i> Previous 
                    </button>
                  </div>
                  <div className="col-md-6">
                    <button
                      className="steps-btn"
                      type="submit"
                      onClick={validation}
                    >
                      Next <i>&#8594;</i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default StepTwo;

