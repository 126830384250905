export const upperCase = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1)
}
export const maxSize = 3 * 1024 * 1024; // 1mb MB (you can adjust this value as needed)
// export const maxSize = 5 * 1024 * 1024; // 5 MB (you can adjust this value as needed)

//find something in array
export const findInArray = (array, value) => {
    if (array.find(item => item._id === value)) {
        return array.find(item => item._id === value).name
    }
}