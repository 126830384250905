export const BASE_URL = "https://api.sortyfy.com/api/vendor/";
export const CAT_URL = "https://api.sortyfy.com/api/manageCategory/getCategorys"
export const MAP_KEY = "AIzaSyD49_3x1vse9MyoHB3VugiybQphSM0THU4";
export const image_url = "https://api.sortyfy.com/api/vendor/menu/viewImage/";
const apiPath = {
  signUp: "",
  login: "login",
  getProfileDetails: "user/getManagerProfile",
  updateProfile: "user/updateProfile",
  //registeration
  registerStepOne: "registerStepOne",
  registerStepTwo: "registerStepTwo",
  registerStepThree: "registerStepThree",
  registerStepFour: "registerStepFour",
  registerStepFive: "registerStepFive",
  registerStepSix: "registerStepSix",
  getDesignation: "getDesignationCategory",
  sendPasswordOtp: "sendPasswordOtp",
  generatePassword: "generatePassword",
  getCountries: "getCountry",
  getStates: "getState",
  getCities: "getCity",
  verifyAddress: "getLatLong",
  verifyOTP: "registerVerifyOtp",
  verifyPasswordOTP: "verifyPasswordOtp",
  getVendorCategories: "getVendorCategory",
  getAmenitiesCategories: "getAmenitiesCategory",
  getCuisineCategories: "getCuisineCategory",
  getGoodForCategories: "getGoodForCategory",
  getMealsCategories: "getMealsCategory",
  getParkingCategories: "getParkingCategory",
  getAtmosphereCategories: "getAtmosphereCategory",

  //dashboard
  getDashboardData: "dashboard/getAllData",
  //menu
  addMenu: "menu/addMenu",
  getMenuList: "menu/getAllMenu",
  updateMenu: "menu/updateMenu",
  removeMenu: "menu/removeMenu",
  getMenuDetails: "menu/getMenuItem",

  //Discount
  addDiscount: "discount/addDiscountCoupon",
  getDiscountList: "discount/allDiscountCoupon",
  updateDiscount: "discount/updateDiscountCoupon",
  removeDiscount: "discount/removeDiscount",

  //promotion
  addPromotion: "discount/addPromotion",
  getPromotionList: "discount/allPromotions",
  updatePromotion: "discount/updatePromotion",
  removePromotion: "discount/removePromotions",

  //outlets
  outletStepOne: "outlet/registerStepOne",
  outletStepTwo: "outlet/registerStepTwo",
  outletStepThree: "outlet/registerStepThree",
  outletStepFour: "outlet/registerStepFour",
  getAllOutlets: "outlet/getAllOutlet",
  getAllOutletDetail: "outlet/getOutletDetail",
  getAllReviews: "outlet/getOutletReviews",
  getOutletVistors: "user/getOutletUser",
  sendFeedback: "outlet/addUserReview",
  blockUser: "user/addBlockUser",
  addCompetitorApi: "user/addCompetitor",
  getCompetitorsApi: "user/getCompetitor",
  getAllOutlet: "user/getAllOutlet",
  getArea: "user/getOutletByArea",
  unblockUser: "user/unBlockUser",
  ratings: "outlet/getUserReview",
  setRewardPoint: "outlet/updateRewardStatus",
  setCommision: "outlet/addCommissionPercent",

  //orders
  getOrders: "order/orderHistory",
  updateOrder: "order/updateOrderStatus",
  getOrderDetails: "order/getOrderDetail",
  verifyOrder: "order/updateVerifyOrder",

  //reports
  getBusinessReports: "report/businessReport",
  getBookingReports: "report/bookingReport",
  getRevenueReports: "report/RevenueReport",
  getTrendsReports: "report/trendReport",

  //finance
  getBillingList: "report/financeReport",
  getPaymentList: "report/paymentReport",
  getPendingList: "report/pendingPaymentReport",

  //settings
  settingsProfile: "setting/updateProfile",
  getProfileSettingsDetails: "setting/getManagerProfile",
  getSettingAddress: "setting/getVendorAddress",
  updateSettingAddress: "setting/updateVendorAddress",
  updateSettingsDocument: "setting/updateVendorDocument",
};

export default apiPath;
