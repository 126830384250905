import React from "react";
import GoogleMapReact from 'google-map-react';
import { MAP_KEY } from "../redux/services/apiPath";
export default function SimpleMap(props) {
    const defaultProps = {
        center: {
            lat: props.latitude,
            lng: props.longitude
        },
        zoom: 16
    };
    const renderMarkers = (map, maps) => {
        let marker = new maps.Marker({
            position: { lat: props.latitude, lng: props.longitude },
            map,
            title: 'Hello World!'
        });
        return marker;
    };


    return (
        // Important! Always set the container height explicitly
        <div style={{
            height: '40vh', width: '100%',
            // position: 'relative',
            // top: '0px',
            // left: '0px',
            // right: '0px',
            // bottom: '0px',
            // margin: '0 auto'

        }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: MAP_KEY }}
                center={defaultProps.center}
                zoom={defaultProps.zoom}
                debounced={true}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
            >
            </GoogleMapReact>
        </div>
    );
}