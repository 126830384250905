import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {useParams, useHistory} from 'react-router-dom';
import { toast } from "react-toastify";
import StepOne from './Outlet/StepOne';
import StepTwo from './Outlet/StepTwo';
import StepThree from './Outlet/StepThree';
import StepFour from './Outlet/StepFour';
import { clearAllDetails, outletStepFourSave, outletStepOneSave, outletStepThreeSave, outletStepTwoSave } from '../redux/slices/SaveOutletDetails';
import { getOutletDetail } from '../redux/slices/Outlet';

const EditOutlet = () => {
    const dispatch                                                                  = useDispatch();
    const history                                                                   = useHistory();
    const {hashKey}                                                                 = useParams();
    const [nextStep, setNextStep]                                                   = useState(1);
    const [outletId, setOutletId]                                                   = useState();

    useEffect(() => {

        if(hashKey){
            dispatch(getOutletDetail({ outletId: hashKey}))
            .unwrap()
            .then((res) => {
                if(res?.status == "success" || res?.status == true){
                    dispatch(
                        outletStepOneSave({
                        vendorSubCategory: res.data.subCategory.map(
                            (item) => {
                            return {
                                value: item._id,
                                label: item.name,
                            };
                            }
                        ),
                        outletName: res.data.name,
                        outletId: hashKey
                        })
                    );
        
                    if(res?.data?.cuisineCategory){
                        
                        dispatch(
                            outletStepTwoSave({
                            hoursData: res.data.openingHours,
                            cuisine: res.data.cuisineCategory.map(
                                (item) => item._id
                            ),
                            meals: res.data.mealsCategory.map(
                                (item) => item._id
                            ),
                            payment: res.data.paymentCategory.map(
                                (item) => item._id
                            ),
                            parking: res.data.parkingCategory.map(
                                (item) => item._id
                            ),
                            themes: res.data.themeType.map(
                                (item) => item._id
                            ),
                            otherAttractions:
                                res.data.otherAttraction.map(
                                (item) => item._id
                                ),
                            moreFacilities: res.data.moreFacilities.map(
                                (item) => item._id
                            ),
                            })
                        );
                    }

                    if(res?.data?.aboutVendor){
                        dispatch(
                            outletStepThreeSave({
                                coverPicture:res?.data?.allDetail?.coverPicture?.length ? res?.data?.allDetail?.coverPicture : [],
                                image: res?.data?.allDetail?.otherImage?.length ? res?.data?.allDetail?.otherImage : [],
                                facadeImage: res?.data?.allDetail?.facadeImage?.length ? res?.data?.allDetail?.facadeImage : [],
                                interiorsImage: res?.data?.allDetail?.interiorsImage?.length ? res?.data?.allDetail?.interiorsImage : [],
                                sittingImage: res?.data?.allDetail?.sittingImage?.length ? res?.data?.allDetail?.sittingImage : [],
                                staffImage: res?.data?.allDetail?.staffImage?.length ? res?.data?.allDetail?.staffImage : [],
                                savedData:true,
                                savedCoverPicture:true,
                                savedfacadePicture:true,
                                savedInteriorsPicture:true,
                                savedSittingPicture:true,
                                savedStaffPicture:true,
                                savedOtherPicture:true,
                                description: res.data.aboutVendor,
                            })
                        );
                    }
                    
                    if(res?.data?.managerDetail?.name && res?.data?.managerDetail?.email && res?.data?.managerDetail?.mobile && res?.data?.managerDetail?.password){
                        dispatch(
                        outletStepFourSave({
                        name: res.data.managerDetail.name,
                        email: res.data.managerDetail.email,
                        mobile: res.data.managerDetail.mobile,
                        password: res.data.managerDetail.password,
                        rePassword: res.data.managerDetail.password,
                        })
                    );
                    }
                   
                                     
                                        
                }else{
                    history.push(`/total-outlets`);
                    dispatch(clearAllDetails);
                }
            })
            .catch((err) => {
                dispatch(clearAllDetails);
                toast.error(err);
            });
        }else{
            history.push(`/total-outlets`);
        }
       
    },[])

    const handleNextStep = (val) => {
        setNextStep(val);
    }

    const handleOutletId = (val) => {
        setOutletId(val);
    }

    const renderContent = () => {
        switch (nextStep) {
            case 1:
                return <StepOne handleNextStep={handleNextStep} nextStep={nextStep} handleOutletId={handleOutletId} outletId={outletId} isEdit={true}/>;
            case 2:
                return <StepTwo handleNextStep={handleNextStep} nextStep={nextStep} outletId={outletId} isEdit={true}/>;
            case 3:
                return <StepThree handleNextStep={handleNextStep} nextStep={nextStep} outletId={outletId} isEdit={true}/>;
            case 4:
                return <StepFour handleNextStep={handleNextStep} nextStep={nextStep} outletId={outletId} isEdit={true}/>;
            default:
                return;
        }
    }
    useEffect(() => {
        dispatch(clearAllDetails());
    }, [dispatch])

    return (
        <>
            {renderContent()}
           
        </>
    );

}

export default EditOutlet;