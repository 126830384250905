import React, { Component } from 'react';
import Content from '../sections/Support/Content';

const Support = () => {
  return (
    <Content/>
              
  )
}

export default Support