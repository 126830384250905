import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import loginLogo from "../../../assets/img/logo.png";
import useRequest from "../../../hooks/useRequest";
import Select from "react-select";
import { BASE_URL } from "../../../redux/services/apiPath";
import axios from "axios";

const SendLink = () => {
  const history = useHistory();
  const [touchedError, setTouchedError] = useState(false);
  const [displayImage, setDisplayImage] = useState([]);
  const [email, setEmail] = useState("");
  const [image2, setImage2] = useState([]);
  const { request, response } = useRequest();
  const [allCity, setAllCity] = useState([]);
  const { request: requestSubmit, response: responseSubmit } = useRequest();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    clearErrors,
  } = useForm();

  const onSubmit = async (data) => {
    const { email } = data;
    const data1 = {
      email,
    };
    const res = await axios.post(`${BASE_URL}/sendRegistrationLink`, data1);
    console.log("res", res?.data?.status);
    if (res?.data?.status == "success") {
      setValue("email", "");
      toast.success("Link Sent Successfully");
    }
  };

  return (
    <div className="ms-content-wrapper ms-auth">
      <Row>
        <Col md={5}>
          <div className="ms-auth-bg">
            <img src={loginLogo} />
          </div>
        </Col>
        <Col md={7}>
          <div className="regi-form two-step">
            <form
              className="needs-validation"
              onSubmit={handleSubmit(onSubmit)}
            >
              <h3>Sent Link</h3>
              <p>
                Please Enter Email Address And Sent Registration Link To Vendor
              </p>
              <div className="form-vendor-regi">
                <div className="form-vendor">
                  <label htmlFor="validationCustom08">
                    Email Address<span className="error">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Email Address"
                      className={`form-control ${
                        touchedError
                          ? errors.email
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                      {...register("email", {
                        required: true,
                        pattern:
                          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      })}
                    />

                    {errors.email?.type === "required" && (
                      <div className="invalid-feedback">
                        This field is required.
                      </div>
                    )}
                    {errors.email?.type === "pattern" && (
                      <div className="invalid-feedback">
                        Please enter a valid email address.
                      </div>
                    )}
                  </div>
                </div>

                <button
                  className=" steps-btn"
                  type="submit"
                  onClick={() => setTouchedError(true)}
                >
                  Send Link
                </button>
              </div>
            </form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SendLink;
