import React, { Component } from "react";
import DiscountTable from "../../pages/Discount/DiscountTable";
import Breadcrumb from "../../Breadcrumb";

class Gridcontent extends Component {
    render() {
        return (
            <div className="ms-content-wrapper">
                  {localStorage.getItem("vendorType") === "single" ? (
                   <Breadcrumb
                    title="Discounts"
                    links={[
                    { to: "/", name: "Home" },
                    ]}
                />
                   ):(
                    <Breadcrumb
                        title="Discounts"
                        links={[
                        { to: "/", name: "Home" },
                        { to: "/total-outlets", name: "Outlets" },
                        ]}
                    />
                   )}
               
                <div className="row">
                    <div className="col-md-12">
                        <DiscountTable />
                    </div>
                </div>
            </div>
        );
    }
}

export default Gridcontent;
