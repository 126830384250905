import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  FormControl,
  InputGroup,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { stepThreeSave } from "../../redux/slices/SaveDetails";
import { registerStepThree } from "../../redux/slices/Registration";
import loginLogo from "../../assets/img/logo.png";
import useRequest2 from "../../hooks/useRequest2";
import { upperCase } from "../../constants";
import { daysData } from "../../util/constant";

const StepThree = ({ nextStep, handleNextStep }) => {
  const [loader, setLoader] = useState(false);
  const { outletId } = useSelector((state) => state.register);
  const dispatch = useDispatch();

  const { stepThree } = useSelector((state) => state.saveDetails);

  const { response: responseMealCategory, request: requestMealCategory } =
    useRequest2();
  const { response: responsePaymentMethod, request: requestPaymentMethod } =
    useRequest2();
  const { response: responseParking, request: requestParking } = useRequest2();
  const { response: responseThemes, request: requestThemes } = useRequest2();
  const {
    response: responseOtherAttractions,
    request: requestOtherAttractions,
  } = useRequest2();
  const { response: responseMoreFacilities, request: requestMoreFacilities } =
    useRequest2();
  const { response: responseOtherFacilities, request: requestOtherFacilities } =
    useRequest2();
  const { response: responseCuisineCategory, request: requestCuisineCategory } =
    useRequest2();

  const [mealsCategoriess, setMealsCategories] = useState([]);
  const [paymentss, setPayments] = useState([]);
  const [parkingCategoriess, setParkingCategories] = useState([]);
  const [services, setServices] = useState(
    localStorage.getItem("services")
      ? JSON.parse(localStorage.getItem("services"))
      : [{ title: "", amount: "", taxOn: "", applyOn: "" }]
  );

  const [themes, setThemes] = useState([]);
  const [otherAttractionss, setOtherAttractions] = useState([]);
  const [moreFacilitiess, setMoreFacilities] = useState([]);
  const [otherFacilities, setOtherFacilities] = useState([]);
  const [cuisineCategoriess, setCuisineCategories] = useState([]);
  const [touchedError, setTouchedError] = useState(false);
  const [showCuisineModal, setShowCuisineModal] = useState(false);
  const [errorss, setErrorss] = useState([]);

  const [showHoursModal, setShowHoursModal] = useState(false);
  const [searchCuisine, setSearchCuisine] = useState("");
  const [isCuisineValue, setIsCuisineValue] = useState(false);
  const [cusineData, setCusineData] = useState([]);
  const [hoursData, setHoursData] = useState(daysData);
  const [hoursData2, setHoursData2] = useState(daysData);
  const validateFields = () => {
    const errorsArr = [];
    services.forEach((service, index) => {
      if (!service.title || !service.amount || !service.taxOn) {
        errorsArr.push(index);
      } else if (isNaN(service.amount)) {
        errorsArr.push(index);
      }
    });
    setErrorss(errorsArr);
    return errorsArr.length === 0;
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
    setValue,
  } = useForm();

  const {
    register: registerCuisine,
    handleSubmit: handleSubmitCuisine,
    formState: { errors: errorsCuisine },
    getValues: getValuesCuisine,
    setError: setErrorCuisine,
    setValue: setValueCuisine,
  } = useForm();

  useEffect(() => {
    requestMealCategory("POST", "manageCategory/getCategorys", {
      type: "meals",
    });
    requestPaymentMethod("POST", "manageCategory/getCategorys", {
      type: "payment",
    });
    requestParking("POST", "manageCategory/getCategorys", { type: "parking" });
    requestThemes("POST", "manageCategory/getCategorys", { type: "theme" });
    requestOtherAttractions("POST", "manageCategory/getCategorys", {
      type: "otherAttraction",
    });
    requestOtherFacilities("POST", "manageCategory/getCategorys", {
      type: "otherFacilities",
    });
    requestMoreFacilities("POST", "manageCategory/getCategorys", {
      type: "moreFacilities",
    });
    requestCuisineCategory("POST", "manageCategory/getCategorys", {
      type: "cuisine",
    });

    // register("cuisine", {required: true})
  }, []);

  useEffect(() => {
    if (responseMealCategory) {
      if (
        responseMealCategory?.status == true ||
        responseMealCategory?.status == "success"
      ) {
        setMealsCategories(
          responseMealCategory?.data?.length > 0
            ? responseMealCategory?.data
            : []
        );
      }
    }
  }, [responseMealCategory]);

  useEffect(() => {
    if (responsePaymentMethod) {
      if (
        responsePaymentMethod?.status == true ||
        responsePaymentMethod?.status == "success"
      ) {
        setPayments(
          responsePaymentMethod?.data?.length > 0
            ? responsePaymentMethod?.data
            : []
        );
      }
    }
  }, [responsePaymentMethod]);

  useEffect(() => {
    if (responseParking) {
      if (
        responseParking?.status == true ||
        responseParking?.status == "success"
      ) {
        setParkingCategories(
          responseParking?.data?.length > 0 ? responseParking?.data : []
        );
      }
    }
  }, [responseParking]);

  useEffect(() => {
    if (responseThemes) {
      if (
        responseThemes?.status == true ||
        responseThemes?.status == "success"
      ) {
        setThemes(responseThemes?.data?.length > 0 ? responseThemes?.data : []);
      }
    }
  }, [responseThemes]);

  useEffect(() => {
    if (responseOtherAttractions) {
      if (
        responseOtherAttractions?.status == true ||
        responseOtherAttractions?.status == "success"
      ) {
        setOtherAttractions(
          responseOtherAttractions?.data?.length > 0
            ? responseOtherAttractions?.data
            : []
        );
      }
    }
  }, [responseOtherAttractions]);

  useEffect(() => {
    if (responseMoreFacilities) {
      if (
        responseMoreFacilities?.status == true ||
        responseMoreFacilities?.status == "success"
      ) {
        setMoreFacilities(
          responseMoreFacilities?.data?.length > 0
            ? responseMoreFacilities?.data
            : []
        );
      }
    }
  }, [responseMoreFacilities]);

  useEffect(() => {
    if (responseCuisineCategory) {
      if (
        responseCuisineCategory?.status == true ||
        responseCuisineCategory?.status == "success"
      ) {
        setCuisineCategories(
          responseCuisineCategory?.data?.length > 0
            ? responseCuisineCategory?.data
            : []
        );
      }
    }
  }, [responseCuisineCategory]);

  useEffect(() => {
    if (responseOtherFacilities) {
      if (
        responseOtherFacilities?.status == true ||
        responseOtherFacilities?.status == "success"
      ) {
        setOtherFacilities(
          responseOtherFacilities?.data?.length > 0
            ? responseOtherFacilities?.data
            : []
        );
      }
    }
  }, [responseOtherFacilities]);

  const renderMeals = () => {
    return mealsCategoriess.map((value, index) => {
      return (
        <div className="col-md-4">
          <label>
            <input
              type="checkbox"
              value={value._id}
              name="meals"
              {...register("meals", {
                required: true,
              })}
            />
            &nbsp;{upperCase(value.name)}
          </label>
        </div>
      );
    });
  };

  const renderPayment = () => {
    return paymentss.map((value, index) => {
      return (
        <div className="col-md-4">
          <label>
            <input
              type="checkbox"
              value={value._id}
              name="payment"
              {...register("payment", {
                required: true,
              })}
            />
            &nbsp;{upperCase(value.name)}
          </label>
        </div>
      );
    });
  };

  const renderParking = () => {
    return parkingCategoriess.map((value, index) => {
      return (
        <div className="col-md-4">
          <label>
            <input
              type="checkbox"
              value={value._id}
              name="parking"
              {...register("parking", {
                required: true,
              })}
            />
            &nbsp;{upperCase(value.name)}
          </label>
        </div>
      );
    });
  };

  const renderGoodFor = () => {
    return themes.map((value, index) => {
      return (
        <div className="col-md-4">
          <label>
            <input
              type="checkbox"
              value={value._id}
              name="themes"
              {...register("themes", {
                required: true,
              })}
            />
            &nbsp;{upperCase(value.name)}
          </label>
        </div>
      );
    });
  };

  const renderOtherAttractions = () => {
    return otherAttractionss.map((value, index) => {
      return (
        <div className="col-md-4">
          <label>
            <input
              type="checkbox"
              value={value._id}
              name="otherAttractions"
              {...register("otherAttractions", { required: false })}
            />
            &nbsp;{upperCase(value.name)}
          </label>
        </div>
      );
    });
  };

  const renderOtherFacilites = () => {
    return otherFacilities?.map((value, index) => {
      return (
        <div className="col-md-4">
          <label>
            <input
              type="checkbox"
              value={value._id}
              name="otherFacilities"
              {...register("otherFacilities", { required: false })}
            />
            &nbsp;{upperCase(value.name)}
          </label>
        </div>
      );
    });
  };

  const renderMoreFacilities = () => {
    return moreFacilitiess.map((value, index) => {
      return (
        <div className="col-md-4">
          <label>
            <input
              type="checkbox"
              value={value._id}
              name="moreFacilities"
              {...register("moreFacilities", { required: false })}
            />
            &nbsp;{upperCase(value.name)}
          </label>
        </div>
      );
    });
  };

  const renderOpenHoursModal = () => {
    return (
      <Modal
        show={showHoursModal}
        onHide={handleCloseShowHours}
        style={{ height: "90vh", maxHeight: "90vh", overflow: "hidden" }}
        scrollable
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Add open hours
            <br />
            <span style={{ fontSize: "1rem" }}>
              Days without hours added will display as closed.
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{renderHoursData()}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseShowHours}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveShowHours}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  // const handleHoursData = (e, index, day) => {
  //   setHoursData2(
  //     hoursData2.map((item, i) => {
  //       if (i === index) {
  //         if (item.day == day) {
  //           if (e.target.name == "fullDay") {
  //             if (e.target.checked) {
  //               return {
  //                 ...item,
  //                 ["fromTime"]: "00:00",
  //                 ["toTime"]: "23:59",
  //                 ["fullDay"]: true,
  //               };
  //             } else {
  //               return {
  //                 ...item,
  //                 ["fromTime"]: "",
  //                 ["toTime"]: "",
  //                 ["fullDay"]: false,
  //               };
  //             }
  //           } else {
  //             // item.fullDay = false;

  //             if (e.target.name == "fromTime") {
  //               return {
  //                 ...item,
  //                 ["fromTime"]: e.target.value,
  //                 ["fullDay"]: false,
  //               };
  //             } else if (e.target.name == "toTime") {
  //               return {
  //                 ...item,
  //                 ["toTime"]: e.target.value,
  //                 ["fullDay"]: false,
  //               };
  //             }
  //           }
  //         }
  //       }
  //       return item;
  //     })
  //   );
  // };
  // const handleHoursDataForAll = (e) => {
  //   setHoursData2(
  //     hoursData2.map((item) => {
  //       if (e.target.name === "fullDay") {
  //         if (e.target.checked) {
  //           return {
  //             ...item,
  //             ["fromTime"]: "00:00",
  //             ["toTime"]: "23:59",
  //             ["fullDay"]: true,
  //           };
  //         } else {
  //           return {
  //             ...item,
  //             ["fromTime"]: "",
  //             ["toTime"]: "",
  //             ["fullDay"]: false,
  //           };
  //         }
  //       } else {
  //         if (e.target.name === "fromTime") {
  //           return {
  //             ...item,
  //             ["fromTime"]: e.target.value,
  //             ["fullDay"]: false,
  //           };
  //         } else if (e.target.name === "toTime") {
  //           return {
  //             ...item,
  //             ["toTime"]: e.target.value,
  //             ["fullDay"]: false,
  //           };
  //         }
  //       }
  //       return item;
  //     })
  //   );
  // };

  // const renderHoursData = () => {
  //   return hoursData2?.map((valueObj, index) => {
  //     const { day, fromTime, toTime, fullDay } = valueObj;
  //     return (
  //       <div className="Section-time">
  //         <div>
  //           <h5>{day}</h5>
  //         </div>
  //         <div>
  //           {!fullDay && (
  //             <>
  //               <input
  //                 type="time"
  //                 name="fromTime"
  //                 value={fromTime}
  //                 style={{ marginBottom: "20px" }}
  //                 onChange={(e) => {
  //                   handleHoursData(e, index, day);
  //                   if (index == 0) {
  //                     handleHoursDataForAll(e);
  //                   }
  //                 }}
  //               />{" "}
  //               to{" "}
  //               <input
  //                 type="time"
  //                 name="toTime"
  //                 value={toTime}
  //                 style={{ margin: "10px" }}
  //                 onChange={(e) => {
  //                   handleHoursData(e, index, day);
  //                   if (index == 0) {
  //                     handleHoursDataForAll(e);
  //                   }
  //                 }}
  //               />
  //             </>
  //           )}
  //           <label>
  //             <input
  //               type="checkbox"
  //               name="fullDay"
  //               defaultChecked={fullDay}
  //               onChange={(e) => handleHoursData(e, index, day)}
  //             />
  //             &nbsp;Open 24 Hours
  //           </label>
  //         </div>
  //       </div>
  //     );
  //   });
  // };

  const handleHoursData = (e, index, day) => {
    setHoursData2(
      hoursData2.map((item, i) => {
        if (i === index) {
          if (item.day == day) {
            if (e.target.name == "fullDay") {
              if (e.target.checked) {
                return {
                  ...item,
                  ["fromTime"]: "00:00",
                  ["toTime"]: "23:59",
                  ["fullDay"]: true,
                };
              } else {
                return {
                  ...item,
                  ["fromTime"]: "",
                  ["toTime"]: "",
                  ["fullDay"]: false,
                };
              }
            } else {
              // item.fullDay = false;

              if (e.target.name == "fromTime") {
                return {
                  ...item,
                  ["fromTime"]: e.target.value,
                  ["fullDay"]: false,
                };
              } else if (e.target.name == "toTime") {
                return {
                  ...item,
                  ["toTime"]: e.target.value,
                  ["fullDay"]: false,
                };
              }
            }
          }
        }
        return item;
      })
    );
  };
  const handleHoursDataForAll = (e) => {
    setHoursData2(
      hoursData2.map((item) => {
        if (e.target.name === "fullDay") {
          if (e.target.checked) {
            return {
              ...item,
              ["fromTime"]: "00:00",
              ["toTime"]: "23:59",
              ["fullDay"]: true,
            };
          } else {
            return {
              ...item,
              ["fromTime"]: "",
              ["toTime"]: "",
              ["fullDay"]: false,
            };
          }
        } else {
          if (e.target.name === "fromTime") {
            return {
              ...item,
              ["fromTime"]: e.target.value,
              ["fullDay"]: false,
            };
          } else if (e.target.name === "toTime") {
            return {
              ...item,
              ["toTime"]: e.target.value,
              ["fullDay"]: false,
            };
          }
        }
        return item;
      })
    );
  };

  const renderHoursData = () => {
    return hoursData2?.map((valueObj, index) => {
      const { day, fromTime, toTime, fullDay } = valueObj;
      return (
        <div className="Section-time">
          <div>
            <h5>{day}</h5>
          </div>
          <div>
            {!fullDay && (
              <>
                <input
                  type="time"
                  name="fromTime"
                  value={fromTime}
                  style={{ marginBottom: "20px" }}
                  onChange={(e) => {
                    handleHoursData(e, index, day);
                    if (index == 0) {
                      handleHoursDataForAll(e);
                    }
                  }}
                />{" "}
                to{" "}
                <input
                  type="time"
                  name="toTime"
                  value={toTime}
                  style={{ margin: "10px" }}
                  onChange={(e) => {
                    handleHoursData(e, index, day);
                    if (index == 0) {
                      handleHoursDataForAll(e);
                    }
                  }}
                />
              </>
            )}
            <label>
              <input
                type="checkbox"
                name="fullDay"
                defaultChecked={fullDay}
                onChange={(e) => handleHoursData(e, index, day)}
              />
              &nbsp;Open 24 Hours
            </label>
          </div>
        </div>
      );
    });
  };

  const handleShowCuisine = () => setShowCuisineModal(!showCuisineModal);
  const handleShowHours = () => setShowHoursModal(!showHoursModal);

  const handleSaveShowHours = () => {
    setHoursData(hoursData2);
    setShowHoursModal(false);
  };
  const handleCloseShowHours = () => {
    setHoursData2(hoursData);
    setShowHoursModal(false);
  };

  const handleCloseCuisine = () => {
    setShowCuisineModal(false);
    setErrorCuisine("cuisineModal", "");
    setValueCuisine("cuisineModal", getValues("cuisine"));
  };

  const handleSaveCuisine = () => {
    if (
      !getValuesCuisine("cuisineModal") ||
      getValuesCuisine("cuisineModal")?.length == 0
    ) {
      setErrorCuisine("cuisineModal", { type: "required" });
    } else {
      setErrorCuisine("cuisineModal", "");
      setError("cuisine", "");
      setValue("cuisine", getValuesCuisine("cuisineModal"));
      setShowCuisineModal(false);
    }
  };

  useEffect(() => {
    if (stepThree) {
      setValue("cuisine", stepThree?.cuisine ?? "");
      setValueCuisine("cuisineModal", stepThree?.cuisine ?? "");
      setValue("meals", stepThree?.meals ?? "");
      setValue("moreFacilities", stepThree?.moreFacilities ?? "");
      setValue("parking", stepThree?.parking ?? "");
      setValue("payment", stepThree?.payment ?? "");
      setValue("themes", stepThree?.themes ?? "");
      setValue("otherAttractions", stepThree?.otherAttractions ?? "");
      setValue("otherFacilities", stepThree?.otherFacilities ?? "");
      setHoursData(stepThree.hoursData);
      setHoursData2(stepThree.hoursData);
    }
  }, [stepThree]);
  const renderCuisineModal = () => {
    return (
      <Modal
        show={showCuisineModal}
        onHide={handleCloseCuisine}
        style={{ height: "90vh", maxHeight: "90vh", overflow: "hidden" }}
        scrollable
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add cuisines</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">&#128269;</InputGroup.Text>
            <FormControl
              placeholder="search cuisine"
              aria-label="search cuisine"
              aria-describedby="basic-addon1"
              onChange={(e) => setSearchCuisine(e.target.value)}
            />
          </InputGroup>
          <form onSubmit={handleSubmitCuisine(onSubmitCuisine)}>
            <div className="row" style={{ margin: "auto" }}>
              {renderCuisineData()}
            </div>
            {errorsCuisine?.cuisineModal?.type === "required" && (
              <div className="error">Please Select atleast one cuisine.</div>
            )}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCuisine}>
            Close
          </Button>

          <Button variant="primary" onClick={handleSaveCuisine}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const renderCuisineData = () => {
    return (
      <div className="row">
        <div className="col-md-4">
          <h6 className="greencolor">General Cuisines</h6>
          <div>
            {cuisineCategoriess
              .filter((item) =>
                item.name.toLowerCase().includes(searchCuisine.toLowerCase())
              )
              .filter((items) => items.category == "general cuisines")
              .map((value, index) => {
                return (
                  <>
                    <div className="w-100" key={index}>
                      <label>
                        <input
                          type="checkbox"
                          name="cuisineModal"
                          value={value._id}
                          {...registerCuisine("cuisineModal", {
                            required: true,
                          })}
                        />
                        &nbsp;{upperCase(value.name)}
                      </label>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
        <div className="col-md-4">
          <h6 className="greencolor">Indian Cuisines</h6>
          <div>
            {cuisineCategoriess
              .filter((item) =>
                item.name.toLowerCase().includes(searchCuisine.toLowerCase())
              )
              .filter((items) => items.category == "indian cuisines")
              .map((value, index) => {
                return (
                  <>
                    <div className="w-100" key={index}>
                      <label>
                        <input
                          type="checkbox"
                          name="cuisineModal"
                          value={value._id}
                          {...registerCuisine("cuisineModal", {
                            required: true,
                          })}
                        />
                        &nbsp;{upperCase(value.name)}
                      </label>
                    </div>
                  </>
                );
              })}
          </div>
        </div>

        <div className="col-md-4">
          <h6 className="greencolor">International Cuisines</h6>
          <div>
            {cuisineCategoriess
              .filter((item) =>
                item.name.toLowerCase().includes(searchCuisine.toLowerCase())
              )
              .filter((items) => items.category == "international cuisines")
              .map((value, index) => {
                return (
                  <>
                    <div className="w-100" key={index}>
                      <label>
                        <input
                          type="checkbox"
                          name="cuisineModal"
                          value={value._id}
                          {...registerCuisine("cuisineModal", {
                            required: true,
                          })}
                        />
                        &nbsp;{upperCase(value.name)}
                      </label>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
        <div className="col-md-4">
          <h6 className="greencolor">European Cuisines</h6>
          <div>
            {cuisineCategoriess
              .filter((item) =>
                item.name.toLowerCase().includes(searchCuisine.toLowerCase())
              )
              .filter((items) => items.category == "european cuisines")
              .map((value, index) => {
                return (
                  <>
                    <div className="w-100" key={index}>
                      <label>
                        <input
                          type="checkbox"
                          name="cuisineModal"
                          value={value._id}
                          {...registerCuisine("cuisineModal", {
                            required: true,
                          })}
                        />
                        &nbsp;{upperCase(value.name)}
                      </label>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </div>
    );
  };

  const onSubmitCuisine = () => {};

  const onSubmit = (data) => {
    const isValid = validateFields();
    if (isValid) {
      localStorage.setItem("services", JSON.stringify(services));

      setLoader(true);
      dispatch(
        stepThreeSave({
          ...data,
          hoursData: hoursData,
          tax: services,
        })
      );
      dispatch(
        registerStepThree({
          ...data,
          outletId: outletId,
          tax: services,
          hoursData: hoursData,
          openingHoures: hoursData,
        })
      )
        .unwrap()
        .then((res) => {
          toast.success(res.message);
          handleNextStep(4);
        })
        .catch((err) => {
          setLoader(false);
          toast.error(err);
        });
    } else {
      toast.error("Please Fill Tax & Other Charges ");
    }
  };

  const handleAddService = () => {
    const newService = { title: "", amount: "", taxOn: "", applyOn: "" };
    setServices([...services, newService]);
  };

  const handleRemoveService = (index) => {
    const updatedServices = services.filter((service, i) => i !== index);
    setServices(updatedServices);
  };

  const handleServiceChange = (index, field, value) => {
    const updatedServices = services.map((service, i) => {
      if (i === index) {
        return { ...service, [field]: value };
      }
      return service;
    });
    setServices(updatedServices);
  };

  return (
    <div className="ms-content-wrapper ms-auth">
      <div className="regi-section">
        <Row>
          <Col md={5}>
            <div className="ms-auth-bg">
              <img src={loginLogo} />
            </div>
          </Col>
          <Col md={7}>
            <div className="regi-form three-staps">
              <form
                className="needs-validation"
                onSubmit={handleSubmit(onSubmit)}
                style={{ width: "100%" }}
              >
                <h3>
                  Registration : Step <span>{nextStep} of 5</span>
                </h3>
                <div className="" style={{ marginTop: "20px" }}>
                  <div className=" ">
                    <h6>
                      What type of cuisine does this place serve?
                      <span className="error">*</span>
                    </h6>
                    {/* <span className="text-muted">Select 10 max</span> */}
                    <br />
                    <button
                      className="cuisines-btn steps-btn float-none mt-4"
                      style={{ borderRadius: "20px" }}
                      type="button"
                      onClick={handleShowCuisine}
                    >
                      {getValues("cuisine")?.length > 0
                        ? "Edit cuisines"
                        : "Add cuisines"}
                    </button>
                    {/* cuisine modal */}
                    {renderCuisineModal()}
                    <input
                      type="hidden"
                      {...register("cuisine", { required: true })}
                    />
                    {errors?.cuisine?.type === "required" && (
                      <div className="error">
                        Please Select atleast one cuisine.
                      </div>
                    )}
                    <div
                      style={{
                        // display: "flex",
                        flexDirection: "row",
                        // flexWrap: "wrap",
                        justifyContent: "space-between",
                        marginTop: "20px",
                      }}
                    >
                      {getValues("cuisine")?.length > 0 ? (
                        <div className="row">
                          {getValues("cuisine").map((value, index) => {
                            return value ? (
                              <div className="col-md-4" key={index}>
                                <span
                                  style={{
                                    backgroundColor: "#f5f5f5",
                                    borderRadius: "20px",
                                    padding: "5px",
                                    margin: "5px",
                                    fontSize: "1.0rem",
                                    fontWeight: "bold",
                                    color: "#000",
                                  }}
                                >
                                  {cuisineCategoriess.filter(
                                    (item) => item._id === value
                                  )[0]?.name
                                    ? upperCase(
                                        cuisineCategoriess.filter(
                                          (item) => item._id === value
                                        )[0]?.name
                                      )
                                    : ""}
                                </span>
                              </div>
                            ) : (
                              ""
                            );
                          })}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-row" style={{ marginTop: "20px" }}>
                  <div className="col-md-12 ">
                    <h6>What hours is this place open?</h6>
                    <button
                      className="btn btn-outline-primary font-weight-bold"
                      style={{ borderRadius: "20px" }}
                      type="button"
                      onClick={handleShowHours}
                    >
                      Add open hours
                    </button>
                    {/* open hours modal */}
                    {renderOpenHoursModal()}
                  </div>
                </div>
                <div
                  style={{
                    // display: "flex",
                    flexDirection: "row",
                    // flexWrap: "wrap",
                    justifyContent: "space-between",
                    marginTop: "20px",
                  }}
                >
                  {hoursData?.length > 0 && (
                    <div className="row">
                      {/* {hoursData?.map((value, index) => {
                        return value.fullDay ||
                          (value.fromTime && value.toTime) ? (
                          <div className="col-md-4" key={index}>
                            <span
                              style={{
                                backgroundColor: "#f5f5f5",
                                borderRadius: "20px",
                                padding: "5px",
                                margin: "5px",
                                fontSize: "1.0rem",
                                fontWeight: "bold",
                                color: "#000",
                              }}
                            >
                              <br />
                              {value.day}
                              <br />
                              {value.fromTime !== ""
                                ? `( ${value.fromTime} to ${value.toTime} )`
                                : ""}
                            </span>
                          </div>
                        ) : (
                          ""
                        );
                      })} */}

                      <div className="col-md-12">
                        <table className="table">
                          <thead>
                            <tr>
                              {hoursData.map((value, index) => (
                                <th key={index}>{value.day}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              {hoursData.map((value, index) => (
                                <td key={index}>
                                  {value.fromTime && value.toTime ? (
                                    <span>
                                      {value.fromTime} to {value.toTime}
                                    </span>
                                  ) : (
                                    <span>Select Time</span>
                                  )}
                                </td>
                              ))}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
                <div className="form-row" style={{ marginTop: "20px" }}>
                  <div className="col-md-12 ">
                    <h6>
                      What meals does this restaurant serve?
                      <span className="error">*</span>
                    </h6>
                    <div className="row">{renderMeals()}</div>

                    {errors?.meals?.type === "required" && (
                      <div className="error">Atleast one meal is required.</div>
                    )}
                  </div>
                </div>
                <div className="form-row" style={{ marginTop: "20px" }}>
                  <div className="col-md-12 ">
                    <h6>
                      Payment method<span className="error">*</span>
                    </h6>
                    <div className="row">{renderPayment()}</div>
                    {errors?.payment?.type === "required" && (
                      <div className="error">
                        Atleast one payment method is required.
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-row" style={{ marginTop: "20px" }}>
                  <div className="col-md-12 ">
                    <h6>
                      Parking<span className="error">*</span>
                    </h6>
                    <div className="row">{renderParking()}</div>
                    {errors?.parking?.type === "required" && (
                      <div className="error">
                        Atleast one parking selection is required.
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-row" style={{ marginTop: "20px" }}>
                  <div className="col-md-12 ">
                    <h6>
                      Themes<span className="error">*</span>
                    </h6>
                    <div className="row">{renderGoodFor()}</div>
                    {errors?.themes?.type === "required" && (
                      <div className="error">
                        Atleast one theme selection is selected.
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-row" style={{ marginTop: "20px" }}>
                  <div className="col-md-12 ">
                    <h6>Other Attractions</h6>
                    <div className="row">{renderOtherAttractions()}</div>
                  </div>
                </div>

                <div className="form-row" style={{ marginTop: "20px" }}>
                  <div className="col-md-12 ">
                    <h6>Other Facilities</h6>
                    <div className="row">{renderOtherFacilites()}</div>
                  </div>
                  <div className="w-25 border-class">
                    <span className="text-color-red">
                      Upon Selecting the Delivery Option, Outlet choose to offer
                      the Food to be Delivered to the guests at their door step
                      on the given address without any Extra Cost/Charges.
                      Sortyfy does not take any responisbility for arranging any
                      kind of delivery services.
                    </span>
                  </div>
                </div>
                <div className="form-row" style={{ marginTop: "20px" }}>
                  <div className="col-md-12 ">
                    <h6>More Facilities</h6>
                    <div className="row">{renderMoreFacilities()}</div>
                  </div>
                </div>

                <div className="form-row" style={{ marginTop: "20px" }}>
                  <div className="col-md-12">
                    <h2 className="text-center">Tax & Other Charges</h2>
                    <div className="row">
                      <div className="col-md-12 mb-5">
                        <div className=" border-class">
                          <span className="text-color-red">
                            Please mention here all the applicable taxes applied
                            on the final bills. The Final bills will be
                            calculated and prepared as per the Tax calculation
                            mentioned here. Please make sure the Tax percentages
                            are mentioned accurately as it is applicable in your
                            state and city. Sortyfy holds no responsibility of
                            any discrepancies in the taxes. Please note that
                            this is a mandatory field. Only Government /
                            legitimate Applicable taxes should be mentioned.
                          </span>
                        </div>
                      </div>

                      <div className="container">
                        {services.map((service, index) => (
                          <div key={index} className="row mb-3">
                            <div className="col-md-2">
                              <label className="form-label">
                                Select Services
                              </label>
                              <div className="dropdown-group form-vendor-regi">
                                <select
                                  className={`form-control ${
                                    errorss.includes(index) &&
                                    !service.title &&
                                    "is-invalid"
                                  }`}
                                  value={service.title}
                                  onChange={(e) =>
                                    handleServiceChange(
                                      index,
                                      "title",
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value={""}>Please Select</option>
                                  <option value={"Gst"}>GST</option>
                                  <option value={"Igst"}>IGST</option>
                                  <option value={"Cgst"}>CGST</option>
                                  <option value={"Sgst"}>SGST</option>
                                  <option value={"Vat"}>VAT</option>
                                  <option value={"Service Tax"}>
                                    Service Tax
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <label className="form-label">
                                Enter Value (%)
                              </label>
                              <div className="dropdown-group form-vendor-regi">
                                <input
                                  className={`form-control ${
                                    errorss.includes(index) &&
                                    !service.amount &&
                                    "is-invalid"
                                  }`}
                                  type="text"
                                  value={service.amount}
                                  onChange={(e) =>
                                    handleServiceChange(
                                      index,
                                      "amount",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-2">
                              <label className="form-label">Select Type</label>
                              <div className="dropdown-group form-vendor-regi">
                                <select
                                  className={`form-control ${
                                    errorss.includes(index) &&
                                    !service.taxOn &&
                                    "is-invalid"
                                  }`}
                                  // className="form-control"
                                  value={service.taxOn}
                                  onChange={(e) =>
                                    handleServiceChange(
                                      index,
                                      "taxOn",
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value={""}>Please Select</option>
                                  <option value="food">Food/Beverage</option>
                                  <option value="beverage">Alc. Beverage</option> 
                                  <option value="both">both</option>  
                                </select>
                              </div>
                            </div>
                            {/* <div className="col-md-2">
                              <label className="form-label">Select Discount Type</label>
                              <div className="dropdown-group form-vendor-regi">
                                <select
                                  className={`form-control ${errorss.includes(index) && !service.applyOn && 'is-invalid'}`}

                                  value={service.applyOn}
                                  onChange={(e) => handleServiceChange(index, 'applyOn', e.target.value)}
                                >
                                  <option value={''}>Please Select</option>
                                  <option value={'after discount'}>After Discount</option>
                                  <option value={'before discount'}>Before Discount</option>
                                </select>
                              </div>
                            </div> */}
                            {index === services.length - 1 && (
                              <div className="col-md-2">
                                <label className="form-label mt-3"></label>
                                <div className="dropdown-group form-vendor-regi">
                                  <button
                                    className="steps-btn float-right"
                                    type="button"
                                    onClick={handleAddService}
                                  >
                                    Add More
                                  </button>
                                </div>
                              </div>
                            )}
                            {index !== 0 && (
                              <div className="col-md-2">
                                <label className="form-label mt-3"></label>
                                <div className="dropdown-group form-vendor-regi">
                                  <button
                                    className="steps-btn float-right"
                                    type="button"
                                    onClick={() => handleRemoveService(index)}
                                  >
                                    Remove
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-6">
                    <button
                      className="steps-btn float-left"
                      type="button"
                      onClick={() => handleNextStep(2)}
                    >
                      <i>&#8592;</i> Previous
                    </button>
                  </div>
                  <div className="col-md-6">
                    {loader ? (
                      <center>
                        <div
                          style={{
                            flex: 1,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Spinner
                            animation="border"
                            className="d-flex justify-content-center"
                          />
                        </div>
                      </center>
                    ) : (
                      <button
                        className="steps-btn"
                        type="submit"
                        onClick={() => setTouchedError(true)}
                      >
                        Next<i>&#8594;</i>
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default StepThree;
