// import React, { useEffect, useState } from 'react';
// import Chart from 'react-apexcharts';

// const StackedLineAreaColumnChart1 = ({ data }) => {

//     const [columns, setColumn] = useState([])
//     const [area, setArea] = useState([])
//     const [paxdata, setPax] = useState([])
//     const [labels,setLables] = useState([])
//     useEffect(() => {
//         if (data) {
//             const columnData = [];
//             const areaData = [];
//             const lineData = [];

//             // Iterate over each object in the array
//             data.forEach(item => {
//                 // Extract data for each type based on conditions
//                 columnData.push(item.booking);
//                 areaData.push(item.revenue);
//                 lineData.push(item.pax);
//             })
//             setColumn(columnData)
//             setArea(areaData)
//             setPax(lineData)
            
//             let s  = data?.map((val)=>{
//                 return  val.dateOfBooking
//             })
//             setLables(s)

//         }

//     }, [data])
  
//     const options = {
//         series: [
           
//             {
//                 name: 'BOOKING',
//                 type: 'line',
//                 data: columns,
        
//             },
          
//             {
//                 name: 'PAX',
//                 type: 'line',
//                 data: paxdata,
//             }
//         ],
//         chart: {
//             height: 350,
//             type: 'line',
//             stacked: false,
//         },
//         stroke: {
//             width: [0, 2, 5],
//             curve: 'smooth',
//         },
//         plotOptions: {
//             bar: {
//                 columnWidth: '50%',
//             },
//         },
//         fill: {
//             opacity: [0.85, 0.25, 1],
//             gradient: {
//                 inverseColors: false,
//                 shade: 'light',
//                 type: 'vertical',
//                 opacityFrom: 0.85,
//                 opacityTo: 0.55,
//                 stops: [0, 100, 100, 100],
//             },
//         },
//         labels: labels,
//         markers: {
//             size: 0,
//         },
//         xaxis: {
//             // type: ',
//         },
//         yaxis: {
//             title: {
//                 text: 'Points',
//             },
//             min: 0,
//         },
//         tooltip: {
//             shared: true,
//             intersect: false,
//             y: {
//                 formatter: function (y) {
//                     if (typeof y !== 'undefined') {
//                         return y.toFixed(0) + ' points';
//                     }
//                     return y;
//                 },
//             },
//         },
//     };

//     return <Chart options={options} series={options.series} type="line" height={350} />;
// };

// export default StackedLineAreaColumnChart1;

import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

const StackedLineAreaColumnChart = ({ data }) => {

    const [columns, setColumn] = useState([])
    const [area, setArea] = useState([])
    const [paxdata, setPax] = useState([])
    const [labels,setLables] = useState([])
    useEffect(() => {
        if (data) {
            const columnData = [];
            const areaData = [];
            const lineData = [];

            // Iterate over each object in the array
            data.forEach(item => {
                // Extract data for each type based on conditions
                columnData.push(item.booking);
                areaData.push(Number(item.revenue));
                lineData.push(item.pax);
            })
            setColumn(columnData)
            setArea(areaData)
            setPax(lineData)
            
            let s  = data?.map((val)=>{
                return  val.dateOfBooking
            })
            setLables(s)

        }

    }, [data])
  
  
    var options = {
        chart: {
          type: "line"
        },
        
      
        xaxis: {

           
            categories: labels ? labels : [],
          
        }
      };
      var series = [
        
        {
            name: 'BOOKING',
            
            data: columns,
    
        },
      
        {
            name: 'PAX',
           
            data: paxdata,
        }
      ];

    return <Chart options={options} series={series}  type="line" height={350} />;
};

export default StackedLineAreaColumnChart;
