import React, { useState, useEffect } from 'react';

const Preloader = () => {
    const [fetchSuccess, setFetchSuccess] = useState(false);

    useEffect(() => {
        const handleLoad = () => {
            setFetchSuccess(true);
        };

        window.addEventListener('load', handleLoad);

        // Cleanup function to remove the event listener
        return () => {
            window.removeEventListener('load', handleLoad);
        };
    }, []); // Empty dependency array ensures that this effect only runs once after the initial render

    const preloaderclass = fetchSuccess ? 'loaded' : '';

    return (
        <div id="preloader-wrap" className={preloaderclass}>
            <div className="spinner spinner-8">
                {[...Array(12)].map((_, index) => (
                    <div key={index} className={`ms-circle${index + 1} ms-child`} />
                ))}
            </div>
        </div>
    );
};

export default Preloader;
