import React, { useEffect, useRef, useState } from "react";
import { upperCase } from "../../../constants";
import ReactPaginate from "react-paginate";
import useRequest from "../../../hooks/useRequest";
import Breadcrumb from "./Breadcrumb";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Documents = (props) => {
  const history = useHistory();
  const {
    request: requestSettingsDocuments,
    response: responseSettingsDocuments,
  } = useRequest();
  //Pagination
  const itemsPerPage = 10;
  const [currentItems, setCurrentItems] = useState([]);
  const tableRef = useRef(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  const [allOrders, setAllOrders] = useState([]);
  const [orders, setOrders] = useState();

  useEffect(() => {
    requestSettingsDocuments(
      "GET",
      `setting/getVendorDocument?vendorId=${localStorage.getItem("vendorId")}`
    );
  }, []);

  useEffect(() => {
    if (responseSettingsDocuments) {
      setOrders(responseSettingsDocuments);

      if (responseSettingsDocuments?.data?.length) {
        setAllOrders(responseSettingsDocuments?.data);
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(
          responseSettingsDocuments?.data?.slice(itemOffset, endOffset)
        );
        setPageCount(
          Math.ceil(responseSettingsDocuments?.data?.length / itemsPerPage)
        );
      } else {
        setCurrentItems([]);
        setPageCount(0);
      }
    }
  }, [responseSettingsDocuments, itemOffset, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % orders?.data?.length;
    setItemOffset(newOffset);
  };

  const handleItemClick = (item) => {
    history.push({
      pathname: `/settings/document-detail/${item?.number}`,
      state: {
        ...item,
      },
    });
  };

  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb title="Documents" links={[{ to: "/", name: "Home" }]} />
          {/* <Favorder/> */}
          <div className="col-12">
            <div className="ms-panel">
              <div className="ms-panel-header">
                <h6> Documents </h6>
              </div>
              <div className="ms-panel-body">
                <div className="table-responsive">
                  <table
                    className="table table-hover thead-primary"
                    ref={tableRef}
                  >
                    <thead>
                      <tr>
                        <th scope="col">Sr.</th>
                        <th scope="col">Name</th>
                        <th scope="col">Number</th>
                        <th scope="col">Key</th>
                        <th scope="col">Document</th>
                        <th scope="col">Is Verified</th>
                        <th scope="col">Edit</th>
                        {/* <th scope="col">Price</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems ? (
                        currentItems.map((order, index) => {
                          return (
                            <tr key={index}>
                              <td scope="row">
                                {orders?.data?.indexOf(order) + 1}
                              </td>
                              <td>{order.name}</td>
                              <td>{order.number}</td>
                              <td>{order.key}</td>
                              <td>
                               <a href={order.src} target="_blank" >

                               <img
                                src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGAA4Wd4bco5Xv33GasXrnDdQT5OFXwa3HUQ&s'}
                                  // src={order.src}
                                  height={50}
                                  width={50}
                                  alt="Img"
                                />
                               </a>
                              </td>
                              <td>
                                <span
                                  className={
                                    order?.isVerified
                                      ? "badge badge-success"
                                      : "badge badge-primary"
                                  }
                                >
                                  {upperCase(
                                    order?.isVerified ? "true" : "false"
                                  )}
                                </span>
                              </td>
                              <td>
                                <button
                                  type="button"
                                  onClick={() => {
                                    handleItemClick(order);
                                  }}
                                  className="ms-btn-icon-outline btn-square btn-info ml-2"
                                >
                                  <i className="flaticon-information mr-0"></i>
                                </button>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="7">No Documents Found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {currentItems && (
                    <ReactPaginate
                      nextLabel="next >"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={2}
                      pageCount={pageCount}
                      previousLabel="< previous"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      containerClassName="pagination"
                      activeClassName="active"
                      renderOnZeroPageCount={null}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Documents;
