import React, { useEffect, useRef, useState } from "react";
import "datatables.net-bs4/js/dataTables.bootstrap4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { upperCase } from "../../../constants";
import { getAllOutlets } from "../../../redux/slices/Outlet";
import { useDispatch, useSelector } from "react-redux";
import { getBillingList } from "../../../redux/slices/Finance";
import { Col, Container, Row } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { downloadExcel, downloadExcel1 } from "../../../util/fn";
import Breadcrumb from "../../Breadcrumb";
import { FaUpload } from "react-icons/fa6";
import useRequest from "../../../hooks/useRequest";
import axios from "axios";
import { BASE_URL } from "../../../redux/services/apiPath";
import { toast } from "react-toastify";
import CustomFileUpload from "./CustomFileUpload";

const Billing = () => {
  const { request: requestReport, response: responseReport } = useRequest(true);
  const { request: requestReportOther, response: responseReportOther } =
    useRequest(true);
  const { billingList, billingData } = useSelector((state) => state.finance);
  const dispatch = useDispatch();

  const itemsPerPage = 10;
  const [overallData, setOverallData] = useState({
    totalBooking: 0,
    totalPerson: 0,
    totalRevenue: 0,
  });
  const [currentItems, setCurrentItems] = useState(null);
  const [currentItems1, setCurrentItems1] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageCount1, setPageCount1] = useState(0);
  const [page, setPage] = useState(1);
  const [page1, setPage1] = useState(1);
  const [itemOffset, setItemOffset] = useState(0);

  // const handlePageClick = (event) => {
  //   const newOffset = (event.selected * itemsPerPage) % billingList.length;
  //   setItemOffset(newOffset);
  // };
  const handlePageClick = (event) => {
    console.log("event.selected", event.selected + 1);
    setPage(event.selected + 1);
    // const newOffset = (event.selected * itemsPerPage) % menuList?.data.length;
    // setItemOffset(newOffset);
  };

  const handlePageClick1 = (event) => {
    console.log("event.selected", event.selected + 1);
    setPage1(event.selected + 1);
    // const newOffset = (event.selected * itemsPerPage) % menuList?.data.length;
    // setItemOffset(newOffset);
  };
  const tableRef = useRef(null);

  const [selectedOutlet, setSelectedOutlet] = useState({
    outletId: "",
    startDate: "",
    endDate: "",
  });
  const [token, setToken] = useState(localStorage.getItem("user"));

  const [selectedOutletId, setSelectedOutletId] = useState(
    localStorage.getItem("outletId")
  );
  const [minEndDate, setMinEndDate] = useState();
  const [maxStartDate, setMaxStartDate] = useState();
  const [modalShow, setModalShow] = React.useState(false);
  const [reportId, setReportId] = React.useState("");

  const { outletList } = useSelector((state) => state.outlet);
  useEffect(() => {
    dispatch(getAllOutlets({ vendorId: localStorage.getItem("vendorId") }));
    if (localStorage.getItem("vendorType") === "single") {
      requestReport("POST", "report/financeReportTesting", {
        outletId: localStorage.getItem("outletId"),
      });
      requestReportOther("POST", "report/financeReportOther", {
        outletId: localStorage.getItem("outletId"),
      });
    }
  }, [dispatch, modalShow]);

  const filterData = (e) => {
    setSelectedOutletId(e.target.value);
    if (e.target.value == "") {
      setOverallData({ totalBooking: 0, totalPerson: 0, totalRevenue: 0 });
      setCurrentItems(null);
    } else {
      if (e.target.name === "outletId") {
        setSelectedOutlet({
          ...selectedOutlet,
          outletId: e.target.value,
        });

        const startDate = document.getElementById("startDate").value;
        const endDate = document.getElementById("endDate").value;
        const status = "";

        requestReportOther("POST", "report/financeReportOther", {
          status,
          startDate,
          endDate,
          outletId: e.target.value,
          page: page1,
          limit: itemsPerPage,
        });
        // requestReport("POST", "report/financeReport", {
        requestReport("POST", "report/financeReportTesting", {
          status,
          startDate,
          endDate,
          outletId: e.target.value,
          page,
          limit: itemsPerPage,
        });

        // requestReportOther("POST", "report/financeReportOther", { outletId: localStorage.getItem("outletId") })

        // dispatch(
        //   getBillingList({
        //     status,
        //     startDate,
        //     endDate,
        //     outletId: e.target.value,
        //   })
        // ).then((res) => {
        //   paginateEffect(res.payload.data);
        // });
      }
    }
  };

  const downloadExcelHandler = () => {
    let data = [];

    if ((currentItems && currentItems.length > 0) || currentItems1?.length) {
      let firstTable = currentItems?.map((obj, index) => ({
        "Sr.": index + 1,
        orderId: obj?.orderId,
        userName: obj?.userName,
        guestName: obj?.guestName,
        bookingMadeOn: obj?.bookingMadeOn,
        dateOfBooking: obj?.dateOfBooking,
        numberOfGuest: obj?.numberOfGuest,
        bookedFor: obj?.bookedFor,
        tableAmount: obj?.tableAmount,
        totalBilledAmount: obj?.totalBilledAmount,
        billCopyShared: obj?.billCopyShared,
        outletDiscount: obj?.outletDiscount,
        sortyfyDiscount: obj?.sortyfyDiscount,
        paymentDoneByGuestAtOutlet: obj?.paymentDoneByGuestAtOutlet,
        tax: obj?.tax,
        taxAmount: obj?.taxAmount,
        netAmountBeforeTax: obj?.netAmountBeforeTax,
        paidThrough: obj?.paidThrough,
        paymentClearedAt: obj?.paymentClearedAt,
        outletPaymentStatus: obj?.outletPaymentStatus,
        sortyfyCommission: obj?.sortyfyCommission,
        commissionAmount: obj?.commissionAmount,
        tableAmountForfitted: obj?.tableAmountForfitted,
        paymentMadeToOutletFromSortyfy: obj?.paymentMadeToOutletFromSortyfy,
        commissionStatus: obj?.commissionStatus,
        commissionPaymentDetails: obj?.commissionPaymentDetails,
      }));

      let secondTable = currentItems1?.map((report, index) => ({
        "Sr.": index + 1,
        orderId: report?.orderId,
        userName: report?.userName,
        guestName: report?.guestName,
        bookingMadeOn: report?.bookingMadeOn,
        dateOfBooking: report?.dateOfBooking,
        numberOfGuest: report?.numberOfGuest,
        bookedFor: report?.bookedFor,
        tableAmount: report?.tableAmount,
        totalBilledAmount: report?.totalBilledAmount,
        billCopyShared: report?.billCopyShared,
        outletDiscount: report?.outletDiscount,
        sortyfyDiscount: report?.sortyfyDiscount,
        paymentDoneByGuestAtOutlet: report?.paymentDoneByGuestAtOutlet,
        tax: report?.tax,
        taxAmount: report?.taxAmount,
        netAmountBeforeTax: report?.netAmountBeforeTax,
        paidThrough: report?.paidThrough,
        paymentClearedAt: report?.paymentClearedAt,
        outletPaymentStatus: report?.outletPaymentStatus,
        sortyfyCommission: report?.sortyfyCommission,
        commissionAmount: report?.commissionAmount,
        tableAmountForfitted: report?.tableAmountForfitted,
        commissionPaymentToSortyfyFromOutlet:
          report?.commissionPaymentToSortyfyFromOutlet,
        commissionStatus: report?.commissionStatus,
        commissionPaymentDetails: report?.commissionPaymentDetails,
      }));

      const dataArray = [firstTable, secondTable];
      const namesArray = ["Payment GateWay", "Only Table & Direct Payment"];
      const headingsArray = [null, null]; // Assuming no headers for the data

      downloadExcel1(dataArray, namesArray, headingsArray);
    } else {
      toast.error("SomeThing Went wrong");
    }
    // downloadExcel(data, "Billing Reports");
  };

  const filterHandler = () => {
    const startDate = document.getElementById("startDate").value;
    const endDate = document.getElementById("endDate").value;
    const status = "";
    // const status = "";

    requestReport("POST", "report/financeReportTesting", {
      status,
      startDate,
      endDate,
      outletId: selectedOutletId,
      page,
      limit: itemsPerPage,
    });
    requestReportOther("POST", "report/financeReportOther", {
      status,
      startDate,
      endDate,
      outletId: selectedOutletId,
      page: page1,
      limit: itemsPerPage,
    });

    // dispatch(
    //   getBillingList({
    //     status,
    //     startDate,
    //     endDate,
    //     outletId: selectedOutletId,
    //   })
    // ).then((res) => {
    //   paginateEffect(res.payload.data);
    // });
  };

  useEffect(() => {
    filterHandler();
  }, [page, page1]);
  const handleStartDate = (e) => {
    if (e.target.value) {
      setMinEndDate(e.target.value);
    } else {
      setMinEndDate("");
    }
  };

  const handleEndDate = (e) => {
    if (e.target.value) {
      setMaxStartDate(e.target.value);
    } else {
      setMaxStartDate("");
    }
  };

  useEffect(() => {
    if (responseReportOther) {
      setCurrentItems1([]);
      if (responseReportOther.status == "success") {
        setCurrentItems1(responseReportOther?.data);
        setPageCount1(responseReportOther?.totalPage);
      }
    }
  }, [responseReportOther]);

  useEffect(() => {
    if (responseReport) {
      if (responseReport.status || responseReport.status == "success") {
        setOverallData({
          ...overallData,
          totalBooking: responseReport?.totalBooking ?? 0,
          totalPerson: responseReport?.totalPerson ?? 0,
          totalRevenue: responseReport?.totalRevenue ?? 0,
        });
        setCurrentItems(responseReport?.data);
        setPageCount(responseReport?.totalPage);
      }
    }
  }, [responseReport]);

  // const paginateEffect = (rep) => {
  //   if (billingList.length) {
  //     const endOffset = itemOffset + itemsPerPage;
  //     console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  //     setCurrentItems(rep.slice(itemOffset, endOffset));
  //     setPageCount(Math.ceil(billingList.length / itemsPerPage));
  //   }
  // };

  useEffect(() => {}, []);

  return (
    <>
      <div className="ms-content-wrapper">
        <Breadcrumb
          title="Finance Reports"
          links={[{ to: "/", name: "Home" }]}
        />
        <div className="row">
          <div className="col-md-12">
            <div className="ms-panel">
              <div className="ms-panel-header">
                {/* <div className="date-filter"> */}
                <div className="">
                  <Row>
                    <Col md={6}>
                      <div>
                        <h6>Finance Reports</h6>
                      </div>
                    </Col>
                    <Col md={6}>
                      {localStorage.getItem("vendorType") === "multiple" && (
                        <div
                          className="outletDropdown"
                          style={{
                            padding: "0 30px 0",
                            display: "inline-block",
                            width: "100%",
                          }}
                        >
                          <div className="float-right d-flex">
                            <label
                              htmlFor="validationCustom01"
                              className="form-label mr-3 mt-2"
                            >
                              Select Outlet
                            </label>
                            <div className="dropdown-group form-vendor-regi">
                              <select
                                className="form-control"
                                id="validationCustom01"
                                name="outletId"
                                onChange={filterData}
                              >
                                <option value={""}>Please Select</option>
                                {outletList.length ? (
                                  outletList.map((item) => {
                                    return (
                                      <option value={item.outletId}>
                                        {upperCase(item.outletName)}
                                      </option>
                                    );
                                  })
                                ) : (
                                  <option value={0}> Not found</option>
                                )}
                              </select>
                              {/* <div className="invalid-feedback">
                            Please Select a valid Designation.
                          </div> */}
                            </div>
                          </div>
                        </div>
                      )}
                    </Col>
                    <Col md={3}>
                      <div className="startDate">
                        {/* date picker input */}
                        <label
                          htmlFor="validationCustom01"
                          className="form-label"
                        >
                          Start Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="startDate"
                          name="startDate"
                          placeholder="Start Date"
                          max={maxStartDate}
                          onChange={handleStartDate}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="endDate">
                        <label
                          htmlFor="validationCustom01"
                          className="form-label"
                        >
                          End Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="endDate"
                          name="endDate"
                          placeholder="End Date"
                          min={minEndDate}
                          onChange={handleEndDate}
                          // onChange={filterData}
                        />
                      </div>
                    </Col>
                    {/* <Col md={2}>
                      <div className="endDate">
                        <label htmlFor="status" className="form-label">
                          Status
                        </label>
                        <select
                          className="form-control"
                          id="status"
                          name="status"
                        // onChange={filterData}
                        >
                          <option value={""}>Please Select</option>
                          <option value={"accepted"}>Accepted</option>
                          <option value={"pending"}>Pending</option>
                          <option value={"decline"}>Decline</option>
                        </select>
                      </div>
                    </Col> */}
                    <Col md={2}>
                      <button
                        type="button"
                        onClick={filterHandler}
                        className="excle-dowm"
                      >
                        {" "}
                        Filter{" "}
                      </button>
                    </Col>
                    <Col md={2}>
                      <button
                        type="button"
                        onClick={downloadExcelHandler}
                        className="excle-dowm"
                      >
                        {" "}
                        Export excel{" "}
                      </button>
                    </Col>

                    <Col md={12}>
                      <div className="text-center border-classss mt-4">
                        <h5 className="mt-4">Payment Gateway</h5>
                      </div>

                      <div className="table-responsive mt-5">
                        <table
                          className="table table-hover thead-primary"
                          ref={tableRef}
                        >
                          <thead>
                            <tr>
                              <th scope="col">S.No</th>
                              <th scope="col">Booking ID</th>
                              <th scope="col">Username</th>
                              <th scope="col">Guest Name</th>
                              <th scope="col">Booking Made On</th>
                              <th scope="col">Date of Booking</th>
                              <th scope="col">Number of Guests</th>
                              <th scope="col">Booked For</th>
                              <th scope="col">Billing Amount</th>
                              <th scope="col">Outlet Discount</th>
                              <th scope="col">Sortyfy Discount</th>
                              <th scope="col">Billed Amount</th>
                              <th scope="col">Tax </th>
                              <th scope="col">Tax Amount</th>
                              <th scope="col">Final Amount After Tax</th>
                              <th scope="col">Reward Points Accrued</th>
                              <th scope="col">Reward Amount</th>
                              <th scope="col">Guest Payable Bill Amount</th>
                              <th scope="col">
                                Advanced Table Amount Adjusted
                              </th>
                              <th scope="col">Payment Done by Guest</th>
                              <th scope="col">Paid Through</th>
                              <th scope="col">Payment Cleared At</th>
                              <th scope="col">Outlet Payment Status</th>
                              <th scope="col">Sortyfy Commission</th>
                              <th scope="col">Commission Amount</th>
                              <th scope="col">Payment Gateway Charge</th>
                              <th scope="col">Payment Gateway Amount</th>
                              <th scope="col">
                                Payment Made to Outlet through Sortyfy
                              </th>
                              <th scope="col">No-Show</th>
                              <th scope="col">No-Show Amount</th>
                              <th scope="col">
                                Full & Final Payment Settlement
                              </th>
                              <th scope="col">Payment Status</th>
                              <th scope="col">Commission Payment Details</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentItems?.length ? (
                              currentItems.map((report, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{report?.bookingNumber}</td>
                                    <td>{report?.userName}</td>
                                    <td>{report?.guestName}</td>
                                    <td>{report?.bookingMadeOn}</td>
                                    <td>{report?.dateOfBooking}</td>
                                    <td>{report?.numberOfGuest}</td>
                                    <td>{report?.bookedFor}</td>
                                    <td>{report?.billingAmount}</td>

                                    <td>
                                      {report?.outletDiscount > 0
                                        ? report?.outletDiscount
                                        : "0"}
                                    </td>
                                    <td>{report?.sortyfyDiscount}</td>
                                    <td>{report?.billedAmount}</td>
                                    <td>
                                      {report?.tax?.map((vals, i) => (
                                        <span key={i}>
                                          {`${vals?.title}: ${vals?.amount}`},
                                        </span>
                                      ))}
                                    </td>
                                    <td>{report?.taxAmount}</td>
                                    <td>{report?.finalAmountAfterTax}</td>
                                    <td>{report?.rewardPoint}</td>
                                    <td>{report?.rewardAmount}</td>
                                    <td>{report?.billedAmountAfterReward}</td>
                                    <td>{report?.advanceTableAmount}</td>
                                    <td>{report?.paymentDoneByGuest}</td>
                                    <td>{report?.paidThrough}</td>
                                    <td>{report?.paymentClearedAt}</td>
                                    <td>{report?.outletPaymentStatus}</td>
                                    <td>{report?.sortyfyCommission}</td>
                                    <td>{report?.commissionAmount}</td>
                                    <td>{report?.paymentGatewayCharge}</td>
                                    <td>{report?.paymentGatewayAmount}</td>
                                    <td>
                                      {report?.paymentMadeToOutletFromSortyfy}
                                    </td>
                                    <td>{report?.noShowPercent}</td>
                                    <td>{report?.noShowAmount}</td>
                                    <td>
                                      {report?.fullFinalPaymentSettlement}
                                    </td>
                                    <td>{report?.paymentStatus}</td>
                                    <td>{report?.commissionPaymentDetails}</td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td style={{ textAlign: "center" }} colSpan="7">
                                  No Reports Found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {currentItems?.length ? (
                          <ReactPaginate
                            nextLabel="next >"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            pageCount={pageCount}
                            previousLabel="< previous"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>

                    <Col md={12}>
                      <div className="text-center border-classss mt-4">
                        <h5 className="mt-4">Only Table & Direct Payment</h5>
                      </div>

                      <div className="table-responsive mt-5">
                        <table className="table table-hover thead-primary">
                          <thead>
                            <tr>
                              <th scope="col">S.No</th>
                              <th scope="col">Booking Number</th>
                              <th scope="col">User Name</th>
                              <th scope="col">Guest Name</th>
                              <th scope="col">Booking Made On</th>
                              <th scope="col">Date of Booking</th>
                              <th scope="col">Number of Guest</th>
                              <th scope="col">Booked For</th>
                              <th scope="col">Table Amount</th>
                              <th scope="col">Total Billed Amount</th>
                              <th scope="col">Bill Copy Shared</th>
                              <th scope="col">
                                Payment Done by Guest at Outlet
                              </th>
                              <th scope="col">Tax</th>
                              <th scope="col">Tax Amount</th>
                              <th scope="col">Net Amount Before Tax</th>
                              <th scope="col">Paid Through</th>
                              <th scope="col">Payment Cleared At</th>
                              <th scope="col">Outlet Payment Status</th>
                              <th scope="col">Sortyfy Commission</th>
                              <th scope="col">Commission Amount</th>
                              <th scope="col">Table Amount Forfitted</th>
                              <th scope="col">
                                Commission Payment Made to Sortyfy From Outlet
                              </th>
                              <th scope="col">Comission Status</th>
                              <th scope="col">Comission Payment Details</th>

                              <th scope="col">Upload Data</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentItems1?.length ? (
                              currentItems1?.map((report, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{report?.bookingNumber}</td>
                                    <td>{report?.userName}</td>
                                    <td>{report?.guestName}</td>
                                    <td>{report?.bookingMadeOn}</td>
                                    <td>{report?.dateOfBooking}</td>
                                    <td>{report?.numberOfGuest}</td>
                                    <td>{report?.bookedFor}</td>
                                    <td>{report?.tableAmount}</td>
                                    <td>{report?.totalBilledAmount}</td>
                                    <td>{report?.billCopyShared}</td>
                                    <td>
                                      {report?.paymentDoneByGuestAtOutlet}
                                    </td>

                                    <td>{report?.tax}</td>
                                    <td>{report?.taxAmount}</td>
                                    <td>{report?.netAmountBeforeTax}</td>
                                    <td>{report?.paidThrough}</td>
                                    <td>{report?.paymentClearedAt}</td>

                                    <td>{report?.outletPaymentStatus}</td>
                                    <td>{report?.sortyfyCommission}</td>
                                    <td>{report?.commissionAmount}</td>
                                    <td>{report?.tableAmountForfitted}</td>
                                    <td>
                                      {
                                        report?.commissionPaymentToSortyfyFromOutlet
                                      }
                                    </td>
                                    <td>{report?.commissionStatus}</td>
                                    <td>{report?.commissionPaymentDetails}</td>

                                    <td>
                                      {report?.billCopyShared == "No" && (
                                        <div
                                          className="cursor-pointer"
                                          onClick={() => {
                                            setReportId(report?.orderId);
                                            setModalShow(true);
                                          }}
                                        >
                                          <FaUpload />
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td style={{ textAlign: "center" }} colSpan="7">
                                  No Reports Found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {currentItems1?.length ? (
                          <ReactPaginate
                            nextLabel="next >"
                            onPageChange={handlePageClick1}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            pageCount={pageCount1}
                            previousLabel="< previous"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* 
                <Container>
                  <div className="dh-section mt-4">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                          <div className="ms-card-body media">
                            <div className="media-body">
                              <span className="black-text">
                                <strong>Total Person</strong>
                              </span>
                              <h2>{overallData.totalPerson}</h2>
                            </div>
                          </div>
                          <LineChart data={this.state.data1} options={options} />
                        </div>
                      </div>
                      <div className=" col-md-6">
                        <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                         
                          <div className="ms-card-body media">
                            <div className="media-body">
                              <span className="black-text">
                                <strong>Total Revenue</strong>
                              </span>
                              <h2>{overallData.totalRevenue}</h2>
                            </div>
                          </div>
                          <LineChart data={this.state.data2} options={options} />
                        </div>
                      </div>

                      <div className=" col-md-6">
                        <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">

                          <div className="ms-card-body media">
                            <div className="media-body">
                              <span className="black-text">
                                <strong>Total Booking</strong>
                              </span>
                              <h2>{overallData.totalBooking}</h2>
                            </div>
                          </div>
                          <LineChart data={this.state.data4} options={options} />
                        </div>
                      </div>
                    </div>
                  </div>
                </Container> */}
              </div>
            </div>
          </div>
        </div>
        <CustomFileUpload
          show={modalShow}
          selectedOutletId={selectedOutletId}
          reportId={reportId}
          setModalShow={setModalShow}
          onHide={() => setModalShow(false)}
        />
      </div>
    </>
  );
};

export default Billing;
