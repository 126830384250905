import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import useRequest from "../../../hooks/useRequest"

import {
  getOutletRating
} from "../../../redux/slices/Outlet";

const FeedbackChats = (props) => {

  const dispatch = useDispatch();
  const { request, response } = useRequest()
  const { outletRatings } = useSelector((state) => state.outlet);
  const [showReplyInput, setShowReplyInput] = useState(false)
  const [reviewId, setReviewId] = useState(outletRatings?.userReview[0]?.reviewId)
  const [outletId, setOutletId] = useState(props?.selectedOutlet?.outletId)
  const [toUserId, setUserId] = useState()
  const [UserReview, setUserReview] = useState("")
  const [toUserName, setToUserName] = useState()
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset
  } = useForm();

  const onSubmit = (data) => {
    const { replyMessage } = data;
    request("POST", "outlet/addUserReview", { outletId, reviewId, message: replyMessage, toUserId: toUserId })
  }

  const handleReply = (userId, username) => {
     console.log('usernameusernameusername',userId, username)
    const scrollHeight = document.getElementsByClassName('modal')[0].scrollHeight
    document.getElementsByClassName('modal-body')[0].scrollTo(0, scrollHeight);
    // window.scrollTo(0, document.getElementsByClassName('modal')[0].scrollHeight);
    setUserId(userId)
    setToUserName(username);
    setShowReplyInput(true)
  }

  useEffect(() => {
    if (response) {

      dispatch(
        getOutletRating({
          outletId: outletId,
        })
      ).then(() => {
        setShowReplyInput(false)
        setUserId()
        setToUserName()
        reset({ 'replyMessage': '' })
      }).catch((err) => {

      });
    }
    const user = outletRatings?.userReview?.filter((val, i) => {
      if (val.reviewId == props.index) {
        return val;
      }
    })
    setUserReview(user[0])
  }, [response])

  console.log('UserReview', UserReview)
  return (
    <Modal
      show={props.setReviewModal}
      onHide={props.handleShowReviewModal}
      style={{ height: "90vh", maxHeight: "90vh", overflow: "hidden" }}
      scrollable
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Review Responses</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        {UserReview
          && UserReview?.reviewResponse?.map((i) => {
            return (
              <div className="col-md-12">
                <div className="comment-section-sssss">
                  <div className="d-flex">
                    <div className="usere-img">
                      <img src={i.userImage} />
                    </div>
                    <div className="user-name">
                      <h4>{i.userName}</h4>
                      <p> <span style={{color:'red'}}>{i.toUserName && ('@' + i.toUserName)}</span> {i.userMessage}</p>
                      {outletId != i.userId && i.userId && (
                        <>
                           <Link to="#" onClick={() => handleReply(i.userId, i.userName)}>Reply</Link>
                        </>

                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        }


        {showReplyInput && (

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="rply-btn mt-3">
             <span
                style={{ marginInline: "20px" ,color:'red'}}
             
             > {toUserName && ('@' + toUserName)} </span>
              <input
                {...register("replyMessage", { required: true })} type="text"
                style={{ width: "75%" }}
                placeholder="Reply to comment"
                className="p-2 "
              />
              {errors.replyMessage?.type === "required" && (
                <div className="error">
                  Please enter review.
                </div>
              )}
              <button
                className="mt-0 excle-dowm"
                style={{
                  width: "150px",
                  marginRight: "40px"
                }}

                type="submit"
              >
                Reply
              </button>

            </div>
            {/* <input {...register("replyMessage", { required: true })} type="text"/>
              {errors.replyMessage?.type === "required" && (
                  <div className="error">
                      Please enter review.
                    </div>
                  )}
              <button type="submit">Submit</button> */}
          </form>
        )}


      </Modal.Body>
      {/* <Modal.Footer>
        <Button variant="secondary">Deactivate</Button>
        <Button variant="primary" onClick={() => props.handleShowReviewModal()}>
          Ok
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
};

export default FeedbackChats;

