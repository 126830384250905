import React from 'react';
import { API_URL } from '../../../util/constant';
// import { Carousel } from 'react-bootstrap'
// import { image_url } from '../../../redux/services/apiPath';

const Productslider = (props) => {
    
    return (
        props?.images?.length>0 && props?.images.map(
            (image, index) => {
                return (
                    props.isEdit === false ?
                            <img className="d-block w-100" src={`${image}`} alt="First slide" />
                            :
                            <img src={URL.createObjectURL(image)} alt="First slide" />
                    
                )
            }
        )
    
        // <Carousel id="imagesSlider" className="ms-image-slider carousel slide" data-ride="carousel" indicators={false}>

        //     {
        //         props.images.map(
        //             (image, index) => {
        //                 return (
        //                     <Carousel.Item>
        //                         {props.isEdit === false ?
        //                             <img className="d-block w-100" src={image} alt="First slide" />
        //                             :
        //                             <img src={URL.createObjectURL(image)} alt="First slide" />
        //                         }
        //                     </Carousel.Item>
        //                 )
        //             }
        //         )
        //     }
        // </Carousel>
    );
}


export default Productslider;