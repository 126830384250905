


// import React, { useState, useEffect } from "react";
// import { Bar as BarChart, HorizontalBar } from "react-chartjs-2";

// // Bar chart
// function chartData1(props) {
//     let labels = [];
//     let dataValues = [];

//     if (Array.isArray(props.data)) {
//         // For array of objects like [{ label: "Sharma Gujrati Corner ", value: "2.43" }]
//         labels = props.data.map(item => item.label);
//         dataValues = props.data.map(item => parseFloat(item.value));
//     } else if (typeof props.data === 'object') {
//         // For object like { execellemt: 1, good: 2 }
//         labels = Object.keys(props.data);
//         dataValues = Object.values(props.data).map(value => parseFloat(value));
//     }

//     return {
//         labels: labels,
//         datasets: [
//             {
//                 label: props.about,
//                 backgroundColor: "#3ea99f",
//                 data: dataValues,
//             },
//         ],
//     };
// }

// const NewBarChart = (props) => {
//     const [data1, setData1] = useState(chartData1(props));
//     const [values, setValues] = useState(props.data ? Object.values(props.data) : []);

//     useEffect(() => {
//         setData1(chartData1(props));
//         const datas = props.data?.reduce((acc, obj) => {
//             acc[obj.label] = parseFloat(obj.value);
//             return acc;
//         }, {});

//         console.log(datas);
//         const modifiedData = {};
//         Object.keys(datas).forEach(key => {
//             modifiedData[key.trim()] = datas[key];
//         });
//         setValues(modifiedData ? modifiedData : []);
//     }, [props]);



//     return (
//         <div>
//             {props.isHoriz ? (
//                 <HorizontalBar
//                     data={data1}
//                     options={{
//                         indexAxis: 'y',
//                         legend: { display: false },
//                         title: {
//                             display: true,
//                             text: props.about,
//                         },
//                     }}
//                 />
//             ) : (
//                 <BarChart
//                     data={data1}
//                     options={{
//                         indexAxis: 'y',
//                         legend: { display: false },
//                         title: {
//                             display: true,
//                             text: props.about,
//                         },
//                         scales: {
//                             yAxes: [{
//                                 ticks: {
//                                     max: Math.max(...values),
//                                     min: 0,
//                                 },
//                             }],
//                         },
//                     }}
//                 />
//             )}
//         </div>
//     );
// };

// export default NewBarChart;

import React, { useState, useEffect } from "react";
import { Bar as BarChart, HorizontalBar } from "react-chartjs-2";

function chartData1(props) {
    let labels = [];
    let dataValues = [];

    if (Array.isArray(props.data)) {
        labels = props.data.map(item => item.label);
        dataValues = props.data.map(item => parseFloat(item.value));
    } else if (typeof props.data === 'object') {
        labels = Object.keys(props.data);
        dataValues = Object.values(props.data).map(value => parseFloat(value));
    }

    return {
        labels: labels,
        datasets: [
            {
                label: props.about,
                backgroundColor: "#3ea99f",
                data: dataValues,
            },
        ],
    };
}

const NewBarChart = (props) => {
    const [data1, setData1] = useState(chartData1(props));
    const [values, setValues] = useState(props.data ? Object.values(props.data) : []);

    useEffect(() => {
        setData1(chartData1(props));
        const datas = props.data?.reduce((acc, obj) => {
            acc[obj.label] = parseFloat(obj.value);
            return acc;
        }, {});

        const modifiedData = {};
        Object.keys(datas).forEach(key => {
            modifiedData[key.trim()] = datas[key];
        });
        // console.log("data", modifiedData)

        setValues(modifiedData ? Object.values(modifiedData) : []);
    }, [props]);

    return (
        <div>
            {props.isHoriz ? (
                <HorizontalBar
                    data={data1}
                    options={{
                        indexAxis: 'y',
                        legend: { display: false },
                        title: {
                            display: true,
                            text: props.about,
                        },
                    }}
                />
            ) : (
                <BarChart
                    data={data1}
                    options={{
                        indexAxis: 'y',
                        legend: { display: false },
                        title: {
                            display: true,
                            text: props.about,
                        },
                        scales: {
                            yAxes: [{
                                ticks: {
                                    max: Math.max(...values),
                                    min: 0,
                                },
                            }],
                        },
                    }}
                />
            )}
        </div>
    );
};

export default NewBarChart;


