import React, { Component } from 'react';
import Sidenavigation from '../layouts/Sidenavigation';
import Topnavigation from '../layouts/Topnavigation';
import Content from '../sections/Defaultlogin/Content';
import Login from './Auth/Login';

class Defaultlogin extends Component {
    render() {
        return (
            <div className="ms-body ms-primary-theme ms-logged-out">
                <Sidenavigation />
                <main className="body-content">
                    {/* <Topnavigation /> */}
                    {/* <Content/> */}
                    <Login />
                </main>
            </div>
        );
    }
}

export default Defaultlogin;