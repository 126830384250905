import React, { useEffect, useState } from "react";
import StepOne from "./Outlet/StepOne";
import StepTwo from "./Outlet/StepTwo";
import StepThree from "./Outlet/StepThree";
import StepFour from "./Outlet/StepFour";
import { useDispatch } from "react-redux";
import { clearAllDetails } from "../redux/slices/SaveOutletDetails";
import { BASE_URL } from "../redux/services/apiPath";
import axios from "axios";
import UpdateCountModal from "../components/UpdateCountModal";

const AddOutlet = () => {
  const dispatch = useDispatch();
  const [token, setToken] = useState(localStorage.getItem("user"));

  const [nextStep, setNextStep] = useState(1);
  const [outletId, setOutletId] = useState();
  const [data, setData] = useState(false);
  const [modal, setModal] = useState(false);
  const [count, setCount] = useState();

  const handleNextStep = (val) => {
    setNextStep(val);
  };

  const handleOutletId = (val) => {
    setOutletId(val);
  };

  const getLimit = async () => {
    const param = {
      vendorId: localStorage.getItem("vendorId"),
    };
    const res = await axios.post(`${BASE_URL}outlet/isMoreAddOutlet`, param, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    if (res?.data?.status === "success") {
      setData(res?.data?.data?.addOutlet);
      setCount(res?.data?.data?.totalOutlet);
    }
  };
  useEffect(() => {
    getLimit();
  }, [modal]);

  console.log("param", data);

  const renderContent = () => {
    switch (nextStep) {
      case 1:
        return (
          <StepOne
            handleNextStep={handleNextStep}
            nextStep={nextStep}
            handleOutletId={handleOutletId}
            outletId={outletId}
          />
        );
      case 2:
        return (
          <StepTwo
            handleNextStep={handleNextStep}
            nextStep={nextStep}
            outletId={outletId}
          />
        );
      case 3:
        return (
          <StepThree
            handleNextStep={handleNextStep}
            nextStep={nextStep}
            outletId={outletId}
          />
        );
      case 4:
        return (
          <StepFour
            handleNextStep={handleNextStep}
            nextStep={nextStep}
            outletId={outletId}
          />
        );
      default:
        return;
    }
  };
  useEffect(() => {
    dispatch(clearAllDetails());
  }, [dispatch]);

  return (
    <>
      {data ? (
        renderContent()
      ) : (
        <div className="bg-clr  py-5">
          <center>
            <h2>
              {count} Max Outlet Added
              <button
                className="btn btn-primary mt-4 d-block w-25"
                onClick={() => {
                  setModal(true);
                }}
              >
                Update Max Outlet
              </button>
            </h2>
          </center>
        </div>
      )}
      <UpdateCountModal
        show={modal}
        onHide={() => {
          setModal(false);
        }}
        count={count}
      />
    </>
  );
};

export default AddOutlet;
