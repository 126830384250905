import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

const PipeChart = ({ data }) => {
    const [categories, setCategories] = useState([])
    const [values, setValues] = useState([])
    useEffect(() => {
        if (data) {
            setCategories(data.map(obj => obj.dateOfBooking))
            setValues(data.map(obj => obj.occupancy))
        }

    }, [data])
    const options = {
        chart: {
            id: 'line-chart',
        },
        xaxis: {
            categories: categories ? categories : [],
        },
    };

    const series = [
        {
            name: 'Series 1',
            data: values ? values : [],
        },
    ];

    return (
        <>

            <Chart
                options={options}
                series={series}
                type="bar"
                width="500"
            />
        </>
    );
};

export default PipeChart;
