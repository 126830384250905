import React, { Component } from "react";
import Bookings from "./Bookings";
import BusinessReports from "./BusinessReports";
import Revenue from "./Revenue";
import Trends from "./Trends";

class Reports extends Component {
    render() {
        return (
            <>
                    {window.location.pathname === "/reports/business-reports" ? (
                        <BusinessReports />
                    ) : window.location.pathname === "/reports/bookings" ? (
                        <Bookings />
                    ) : window.location.pathname === "/reports/revenue" ? (
                        <Revenue />
                    ) : window.location.pathname === "/reports/trends" ? (
                        <Trends />
                    ) : null
                    }
            </>
        );
    }
}

export default Reports;

