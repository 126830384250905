import axios from "axios";
import apiPath, { BASE_URL } from "./apiPath";
import authHeader from "./authHeader";

const registerOutletStepOneApi = (props) => {
    return axios.post(BASE_URL + apiPath.outletStepOne, props, authHeader());
}
const registerOutletStepTwoApi = (props) => {
    return axios.post(BASE_URL + apiPath.outletStepTwo, props, authHeader());
}
const registerOutletStepThreeApi = (props) => {
    return axios.post(BASE_URL + apiPath.outletStepThree, props, authHeader());
}
const registerOutletStepFourApi = (props) => {
    return axios.post(BASE_URL + apiPath.outletStepFour, props, authHeader());
}

const getOutletDetailApi = (props) => {
    return axios.post(BASE_URL + apiPath.getAllOutletDetail, props, authHeader());
}
const getAllOutletsApi = (props) => {
    return axios.post(BASE_URL + apiPath.getAllOutlets, props, authHeader());
}

const getAllArea = (props) => {
    return axios.post(BASE_URL + apiPath.getArea, props, authHeader());
}
const getAllReviewsApi = (props) => {
    return axios.post(BASE_URL + apiPath.getAllReviews, props, authHeader());
}
const getOutletVistorsApi = (props) => {
    return axios.post(BASE_URL + apiPath.getOutletVistors, props, authHeader());
}
const getOutletRating = (props) => {
    return axios.post(BASE_URL + apiPath.ratings, props, authHeader());
}
const sendFeedBackApi = (props) => {
    return axios.post(BASE_URL + apiPath.sendFeedback, props, authHeader());
}
const blockUserApi = (props) => {
    return axios.post(BASE_URL + apiPath.blockUser, props, authHeader());
}
const unblockUserApi = (props) => {
    return axios.post(BASE_URL + apiPath.unblockUser, props, authHeader());
}
const getAllOutlet = (props) => {
    return axios.get(BASE_URL + apiPath.getAllOutlet, props, authHeader());
}
const setCommision = (props) => {
    return axios.post(BASE_URL + apiPath.setCommision, props, authHeader());
}
const setRewardPoint = (props) => {
    return axios.post(BASE_URL + apiPath.setRewardPoint, props, authHeader());
}

const OutletApi = {
    registerOutletStepOneApi,
    registerOutletStepTwoApi,
    registerOutletStepThreeApi,
    registerOutletStepFourApi,
    getAllOutletsApi,
    getOutletDetailApi,
    getAllReviewsApi,
    getOutletVistorsApi,
    sendFeedBackApi,
    blockUserApi,
    unblockUserApi,
    getOutletRating,
    getAllArea,
    getAllOutlet,
    setCommision,
    setRewardPoint
}

export default OutletApi;