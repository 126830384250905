import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import loginLogo from "../../../assets/img/logo.png";
import useRequest from "../../../hooks/useRequest";
import Select from "react-select";
import { BASE_URL } from "../../../redux/services/apiPath";
import axios from "axios";

const VRegistration = () => {
  const history = useHistory();
  const [touchedError, setTouchedError] = useState(false);
  const [displayImage, setDisplayImage] = useState([]);
  const [image, setImage] = useState([]);
  const [image2, setImage2] = useState([]);
  const { request, response } = useRequest();
  const [allCity, setAllCity] = useState([]);
  const { request: requestSubmit, response: responseSubmit } = useRequest();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    clearErrors,
  } = useForm();

  useEffect(() => {
    request("GET", "getCitys");
  }, []);

  useEffect(() => {
    if (response) {
      if (response.status == "success" && response.data) {
        setAllCity(response.data);
      }
    }
  }, [response]);

  const cityOptions = allCity.map((city) => ({
    value: city?._id,
    label: city?.name,
  }));

  const handleChange = (selectedOption) => {
    register("cityId", { required: true });
    // Handle selected option
    setValue("cityId", selectedOption.value);
  };
  const onSubmit = (data) => {
    const { vendorName, email, mobile, instaProfile, facebookProfile, cityId } =
      data;
    if (cityId) {
      const formData = new FormData();
      formData.append("vendorName", vendorName);
      formData.append("email", email);
      formData.append("mobile", mobile);
      formData.append("instaProfile", instaProfile);
      formData.append("facebookProfile", facebookProfile);
      formData.append("cityId", cityId);
      if (displayImage) {
        for (let i = 0; i < image2.length > 0; i++) {
          formData.append("image", image2[i]);
        }
      } else {
        if (image == null || image == "undefined") {
          formData.append("oldImage", "");
        } else {
          formData.append("oldImage", image);
        }
      }

      requestSubmit("POST", "vendorRegistration", formData);
    } else {
      toast.error("Please Select City");
    }
  };

  // useEffect(async () => {
  //   console.log('ddddd', responseSubmit.data)
  //   if (responseSubmit) {
  //     if (responseSubmit.status == "success") {
  //       //   async function CallWebhoook(data) {
  //       //     await axios.post(`https://api.quickreply.ai/webhook/company/W7CmSYhFotkieadds_c/key/BYKK7nxYmkjTCZ68p`, data);
  //       //     // history.push({ pathname: "/thankyou" })

  //       //   }
  //       //   // history.push({ pathname: "/thankyou" })
  //       //   CallWebhoook(responseSubmit.data)
  //       // }
  //     }

  //   }
  // }, [responseSubmit])

  useEffect(() => {
    const fetchData = async () => {
      if (responseSubmit && responseSubmit.status === "success") {
        history.push({ pathname: "/thankyou" });

        // async function CallWebhoook(data) {
        //   await axios.post(`https://api.quickreply.ai/webhook/company/W7CmSYhFotkieadds_c/key/BYKK7nxYmkjTCZ68p`, data);
        //   history.push({ pathname: "/thankyou" })

        // }
        // CallWebhoook(responseSubmit.data)
      }
    };

    fetchData();
  }, [responseSubmit]);

  const handleImage = (event) => {
    event.preventDefault();
    let displayImages = [];
    let images = [];

    if (event.target.files.length > 0) {
      setImage2(event.target.files);
      for (let i = 0; i < event.target.files.length; i++) {
        displayImages.push(URL.createObjectURL(event.target.files[i]));
        images.push(event.target.files[i]);
      }
      setDisplayImage(displayImages);

      setValue("image", images);
    } else {
      setImage2([]);
      setDisplayImage([]);
      setImage([]);
      setValue("image", "");
    }
  };

  return (
    <div className="ms-content-wrapper ms-auth">
      <Row>
        <Col md={5}>
          <div className="ms-auth-bg">
            <img src={loginLogo} />
          </div>
        </Col>
        <Col md={7}>
          <div className="regi-form two-step">
            <form
              className="needs-validation"
              onSubmit={handleSubmit(onSubmit)}
            >
              <h3>Registration</h3>
              <p>Please enter your details to register</p>
              <div className="form-vendor-regi">
                <div className="form-vendor">
                  <label htmlFor="vendorName">
                    Vendor Name<span className="error">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="vendorName"
                      id="vendorName"
                      placeholder="Vendor Name"
                      className={`form-control ${
                        touchedError
                          ? errors.vendorName
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                      {...register("vendorName", {
                        required: true,
                      })}
                    />

                    {errors.vendorName?.type === "required" && (
                      <div className="invalid-feedback">
                        This field is required.
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-vendor">
                  <label htmlFor="validationCustom08">
                    Email Address<span className="error">*</span>
                  </label>
                  <div className="input-group">
                    <input
                  type="email"
                      name="email"
                      id="email"
                      placeholder="Email Address"
                      className={`form-control ${
                        touchedError
                          ? errors.email
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                      {...register("email", {
                        required: true,
                        pattern:
                          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      })}
                    />

                    {errors.email?.type === "required" && (
                      <div className="invalid-feedback">
                        This field is required.
                      </div>
                    )}
                    {errors.email?.type === "pattern" && (
                      <div className="invalid-feedback">
                        Please enter a valid email address.
                      </div>
                    )}
                  </div>
                </div>

                <div className="form-vendor">
                  <label htmlFor="validationCustom08">
                    Mobile<span className="error">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="mobile"
                      id="mobile"
                      placeholder="Mobile"
                      className={`form-control ${
                        touchedError
                          ? errors.mobile
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                      {...register("mobile", {
                        required: true,
                        pattern: /^[0-9]{10}$/gm,
                      })}
                    />

                    {errors.mobile?.type === "required" && (
                      <div className="invalid-feedback">
                        This field is required.
                      </div>
                    )}
                    {errors.mobile?.type === "pattern" && (
                      <div className="invalid-feedback">
                        Please enter a valid mobile number.
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-vendor">
                  <label htmlFor="cityId">
                    City<span className="error">*</span>
                  </label>
                  {/* <div className="input-group">
                        <select
                            id="cityId"
                            name="cityId"
                            className={`form-control ${
                              touchedError ? ( errors.cityId ? "is-invalid" : "is-valid" ): "" 
                            }`}
                            {...register("cityId", {
                                required: true,
                            })} 
                         
                          >
                            <option selected disabled value="">
                              Select City
                            </option>

                            {allCity.length > 0 ? (
                              allCity.map((city) => {
                                return (
                                  <option value={city?._id}>
                                    {city?.name}
                                  </option>
                                );
                              })
                            ) : (
                             ""
                            )}
                          </select>
                          {errors.cityId?.type === "required" && (
                                    <div className="invalid-feedback">
                                        This field is required.
                                    </div>
                                )}
                  
                  </div> */}
                  <div className="">
                    <Select
                      id="cityId"
                      name="cityId"
                      className={`border-blue `}
                      options={cityOptions}
                      onChange={handleChange}
                      placeholder="Select City"
                      //  {...register("cityId", { required: true })}
                    />
                    {errors.cityId?.type === "required" && (
                      <div className="invalid-feedback">
                        This field is required.
                      </div>
                    )}
                  </div>
                </div>

                <div className="form-vendor">
                  <label htmlFor="validationCustom08">Facebook Profile</label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="facebookProfile"
                      id="facebookProfile"
                      placeholder="Facebook Profile"
                      className={`form-control ${
                        touchedError
                          ? errors.facebookProfile
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                      {...register("facebookProfile", {
                        required: false,
                      })}
                    />
                  </div>
                </div>

                <div className="form-vendor">
                  <label htmlFor="validationCustom08">Insta Profile</label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="instaProfile"
                      id="instaProfile"
                      placeholder="Insta Profile"
                      className={`form-control ${
                        touchedError
                          ? errors.instaProfile
                            ? "is-invalid"
                            : "is-valid"
                          : ""
                      }`}
                      {...register("instaProfile", {
                        required: false,
                      })}
                    />
                  </div>
                </div>
                <div className="form-vendor">
                  <label htmlFor="validationCustom08">Image</label>
                  <div className="input-group">
                    <Controller
                      control={control}
                      name="file"
                      {...register("image", {
                        required: false,
                      })}
                      render={({ field: { onChange, value, ref } }) => (
                        <input
                          type="file"
                          id="image"
                          onChange={handleImage}
                          inputRef={ref}
                          accept="image/*"
                          multiple
                          className="uploadFile"
                        />
                      )}
                    />

                    <div className="invalid-feedback">
                      Please provide a valid email.
                    </div>
                  </div>
                </div>
                <button
                  className=" steps-btn"
                  type="submit"
                  onClick={() => setTouchedError(true)}
                >
                  Register
                </button>
              </div>
            </form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default VRegistration;
