import axios from 'axios';
import React, { useState } from 'react';
import { Modal, Col } from 'react-bootstrap';

import { BASE_URL } from '../redux/services/apiPath';
import { toast } from 'react-toastify';
import { downloadAllData, downloadExcel, downloadExcel1 } from "../util/fn";
const ExcelDateModal = (props) => {
    const [reportTable, setReportTable] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [token, setToken] = useState(localStorage.getItem("user"))
    const handleGetExcel = async () => {
        const data = {
            startDate,
            endDate,
            outletId: props?.selectedOutletId
        }
        const res = await axios.post(`${BASE_URL}report/downloadBusinessReport`,

            data
            ,
            {
                headers: {
                    "Authorization": "Bearer " + token
                }
            })

        if (res?.data?.status === "success") {

            let footFallData = res?.data?.data?.footFallReport?.map((obj, index) => ({
                'Sr.': index + 1,
                'availableCovers': obj?.availableCovers,
                'dateOfBooking': obj?.dateOfBooking,
                'occupancy': obj?.occupancy,
                'totalGuest': obj?.totalGuest,
            }));


            let revenueReport = res?.data?.data?.revenueReport?.map((obj, index) => ({
                'Sr.': index + 1,
                'dateOfBooking': obj?.dateOfBooking,
                'totalRevenue': obj?.totalRevenue,
            }));

            let perPersonRevenueReport = res?.data?.data?.perPersonRevenueReport?.map((obj, index) => ({
                'Sr.': index + 1,
                'dateOfBooking': obj?.dateOfBooking,
                'averagePPSale': obj?.averagePPSale,
            }));

            let businessForcastReport = res?.data?.data?.businessForcastReport?.map((obj, index) => ({
                'Sr.': index + 1,
                'dateOfBooking': obj?.dateOfBooking,
                'avgPPRevenue': obj?.avgPPRevenue,
                'booking': obj?.booking,
                'pax': obj?.pax,
                'revenue': obj?.revenue,
            }));

            let timeTrendLunch = res?.data?.data?.timeTrendLunch?.map((obj, index) => ({
                'Sr.': index + 1,
                'dateOfBooking': obj?.dateOfBooking,
                '12to13': obj?.['12to13'],
                '13to14': obj?.['13to14'],
                '14to15': obj?.['14to15'],
                '15to16': obj?.['15to16'],

            }));

            let timeTrendDinner = res?.data?.data?.timeTrendDinner?.map((obj, index) => ({
                'Sr.': index + 1,
                'dateOfBooking': obj?.dateOfBooking,
                '8to9': obj?.['8to9'],
                '9to10': obj?.['9to10'],
                '10to11': obj?.['10to11'],
                '11to12': obj?.['11to12'],
            }));

            const dataArray = [footFallData, revenueReport, perPersonRevenueReport, businessForcastReport, timeTrendLunch, timeTrendDinner];
            const namesArray = ["footFallData", "revenueReport", "perPersonRevenueReport", "businessForcastReport", "timeTrendLunch", "timeTrendDinner"];
            const headingsArray = [null, null, null, null, null, null]; // Assuming no headers for the data

            downloadExcel1(dataArray, namesArray, headingsArray);

            //    downloadAllData(footFallData, revenueReport, perPersonRevenueReport, businessForcastReport, timeTrendLunch, timeTrendDinner)

            // downloadExcel(footFallData, "footFallData");
            // downloadExcel(revenueReport, "revenueReport");
            // downloadExcel(perPersonRevenueReport, "perPersonRevenueReport");
            // downloadExcel(businessForcastReport, "businessForcastReport");
            // downloadExcel(timeTrendLunch, "timeTrendLunch");
            // downloadExcel(timeTrendDinner, "timeTrendDinner");

            // downloadExcel1(
            //     [footFallData, revenueReport],
            //     ["FootFallReport", "SalesReport"],
            //     [footFallHeadings, revenueReportHeading]
            // );

        }
        else {
            toast.error("Something Went Wrong")
        }
    }

    return (
        <div>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Please Select Date
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className="row mb-5">
                            <Col md={6}>
                                <div className="startDate">
                                    <label htmlFor="startDate" className="form-label">
                                        Start Date
                                    </label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        id="startDate"
                                        name="startDate"
                                        placeholder="Start Date"
                                        value={startDate}
                                        onChange={(e) => {
                                            setStartDate(e.target.value)
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="endDate">
                                    <label htmlFor="endDate" className="form-label">
                                        End Date
                                    </label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        id="endDate"
                                        name="endDate"
                                        placeholder="End Date"

                                        value={endDate}
                                        onChange={(e) => {
                                            setEndDate(e.target.value)
                                        }}
                                    // min={} // Set minimum date as the selected start date
                                    />
                                </div>
                            </Col>

                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="excle-dowm w-25" onClick={handleGetExcel}>Export Data</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ExcelDateModal;
