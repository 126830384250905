import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setRewardPoint,getOutletDetail } from "../../../redux/slices/Outlet";

const RewardModal = (props) => {
    const dispatch = useDispatch()
    return (
        <Modal
            show={props.setShowRewardModal}
            onHide={props.handleShowRewardModal}
            style={{ height: "90vh", maxHeight: "90vh", overflow: "hidden" }}
            scrollable
            size="lg"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Reward point partner</Modal.Title>
            </Modal.Header>
            <Modal.Body>5% reward point will be offered as reward loyalty program</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => {
                    dispatch(
                        setRewardPoint({
                            status: "false",
                            outletId: props.outletId,
                        })
                    )
                        .unwrap()
                        .then(() => {
                            dispatch(getOutletDetail({ outletId: props.outletId })).unwrap().then(() => {
                                toast.success("Reward partner points Deactivated successfully");
                                props.handleShowRewardModal();
                            }).catch((err) => {
                                toast.error(err);
                            })
                        })
                        .catch((err) => {
                            toast.error(err);
                            props.handleShowRewardModal();
                        });
                }}>
                    Deactivate
                </Button>
                <Button
                    variant="primary"
                    onClick={() => {
                        dispatch(
                            setRewardPoint({
                                status: "true",
                                outletId: props.outletId,
                            })
                        )
                            .unwrap()
                            .then(() => {
                                dispatch(getOutletDetail({ outletId: props.outletId })).unwrap().then(() => {
                                    toast.success("Reward partner points activated successfully");
                                    props.handleShowRewardModal();
                                }).catch((err) => {
                                    toast.error(err);
                                })
                            })
                            .catch((err) => {
                                toast.error(err);
                                props.handleShowRewardModal();
                            });
                    }}
                >
                    Activate
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
export default RewardModal;