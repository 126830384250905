import React, { useState, useEffect } from 'react';
import { toast } from "react-toastify";
import Select from "react-select";
import { Col, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Breadcrumb from "../../components/Breadcrumb";
import useRequest2 from '../../hooks/useRequest2'
import { upperCase } from "../../constants";
import { useDispatch, useSelector } from 'react-redux';
import { outletStepOneSave } from '../../redux/slices/SaveOutletDetails';
import { registerOutletStepOne } from '../../redux/slices/Outlet';

const StepOne = ({ handleNextStep, handleOutletId, isEdit, outletId }) => {
  const [loader, setLoader] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
    setValue
  } = useForm();

  const { vendorId } = useSelector(state => state.register);
  const { outletStepOne } = useSelector((state) => state.saveOutletDetails);

  const dispatch = useDispatch();

  const [subCategories, setSubCategories] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState([]);
  const [touchedError, setTouchedError] = useState(false);
  const { response: responseSubCategory, request: requestSubCategory } = useRequest2();

  useEffect(() => {
    localStorage.removeItem("services")
    requestSubCategory("POST", "manageCategory/getCategorys", { type: "vendorSubCategory" })
  }, [])

  useEffect(() => {
    if (responseSubCategory) {
      if (responseSubCategory?.status == true || responseSubCategory?.status == "success") {
        setSubCategories(responseSubCategory?.data?.length > 0 ? responseSubCategory?.data : []);
      }
    }
  }, [responseSubCategory])

  useEffect(() => {
    if (outletStepOne) {
      handleOutletId(outletStepOne?.outletId);
      setSelectedSubCategory(outletStepOne?.vendorSubCategory);
      setValue('outletName', outletStepOne?.outletName ?? "");
      setValue('vendorSubCategory', outletStepOne?.vendorSubCategory ?? "");
    }
  }, [outletStepOne])
  const onSubmit = (data) => {
    setLoader(true)
    const { vendorSubCategory, outletName } = data

    let subCategoryValues = [];
    for (const key in vendorSubCategory) {
      subCategoryValues.push(vendorSubCategory[key].value);
    }

    dispatch(
      registerOutletStepOne({
        outletName: outletName,
        vendorSubCategories: subCategoryValues,
        vendorId: vendorId,
        outletId: outletId ? outletId : "",
      })
    )
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        if (res?.status == "success" || res?.status == true) {
          dispatch(outletStepOneSave({ ...data, outletId: res.outletId }));
          handleNextStep(2);
        }
      })
      .catch((err) => {
        setLoader(false)
        toast.error(err);
      });
  }

  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb
            title="Add/Edit Outlet"
            links={[
              { to: "/", name: "Home" },
              { to: "/total-outlets", name: "Outlets" },
            ]}
          />
        </div>
        <div className="col-md-12">
          <div className="ms-panel ms-panel-fh">
            <div className="ms-panel-header">
              <h6>{isEdit ? "Edit Outlet" : "Add Outlet"}</h6>
            </div>
            <div className="ms-panel-body">
              <form
                className="needs-validation"
                onSubmit={handleSubmit(onSubmit)}
                noValidate
              >
                {/* <p>Fill the Required Details of Person Incharge handling the Bookings and Guest Communications.</p> */}
                <div className="form-vendor-regi">
                  <div className="form-vendor">
                    <Row>
                      <Col md={12} >
                        <div >
                          <label htmlFor="vendorSubCategory">
                            Select Your Sub-Category<span className="error">*</span>
                          </label>
                          <div className="dropdown-group">

                            <Select
                              isMulti
                              name="vendorSubCategory"
                              options={
                                subCategories.length > 0 &&
                                subCategories.map((c) => {
                                  return { value: c._id, label: upperCase(c.name) };
                                })
                              }
                              {...register("vendorSubCategory", {
                                required: true,
                              })}
                              value={selectedSubCategory}
                              // defaultValue={selectedSubCategory}
                              onChange={(action) => {
                                setSelectedSubCategory(action)
                                setValue("vendorSubCategory", action);

                                if (action?.length > 0) {
                                  setError("vendorSubCategory", "");
                                } else {
                                  setError("vendorSubCategory", { type: "required" });
                                }
                              }}

                              className={`basic-multi-select ${touchedError ? (errors.vendorSubCategory ? "is-invalid" : "is-valid") : ""
                                }`}
                              classNamePrefix="select"
                            />
                            {errors.vendorSubCategory?.type === "required" && (
                              <div className="invalid-select invalid-feedback">
                                The sub category field is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div
                          style={{
                            marginTop: "20px",
                          }}
                        >
                          <label htmlFor="outletName">
                            Name of the Restaurent/Hotel<span className="error">*</span>
                          </label>
                          <div className="input-group">
                            <input type="text" name="outletName" id="outletName" placeholder="Place Name"
                              className={`form-control ${touchedError ? (errors.outletName ? "is-invalid" : "is-valid") : ""
                                }`}
                              {...register("outletName", {
                                required: true,
                              })}
                            />

                            {errors.outletName?.type === "required" && (
                              <div className="invalid-feedback">
                                The name of Restaurent/Hotel field is required.
                              </div>
                            )}

                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                </div>

                {
                  loader ?
                    <center>
                      <div
                        style={{
                          flex: 1,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Spinner
                          animation="border"
                          className="d-flex justify-content-center"
                        />
                      </div>
                    </center> :
                    <button
                      className="btn btn-primary"
                      type="submit" onClick={() => setTouchedError(true)}
                    >
                      Next
                    </button>
                }

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StepOne
