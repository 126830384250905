import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { stepOneSave } from "../../../../redux/slices/SaveDetails";
import {
  getCategories,
  registerStepOne,
  verifyOTP,
} from "../../../../redux/slices/Registration";
import { toast } from "react-toastify";
import loginLogo from "../../../../assets/img/logo.png";

import { Col, Row, Button, Form, Modal } from "react-bootstrap";
import { upperCase } from "../../../../constants";
import Swal from "sweetalert2";
import {useParams} from 'react-router-dom';
import useRequest from "../../../../hooks/useRequest";

const StepOne = ({ setNextStep, nextStep }) => {
  const {userId} = useParams();
  const dispatch = useDispatch();
  
  const {request,response}                                                      = useRequest();

  const { stepOne } = useSelector((state) => state.saveDetails);
  const { designations, idProofs } = useSelector((state) => state.register);
  // const [modalShow, setModalShow] = React.useState(true);
  const [vId, setVID] = React.useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState([{
    password:false,
    confirmPassword:false
  }]);
  const [initialValues, setInitialValues] = React.useState(
    stepOne
      ? stepOne
      : {
          designation: "",
          other: "",
          name: "",
          idType: "",
          idNumber: "",
          idImage: "",
          email: "",
          password: "",
          rePassword: "",
          mobile: "",
        }
  );
  const [otp, setOTP] = useState({
    mobileOTP: "",
    emailOTP: "",
  });

  
  const validationSchema = Yup.object().shape({
    designation: Yup.string().required("Designation is required"),
    name: Yup.string().required("Place Nmae is required"),
    idType: Yup.string().required("Id type is required"),
    idNumber: Yup.string().required("Id number is required"),
    idImage: Yup.string().required("Id image is required"),
    email: Yup.string().email().required("Email is required"),
    password: Yup.string().min(8,"Password must be 8 characters long.").required("Please provide a valid Password."),
    rePassword: Yup.string().when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be the same"
      ),
    }),
    mobile: Yup.number().required("mobile is required"),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      dispatch(stepOneSave(values));
      const data = new FormData();
      for (const key in values) {
        if (key !== "rePassword" && key !== "other") {
          data.append(key, values[key]);
        }
      }
      dispatch(registerStepOne(data))
        .unwrap()
        .then((res) => {
          setVID(res.managerId);
          toast.success("OTP successfully sent to your email and mobile");
          // setModalShow(true);

          handleShowModal(res.managerId);
        })
        .catch((err) => {
          toast.error(err);
        });
      // setNextStep(2);
    },
  });

  useEffect(() => {
    if(userId){
      request("GET",`user/getRegisterDetail/${userId}`)
    }
  },[userId])

  useEffect(() => {
    if(response){
      if(response.status == "success"){
        
      
          
        if(response?.data?.mobile){
          formik.setFieldValue('mobile',response?.data?.mobile);
        }
       
        // if(response?.data?.cityId){
        //   formik.setFieldValue('cityId',response?.data?.cityId);
        // } 

        if(response?.data?.email){
          formik.setFieldValue('email',response?.data?.email);
        }

        if(response?.data?.name){
          formik.setFieldValue('name',response?.data?.name);
          
        }

      }
    }
  },[response])
  const validation = () => {
    var forms = document.getElementsByClassName("needs-validation");
    Array.prototype.filter.call(forms, function (form) {
      form.addEventListener(
        "submit",
        function (event) {
          if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
          }
          form.classList.add("was-validated");
        },
        false
      );
    });
  };
  const handleShowOtpModal = () => {
    if (otp.emailOTP !== "" && otp.mobileOTP !== "") {
      dispatch(
        verifyOTP({
          managerId: vId,
          otp: otp.mobileOTP,
          otpEmail: otp.emailOTP,
        })
      )
        .unwrap()
        .then((res) => {
          toast.success(res.message);
          setNextStep(2);
        })
        .catch((err) => {
          toast.error("Invalid OTP");
        });
    }
  };
  const handleShowModal = async (id) => {
    Swal.fire({
      title: "Enter your email otp",
      input: "text",
      inputLabel: "OTP",
      inputPlaceholder: "Enter otp here",
      inputAttributes: {
        maxlength: 8,
        autocapitalize: "off",
        autocorrect: "off",
        required: "true",
      },
      inputValidator: (value) => {
        if (value === "") {
          return "Please enter otp!";
        }
      },
      confirmButtonText: "Submit",
      confirmButtonColor: "#3ea99f",
    })
      .then((r) => {
        if (r.value === null) return false;

        if (r.isConfirmed) {
          setOTP({
            ...otp,
            emailOTP: r.value,
          });
          dispatch(
            verifyOTP({
              managerId: id,
              otpEmail: r.value,
            })
          )
            .unwrap()
            .then(() => {
              Swal.fire({
                icon: "success",
                title: "Good Job!",
                text: "otp verified",
                confirmButtonColor: "#3ea99f",
                confirmButtonText: "Next",
              }).then((res) => {
                if (res.isConfirmed) {
                  setNextStep(2);
                }
              });
            })
            .catch((err) => {
              toast.error("Invalid OTP");
            });
        }
      })
      .catch((err) => {
        toast.error(err);
      });
    // Swal.fire({
    //   title: "Enter your mobile otp",
    //   input: "text",
    //   inputLabel: "OTP",
    //   inputPlaceholder: "Enter otp here",
    //   inputAttributes: {
    //     maxlength: 8,
    //     autocapitalize: "off",
    //     autocorrect: "off",
    //     required: "true",
    //   },
    //   inputValidator: (value) => {
    //     if (value === "") {
    //       return "Please enter otp!";
    //     }
    //   },
    //   confirmButtonText: "Submit",
    //   confirmButtonColor: "#3ea99f",
    // }).then((result) => {
    //   if (result.value === null) return false;

    //   if (result.isConfirmed) {
    //     console.log(result.value);
    //     setOTP({
    //       ...otp,
    //       mobileOTP: result.value,
    //     });

    //   }
    // });
  };

  const renderFilePreview = (src, name) => {
    return (
      <div className="img-pos col-md-4">
       
            <img
            src={URL?.createObjectURL(src)}
            alt="preview"
            style={{ width: "200px", height: "50px" }}
            onError={(e) => {
              e.target.src = "";
              e.target.alt = name;
            }}
            />
       
       
      </div>
    );
  };
  const rederIdImage = () => {
    if (formik.values.idImage) {
      return (
        <>
          {renderFilePreview(formik.values.idImage, formik.values.idImage.name)}
        </>
      );
    }
  };

  const LoginForm = () => {
    const innerRef = useRef();
    const innerRef2 = useRef();
    const [firstFocus, setFirstFocus] = useState(true);
    const [secondFocus, setSecondFocus] = useState(false);
    // useEffect(() => innerRef.current && innerRef.current.focus());
    // useEffect(() => innerRef2.current && innerRef2.current.focus());
    const handleChange = (e) => {
      e.preventDefault();
      if (e.target.id === "mobileOTP") {
        setFirstFocus(true);
        setSecondFocus(false);
      } else if (e.target.id === "emailOTP") {
        setFirstFocus(false);
        setSecondFocus(true);
      }
      setOTP({
        ...otp,
        [e.target.name]: e.target.value,
      });
    };
    return (
      <Form>
        <Form.Group>
          <Form.Label>Phone OTP </Form.Label>
          <Form.Control
            type="number"
            ref={innerRef}
            // autoFocus={firstFocus}
            value={otp.mobileOTP}
            name="mobileOTP"
            onChange={(e) => {
              setOTP({
                ...otp,
                [e.target.name]: e.target.value,
              });
            }}
            style={{
              borderColor: otp.mobileOTP === "" ? "red" : "black",
            }}
          />
        </Form.Group>
        <Form.Group>
          <label htmlFor="example2">Email OTP</label>
          <input
            type="number"
            id="example2"
            // autoFocus={secondFocus}
            ref={innerRef2}
            name="emailOTP"
            value={otp.emailOTP}
            onChange={handleChange}
            style={{
              borderColor: otp.emailOTP === "" ? "red" : "black",
            }}
            className="form-control form-control-md"
          />
        </Form.Group>
      </Form>
    );
  };
  useEffect(() => {
    if (stepOne) {
      setInitialValues(stepOne);
    }
    dispatch(getCategories("designation"));
    dispatch(getCategories("idProof"));
  }, [dispatch, initialValues]);
  return (
    <div className="ms-content-wrapper ms-auth">
      <div className="regi-section">
        <Row>
          <Col md={5}>
            <div className="ms-auth-bg">
              <img src={loginLogo} />
            </div>
          </Col>
          <Col md={7}>
            <div className="regi-form">
              <form
                className="needs-validation"
                onSubmit={formik.handleSubmit}
                noValidate
              >
                <h3>
                    Registration : Step <span>{nextStep} of 6</span>
                </h3>
                <p>Please enter information to continue</p>
                <div className="form-vendor-regi">
                  <div className="form-vendor">
                    <Row>
                      <Col md={12}>
                        <label htmlFor="validationCustom01" className="form-label">
                          Please Introduce Yourself<span className="error">*</span>
                        </label>
                        <div className="dropdown-group">
                          <select
                            className="form-control"
                            id="validationCustom01"
                            name="designation"
                            placeholder="You are:-"
                            onChange={formik.handleChange}
                            value={formik.values.designation}
                            required
                            {...formik.getFieldProps("designation")}
                          >
                            <option selected disabled value="">
                              Select Designation
                            </option>

                            {designations.length > 0 ? (
                              designations.map((country) => {
                                return (
                                  <option value={country._id}>
                                    {upperCase(country.name)}
                                  </option>
                                );
                              })
                            ) : (
                              <option value={0}> Not found</option>
                            )}
                          </select>
                          <div className="invalid-select invalid-feedback">
                            Please Select a valid Designation.
                          </div>
                        </div>
                      </Col>
                      <Col md={12}>
                        {formik.values.designation === 3 && (
                          <div>
                            <label htmlFor="validationCustom02">Other</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                id="validationCustom02"
                                placeholder="You are:-"
                                name="other"
                                onChange={formik.handleChange}
                                value={formik.values.other}
                                required
                              />
                              <div className="invalid-feedback">
                                Please provide a valid Name.
                              </div>
                            </div>
                          </div>
                        )}
                      </Col>
                      <Col md={12}>
                        <div
                          style={{
                            marginTop: "20px",
                          }}
                        >
                          <label htmlFor="validationCustom03">
                            Name of Authorized Person<span className="error">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              id="validationCustom03"
                              placeholder="Enter name"
                              name="name"
                              onChange={formik.handleChange}
                              value={formik.values.name}
                              required
                            />
                            <div className="invalid-feedback">
                              Please provide a valid Name.
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md={12}>
                        <label htmlFor="validationCustom04" className="form-label">
                          ID type<span className="error">*</span>
                        </label>
                        <div className="dropdown-group">
                          <select
                            className="form-control"
                            name="idType"
                            id="validationCustom04"
                            placeholder="Id type"
                            onChange={formik.handleChange}
                            value={formik.values.idType}
                            required
                          >
                            <option selected disabled value="">
                              Select Id type
                            </option>

                            {idProofs.length > 0 ? (
                              idProofs.map((country) => {
                                return (
                                  <option value={country._id}>
                                    {upperCase(country.name)}
                                  </option>
                                );
                              })
                            ) : (
                              <option value={0}> Not found</option>
                            )}
                          </select>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div
                          style={{
                            marginTop: "20px",
                          }}
                        >
                          <label htmlFor="validationCustom04">ID No.<span className="error">*</span></label>
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              id="validationCustom04"
                              placeholder="Enter ID No."
                              name="idNumber"
                              onChange={formik.handleChange}
                              value={formik.values.idNumber}
                              required
                            />
                            <div className="invalid-feedback">
                              Please provide a valid Id number.
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="form-group ">
                          <label
                            htmlFor="foodLicence"
                            className="form-label customLabel"
                          >
                            ID Proof<span className="error">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              type="file"
                              className="uploadFile"
                              id="idImage"
                              placeholder="Upload ID Proof"
                              name="idImage"
                              accept="image/*"
                              onChange={(e) =>
                                formik.setFieldValue(
                                  "idImage",
                                  e.currentTarget.files[0]
                                )
                              }
                            />
                            <div>
                              {formik.errors.idImage &&
                                formik.touched.idImage && (
                                  <div className="error">
                                    {formik.errors.idImage}
                                  </div>
                                )}
                            </div>
                          </div>
                          {rederIdImage()}
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="form-group">
                          <label htmlFor="validationCustom03">
                            Email Address<span className="error">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              type="email"
                              className="form-control"
                              id="validationCustom03"
                              placeholder="Email Address"
                              name="email"
                              onChange={formik.handleChange}
                              value={formik.values.email}
                              required
                            />
                            <div className="invalid-feedback">
                              Please provide a valid Email.
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="form-group ">
                          <label htmlFor="validationCustom04">Password<span className="error">*</span></label>
                          <div className="input-group">
                            <input
                              type={isPasswordVisible.password ? "text" : "password"}
                              className="form-control"
                              id="validationCustom04"
                              placeholder="Password"
                              name="password"
                              onChange={formik.handleChange}
                              value={formik.values.password}
                              {...formik.getFieldProps("password")}
                              required
                            />
                            <a href="javascript:void(0);" onClick={() => {
                              const update = {...isPasswordVisible, password : isPasswordVisible.password ? false :true}
                              setIsPasswordVisible(update)
                              }}>
                                {isPasswordVisible.password ? <i className="fas fa-eye-slash"></i> : <i className="fas fa-eye"></i>}
                              </a>
                              {/* {formik.touched.password && formik.errors.password && (
                                <div className="invalid-feedback">
                                  {formik.errors.password}
                                </div>
                              )} */}
                              
                               {formik.touched.password && formik.errors.password && (
                                <div className="field-error">
                                  {formik.errors.password}
                                </div>
                              )}
                          </div>
                          
                               
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="form-group">
                          <label htmlFor="validationCustom04">
                            Re-Cofirm Password<span className="error">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              type={isPasswordVisible.confirmPassword ? "text" : "password"}
                              className="form-control"
                              id="validationCustom04"
                              placeholder="Re-Cofirm Password"
                              name="rePassword"
                              onChange={formik.handleChange}
                              value={formik.values.rePassword}
                              required
                            />
                            <a href="javascript:void(0);" onClick={() => {
                              const update = {...isPasswordVisible, confirmPassword : isPasswordVisible.confirmPassword ? false :true}
                              setIsPasswordVisible(update)
                              }}>
                                {isPasswordVisible.confirmPassword ? <i className="fas fa-eye-slash"></i> : <i className="fas fa-eye"></i>}
                              </a>
                            <div className="field-error">
                            {formik.errors.rePassword}
                          </div>
                          </div>
                          
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="form-group ">
                          <label htmlFor="validationCustom04">Phone<span className="error">*</span></label>
                          <div className="input-group">
                            <input
                              type="number"
                              className="form-control"
                              id="validationCustom04"
                              placeholder="Phone"
                              name="mobile"
                              onChange={formik.handleChange}
                              value={formik.values.mobile}
                              required
                            />
                            <div className="invalid-feedback">
                              Please provide a valid Phone.
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>

                <div className="form-row">{/* password and repassword */}</div>
                {/* <Modal
                  enforceFocus={false}
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Verify your Email and Phone
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <LoginForm />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button>Cancel</Button>
                    <Button onClick={handleShowOtpModal}>Confirm</Button>
                  </Modal.Footer>
                </Modal> */}
                <button
                  className=" steps-btn"
                  type="submit"
                  onClick={validation}
                  // onClick={formik.handleSubmit}
                >
                  Next <i>&#8594;</i>
                </button>
              </form>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default StepOne;

