import React, { Component } from 'react';
import Breadcrumb from '../Breadcrumb';
import Favorder from '../sections/Orders/Favorder';
import Ordertable from '../sections/Orders/Ordertable';

class Orders extends Component {
    render() {
        return (
        <div className="ms-content-wrapper">
            <div className="row">
                <div className="col-md-12">
                 {localStorage.getItem("vendorType") === "single" ? (
                    <Breadcrumb
                       title="Orders"
                       links={[
                       { to: "/", name: "Home" },
                       ]}
                   />
                   ):(
                    <Breadcrumb
                        title="Orders"
                        links={[
                        { to: "/", name: "Home" },
                        { to: "/total-outlets", name: "Outlets" },
                        ]}
                    />
                   )}
                  
                    {/* <Favorder/> */}
                    <Ordertable />
                </div>
            </div>
        </div>
        );
    }
}

export default Orders;