import React, { useState, useEffect } from "react";
import "datatables.net-bs4/js/dataTables.bootstrap4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import { useDispatch, useSelector } from "react-redux";
import {
  blockUser,
  getAllOutlets,
  getOutletVistors,
  unblockUser,
} from "../../../redux/slices/Outlet";
import { upperCase } from "../../../constants";
import $ from "jquery";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import Breadcrumb from "../../Breadcrumb";
import BlockUserModal from "./BlockUserModal";


const Content = () => {

  const [showModal, setShowModal] = useState(false);
  const [userId, setID] = useState(false);

  const dispatch = useDispatch();
  const itemsPerPage = 10;
  const { outletList, outletVisitors, outletBlockingAccDetails } = useSelector(
    (state) => state.outlet
  );
  const [selectedOutlet, setSelectedOutlet] = useState({
    outletId: localStorage.getItem("outletId"),
  });
  useEffect(() => {
    dispatch(getAllOutlets({ vendorId: localStorage.getItem("vendorId") }));
    if (localStorage.getItem("vendorType") === "single") {
      dispatch(
        getOutletVistors({
          outletId: localStorage.getItem("outletId"),
        })
      );
    }
    $("#data-table-4").DataTable({
      data: outletVisitors,
      columns: [
        { title: "Name" },
        { title: "Phone" },
        { title: "Image" },
        {
          title: "Block",
          render: function (data, type, row) {
            return (
              '<!-- Button trigger modal --><button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal">Block</button><!-- Modal --><div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"><div className="modal-dialog" role="document"><div className="modal-content"><div className="modal-header"><h5 className="modal-title" id="test2">' +
              data.proj_name +
              '</h5><button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button></div><div className="modal-body">...</div><div className="modal-footer"><button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button><button type="button" className="btn btn-primary">Save changes</button></div></div></div></div>'
            );
          },
        },
        // { title: "Ordered Item" },
        // { title: "Bill" },
      ],
    });
  }, [dispatch]);
  
  const handleDeleteClick = (userID) => {
    if (selectedOutlet.outletId != "") {
      Swal.fire({
        title: "Enter reason for block",
        input: "text",
        inputLabel: "Reason",
        inputPlaceholder: "Enter text here",
        inputAttributes: {
          maxlength: 8,
          autocapitalize: "off",
          autocorrect: "off",
          required: "true",
        },
        inputValidator: (value) => {
          if (value === "") {
            return "Please enter reason!";
          }
        },
        confirmButtonText: "Submit",
        confirmButtonColor: "#3ea99f",
      }).then((result) => {
        if (result.value === null) return false;
        if (result.isConfirmed) {
          dispatch(
            blockUser({
              userId: userID,
              reason: result.value,
              outletId: selectedOutlet.outletId,
            })
          )
            .unwrap()
            .then((res) => {
              Swal.fire("Blocked!", "", "success");
              dispatch(
                getOutletVistors({
                  outletId: selectedOutlet.outletId,
                })
              );
            })
            .catch((err) => {
              toast.error(err);
            });
        }
      });
    } else {
      Swal.fire("Please Select an outlet first", "", "warning");
    }
  };
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % outletVisitors.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };
  const handleEditClick = (userID) => {
    if (selectedOutlet.outletId != "") {
      dispatch(
        unblockUser({
          userId: userID,
          outletId: selectedOutlet.outletId,
        })
      )
        .unwrap()
        .then((res) => {
          Swal.fire("Unblocked Successfully", "", "success");
          dispatch(
            getOutletVistors({
              outletId: selectedOutlet.outletId,
            })
          );
        })
        .catch((err) => {
          toast.error(err);
        });
    } else {
      Swal.fire("Please Select an outlet first", "", "warning");
    }
  };

  useEffect(() => {
    if (outletVisitors) {
      const endOffset = itemOffset + itemsPerPage;
      console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      setCurrentItems(outletVisitors.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(outletVisitors.length / itemsPerPage));
    }
  }, [itemOffset, itemsPerPage, outletVisitors]);



  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  return (
    <>

      <div className="ms-content-wrapper">
        <Breadcrumb
          title="Blocking Account"
          links={[
            { to: "/", name: "Home" },
          ]}
        />
        <div className="row">
          <div className="col-md-12">
            <div className="ms-panel">
              <div className="ms-panel-header">
                <h6>Blocking Account</h6>
              </div>

              <div className="ms-panel-body">
                <div className="table-responsive">
                  <div className="dh-section mt-4">
                    <div className="row">
                      <div className="col-md-4">
                        {localStorage.getItem("vendorType") === "multiple" && (
                          <div className="outletDropdown" style={{ padding: "0px" }}>
                            <label htmlFor="validationCustom01" className="form-label">
                              Select Outlet
                            </label>
                            <div className="dropdown-group form-vendor-regi">
                              <select
                                className="form-control"
                                id="validationCustom01"
                                name="outletId"
                                onChange={(e) => {
                                  setSelectedOutlet({
                                    ...selectedOutlet,
                                    outletId: e.target.value,
                                  });
                                  dispatch(
                                    getOutletVistors({
                                      ...selectedOutlet,
                                      outletId: e.target.value,
                                    })
                                  );
                                }}
                              >
                                <option value={0}>Please Select</option>
                                {outletList.length ? (
                                  outletList.map((item) => {
                                    return (
                                      <option value={item.outletId}>
                                        {upperCase(item.outletName)}
                                      </option>
                                    );
                                  })
                                ) : (
                                  <option value={0}> Not found</option>
                                )}
                              </select>
                              {/* <div className="invalid-feedback">
                            Please Select a valid Designation.
                          </div> */}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="validationCustom01" className="form-label">

                        </label>
                        <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                          {/* <span className="ms-chart-label bg-red">
                                  <i className="material-icons">arrow_downward</i>{" "}
                                  4.5%
                                </span> */}
                          <div className="ms-card-body media">
                            <div className="media-body">
                              <span className="black-text">
                                <strong>Total Users</strong>
                              </span>
                              <h2>{outletBlockingAccDetails?.totalUser}</h2>
                            </div>
                          </div>
                          {/* <LineChart data={this.state.data2} options={options} /> */}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="validationCustom01" className="form-label">

                        </label>
                        <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                          {/*<span className="ms-chart-label bg-black">
                          <i className="material-icons">arrow_upward</i> 12.5%
                        </span>*/}
                          <div className="ms-card-body media">
                            <div className="media-body">
                              <span className="black-text">
                                <strong>Blocked User</strong>
                              </span>
                              <h2>{outletBlockingAccDetails?.totalBlocked == "00" ? 0 : outletBlockingAccDetails?.totalBlocked}</h2>
                            </div>
                          </div>
                          {/* <LineChart data={this.state.data3} options={options} /> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <table
                    // id="data-table-4"
                    className="table w-100 thead-primary"
                  >
                    <thead>
                      <tr>
                        <th>Sr.</th>
                        <th>Name</th>
                        <th>Phone</th>
                        <th>Image</th>
                        <th>Block/Unblock</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems
                        ? currentItems.map((coupon, index) => (
                          <>
                            {
                              <tr>
                                <td>{index + 1}</td>
                                {/* <td>
                               {cuisineCategories.length &&
                                 findInArray(cuisineCategories, menu.cusineId)}
                             </td> */}
                                <td>{coupon.name}</td>
                                <td>{coupon.phone}</td>
                                <td>
                                  <img
                                    src={coupon.image}
                                    height="50"
                                    weight="50"
                                  />
                                </td>

                                <td>
                                  {coupon.isBlocked === "no" && (
                                    <button
                                      type="button"
                                      className="menu-button"
                                      onClick={() => {
                                        handleShow()
                                        setID(coupon.userId)
                                      }
                                      }
                                    >
                                      Block
                                    </button>
                                  )}
                                  {coupon.isBlocked === "yes" && (
                                    <button
                                      className="menu-button"
                                      type="button"
                                      onClick={() => {
                                        handleEditClick(coupon.userId);
                                      }}
                                    >
                                      Unblock
                                    </button>
                                  )}
                                </td>
                              </tr >
                            }
                          </>
                        ))
                        : "No data Found"}
                    </tbody>
                  </table>
                  {outletVisitors && (
                    <ReactPaginate
                      nextLabel="Next >"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={2}
                      pageCount={pageCount}
                      previousLabel="< Previous"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      containerClassName="pagination"
                      activeClassName="active"
                      renderOnZeroPageCount={null}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <BlockUserModal show={showModal} handleClose={handleClose} userId={userId} selectedOutlet={selectedOutlet} />
      </div >
    </>
  );
};

export default Content;

