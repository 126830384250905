import axios from "axios";
import apiPath, { BASE_URL } from "./apiPath";
import authHeader from "./authHeader";
const getBusinessReportsApi = (props) => {
    return axios.post(BASE_URL + apiPath.getBusinessReports, props, authHeader());
};
const getBookingReportsApi = (props) => {
    return axios.post(BASE_URL + apiPath.getBookingReports, props, authHeader());
};
const getRevenueReportsApi = (props) => {
    return axios.post(BASE_URL + apiPath.getRevenueReports, props, authHeader());
};
const getTrendsReportsApi = (props) => {
    return axios.post(BASE_URL + apiPath.getTrendsReports, props, authHeader());
};
const addCompetitorApi = (props) => {
    return axios.post(BASE_URL + apiPath.addCompetitorApi, props, authHeader());
};
const getCompetitorsApi = (props) => {
    return axios.post(BASE_URL + apiPath.getCompetitorsApi, props, authHeader());
};

const reportsApi = {
    getBusinessReportsApi,
    getBookingReportsApi,
    getRevenueReportsApi,
    getTrendsReportsApi,
    addCompetitorApi,
    getCompetitorsApi
}

export default reportsApi;