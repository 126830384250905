import React, { useEffect, useState } from "react";
import Recentorder from "./Recentorder";
import "react-perfect-scrollbar/dist/css/styles.css";
import Trendingorder from "./Trendingorder";
import Chart from "./Chart";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { upperCase } from "../../../constants";
import { getDashboardData } from "../../../redux/slices/Dashboard";
import { getAllOutlets } from "../../../redux/slices/Outlet";
import Barchart from "../Charts/Barchart";
import Groupedbarchart from "../Charts/Groupedbarchart";
import NewBarChart from "../Charts/NewRatingBarChart";
import authHeader from "../../../redux/services/authHeader";
import { BASE_URL } from "../../../redux/services/apiPath";
import BankDetails from "../../pages/Settings/BankDetails";
import BankdetailsModal from "../../BankdetailsModal";
import Mou from "../../Mou";

const DashboardContent = () => {
  const dispatch = useDispatch();
  const [bankmodal, setBankModal] = useState(false);
  const [mou, setMou] = useState(false);
  const { user } = useSelector((state) => state.register);
  const data = useSelector((state) => state);

  const { dashboardData } = useSelector((state) => state.dashboard);
  const { outletList } = useSelector((state) => state.outlet);
  const [selectedOutlet, setSelectedOutlet] = useState({
    outletId: localStorage.getItem("outletId"),
    days: "1",
  });
  useEffect(() => {
    dispatch(getAllOutlets({ vendorId: localStorage.getItem("vendorId") }));

    let outletID = "";
    if (localStorage.getItem("vendorType") === "single") {
      dispatch(
        getDashboardData({
          outletId: localStorage.getItem("outletId"),
          days: "1",
        })
      );
    } else {
      if (localStorage.getItem("outletId")) {
        outletID = localStorage.getItem("outletId");
      }
      dispatch(
        getDashboardData({
          outletId: outletID,
          days: "1",
        })
      );
    }
  }, [dispatch]);

  const GetBankDetails = async () => {
    const data = {
      vendorId: localStorage.getItem("vendorId")
        ? localStorage.getItem("vendorId")
        : "",
    };
    const res = await axios.post(
      `${BASE_URL}outlet/getBankDetail`,
      data,
      authHeader()
    );

  
    if (res?.data?.status == "success") {
      console.log("res?.data?.data", res?.data?.data?.length);
      if (Object.keys(res?.data?.data).length == 0) {
        setBankModal(true);
        // setMou(true);
      }
    }
  };

  useEffect(() => {
    GetBankDetails();
  }, []);

  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-8">
          <h1 className="db-header-title">
            Welcome,{" "}
            {user && user?.length > 25
              ? user.substring(0, 25) + " " + "..."
              : user}
          </h1>
        </div>
        <div
          className="col-md-4"
          //  style={{ textAlign: "end" }}
        >
          <div className="row">
            {localStorage.getItem("vendorType") === "multiple" && (
              <div className="col-xl-12 col-lg-12 col-md-12">
                <label htmlFor="validationCustom01" className="form-label">
                  Select Outlet
                </label>
                <div className="dropdown-group form-vendor-regi">
                  <select
                    className="form-control"
                    id="validationCustom01"
                    name="outletId"
                    value={selectedOutlet?.outletId}
                    onChange={(e) => {
                      localStorage.setItem("outletId", e.target.value);
                      setSelectedOutlet({
                        ...selectedOutlet,
                        outletId: e.target.value,
                      });
                      dispatch(
                        getDashboardData({
                          ...selectedOutlet,
                          outletId: e.target.value,
                        })
                      );
                    }}
                  >
                    <option value={""}>Please Select</option>
                    {outletList.length ? (
                      outletList.map((item) => {
                        return (
                          <option value={item.outletId}>
                            {upperCase(item.outletName)}
                          </option>
                        );
                      })
                    ) : (
                      <option value={0}> Not found</option>
                    )}
                  </select>
                  {/* <div className="invalid-feedback">
                            Please Select a valid Designation.
                          </div> */}
                </div>
              </div>
            )}
            <div className="col-xl-12 col-lg-12 col-md-12">
              <label htmlFor="validationCustom01" className="form-label">
                Select Days
              </label>
              <div className="dropdown-group form-vendor-regi">
                <select
                  className="form-control"
                  id="validationCustom01"
                  name="days"
                  onChange={(e) => {
                    setSelectedOutlet({
                      ...selectedOutlet,
                      days: e.target.value,
                    });
                    dispatch(
                      getDashboardData({
                        ...selectedOutlet,
                        days: e.target.value,
                      })
                    );
                  }}
                >
                  <option value={0}>Please Select</option>
                  <option value={1}>1 Day</option>
                  <option value={7}>7 Days</option>
                  <option value={30}>30 Days</option>
                </select>
                {/* <div className="invalid-feedback">
                            Please Select a valid Designation.
                          </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12" style={{ height: "60px" }}></div>
        <div className="col-12">
          <Chart dashboardData={dashboardData} />
        </div>
        <div className="col-xl-6 col-md-12">
          <div className="ms-panel">
            <div className="ms-panel-header">
              <h6>Total Ratings By ({dashboardData?.totalReview}) </h6>
            </div>
            {dashboardData?.rating && (
              <div className="ms-panel">
                <Barchart data={dashboardData?.rating} about={"Ratings"} />
              </div>
            )}
          </div>
        </div>
        <div className="col-xl-6 col-md-12">
          <div className="ms-panel">
            <div className="ms-panel-header">
              <h6> Total Feedbacks By ({dashboardData?.totalReview})</h6>
            </div>
            {/* <div className="ms-panel-body"> */}
            {dashboardData?.feedback && (
              <div className="ms-panel">
                <Barchart
                  data={dashboardData?.feedback}
                  about={"Feedbacks"}
                  isHoriz={true}
                />
              </div>
            )}
            {/* </div> */}
          </div>
        </div>
        <div className="col-xl-6 col-md-12">
          <div className="ms-panel">
            <div className="ms-panel-header">
              <h6>Ranking</h6>
            </div>
            {/* <div className="ms-panel-body"> */}
            {dashboardData?.rankingGraph && (
              <div className="ms-panel">
                <NewBarChart
                  data={dashboardData?.rankingGraph}
                  about={"Ranking"}
                  isHoriz={false}
                />
              </div>
            )}
            {/* </div> */}
          </div>
        </div>

        <div className="col-xl-6 col-md-12">
          <div className="ms-panel">
            <div className="ms-panel-header">
              <h6>Net Promoter Score (NPS)</h6>
            </div>
            {/* <div className="ms-panel-body"> */}
            {dashboardData?.npsGraph && (
              <div className="ms-panel">
                <Groupedbarchart
                  data={dashboardData?.npsGraph}
                  about={"NPS"}
                  isHoriz={false}
                  totalReview={dashboardData?.totalReview}
                />
              </div>
            )}
            {/* </div> */}
          </div>
        </div>
        {/* Recent Orders Requested */}

        {/* Food Orders */}
        <div className="col-md-12">
          <Trendingorder
            data={dashboardData ? dashboardData.trendingDish : []}
            name={"Dishes"}
          />
        </div>
        <div className="col-md-12">
          <Trendingorder
            data={dashboardData ? dashboardData.trendingCuisine : []}
            name={"Cuisines"}
          />
        </div>
        {/* END/Food Orders */}
        {/* Recent Orders Requested */}

        {/* Favourite Products */}
        <div className="col-xl-5 col-md-12">{/* Favourite Products */}</div>

        {/* Total Earnings */}
        {/* Recent PlSelect Outletaced Orders< */}
        <div className="col-12">
          <div className="ms-panel">
            <div className="ms-panel-header postitonsss">
              <h6>Upcoming Booking</h6>
            </div>
            <div className="ms-panel-body">
              <Recentorder selectedOutlet={selectedOutlet} />
            </div>
          </div>
        </div>

        {/* Recent Orders< */}
        <BankdetailsModal
          show={bankmodal}
          onHide={() => {
            setBankModal(false);
          }}
          setMou={setMou}
        />
        <Mou
          show={mou}
          onHide={() => {
            setMou(false);
          }}
        />
      </div>
    </div>
  );
};

export default DashboardContent;
