import React, { Component } from 'react';
import Content from '../sections/Comingsoon/Content';

class Comingsoon extends Component {
    render() {
        return (
            <Content/>
        );
    }
}

export default Comingsoon;