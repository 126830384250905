import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

const PieChart = ({ data }) => {
    console.log("pie", data)
    const [categories, setCategories] = useState([])
    const [values, setValues] = useState([])
    useEffect(() => {
        if (data) {
            setCategories(data.map(obj => obj.dateOfBooking))
            setValues(data.map(obj => parseInt(obj.total)))
        }

    }, [data])

    const options = {
        series: values ? values : [],
        chart: {
            width: 380,
            type: 'pie',
        },
        labels: categories ? categories : [],
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            },
        ],
        dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
                return opts.w.config.series[opts.seriesIndex];
            }
        }
    };

    return <Chart options={options} series={options.series} type="pie" width={380} />;
};

export default PieChart;
