import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

const LineChart = ({ data, data1, data2 }) => {

    const [categories, setCategories] = useState([])
    const [values, setValues] = useState([])
    useEffect(() => {
        if (data) {
            setCategories(data.map(obj => obj.dateOfBooking))
            setValues(data.map(obj => obj.occupancy))
        }

    }, [data])

    useEffect(() => {
        if (data1) {
            setCategories(data1.map(obj => obj.dateOfBooking))
            setValues(data1.map(obj => obj.totalRevenue))
        }

    }, [data1])

    useEffect(() => {
        if (data2) {
            setCategories(data2.map(obj => obj.dateOfBooking))
            setValues(data2.map(obj => obj.averagePPSale))
        }

    }, [data2])
    const options = {
        chart: {
            id: 'line-chart',
        },
        xaxis: {
            categories: categories ? categories : [],
        },
        yaxis: {
            forceNiceScale: true, // Ensure integer values on the vertical axis
            labels: {
                formatter: value => parseInt(value), // Convert labels to integers
            },
        },
    };

    const series = [
        {
            name: 'Series 1',
            data: values ? values : [],
        },
    ];

    return (
        <div style={{ width: '100%' }}>
            <Chart
                options={options}
                series={series}
                type="line"
                width="500"
            />
        </div>
    );
};

export default LineChart;
