import axios from 'axios';
import React, { useState } from 'react';
import { Modal, Col } from 'react-bootstrap';

import { toast } from 'react-toastify';
import { downloadAllData, downloadExcel, downloadExcel1 } from "../../../util/fn";
import { BASE_URL } from '../../../redux/services/apiPath';
const CustomFileUpload = (props) => {
    const [reportTable, setReportTable] = useState([]);
    const [amount, setAmount] = useState(0);
    const [files, setFile] = useState('');
    const [token, setToken] = useState(localStorage.getItem("user"))

    console.log("props", props)
    const UpdateReport = async () => {
        const formdata = new FormData()
        console.log("files", files)
        if (amount > 0 && files) {
            formdata.append("image", files)
            formdata.append("netAmount", amount)
            formdata.append("bookingId", props?.reportId)
            formdata.append("outletId", props?.selectedOutletId)
            const res = await axios.post(`${BASE_URL}report/updateInvoice`, formdata, {
                headers: {
                    "Authorization": "Bearer " + token
                }
            })
            if (res?.data?.status === "success") {
                toast.success("Updated!")
                // props?.onHide()
                props.setModalShow(false)
            }
            else {
                toast.error("Something Went Wrong")
            }
        }

        else {
            toast.error("Amount & File Is Required")
        }
    }
    return (
        <div>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Please Fill Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className="row mb-5">
                            <Col md={6}>
                                <div className="startDate">
                                    <label htmlFor="startDate" className="form-label">
                                        Amount
                                    </label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="startDate"
                                        name="startDate"
                                        placeholder="Amount"
                                        value={amount}
                                        onChange={(e) => {
                                            setAmount(e.target.value)
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="file">
                                    <label htmlFor="file" className="form-label">
                                        File
                                    </label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        id="file"
                                        name="file"
                                        onChange={(e) => {
                                            setFile(e.target.files[0])
                                        }}
                                    // min={} // Set minimum date as the selected start date
                                    />
                                </div>
                            </Col>

                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="excle-dowm w-25" onClick={UpdateReport}>Update Data</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default CustomFileUpload;
