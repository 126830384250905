import React, { Component } from "react";
import Content from "./Content";

class BlockingAccount extends Component {
    render() {
        return (
             <Content />
        );
    }
}

export default BlockingAccount;

