/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import {
  getPromotionList,
  removePromotion,
  updatePromotion,
} from "../../../redux/slices/Discount";
import ModalEdit from "../Discount/ModalEdit";

const PromotionTableRow = (props) => {
  const [discount_id, setDiscountId] = useState('')
  const [modal, setModal] = useState(false)
  const dispatch = useDispatch();
  const [data, setData] = useState();

  useEffect(() => {
    setData(props.coupon)
  }, [])

  const handleEditClick = (discountId, status) => {

    setDiscountId(discountId)
    setModal(true)
    // Swal.fire({
    //   title: "Submit Update Status",
    //   input: "select",
    //   inputOptions: {
    //     active: "Active",
    //     inactive: "Inactive",
    //   },
    //   showCancelButton: true,
    //   confirmButtonText: "Ok",
    //   showLoaderOnConfirm: true,
    // }).then(function (result) {
    //   if (result.isConfirmed) {
    //     dispatch(
    //       updatePromotion({
    //         couponId: discountId,
    //         status: result.value,
    //       })
    //     )
    //       .unwrap()
    //       .then(() => {
    //         dispatch(
    //           getPromotionList({
    //             outletId: props.outlet,
    //           })
    //         );
    //         Swal.fire({
    //           icon: "success",
    //           html: "Discount Coupon updated successfully",
    //         });
    //       })
    //       .catch(() => {
    //         Swal.fire({
    //           icon: "error",
    //           html: "Something went wrong",
    //         });
    //       });
    //   }
    // });
  };
  const handleDeleteClick = (discountId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        if (props?.outlet != "") {
          dispatch(
            removePromotion({
              promotionId: discountId,
            })
          )
            .unwrap()
            .then((res) => {
              Swal.fire("Deleted!", "Remove Successfully.", "success");
              dispatch(
                getPromotionList({
                  outletId: props.outlet,
                })
              );
            });
        } else {
          toast.error("Please Select the outlet first");
        }
      }
    });
  };
  useEffect(() => {

  }, [props.callback])
  return (
    <tr>
      <td>{props.index + 1}</td>
      {/* <td>
        {cuisineCategories.length &&
          findInArray(cuisineCategories, props.menu.cusineId)}
      </td> */}
      <td>{data && data.price ? data.price : ''}</td>
      <td>{data && data.discountAmount ? data.discountAmount : ''}%</td>
      <td>{data && data.title ? data.title : ''}</td>
      <td>{data && data.type ? data.type : ''}</td>
      <td>{data && data.description ? data.description : ''}</td>
      <td>{data && data.startDate ? data.startDate : ''}</td>
      <td>{data && data.endDate ? data.endDate : ''}</td>
      <td>{data && data.startTime ? data.startTime : ''}</td>
      <td>{data && data.endTime ? data.endTime : ''}</td>
      <td>
        {data && data.image && (
          <img
            src={data.image}
            style={{ width: "40px", height: "40px" }}
            alt={data.image}
          />
        )}
      </td>
      <td>{data && data.status ? data.status : ''}</td>

      <td>
        {data && data.couponId && (
          <>
            <button
              className="menu-button"
              type="button"
              onClick={() => {
                handleEditClick(data.couponId, data.status);
              }}
            >
              Update
            </button>
            <button
              type="button"
              className="menu-button"
              onClick={() => handleDeleteClick(data.couponId)}
            >
              Delete
            </button>
          </>
        )}

      </td>
      <ModalEdit data={props}
        handleClose={() => {
          setModal(false)
        }}
        show={modal}
        discountId={discount_id}
        outletId={props?.outlet}
        setCallback={props.setCallback}
        callback={props.callback}
      />


    </tr>
  );
};

export default PromotionTableRow;

