import axios from "axios";
import apiPath, { BASE_URL } from "./apiPath";
import authHeader from "./authHeader";
const addDiscountApi = (props) => {
    return axios.post(BASE_URL + apiPath.addDiscount, props, authHeader());
};
const getDiscountListApi = (props) => {
    return axios.post(BASE_URL + apiPath.getDiscountList, props, authHeader());
};
const updateDiscountApi = (props) => {
    return axios.post(BASE_URL + apiPath.updateDiscount, props, authHeader());
};
const removeDiscountApi = (props) => {
    return axios.post(BASE_URL + apiPath.removeDiscount, props, authHeader());
};
const addPromotionApi = (props) => {
    return axios.post(BASE_URL + apiPath.addPromotion, props, authHeader());
};
const getPromotionListApi = (props) => {
    return axios.post(BASE_URL + apiPath.getPromotionList, props, authHeader());
};
const updatePromotionApi = (props) => {
    return axios.post(BASE_URL + apiPath.updatePromotion, props, authHeader());
};
const removePromotionApi = (props) => {
    return axios.post(BASE_URL + apiPath.removePromotion, props, authHeader());
};

const DiscountApi = {
    addDiscountApi,
    getDiscountListApi,
    updateDiscountApi,
    removeDiscountApi,
    addPromotionApi,
    getPromotionListApi,
    updatePromotionApi,
    removePromotionApi,
}

export default DiscountApi;