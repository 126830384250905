import React, { Component } from "react";
import MenuTable from "../../pages/Menu/MenuTable";
import Breadcrumb from "../../Breadcrumb";

class Gridcontent extends Component {
    render() {
        return (
            <div className="ms-content-wrapper">
                {localStorage.getItem("vendorType") === "single" ? (
                    <Breadcrumb
                        title="Menu Items"
                        links={[
                            { to: "/", name: "Home" },
                        ]}
                    />
                ) : (
                    <Breadcrumb
                        title="Menu Items"
                        links={[
                            { to: "/", name: "Home" },
                            { to: "/total-outlets", name: "Outlets" },
                        ]}
                    />
                )}

                <div>

                    <MenuTable />

                </div>
            </div>
        );
    }
}

export default Gridcontent;
