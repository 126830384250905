import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import financeApi from "../services/financeApi";
export const getBillingList = createAsyncThunk(
    "report/getBillingList",
    async (props, thunkAPI) => {
        try {
            const response = await financeApi.getBillingListApi(props);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const getPaymentList = createAsyncThunk(
    "report/getPaymentList",
    async (props, thunkAPI) => {
        try {
            const response = await financeApi.getPaymentListApi(props);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const getPendingList = createAsyncThunk(
    "report/getPendingList",
    async (props, thunkAPI) => {
        try {
            const response = await financeApi.getPendingListApi(props);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
// export const getTrendsReports = createAsyncThunk(
//     "report/getTrendsReports",
//     async (props, thunkAPI) => {
//         try {
//             const response = await financeApi.getTrendsfinanceApi(props);
//             return response.data;
//         } catch (error) {
//             const message =
//                 (error.response &&
//                     error.response.data &&
//                     error.response.data.message) ||
//                 error.message ||
//                 error.toString();
//             return thunkAPI.rejectWithValue(message);
//         }
//     }
// );
const financeSlice = createSlice({
    name: "finance",
    initialState: {
        billingList: [],
        billingData: null,
        paymentList: [],
        paymentData: null,
        pendingList: [],
        pendingData: null,
        trendsReports: []
    },
    extraReducers: {
        [getBillingList.fulfilled]: (state, action) => {
            console.log(action.payload);
            state.billingData = action.payload;
            if (action.payload.data.length) {
                state.billingList = Object.keys(action.payload.data)
                    .map(function (key) {
                        return action.payload.data[key];
                    });
            }
        },
        [getBillingList.rejected]: (state, action) => {
            state.billingList = [];
        },
        [getPaymentList.fulfilled]: (state, action) => {
            state.paymentData = action.payload;
            if (action.payload.data.length) {
                state.paymentList = Object.keys(action.payload.data)
                    .map(function (key) {
                        return action.payload.data[key];
                    });
            }
        },
        [getPaymentList.rejected]: (state, action) => {
            state.paymentList = [];
        },
        [getPendingList.fulfilled]: (state, action) => {
            state.pendingData = action.payload;
            if (action.payload.data.length) {
                state.pendingList = Object.keys(action.payload.data)
                    .map(function (key) {
                        return action.payload.data[key];
                    });
            }
        },
        [getPendingList.rejected]: (state, action) => {
            state.pendingList = [];
        },
        // [getTrendsReports.fulfilled]: (state, action) => {
        //     state.trendsReports = action.payload;
        // },
        // [getTrendsReports.rejected]: (state, action) => {
        //     state.trendsReports = [];
        // }
    }
}
);
const { reducer } = financeSlice;
export default reducer;



