import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { upperCase } from "../../../constants";
import Breadcrumb from "../../Breadcrumb";
import MenuSlider from "./MenuSlider";
import { getMenuList, updateMenu } from "../../../redux/slices/Menu";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { getAllOutlets } from "../../../redux/slices/Outlet";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Addproductcontent = () => {
  const { pathname } = useLocation();
  const { cuisineCategories } = useSelector((state) => state.register);
  const dispatch = useDispatch();
  const history = useHistory();
  const { menuDetails } = useSelector((state) => state.menu);
  const [files, setFiles] = useState();
  const [initialValues, setInitialValues] = useState({
    cusineId: menuDetails.cusineId ? menuDetails.cusineId : "",
    name: menuDetails.name ? menuDetails.name : "",
    course: menuDetails.course ? menuDetails.course : "",
    foodCategory: menuDetails.foodCategory ? menuDetails.foodCategory : "",
    foodType: menuDetails.foodType ? menuDetails.foodType : "",
    ingredients: menuDetails.ingredients ? menuDetails.ingredients : "",
    description: menuDetails.description ? menuDetails.description : "",
    // currency: menuDetails.currency ? menuDetails.currency : "",
    fullPrice: menuDetails.fullPrice ? menuDetails.fullPrice : "",
    status: menuDetails.status ? menuDetails.status : "",
  });
  const validationSchema = Yup.object().shape({
    cusineId: Yup.string().required("Required"),
    name: Yup.string().required("Product Name is required."),
    foodCategory: Yup.string().required("Food Category is required."),
    foodType: Yup.string().required("Food Type is required."),
    ingredients: Yup.string().required("Ingredients is required."),
    description: Yup.string().required("Description is required."),
    // currency: Yup.string().required("Currency is required."),
    fullPrice: Yup.string().required("Full Price is required."),
    status: Yup.string().required("Required"),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const form = new FormData();
      form.append("outletId", localStorage.getItem("outletId"));
      form.append("menuId", menuDetails._id);
      form.append("currency", "inr");

      if (parseFloat(values.fullPrice) <= 0) {
        formik.setErrors({
          fullPrice: "Please enter full price greater than 0.",
        });
        return;
      }

      for (const key in values) {
        if (key === "image") {
          continue;
        } else {
          form.append(key, values[key]);
        }
      }
      if (files) {
        for (let i = 0; i < files.length; i++) {
          form.append("image", files[i]);
        }
      }
      dispatch(updateMenu(form))
        .unwrap()
        .then((res) => {
          toast.success(res.message);
          dispatch(
            getAllOutlets({ vendorId: localStorage.getItem("vendorId") })
          )
            .unwrap()
            .then(() => {
              history.push(`/menu-grid/${localStorage.getItem("outletId")}`);
            });
        })
        .catch((err) => {
          toast.error(err);
        });
    },
  });
  useEffect(() => {
    if (Object.keys(menuDetails).length) {
      console.log(menuDetails);
      setInitialValues({
        cusineId: menuDetails.cusineId ? menuDetails.cusineId : "",
        name: menuDetails.name ? menuDetails.name : "",
        foodCategory: menuDetails.foodCategory ? menuDetails.foodCategory : "",
        foodType: menuDetails.foodType ? menuDetails.foodType : "",
        ingredients: menuDetails.ingredients ? menuDetails.ingredients : "",
        description: menuDetails.description ? menuDetails.description : "",
        course: menuDetails.course ? menuDetails.course : "",
        // currency: menuDetails.currency ? menuDetails.currency : "",
        fullPrice: menuDetails.fullPrice ? menuDetails.fullPrice : "",
        status: menuDetails.status ? menuDetails.status : "",
      });
    } else {
      history.push("/total-outlets");
    }
  }, [menuDetails]);

  console.log('ddddd',formik.values)
  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb
            title="Home"
            links={[
              { to: `/`, name: "Home" },
              {
                to: `/menu-grid/${pathname?.split("u/")[1]?.split("/")[1]}`,
                name: "Menu",
              },
            ]}
          />
        </div>
        <div className="col-xl-6 col-md-12">
          <div className="ms-panel ms-panel-fh">
            <div className="ms-panel-header">
              <h6>Edit Menu Form</h6>
            </div>
            <div className="ms-panel-body">
              <form className="needs-validation clearfix" noValidate>
                <div className="form-row">
                  <div className="col-md-12 mb-3">
                    <label htmlFor="validationCustom18">
                      Product Name<span className="error">*</span>
                    </label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        id="validationCustom18"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        placeholder="Product Name"
                        style={{
                          borderColor:
                            formik.errors.name && formik.touched.name
                              ? "red"
                              : "black",
                        }}
                        required
                      />
                      {formik.errors.name && formik.touched.name && (
                        <div className="invalid-field">
                          {formik.errors.name}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <label htmlFor="validationCustom18">
                      Main Ingredients<span className="error">*</span>
                    </label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        id="validationCustom18"
                        name="ingredients"
                        value={formik.values.ingredients}
                        onChange={formik.handleChange}
                        placeholder="Ingredients"
                        style={{
                          borderColor:
                            formik.errors.ingredients &&
                            formik.touched.ingredients
                              ? "red"
                              : "black",
                        }}
                        required
                      />
                      {formik.errors.ingredients &&
                        formik.touched.ingredients && (
                          <div className="invalid-field">
                            {formik.errors.ingredients}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="validationCustom22">
                      Select Cuisine<span className="error">*</span>
                    </label>
                    <div className="input-group">
                      <select
                        className="form-control"
                        id="validationCustom22"
                        name="cusineId"
                        value={formik.values.cusineId}
                        onChange={formik.handleChange}
                        style={{
                          borderColor:
                            formik.errors.cusineId && formik.touched.cusineId
                              ? "red"
                              : "black",
                        }}
                        required
                      >
                        <option value="">Select Cuisine</option>
                        {cuisineCategories.length > 0 ? (
                          cuisineCategories.map((cuisine) => {
                            return (
                              <option value={cuisine._id}>
                                {" "}
                                {upperCase(cuisine.name)}
                              </option>
                            );
                          })
                        ) : (
                          <option value={0}>Cuisines not found</option>
                        )}
                      </select>
                      {formik.errors.cusineId && formik.touched.cusineId && (
                        <div className="invalid-field">
                          {formik.errors.cusineId}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="validationCustom23">
                      Food Category<span className="error">*</span>
                    </label>
                    <div className="input-group">
                      <select
                        className="form-control"
                        id="validationCustom23"
                        value={formik.values.foodCategory}
                        onChange={formik.handleChange}
                        style={{
                          borderColor:
                            formik.errors.foodCategory &&
                            formik.touched.foodCategory
                              ? "red"
                              : "black",
                        }}
                        name="foodCategory"
                        required
                      >
                        <option value="">Select Food Category</option>
                        <option value="veg">Veg</option>
                        <option value="egg">Egg</option>
                        <option value="nonveg">Non-Veg</option>
                      </select>
                      {formik.errors.foodCategory &&
                        formik.touched.foodCategory && (
                          <div className="invalid-field">
                            {formik.errors.foodCategory}
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="col-md-6 mb-3">
                    <label htmlFor="validationCustom23">
                      Food Type<span className="error">*</span>
                    </label>
                    <div className="input-group">
                      <select
                        className="form-control"
                        id="validationCustom23"
                        value={formik.values.foodType}
                        onChange={formik.handleChange}
                        style={{
                          borderColor:
                            formik.errors.foodType && formik.touched.foodType
                              ? "red"
                              : "black",
                        }}
                        name="foodType"
                        required
                      >
                        <option value="">Select Food Type</option>
                        <option value="food">Food/Beverage</option>
                        <option value="beverage">Alch. Beverage</option>
                      </select>
                      {formik.errors.foodType && formik.touched.foodType && (
                        <div className="invalid-field">
                          {formik.errors.foodType}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div className="col-md-6 mb-3">
                                        <label htmlFor="validationCustom23">Currency</label>
                                        <div className="input-group">
                                            <select
                                                className="form-control"
                                                id="validationCustom23"
                                                value={formik.values.currency}
                                                onChange={formik.handleChange}
                                                style={{
                                                    borderColor:
                                                        formik.errors.currency && formik.touched.currency
                                                            ? "red"
                                                            : "black",
                                                }}
                                                name="currency"
                                                required
                                            >
                                                <option value="">Select currency type</option>
                                                <option value="inr">INR</option>
                                                <option value="usd">USD</option>
                                            </select>
                                            <div className="invalid-feedback">
                                                Please Select a Currency
                                            </div>
                                        </div>
                                    </div> */}
                  <div className="col-md-6 mb-3">
                    <label htmlFor="fullPrice">
                      Full Price<span className="error">*</span>
                    </label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        id="fullPrice"
                        placeholder="Full Price"
                        name="fullPrice"
                        value={formik.values.fullPrice}
                        onChange={formik.handleChange}
                        style={{
                          borderColor:
                            formik.errors.fullPrice && formik.touched.fullPrice
                              ? "red"
                              : "black",
                        }}
                        required
                      />
                      {formik.errors.fullPrice && formik.touched.fullPrice && (
                        <div className="invalid-field">
                          {formik.errors.fullPrice}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div className="col-md-6 mb-3">
                                        <label htmlFor="validationCustom24">Half Price</label>
                                        <div className="input-group">
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="validationCustom24"
                                                name="halfPrice"
                                                value={formik.values.halfPrice}
                                                onChange={formik.handleChange}
                                                style={{
                                                    borderColor:
                                                        formik.errors.halfPrice && formik.touched.halfPrice
                                                            ? "red"
                                                            : "black",
                                                }}
                                                // placeholder="01"
                                                required
                                            />
                                            <div className="invalid-feedback"></div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="validationCustom25">Quat Price</label>
                                        <div className="input-group">
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="validationCustom25"
                                                // placeholder="$10"
                                                name="quatPrice"
                                                value={formik.values.quatPrice}
                                                onChange={formik.handleChange}
                                                style={{
                                                    borderColor:
                                                        formik.errors.quatPrice && formik.touched.quatPrice
                                                            ? "red"
                                                            : "black",
                                                }}
                                                required
                                            />
                                            <div className="invalid-feedback">Price</div>
                                        </div>
                                    </div> */}
                                          <div className="col-md-6 mb-3">
                    <label htmlFor="validationCustom23x">
                      Course<span className="error">*</span>
                    </label>
                    <div className="input-group">
                      <select
                        className="form-control"
                        id="validationCustom23x"
                        value={formik.values.course}
                        onChange={formik.handleChange}
                        style={{
                          borderColor:
                            formik.errors.course &&
                            formik.touched.course
                              ? "red"
                              : "black",
                        }}
                        name="course"
                        required
                      >
                      <option value="">Select</option>
                        {/* <option value="food category">Food Category</option> */}
                        <option value="soups">Soups</option>
                        <option value="starters">Starters</option>
                        <option value="salads">Salads</option>
                        <option value="main course">Main course</option>
                        <option value="breads">Breads</option>
                        <option value="desserts">Desserts</option>
                        <option value="drinks">Drinks</option>
                      </select>
                      {formik.errors.course &&
                        formik.touched.course && (
                          <div className="invalid-field">
                            {formik.errors.course}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <label htmlFor="validationCustom12">
                      Description<span className="error">*</span>
                    </label>
                    <div className="input-group">
                      <textarea
                        rows={5}
                        id="validationCustom12"
                        className="form-control"
                        placeholder="Description"
                        name="description"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        style={{
                          borderColor:
                            formik.errors.description &&
                            formik.touched.description
                              ? "red"
                              : "black",
                        }}
                        required
                      />
                      {formik.errors.description &&
                        formik.touched.description && (
                          <div className="invalid-field">
                            {formik.errors.description}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <label htmlFor="validationCustom12">Product Image</label>
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="validatedCustomFile"
                        multiple
                        onChange={(e) => {
                          setFiles(e.target.files);
                        }}
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="validatedCustomFile"
                      >
                        Upload Images...
                      </label>
                      <div className="invalid-feedback">
                        Example invalid custom file feedback
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-md-12">
          <div className="row">
            <div className="col-md-12">
              <div className="ms-panel">
                <div className="ms-panel-header">
                  <h6>Product </h6>
                </div>
                {(files?.length > 0 || menuDetails?.image?.length > 0) && (
                  <div className="ms-panel-body">
                    <MenuSlider
                      images={
                        files
                          ? Array.from(files)
                          : menuDetails.image
                          ? menuDetails.image
                          : [1]
                      }
                      isEdit={files ? true : false}
                    />
                  </div>
                )}

                <div className="ms-panel-header new">
                  <p className="medium">Status Available</p>
                  <div>
                    <label className="ms-switch">
                      <input
                        type="checkbox"
                        checked={formik.values.status == "active"}
                        onChange={(e) => {
                          let status =
                            e.target.checked === true ? "active" : "inactive";
                          formik.setFieldValue("status", status);
                        }}
                      />
                      <span className="ms-switch-slider round" />
                    </label>
                  </div>
                </div>
                <div className="ms-panel-header new">
                  {/* <button className="btn btn-secondary d-block" type="submit">
                                            Save
                                        </button> */}
                  <button
                    className="btn btn-primary d-block"
                    type="submit"
                    onClick={formik.handleSubmit}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Addproductcontent;
