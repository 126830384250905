import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import OrdersApi from "../services/ordersApi";
export const getOrders = createAsyncThunk(
    "orders/getOrders",
    async (props, thunkAPI) => {
        try {
            const response = await OrdersApi.getOrdersApi(props);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
//update orders
export const updateOrders = createAsyncThunk(
    "orders/updateOrders",
    async (props, thunkAPI) => {
        try {
            const response = await OrdersApi.updateOrdersApi(props);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
//get order Details
export const getOrderDetails = createAsyncThunk(
    "orders/getOrderDetails",
    async (props, thunkAPI) => {
        try {
            const response = await OrdersApi.getOrderDetailsApi(props);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const verifyOrder = createAsyncThunk(
    "orders/verifyOrder",
    async (props, thunkAPI) => {
        try {
            const response = await OrdersApi.verifyOrderApi(props);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
const initialState = {
    orders: [],
    orderDetails: null
};
const ordersSlice = createSlice({
    name: "orders",
    initialState,
    extraReducers: {
        [getOrders.fulfilled]: (state, action) => {
            state.orders = action.payload;
        },
        [getOrders.rejected]: (state, action) => {
            state.orders = [];
        },
        [getOrderDetails.fulfilled]: (state, action) => {
            state.orderDetails = action.payload.data;
        },
        [getOrderDetails.rejected]: (state, action) => {
            state.orderDetails = [];
        },
    }
});
const { reducer } = ordersSlice;
export default reducer;
