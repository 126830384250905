import React, { useState, Fragment, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DownloadTableExcel } from "react-export-table-to-excel";
import "../../../assets/css/menu.css";
import { toast } from "react-toastify";
import DiscountTableRow from "./DiscountTableRow";
import { addDiscount, getDiscountList } from "../../../redux/slices/Discount";
import ReactPaginate from "react-paginate";
import { Col, Row } from "react-bootstrap";
import { upperCase } from "../../../constants";
import { downloadExcel } from "../../../util/fn";
// import { nanoid } from "nanoid";
// import "./App.css";
// import data from "./mock-data.json";

const DiscountTable = () => {
  const { discountList, discountData } = useSelector((state) => state.discount);
  const { outletList } = useSelector((state) => state.outlet);
  const itemsPerPage = 10;
  const tableRef = useRef(null);
  const dispatch = useDispatch();
  const [addFormData, setAddFormData] = useState({
    amount: "",
    title: "",
    discountType: "percent",
    discountCategory: "flat",
    startDate: "",
    endDate: "",
    status: "",
    startTime: "",
    endTime: "",
  });
  const [selectedOutlet, setSelectedOutlet] = useState({
    outletId: "",
    startDate: "",
    endDate: "",
  });
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [minEndDate, setMinEndDate] = useState();
  const [maxStartDate, setMaxStartDate] = useState();
  const [selectedOutletId, setSelectedOutletId] = useState(
    localStorage.getItem("outletId")
  );
  const [overallData, setOverallData] = useState({
    totalDiscount: discountData?.totalDiscount ?? 0,
    totalActive: discountData?.totalActive ?? 0,
    totalInactive: discountData?.totalInactive ?? 0,
  });

  // Fetch items from another resources.
  useEffect(() => {
    if (discountList) {
      const endOffset = itemOffset + itemsPerPage;
      // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      setCurrentItems(discountList.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(discountList.length / itemsPerPage));
    } else {
      setCurrentItems(null);
      setPageCount(0);
    }
  }, [itemOffset, itemsPerPage, discountList]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % discountList.length;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    setItemOffset(newOffset);
  };
  const handleAddFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;
    newFormData["title"] = "discount";

    setAddFormData(newFormData);
  };

  const handleAddFormSubmit = (event) => {
    console.log("addFormData", addFormData, selectedOutletId);
    event.preventDefault();
    dispatch(
      addDiscount({
        ...addFormData,
        outletId: selectedOutletId
          ? selectedOutletId
          : localStorage.getItem("outletId"),
      })
    )
      .unwrap()
      .then((res) => {
        toast.success(res.message);
        dispatch(
          getDiscountList({
            outletId: selectedOutletId
              ? selectedOutletId
              : localStorage.getItem("outletId"),
          })
        );
        const form = document.getElementById("menu_form");
        form.reset();
      })
      .catch((err) => {
        toast.error(err);
      });
  };
  const filterData = (e) => {
    setSelectedOutletId(e.target.value);
    setSelectedOutlet({
      ...selectedOutlet,
      outletId: e.target.value,
    });
    if (e.target.value == "") {
      setOverallData({ totalDiscount: 0, totalActive: 0, totalInactive: 0 });
      setCurrentItems(null);
    } else {
      if (e.target.name === "outletId") {
        //   dispatch(
        //     getDiscountList({
        //       ...selectedOutlet,
        //       outletId: e.target.value,
        //     })
        //   ).then((res) => {
        //     // paginateEffect(res.payload.data);
        //   });
      }

      if (e.target.name === "startDate" || e.target.name === "endDate") {
        setSelectedOutlet({
          ...selectedOutlet,
          [e.target.name]: e.target.value,
        });
        // if (selectedOutlet.outletId !== "") {
        //   dispatch(
        //     getDiscountList({
        //       ...selectedOutlet,
        //       [e.target.name]: e.target.value,
        //     })
        //   );
        // } else {
        //   dispatch(
        //     getDiscountList({
        //       ...selectedOutlet,
        //       [e.target.name]: e.target.value,
        //       outletId: localStorage.getItem("outletId"),
        //     })
        //   );
        // }
      }

      const startDate = document.getElementById("startDate").value;
      const endDate = document.getElementById("endDate").value;
      dispatch(
        getDiscountList({
          startDate,
          endDate,
          outletId: e.target.value,
        })
      );
    }
  };
  useEffect(() => {
    dispatch(
      getDiscountList({
        outletId: selectedOutletId
          ? selectedOutletId
          : localStorage.getItem("outletId"),
      })
    );
    if (localStorage.getItem("outletId")) {
      setSelectedOutlet({
        ...selectedOutlet,
        outletId: localStorage.getItem("outletId"),
      });
    }
  }, [dispatch]);

  const downloadExcelHandler = () => {
    let data = [];
    if (currentItems && currentItems.length > 0) {
      let count = 1;
      currentItems.forEach((obj) => {
        data.push({
          "Sr.": count,
          "Percentage(%)": obj?.discountAmount + "%",
          Title: obj?.title,
          "Start Date": obj?.startDate,
          "End Date": obj?.endDate,
          Status: obj?.status,
          "Discount on": obj?.discount_on,
        });
        count = count + 1;
      });
    } else {
      data.push({
        "Sr.": "",
        "Percentage(%)": "",
        Title: "",
        "Start Date": "",
        "End Date": "",
        Status: "",
        "Discount on": "",
      });
    }
    downloadExcel(data, "Coupons");
  };

  const filterHandler = () => {
    const startDate = document.getElementById("startDate").value;
    const endDate = document.getElementById("endDate").value;
    dispatch(
      getDiscountList({
        startDate,
        endDate,
        outletId: selectedOutletId,
      })
    );
  };

  const handleStartDate = (e) => {
    if (e.target.value) {
      setMinEndDate(e.target.value);
    } else {
      setMinEndDate("");
    }
  };

  const handleEndDate = (e) => {
    if (e.target.value) {
      setMaxStartDate(e.target.value);
    } else {
      setMaxStartDate("");
    }
  };

  useEffect(() => {
    setOverallData({
      totalDiscount: discountData?.totalDiscount ?? 0,
      totalActive: discountData?.totalActive ?? 0,
      totalInactive: discountData?.totalInactive ?? 0,
    });
  }, [discountData]);

  return (
    <form className="bg-clr" id="menu_form" onSubmit={handleAddFormSubmit}>
      <div className="menu">
        <Row>
          <Col md={6}>
            <div className="menu-selectiom">
              <h4 className="menu-h2">Discounts</h4>
            </div>
          </Col>
          <Col md={6}>
            {localStorage.getItem("vendorType") === "multiple" && (
              <div
                className="outletDropdown"
                style={{
                  padding: "0 30px 0",
                  display: "inline-block",
                  width: "100%",
                }}
              >
                <div className="float-right d-flex">
                  <label
                    htmlFor="validationCustom01"
                    className="form-label mr-3 mt-2"
                  >
                    Select Outlet
                  </label>
                  <div className="dropdown-group form-vendor-regi">
                    <select
                      className="form-control"
                      id="validationCustom01"
                      name="outletId"
                      onChange={filterData}
                      value={selectedOutlet?.outletId}
                    >
                      <option value={""}>Please Select</option>
                      {outletList.length ? (
                        outletList.map((item) => {
                          return (
                            <option value={item.outletId}>
                              {upperCase(item.outletName)}
                            </option>
                          );
                        })
                      ) : (
                        <option value={0}> Not found</option>
                      )}
                    </select>
                    {/* <div className="invalid-feedback">
                            Please Select a valid Designation.
                          </div> */}
                  </div>
                </div>
              </div>
            )}
          </Col>
        </Row>
        <Row className="px-3 mt-3 mb-3">
          <Col md={4}>
            <div className="startDate">
              {/* date picker input */}
              <label htmlFor="validationCustom01" className="form-label">
                Start Date
              </label>
              <input
                type="date"
                className="form-control"
                id="startDate"
                name="startDate"
                placeholder="Start Date"
                max={maxStartDate}
                onChange={handleStartDate}
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="endDate">
              <label htmlFor="validationCustom01" className="form-label">
                End Date
              </label>
              <input
                type="date"
                className="form-control"
                id="endDate"
                name="endDate"
                placeholder="End Date"
                min={minEndDate}
                onChange={handleEndDate}
              />
            </div>
          </Col>
          <Col md={2}>
            <button
              type="button"
              onClick={filterHandler}
              className="excle-dowm"
            >
              {" "}
              Filter{" "}
            </button>
          </Col>
          <Col md={2}>
            {/* <DownloadTableExcel
              filename="Coupons"
              sheet="Couponss"
              currentTableRef={tableRef.current}
            >
              <button className="excle-dowm"> Export excel </button>
            </DownloadTableExcel> */}
            <button
              type="button"
              onClick={downloadExcelHandler}
              className="excle-dowm"
            >
              {" "}
              Export excel{" "}
            </button>
          </Col>
        </Row>
        <div className="dh-section mt-4">
          {/* <div className="row">
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                <div className="ms-card-body media">
                  <div className="media-body">
                    <span className="black-text">
                      <strong>Total Discount</strong>
                    </span>
                    <h2>{overallData.totalDiscount}</h2>
                  </div>
                </div>
               
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
            
                <div className="ms-card-body media">
                  <div className="media-body">
                    <span className="black-text">
                      <strong>Active Coupon</strong>
                    </span>
                    <h2>{overallData.totalActive}</h2>
                  </div>
                </div>
              
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6">
              <div className="ms-card ms-widget has-graph-full-width ms-infographics-widget">
                <div className="ms-card-body media">
                  <div className="media-body">
                    <span className="black-text">
                      <strong>InActive Coupon</strong>
                    </span>
                    <h2>{overallData.totalInactive}</h2>
                  </div>
                </div>
              
              </div>
            </div>
          </div> */}
        </div>
        <table className="menu-table mt-4" ref={tableRef}>
          <caption>Coupons</caption>
          <thead>
            <tr>
              <th>Sr.</th>
              <th>Percentage(%)</th>
              <th>Title</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Status</th>
              {/* <th>Discount on</th> */}
              <th>Enter/Update/Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr className="form-vendor-regi">
              <td>0</td>

              <td>
                <input
                  type="number"
                  name="amount"
                  required="required"
                  max={100}
                  min={0}
                  placeholder="Enter discount..."
                  onChange={handleAddFormChange}
                />
              </td>
              <td>
                <input
                  type="text"
                  name="title"
                  required="required"
                  placeholder="Enter title..."
                  value="discount"
                  readOnly
                  // onChange={handleAddFormChange}
                />
              </td>
              <td>
                <input
                  type="date"
                  name="startDate"
                  min={new Date().toISOString().split("T")[0]}
                  required="required"
                  placeholder="Enter Start Date..."
                  onChange={handleAddFormChange}
                />
              </td>
              <td>
                <input
                  type="date"
                  name="endDate"
                  min={addFormData?.startDate}
                  disabled={addFormData?.startDate == ""}
                  required="required"
                  placeholder="Enter End Date..."
                  onChange={handleAddFormChange}
                />
              </td>
              <td>
                <input
                  type="time"
                  name="startTime"
                  required="required"
                  placeholder="Enter Start startTime..."
                  onChange={handleAddFormChange}
                />
              </td>
              <td>
                <input
                  type="time"
                  name="endTime"
                  required="required"
                  placeholder="Enter End time..."
                  onChange={handleAddFormChange}
                />
              </td>
              {/* <td>
                <select
                  // className="form-control"
                  name="status"
                  onChange={handleAddFormChange}
                  required
                >
                  <option value="">Select Status</option>
                  <option value="active">Active</option>
                  <option value="inactive">InActive</option>
                </select>
              </td> */}
              <td>
                <select
                  // className="form-control"
                  name="discount_on"
                  onChange={handleAddFormChange}
                  required
                >
                  <option value="">Please Select</option>
                  <option value="food">Food/Beverage</option>
                  <option value="beverage">Alc. Beverage</option>
                  <option value="both">Both</option>
                </select>
              </td>
              <td>
                <button
                  className="ms-btn-icon-outline btn-square btn-success"
                  type="submit"
                >
                  <i className="flaticon-tick-inside-circle mr-0"></i>
                </button>
              </td>
            </tr>
            {currentItems &&
              currentItems.map((coupon, index) => (
                <Fragment>
                  {
                    <DiscountTableRow
                      coupon={coupon}
                      index={discountList?.indexOf(coupon)}
                      outlet={selectedOutlet.outletId}
                    />
                  }
                </Fragment>
              ))}
          </tbody>
        </table>
        {currentItems && (
          <ReactPaginate
            nextLabel="Next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="< Previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        )}
      </div>
    </form>
  );
};

export default DiscountTable;
