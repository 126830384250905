import React, { useState, useEffect } from 'react';
import { Bar as BarChart } from 'react-chartjs-2';

// Bar chart data function
function chartData1(props) {
    const values = Object.values(props.data);
    let yesData = [];
    let noData = [];
    let allData = [];

    if (values) {
        values.forEach((obj) => {
            Object.keys(obj).forEach((key) => {
                if (key === "Yes") {
                    yesData.push(obj[key]);
                    if (!allData.includes(obj[key])) {
                        allData.push(obj[key]);
                    }
                } else if (key === "No") {
                    noData.push(obj[key]);
                    if (!allData.includes(obj[key])) {
                        allData.push(obj[key]);
                    }
                }
            });
        });
    }

    const allDataMax = Math.max(...allData);
    return {
        labels: Object.keys(props.data),
        datasets: [
            {
                label: "Yes",
                backgroundColor: "#3e95cd",
                data: yesData
            }, {
                label: "No",
                backgroundColor: "#8e5ea2",
                data: noData
            }
        ]
    };
}

const Groupedbarchart = (props) => {
    const [data1, setData1] = useState(chartData1(props));
    const [options] = useState({
        title: {
            display: true,
            text: 'Net Promoter Score (NPS)'
        },
        scales: {
            yAxes: [{
                ticks: {
                    max: props.totalReview ? props.totalReview : 0,
                    min: 0,
                }
            }]
        },
    });

    useEffect(() => {
        setData1(chartData1(props));
    }, [props]);

    return (
        <BarChart data={data1} options={options} />
    );
};

export default Groupedbarchart;
