import React, { Component, useEffect } from 'react';
import Detailcontent from '../sections/Productsdetail/Detailcontent.jsx';

const Productdetail = () => {
    return (
        <Detailcontent />  
    );

}

export default Productdetail;