import React, { Component } from 'react';
import DashboardContent from '../sections/Dashboard/DashboardContent';

class Dashboard extends Component {
    render() {
        return (
            <DashboardContent /> 
        );
    }
}

export default Dashboard;