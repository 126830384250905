import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import "../Steps/styles.css";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { stepFourSave } from "../../../../redux/slices/SaveDetails";
import { registerStepFour } from "../../../../redux/slices/Registration";
import { toast } from "react-toastify";
import loginLogo from "../../../../assets/img/logo.png";
import { Col, Row } from "react-bootstrap";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";

const StepFour = ({ setNextStep, nextStep }) => {

  const dispatch = useDispatch();
  const { outletId, vendorType } = useSelector((state) => state.register);
  const { stepFour } = useSelector((state) => state.saveDetails);
  const [textCount, setTextCount] = useState();
  const [isImageSelected, setIsImageSelected] = React.useState(false);
  const [commisionValue, setCommisionValue] = React.useState({
    min: 0,
    max: 100,
  });
  const [files, setFiles] = React.useState([]);
  const [facadeImage, setfacadeImage] = React.useState([]);
  const [interiorsImage, setinteriorsImage] = React.useState([]);
  const [sittingImage, setsittingImage] = React.useState([]);
  const [staffImage, setstaffImage] = React.useState([]);
  const [initialValues, setInitialValues] = React.useState(
    stepFour
      ? {
        description: stepFour.description,
        coverPicture: stepFour.coverPicture ? stepFour.coverPicture : null,
        facadeImage: stepFour.facadeImage ? stepFour.facadeImage : null,
        interiorsImage: stepFour.interiorsImage
          ? stepFour.interiorsImage
          : null,
        sittingImage: stepFour.sittingImage ? stepFour.sittingImage : null,
        staffImage: stepFour.staffImage ? stepFour.staffImage : null,
      }
      : {
        description: "",
        coverPicture: null,
        sittingImage: null,
        interiorsImage: null,
        facadeImage: null,
      }
  );
  const validationSchema =
    vendorType == "single"
      ? Yup.object().shape({
        description: Yup.string()
          .required()
          .min(50, "Description must be minimum of 50 characters"),
        coverPicture: Yup.mixed().required("Cover picture is required"),
        facadeImage: Yup.mixed().required("Facade picture is required"),
        // interiorsImage: Yup.mixed().required("Interior picture is required"),
        // sittingImage: Yup.mixed().required("Sitting picture is required"),
      })
      : Yup.object().shape({
        // description: Yup.string()
        //   .required()
        //   .min(50, "Description must be minimum of 50 characters"),
        coverPicture: Yup.mixed().required("Cover picture is required"),
        facadeImage: Yup.mixed().required("Facade picture is required"),
      });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      let data = new FormData();
      if (facadeImage) {
        if (facadeImage.length <= 0) {
          formik.setFieldValue("facadeImage", null);
          // formik.setFieldError("facadeImage", "Facade picture is required");
        }
        for (let i = 0; i < facadeImage.length; i++) {
          data.append("facadeImage", facadeImage[i]);
        }
      } else {
      }
      if (interiorsImage.length) {
        for (let i = 0; i < interiorsImage.length; i++) {
          data.append("interiorsImage", interiorsImage[i]);
        }
      }
      if (sittingImage.length) {
        for (let i = 0; i < sittingImage.length; i++) {
          data.append("sittingImage", sittingImage[i]);
        }
      } else {
        formik.setFieldValue("sittingImage", null);
      }

      if (staffImage.length) {
        for (let i = 0; i < staffImage.length; i++) {
          data.append("staffImage", staffImage[i]);
        }
      } else {
        formik.setFieldValue("staffImage", null);
      }
      if (files.length) {
        for (let i = 0; i < files.length; i++) {
          data.append("otherImage", files[i]);
        }
      } else {
        formik.setFieldValue("otherImage", null);
      }

      data.append("coverPicture", values.coverPicture);
      data.append("aboutVendor", values.description);
      data.append("outletId", outletId);
      if (vendorType == "single") {
        if (
          facadeImage.length > 0 &&
          interiorsImage.length > 0 &&
          sittingImage.length > 0
        ) {
          dispatch(
            stepFourSave({
              coverPicture: values.coverPicture,
              description: values.description,
              image: files,
              facadeImage: facadeImage,
              interiorsImage: interiorsImage,
              sittingImage: sittingImage,
              staffImage: staffImage,
            })
          );
          dispatch(registerStepFour(data))
            .unwrap()
            .then((res) => {
              toast.success(res.message);
              setNextStep(5);
              // if (files) {
              // }
            })
            .catch((err) => {
              toast.error(err);
            });
        }
      } else {
        if (facadeImage.length > 0) {
          dispatch(
            stepFourSave({
              coverPicture: values.coverPicture,
              description: values.description,
              image: files,
              facadeImage: facadeImage,
              interiorsImage: interiorsImage,
              sittingImage: sittingImage,
              staffImage: staffImage,
            })
          );
          dispatch(registerStepFour(data))
            .unwrap()
            .then((res) => {
              toast.success(res.message);
              setNextStep(5);
              // if (files) {
              // }
            })
            .catch((err) => {
              toast.error(err);
            });
        }
      }
    },
  });
  useEffect(() => {
    if (stepFour) {
      setInitialValues({
        description: stepFour.description,
        coverPicture: stepFour.coverPicture.length
          ? stepFour.coverPicture
          : null,
        facadeImage: stepFour.facadeImage.length ? stepFour.facadeImage : null,
        interiorsImage: stepFour.interiorsImage.length
          ? stepFour.interiorsImage
          : null,
        sittingImage: stepFour.sittingImage.length
          ? stepFour.sittingImage
          : null,
        staffImage: stepFour.staffImage.length ? stepFour.staffImage : null,
      });
      if (stepFour.facadeImage) {
        setfacadeImage(stepFour.facadeImage);
      }
      if (stepFour.interiorsImage) {
        setinteriorsImage(stepFour.interiorsImage);
      }
      if (stepFour.sittingImage) {
        setsittingImage(stepFour.sittingImage);
      }
      if (stepFour.staffImage) {
        setstaffImage(stepFour.staffImage);
      }
      if (stepFour.image) {
        setFiles(stepFour.image);
      }
    }
  }, []);
  const renderSelectedFiles = (multifiles, setmultifiles, id) => {
    if (multifiles) {
      return (
        <>
          {Array.from(multifiles).map((file, index) => (
            <div
              key={index}
              className="img-pos col-md-4"
              style={{ maxWidth: "30%" }}
            >
              <span
                className="close"
                onClick={(e) => {
                  e.preventDefault();
                  let arrFiles = Array.from(multifiles);
                  arrFiles.splice(index, 1);
                  setmultifiles(arrFiles);
                  document.getElementById(id).value = "";
                }}
                style={{ cursor: "pointer" }}
              >
                &times;
              </span>
              {/* <img
                src={URL.createObjectURL(file)}
                alt="preview"
                style={{ width: "200px" }}
                onError={(e) => {
                  e.target.src = "";
                  e.target.alt = file.name;
                }}
              /> */}
              <div>{file.name}</div>
            </div>
          ))}
        </>
      );
    }
  };
  const renderFilePreview = (src, name) => {
    return <div className="img-pos col-md-4">{name}</div>;
  };

  const rendercoverPicture = () => {
    if (formik.values.coverPicture) {
      return (
        <>
          {renderFilePreview(
            formik.values.coverPicture,
            formik.values.coverPicture.name
          )}
        </>
      );
    }
  };

  return (
    <div className="ms-content-wrapper ms-auth">
      <div className="regi-section">
        <Row>
          <Col md={5}>
            <div className="ms-auth-bg">
              <img src={loginLogo} alt="loginLogo" />
            </div>
          </Col>
          <Col md={7}>
            <div className="regi-form">
              <form
                className="needs-validation"
                onSubmit={formik.handleSubmit}
                style={{ width: "100%" }}
              >
                <h3>
                   Registration : Step <span>{nextStep} of 6</span>
                </h3>
                <p>Please enter information to continue</p>

                <div className="form-vendor-regi">
                  {vendorType == "single" && (
                    <div className="form-vendor-regi">
                      <label htmlFor="validationCustom03">
                        Describe Your Restaurant (minimum of 50 characters and
                        max of 200 chars)<span className="error">*</span> {textCount ? `(${textCount})` : ''}
                      </label>
                      <div className="input-group">
                        <textarea
                          type="text"
                          minLength={50}
                          maxLength={200}
                          className="form-control"
                          id="validationCustom03"
                          name="description"
                          onChange={(action) => {
                            formik.setFieldValue("description", action.target.value)
                            const length = action.target.value.length;
                            setTextCount(length)
                          }}

                          value={formik.values.description}
                          placeholder="Description"
                        ></textarea>
                        <div className="invalid-feedback">
                          Please enter description.
                        </div>
                      </div>
                      {formik.errors.description &&
                        formik.touched.description && (
                          <div className="error">
                            {formik.errors.description}
                          </div>
                        )}
                    </div>
                  )}
                  {/* <Row style={{ marginBottom: "25px" }}>
                    <Col md={6}>
                      <label
                        htmlFor="foodLicence"
                        className="form-label customLabel"
                      >
                        Commision
                      </label>
                      <InputRange
                        maxValue={100}
                        minValue={0}
                        value={commisionValue}
                        onChange={(commisionValue) => {
                          setCommisionValue(commisionValue);
                        }}
                      />
                    </Col>
                  </Row> */}
                  <Row>
                    <Col md={6}>
                      <label
                        htmlFor="foodLicence"
                        className="form-label customLabel"
                      >
                        Hotel Picture <span className="error">*</span>
                      </label>
                      <div className="input-group">
                        <input
                          type="file"
                          accept="image/*"
                          className="uploadFile"
                          id="foddFile"
                          placeholder="Upload Food Licence"
                          name="coverPicture"
                          onChange={(e) =>
                            formik.setFieldValue(
                              "coverPicture",
                              e.currentTarget.files[0]
                            )
                          }
                        />
                        <div>
                          {formik.errors.coverPicture &&
                            formik.touched.coverPicture && (
                              <div className="error">
                                {formik.errors.coverPicture}
                              </div>
                            )}
                        </div>
                      </div>
                      {rendercoverPicture()}
                    </Col>
                    <Col md={6}>
                      <label htmlFor="validationCustom03">
                        Hotel Façade <span className="error">*</span>
                      </label>
                      <div className="input-group">
                        <input
                          type="file"
                          accept="image/*"
                          className="uploadFile"
                          id="uploadFacade"
                          name="facadeImage"
                          placeholder="Upload Images"
                          style={{ color: "transparent" }}
                          multiple
                          onChange={(e) => {
                            formik.setFieldValue(
                              "facadeImage",
                              e.currentTarget.files[0]
                            );
                            setfacadeImage(e.target.files);
                          }}
                        />
                        <div>
                          {formik.errors.facadeImage &&
                            formik.touched.facadeImage && (
                              <div className="error">
                                {formik.errors.facadeImage}
                              </div>
                            )}
                        </div>
                      </div>
                      <div
                        className="row"
                        style={{
                          margin: "auto",
                          overflow: "auto",
                          maxHeight: "40vh",
                        }}
                      >
                        {renderSelectedFiles(
                          facadeImage,
                          setfacadeImage,
                          "uploadFacade"
                        )}
                      </div>
                    </Col>
                    {vendorType == "single" && (
                      <>
                        <Col md={6}>
                          <label htmlFor="validationCustom03">
                            Interiors / Décor
                          </label>
                          <div className="input-group">
                            <input
                              type="file"
                              accept="image/*"
                              className="uploadFile"
                              id="uploadInterior"
                              name="interiorsImage"
                              placeholder="Upload Images"
                              style={{ color: "transparent" }}
                              multiple
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "interiorsImage",
                                  e.currentTarget.files[0]
                                );
                                setinteriorsImage(e.target.files);
                              }}
                            />
                            {/* <div>
                              {formik.errors.interiorsImage &&
                                formik.touched.interiorsImage && (
                                  <div className="error">
                                    {formik.errors.interiorsImage}
                                  </div>
                                )}
                            </div> */}
                          </div>
                          <div
                            className="row"
                            style={{
                              margin: "auto",
                              overflow: "auto",
                              maxHeight: "40vh",
                            }}
                          >
                            {renderSelectedFiles(
                              interiorsImage,
                              setinteriorsImage,
                              "uploadInterior"
                            )}
                          </div>
                        </Col>
                        <Col md={6}>
                          <>
                            <label htmlFor="validationCustom03">
                              Sitting Arrangements
                            </label>
                            <div className="input-group">
                              <input
                                type="file"
                                accept="image/*"
                                className="uploadFile"
                                id="uploadSitting"
                                name="sittingImage"
                                placeholder="Upload Images"
                                style={{ color: "transparent" }}
                                multiple
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "sittingImage",
                                    e.currentTarget.files[0]
                                  );
                                  setsittingImage(e.target.files);
                                }}
                              />
                              {/* <div>
                                {formik.errors.sittingImage &&
                                  formik.touched.sittingImage && (
                                    <div className="error">
                                      {formik.errors.sittingImage}
                                    </div>
                                  )}
                              </div> */}
                            </div>

                            <div
                              className="row"
                              style={{
                                margin: "auto",
                                overflow: "auto",
                                maxHeight: "40vh",
                              }}
                            >
                              {renderSelectedFiles(
                                sittingImage,
                                setsittingImage,
                                "uploadSitting"
                              )}
                            </div>
                          </>
                        </Col>
                        <Col md={6}>
                          <>
                            <label htmlFor="validationCustom03">
                              Team / Staff
                            </label>
                            <div className="input-group">
                              <input
                                type="file"
                                accept="image/*"
                                className="uploadFile"
                                id="uploadStaff"
                                name="images"
                                placeholder="Upload Images"
                                style={{ color: "transparent" }}
                                multiple
                                onChange={(e) => {
                                  setstaffImage(e.target.files);
                                }}
                              />
                            </div>
                            <div
                              className="row"
                              style={{
                                margin: "auto",
                                overflow: "auto",
                                maxHeight: "40vh",
                              }}
                            >
                              {renderSelectedFiles(
                                staffImage,
                                setstaffImage,
                                "uploadStaff"
                              )}
                            </div>
                          </>
                        </Col>
                        <Col md={6}>
                          <>
                            <label htmlFor="validationCustom03">
                              Other Image
                            </label>
                            <div className="input-group">
                              <input
                                type="file"
                                accept="image/*"
                                className="uploadFile"
                                id="uploadFile"
                                name="images"
                                placeholder="Upload Images"
                                style={{ color: "transparent" }}
                                multiple
                                onChange={(e) => {
                                  setFiles(e.target.files);
                                }}
                              />
                              <div className="invalid-feedback">
                                Please upload a image.
                              </div>
                            </div>
                            <div
                              className="row"
                              style={{
                                margin: " auto",
                                overflow: "auto",
                                maxHeight: "40vh",
                              }}
                            >
                              {renderSelectedFiles(
                                files,
                                setFiles,
                                "uploadFile"
                              )}
                            </div>
                          </>
                        </Col>
                      </>
                    )}
                  </Row>
                </div>

                {isImageSelected && (
                  <div className="error">{"please select all Images"}</div>
                )}
                <div className="row mt-4">
                  <div className="col-md-6">
                    <button
                      className="steps-btn float-left"
                      type="button"
                      onClick={() => {
                        if (vendorType == "single") {
                          setNextStep(3);
                        } else {
                          setNextStep(2);
                        }
                      }}
                    >
                      <i>&#8592;</i> Previous
                    </button>
                  </div>
                  <div className="col-md-6">
                    <button
                      className="steps-btn"
                      type="submit"
                    // onClick={() => setNextStep(5)}
                    >
                      Next <i>&#8594;</i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default StepFour;

