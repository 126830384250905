import React, { Component } from 'react';
import OrderContent from './Order/OrderContent';

class OrderDetail extends Component {
    render() {
        return (
            <OrderContent />
        );
    }
}

export default OrderDetail;