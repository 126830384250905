import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import {
  getDiscountList,
  removeDiscount,
  updateDiscount,
} from "../../../redux/slices/Discount";
import ModalEdit from "./ModalEdit";

const DiscountTableRow = (props) => {

  const dispatch = useDispatch();
  const handleEditClick = (discountId) => {

    Swal.fire({
      title: "Submit Update Status",
      input: "select",
      inputOptions: {
        active: "Active",
        inactive: "Inactive",
      },
      showCancelButton: true,
      confirmButtonText: "Ok",
      showLoaderOnConfirm: true,
    }).then(function (result) {
      if (result.isConfirmed) {
        dispatch(
          updateDiscount({
            couponId: discountId,
            status: result.value,
          })
        )
          .unwrap()
          .then(() => {
            dispatch(
              getDiscountList({
                outletId: props.outlet,
              })
            );
            Swal.fire({
              icon: "success",
              html: "Discount Coupon updated successfully",
            });
          })
          .catch(() => {
            Swal.fire({
              icon: "error",
              html: "Something went wrong",
            });
          });
      }
    });
  };


  const handleDeleteClick = (discountId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        if (props.outlet != "") {
          dispatch(
            removeDiscount({
              discountId: discountId,
            })
          )
            .unwrap()
            .then((res) => {
              Swal.fire("Deleted!", "Remove Successfully.", "success");
              dispatch(
                getDiscountList({
                  outletId: props.outlet,
                })
              );
            });
        } else {
          toast.error("Please Select the outlet first");
        }
      }
    });
  };
  return (
    <>
      <tr>
        <td>{props.index + 1}</td>
        {/* <td>
        {cuisineCategories.length &&
          findInArray(cuisineCategories, props.menu.cusineId)}
        </td> */}
        <td>{props.coupon.discountAmount}%</td>
        <td>{props.coupon?.title}</td>
        <td>{props.coupon.startDate}</td>
        <td>{props.coupon.endDate}</td>
        <td>{props.coupon.startTime}</td>
        <td>{props.coupon.endTime}</td>
        <td>{props.coupon.status}</td>
        <td>{props.coupon?.discount_on}</td>

        <td>
          <button
            className="menu-button"
            type="button"
            onClick={() => {
              handleEditClick(props.coupon.couponId);
            }}
          >
            Update
          </button>
          <button
            type="button"
            className="menu-button"
            onClick={() => handleDeleteClick(props.coupon.couponId)}
          >
            Delete
          </button>
        </td>
      </tr>

    </>
  );
};

export default DiscountTableRow;

