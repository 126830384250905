import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Button, Form, Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { Link, useHistory } from "react-router-dom";
import { BiCurrentLocation } from "react-icons/bi";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { useForm } from "react-hook-form";
import Select from "react-select";
import loginLogo from "../../assets/img/logo.png";
import useRequest from "../../hooks/useRequest";
import { upperCase } from "../../constants";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import SimpleMap from "../../components/GoogleMaps";
import { stepFiveSave } from "../../redux/slices/SaveDetails";
import {
  verifyAddress,
  registerStepFive,
} from "../../redux/slices/Registration";

const libraries = ["places"];
const mapContainerStyle = {
  width: "100%",
  height: "400px",
};
const center = {
  lat: 37.7749, // Default latitude (San Francisco for example)
  lng: -122.4194, // Default longitude
};

const StepFive = ({ nextStep, handleNextStep }) => {
  const dispatch = useDispatch();

  const { response: responseCountry, request: requestCountry } = useRequest();
  const { response: responseState, request: requestState } = useRequest();
  const { response: responseCity, request: requestCity } = useRequest();

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [latLong, setLatLong] = useState({
    latitude: "",
    longitude: "",
  });
  const [touchedError, setTouchedError] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [address, setAddress] = useState("");
  const [latelng, setatLng] = useState("");
  const { stepFive } = useSelector((state) => state.saveDetails);
  const { vendorId, outletId } = useSelector((state) => state.register);
  const [formData, setFormData] = useState({});

  const [markerPosition, setMarkerPosition] = useState(center);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDaQcyEyDvVeB8GG3NOzqtXB1i8ukYqcgs", // Make sure to replace with your own key
    libraries,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
    setValue,
  } = useForm();

  useEffect(() => {
    requestCountry("GET", "getCountry");
  }, []);

  useEffect(() => {
    if (responseCountry) {
      if (
        responseCountry?.status == "success" ||
        responseCountry?.status == true
      ) {
        setCountries(
          responseCountry?.data?.length > 0 ? responseCountry?.data : []
        );
      }
    }
  }, [responseCountry]);

  useEffect(() => {
    if (stepFive) {
      setValue("address", stepFive?.address ?? "");
      setValue("country", stepFive?.country ?? "");
      setValue("state", stepFive?.state ?? "");
      setValue("city", stepFive?.city ?? "");
      setValue("street", stepFive?.street ?? "");
      setValue("pincode", stepFive?.pincode ?? "");
      setValue("landMark", stepFive?.landMark ?? "");

      if (stepFive?.country) {
        requestState("POST", "getState", { countryId: stepFive?.country });
      }
      if (stepFive?.state) {
        requestCity("POST", "getCity", { stateId: stepFive?.state });
      }
    }
  }, [stepFive]);

  const onSubmit = (data) => {
    setLoader(true);
    console.log("dta", data);
    setFormData(data);

    dispatch(
      registerStepFive({
        ...data,
        vendorId: vendorId,
        outletId: outletId,
        latitude: getValues("latitude") ? getValues("latitude") : "",
        longitude: getValues("longitude") ? getValues("longitude") : "",
      })
    )
      .unwrap()
      .then((res) => {
        toast.success(res.message);
        setModalShow(false);
        dispatch(stepFiveSave(formData));
        handleNextStep(6);
      })
      .catch((err) => {
        toast.error(err);
      });
    // dispatch(verifyAddress(data))
    //   .unwrap()
    //   .then((res) => {
    //     setValue("latitude", res.data.lat);
    //     setValue("longitude", res.data.lng);

    //     setLatLong({
    //       latitude: res?.data?.lat ? res.data.lat : "",
    //       longitude: res?.data?.lng ? res.data.lng : "",
    //     });
    //     setModalShow(true);
    //     toast.success("Address has been verified successfully.");
    //   })
    //   .catch((err) => {
    //     toast.error(err);
    //     setLoader(false);
    //   });
  };

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        backdrop="static"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Confirm Your Address
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{SimpleMap(latLong)}</Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              props.onHide();
              props.setLoader(false);
            }}
          >
            Change Address
          </Button>
          <Button onClick={handleShowOtpModal}>Confirm</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const handleShowOtpModal = async () => {
    setLatLong({
      latitude: getValues("latitude") ? getValues("latitude") : "",
      longitude: getValues("longitude") ? getValues("longitude") : "",
    });
    dispatch(
      registerStepFive({
        ...formData,

        vendorId: vendorId,
        outletId: outletId,
        latitude: getValues("latitude") ? getValues("latitude") : "",
        longitude: getValues("longitude") ? getValues("longitude") : "",
      })
    )
      .unwrap()
      .then((res) => {
        toast.success(res.message);
        setModalShow(false);
        dispatch(stepFiveSave(formData));
        handleNextStep(6);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  useEffect(() => {
    if (responseState) {
      if (responseState?.status == "success" || responseState?.status == true) {
        setStates(responseState?.data?.length ? responseState?.data : []);
      }
    }
  }, [responseState]);

  useEffect(() => {
    if (responseCity) {
      if (responseCity?.status == "success" || responseCity?.status == true) {
        setCities(responseCity?.data?.length ? responseCity?.data : []);
      }
    }
  }, [responseCity]);

  const handleChangess = (address) => {
    setAddress(address);
  };

  // const handleSelect = (address) => {
  //   setAddress(address);
  //   setValue("address", address);
  //   geocodeByAddress(address)
  //     .then((results) => getLatLng(results[0]))
  //     .then((latLng) => setatLng(latLng))
  //     .catch((error) => console.error("Error", error));
  // };

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setValue("address", results[0]?.formatted_address);
    setValue("latitude", latLng?.lat);
    setValue("longitude", latLng?.lng);
    setMarkerPosition(latLng);
    setAddress(value);
  };

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";

  const geocodeLatLng = (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    const latLng = {
      lat: parseFloat(lat),
      lng: parseFloat(lng),
    };

    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          // formik.setFieldValue(
          //   "pincode",
          //   results[0].formatted_address?.split(",")[2]?.split(" ")[1]
          // );
          setAddress(results[0].formatted_address);
        } else {
          console.log("No results found");
        }
      } else {
        console.log("Geocoder failed due to: " + status);
      }
    });
  };



  return (
    <div className="ms-content-wrapper ms-auth">
      <div className="regi-section">
        <Row>
          <Col md={5}>
            <div className="ms-auth-bg">
              <img src={loginLogo} alt="loginLogo" />
            </div>
          </Col>
          <Col md={7}>
            <div className="regi-form">
              <form
                className="needs-validation"
                onSubmit={handleSubmit(onSubmit)}
              >
                <h3>
                  Registration : Step <span>{nextStep} of 6</span>
                </h3>
                <p>Please enter information to continue</p>
                <div className="form-vendor-regi">
                  <div className="form-vendor">
                    <Row>
                      <Col md={12}>
                        <div className="form-group">
                          <label htmlFor="country" className="form-label">
                            Country<span className="error">*</span>
                          </label>
                          <div className="dropdown-group">
                            <select
                              id="country"
                              name="country"
                              className={`form-control ${
                                touchedError
                                  ? errors.country?.type == "required"
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                              {...register("country", {
                                required: true,
                              })}
                              onChange={(e) => {
                                if (e.target.value) {
                                  setError("country", "");
                                  requestState("POST", "getState", {
                                    countryId: e.target.value,
                                  });
                                } else {
                                  setError("country", { type: "required" });
                                  setStates([]);
                                  setCities([]);
                                }
                                setValue("state", "");
                                setValue("city", "");

                                setValue("country", e.target.value);
                              }}
                            >
                              <option value="">Select Country</option>

                              {countries.length > 0 ? (
                                countries.map((country) => {
                                  return (
                                    <option
                                      value={country._id}
                                      selected={
                                        getValues("country") == country._id
                                          ? "selected"
                                          : ""
                                      }
                                    >
                                      {country.name}
                                    </option>
                                  );
                                })
                              ) : (
                                <option value={0}>No Country Found</option>
                              )}
                            </select>
                            {errors.country?.type === "required" && (
                              <div className="invalid-select invalid-feedback">
                                The country field is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="form-group">
                          <label htmlFor="state" className="form-label">
                            State<span className="error">*</span>
                          </label>
                          <div className="dropdown-group">
                            <select
                              id="state"
                              name="state"
                              className={`form-control ${
                                touchedError
                                  ? errors.state?.type == "required"
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                              {...register("state", {
                                required: true,
                              })}
                              onChange={(e) => {
                                if (e.target.value) {
                                  setError("state", "");
                                  requestCity("POST", "getCity", {
                                    stateId: e.target.value,
                                  });
                                } else {
                                  setCities([]);
                                  setError("state", { type: "required" });
                                }
                                setValue("state", e.target.value);
                              }}
                            >
                              <option value="">Select State</option>

                              {states.length > 0 &&
                                states.map((state) => {
                                  return (
                                    <option
                                      value={state._id}
                                      selected={
                                        getValues("state") == state._id
                                          ? "selected"
                                          : ""
                                      }
                                    >
                                      {state.name}
                                    </option>
                                  );
                                })}
                            </select>
                            {errors.state?.type === "required" && (
                              <div className="invalid-select invalid-feedback">
                                The state field is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="form-group">
                          <label htmlFor="city" className="form-label">
                            City<span className="error">*</span>
                          </label>
                          <div className="dropdown-group">
                            <select
                              id="city"
                              name="city"
                              className={`form-control ${
                                touchedError
                                  ? errors.city?.type == "required"
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                              {...register("city", {
                                required: true,
                              })}
                              onChange={(e) => {
                                if (e.target.value) {
                                  setError("city", "");
                                } else {
                                  setError("city", { type: "required" });
                                }
                                setValue("city", e.target.value);
                              }}
                            >
                              <option value="">Select City</option>

                              {cities.length > 0 &&
                                cities.map((city) => {
                                  return (
                                    <option
                                      value={city._id}
                                      selected={
                                        getValues("city") == city._id
                                          ? "selected"
                                          : ""
                                      }
                                    >
                                      {city.name}
                                    </option>
                                  );
                                })}
                            </select>
                            {errors.city?.type === "required" && (
                              <div className="invalid-select invalid-feedback">
                                The city field is required.
                              </div>
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="form-group ">
                          <label
                            htmlFor="address"
                            className="form-label customLabel"
                          >
                            Address<span className="error">*</span>
                          </label>

                          {/* <PlacesAutocomplete
                            value={address}
                            onChange={handleChangess}
                            onSelect={handleSelect}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div className="input-group">
                                <>
                                  <input
                                    type="text"
                                    id="address"
                                    placeholder="Address"
                                    name="address"
                                    className={`form-control ${
                                      touchedError
                                        ? errors.address?.type === "required"
                                          ? "is-invalid"
                                          : "is-valid"
                                        : ""
                                    }`}
                                    {...register("address", {
                                      required: true,
                                    })}
                                    {...getInputProps({
                                      placeholder: "Search Places ...",
                                      className: "location-search-input",
                                    })}
                                  />
                                  <div>
                                    {errors.address?.type === "required" && (
                                      <div className="invalid-field">
                                        The address field is required.
                                      </div>
                                    )}
                                  </div>
                                </>

                              

                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map((suggestion) => {
                                    const className = suggestion.active
                                      ? "suggestion-item--active"
                                      : "suggestion-item";
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                      ? {
                                          backgroundColor: "#fafafa",
                                          cursor: "pointer",
                                        }
                                      : {
                                          backgroundColor: "#ffffff",
                                          cursor: "pointer",
                                        };
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete> */}

                          <PlacesAutocomplete
                            value={address}
                            onChange={setAddress}
                            onSelect={handleSelect}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div className="input-group">
                                <input
                                  {...getInputProps({
                                    placeholder: "Search Places ...",
                                    className: "form-control",
                                  })}
                                />
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map((suggestion) => {
                                    const className = suggestion.active
                                      ? "suggestion-item--active"
                                      : "suggestion-item";
                                    const style = suggestion.active
                                      ? {
                                          backgroundColor: "#fafafa",
                                          cursor: "pointer",
                                        }
                                      : {
                                          backgroundColor: "#ffffff",
                                          cursor: "pointer",
                                        };
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                          <div className="mt-3">
                            <GoogleMap
                              mapContainerStyle={mapContainerStyle}
                              zoom={10}
                              center={markerPosition}
                              onClick={(event) => {
                                setValue("latitude", event.latLng.lat());
                                setValue("longitude", event.latLng.lng());
                                geocodeLatLng(
                                  event.latLng.lat(),
                                  event.latLng.lng()
                                );
                                setMarkerPosition({
                                  lat: event.latLng.lat(),
                                  lng: event.latLng.lng(),
                                });
                                setLatLong({
                                  latitude: event.latLng.lat(),
                                  longitude: event.latLng.lng(),
                                });
                              }}
                            >
                              <Marker position={markerPosition} />
                            </GoogleMap>
                          </div>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="form-group ">
                          <label
                            htmlFor="landMark"
                            className="form-label customLabel"
                          >
                            Nearest Land Mark
                            {/* Nearest Land Mark<span className="error">*</span> */}
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              id="landMark"
                              placeholder="Nearest Land Mark"
                              name="landMark"
                              // className={`form-control ${
                              //     touchedError ? ( errors.landMark?.type === "required" ? "is-invalid" : "is-valid" ): ""
                              // }`}
                              {...register("landMark", {
                                required: false,
                              })}
                            />
                            <div>
                              {errors.landMark?.type === "required" && (
                                <div className="invalid-field">
                                  The nearest land mark field is required.
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group ">
                          <label
                            htmlFor="street"
                            className="form-label customLabel"
                          >
                            Street<span className="error">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              id="street"
                              placeholder="Street"
                              name="street"
                              className={`form-control ${
                                touchedError
                                  ? errors.street?.type === "required"
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                              {...register("street", {
                                required: true,
                              })}
                            />
                            <div>
                              {errors.street?.type === "required" && (
                                <div className="invalid-field">
                                  The street field is required.
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="form-group ">
                          <label
                            htmlFor="pincode"
                            className="form-label customLabel"
                          >
                            Pincode<span className="error">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              id="pincode"
                              placeholder="Postal Code"
                              name="pincode"
                              className={`form-control ${
                                touchedError
                                  ? errors.pincode?.type === "required" ||
                                    errors.pincode?.type === "pattern"
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                              {...register("pincode", {
                                required: true,
                                pattern: /^[1-9][0-9]{5}$/,
                              })}
                            />
                            <div>
                              {errors.pincode?.type === "required" && (
                                <div className="invalid-field">
                                  The pincode field is required.
                                </div>
                              )}
                              {errors.pincode?.type === "pattern" && (
                                <div className="invalid-field">
                                  Please enter valid pincode.
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </Col>

                      {latLong.latitude && latLong.longitude && (
                        <>
                          <Col md={6}>
                            <div className="form-group ">
                              <label
                                htmlFor="latitude"
                                className="form-label customLabel"
                              >
                                Latitude<span className="error">*</span>
                              </label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  id="latitude"
                                  placeholder="Latitude"
                                  name="latitude"
                                  disabled
                                  className={`form-control ${
                                    touchedError
                                      ? errors.latitude?.type === "required"
                                        ? "is-invalid"
                                        : "is-valid"
                                      : ""
                                  }`}
                                  {...register("latitude", {
                                    required: false,
                                  })}
                                />
                                <div></div>
                              </div>
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="form-group ">
                              <label
                                htmlFor="longitude"
                                className="form-label customLabel"
                              >
                                Longitude<span className="error">*</span>
                              </label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  id="longitude"
                                  placeholder="Longitude"
                                  name="longitude"
                                  disabled
                                  className={`form-control ${
                                    touchedError
                                      ? errors.longitude?.type === "required"
                                        ? "is-invalid"
                                        : "is-valid"
                                      : ""
                                  }`}
                                  {...register("longitude", {
                                    required: false,
                                  })}
                                />
                                <div></div>
                              </div>
                            </div>
                          </Col>
                        </>
                      )}
                    </Row>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <button
                      className="steps-btn float-left"
                      type="button"
                      onClick={() => handleNextStep(4)}
                    >
                      <i>&#8592;</i> Previous
                    </button>
                  </div>
                  <div className="col-md-6">
                    {loader ? (
                      <center>
                        <div
                          style={{
                            flex: 1,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Spinner
                            animation="border"
                            className="d-flex justify-content-center"
                          />
                        </div>
                      </center>
                    ) : (
                      <button
                        className="steps-btn"
                        type="submit"
                        onClick={() => setTouchedError(true)}
                      >
                        {"Verify Address"}
                      </button>
                    )}
                  </div>
                </div>

                {/* {latLong.latitude && SimpleMap(latLong)} */}
                {getValues("latitude") && getValues("longitude") && (
                  <MyVerticallyCenteredModal
                    show={modalShow}
                    setLoader={setLoader}
                    onHide={() => setModalShow(false)}
                  />
                )}
              </form>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default StepFive;
