import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import useRequest from "../hooks/useRequest";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function Mou(props) {
  const { request, response } = useRequest();

  const [name, setName] = useState("");

  const handleClose = () => {
    props.onHide();
  };
  const UpdateMou = async () => {
    const data = {
      vendorId: localStorage.getItem("vendorId"),
      mouSignature: name,
    };
    request("POST", `saveSignature`, data);
  };

  useEffect(() => {
    if (response) {
      if (response.status == "success") {
        props.onHide();
      }
    }
  }, [response]);

  return (
    <div>
      {" "}
      <div>
        <Modal
          {...props}
          // onHide={handleClose}
          size="lg"
          fullScreen
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header >
            <Modal.Title id="contained-modal-title-vcenter">
              <h3>I understand and agree that:</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <div className="row">
              <div className="col-md-12">
                <div className="ms-panel ms-panel-fh">
                  <div>
                    <div>
                      <h1>RESTAURANT PARTNER ENROLMENT FORM (“FORM”)</h1>
                      <h2>FOR TABLE BOOKING AND FOOD ORDERING SERVICES</h2>

                      <div>
                        <label>Effective Date: </label>
                        <span>2024-07-05 16:13:11</span>
                      </div>

                      <div>
                        <label>Restaurant Name: </label>
                        <span>As set out in Annexure A</span>
                      </div>

                      <div>
                        <label>Restaurant's Business Address: </label>
                        <span>6/394 Malviya Nagar, Jaipur</span>
                      </div>

                      <div>
                        <label>
                          Legal Entity Name ("Restaurant Partner"):{" "}
                        </label>
                        <span>Ajayveer Singh Bhadouria</span>
                      </div>

                      <div>
                        <label>Communication Address: </label>
                        <span>6/394 Malviya Nagar, Jaipur</span>
                      </div>

                      <div>
                        <label>Contact Person: </label>
                        <span>Ajayveer Singh Bhadouria</span>
                      </div>

                      <div>
                        <label>Phone: </label>
                        <span>9828064987</span>
                      </div>

                      <div>
                        <label>Email Id: </label>
                        <span>Ajay.mgr10@gmail.com</span>
                      </div>

                      <h3>Services</h3>
                      <p>
                        The Restaurant Partner has decided to avail Services
                        from Sortyfy including the following:
                      </p>
                      <ul>
                        <li>
                          <strong>
                            Table Booking Order placement and catalog hosting
                            services:
                          </strong>{" "}
                          Sortyfy provides the Table booking and Order placement
                          mechanism for the Customers to book the table and
                          place Orders with the Restaurant Partners on a
                          real-time basis and helps with listing of the menu and
                          price lists as provided by the Restaurant Partners.
                        </li>
                        <li>
                          <strong>Demand generation and marketing:</strong>{" "}
                          Sortyfy helps bring new Customers to Restaurant
                          Partners every day. This is done through targeted
                          marketing enabling appropriate discovery and creating
                          a seamless Table bookings and food ordering
                          experience.
                        </li>
                        <li>
                          <strong>Other Services:</strong> Sortyfy creates
                          competitive earning opportunities through Takeaway and
                          Services. (Services: As per Term mentioned)
                        </li>
                        <li>
                          <strong>Support for Customers & Restaurants:</strong>{" "}
                          Customers & Restaurant Partners all have unique needs
                          that often need addressing immediately. Sortyfy has a
                          team working 24*7/365 ready to help solve any issues
                          so that the Customers & Restaurants experience is
                          seamless.
                        </li>
                        <li>
                          <strong>Technology infrastructure:</strong> Sortyfy
                          builds and supports products for Customers and
                          Restaurants including payment infrastructure which
                          requires high-quality talent and investments.
                        </li>
                      </ul>

                      <h3>II. Charges</h3>
                      <p>
                        For the Services above, the Restaurant Partner shall be
                        liable to pay Sortyfy applicable charges as may be
                        communicated to the Restaurant Partner from time to time
                        including as set out under Annexure A and the Terms
                        (defined below). Such charges and other amounts shall be
                        subject to applicable taxes.
                      </p>

                      <h3>III. Payment Settlement Process</h3>
                      <p>
                        Sortyfy shall transfer the Order Value received to the
                        Restaurant Partner after deduction of agreed Charges and
                        commission on a 24 Hrs Basis. The payment settlement
                        time for Orders serviced will be within 24 Hrs. If the
                        payment settlement day falls on a bank holiday it shall
                        be the following working day.
                      </p>

                      <h3>IV. Additional Terms</h3>
                      <ul>
                        <li>
                          The Restaurant Partner will be offering the Logistics
                          Services as agreed and agrees not to charge Service
                          charges from any Customers.
                        </li>
                        <li>
                          The Restaurant Partner agrees not to charge the
                          Customer for anything other than food, beverages, and
                          packaging charges on the Platform.
                        </li>
                        <li>
                          The Restaurant Partner will at all times maintain
                          equal or lower prices for all its products offered to
                          the Customers via the Platform as against the prices
                          through its direct channels including dine-in takeaway
                          or Services from its own Restaurant or franchise
                          locations or its other channels like websites/apps
                          etc.
                        </li>
                        <li>
                          For the purpose of clarity, pricing of products shall
                          include pricing of food and beverage items, charges
                          for packaging, etc.
                        </li>
                        <li>
                          In case special portion sizes are created for the
                          Platform, the Restaurant Partner shall maintain
                          proportionate or lower pricing for such portion sizes
                          for the Platform compared to portion sizes on its own
                          channel(s).
                        </li>
                        <li>
                          The Restaurant Partner will desist from directly or
                          indirectly giving or offering any marketing material
                          including flyers, brochures, etc. to Customers with
                          the Orders placed via the Platform containing
                          commercial offers such as discounts to Customers
                          and/or text images, statements, or content which
                          directly or indirectly discourages the Customers from
                          booking tables and placing orders with the Sortyfy
                          platform.
                        </li>
                        <li>
                          This Form forms an integral part of the Terms and
                          Conditions for Table booking and Food Ordering
                          Services available at https://www.Sortyfy.com
                          (“Terms”). This Form (along with its annexures) and
                          the Terms constitute a legally binding contract
                          between the Parties.
                        </li>
                      </ul>

                      <h3>Annexure A</h3>
                      <div>
                        <label>Restaurant Name (Res ID): </label>
                        <span>Tina Cafe (21300392)</span>
                      </div>
                      <div>
                        <label>Locality: </label>
                        <span>Malviya Nagar</span>
                      </div>

                      <h4>Description</h4>
                      <ul>
                        <li>Charges and other Amounts Payable</li>
                        <li>Effective from</li>
                        <li>Remarks</li>
                      </ul>

                      <h4>Service Fee</h4>
                      <ul>
                        <li>0 % service fee for first 30 days.</li>
                        <li>
                          Base service fee = 5% of Net Sales per Order (after 30
                          days)
                        </li>
                        <li>Effective from: 2024-07-05 16:13:11</li>
                        <li>
                          For new onboarded restaurants Service Fee will be
                          effective from the time of going live on the platform.
                        </li>
                      </ul>

                      <h4>Payment Mechanism (Payment Gateway) Fee</h4>
                      <ul>
                        <li>As per the actual charges gateway charges.</li>
                        <li>Not Exceeding 1.5% of Order Value</li>
                        <li>Effective from: 2024-07-05 16:13:11</li>
                      </ul>

                      <h4>Charges for Orders requiring support</h4>
                      <ul>
                        <li>
                          If Orders requiring Sortyfy Team support: Free -
                          service fee for first 30 days.
                        </li>
                        <li>
                          INR 10.00 per Order for all Orders requiring support
                        </li>
                        <li>Effective from: 2024-07-05 16:13:11</li>
                        <li>
                          Exception for new Restaurant for four (4) weeks from
                          the Effective Date. The Restaurant Partner agrees that
                          this amount will be deducted from its ongoing payouts.
                        </li>
                      </ul>

                      <h4>
                        Charges for Restaurant Partner Cancels or Unaccepted
                        Orders
                      </h4>
                      <ul>
                        <li>
                          If Restaurant Partner Cancels Orders (After Accepting)
                          or doesn’t accept within 15 Minutes: INR 50 Per
                          Cancelled or unaccepted bookings.
                        </li>
                        <li>
                          If weekly Restaurant Partner Cancels Orders (After
                          Accepting) or don’t accept within 15 Minutes exceed
                          more than 2% of weekly Order volume: INR.250 will be
                          charged to the Restaurant Partner for the relevant
                          week.
                        </li>
                        <li>Effective from: 2024-07-05 16:13:11</li>
                        <li>
                          Exception for new Restaurant for two (2) weeks from
                          the Effective Date. The Restaurant Partner agrees that
                          this amount will be deducted from its ongoing payouts.
                        </li>
                      </ul>

                      <h4>Charges for Price Disparity</h4>
                      <ul>
                        <li>
                          Three (3) times the differential amount for each Order
                        </li>
                        <li>
                          Date on which the difference in price of the
                          product(s) is introduced to the Platform until the
                          disparity in prices is corrected by the Restaurant
                          Partner.
                        </li>
                        <li>
                          Sortyfy may use various means including but not
                          limited to customer complaints and mystery shopping to
                          identify price disparity between a Restaurant’s
                          offline (dine-in) menu prices and the prices on the
                          Platform. The Restaurant Partner agrees that this
                          amount will be deducted from its ongoing payouts.
                        </li>
                      </ul>

                      <h4>Charges for discouraged practices and offers</h4>
                      <ul>
                        <li>
                          An amount of up to INR.25000 for each contravention.
                          For repeated contraventions INR.5000 and/or suspension
                          or termination of Services.
                        </li>
                        <li>Effective from: 2024-07-05 16:13:11</li>
                        <li>
                          The Restaurant Partner agrees that this amount will be
                          deducted from its ongoing payouts.
                        </li>
                      </ul>

                      <h4>Orders requiring support</h4>
                      <p>
                        Orders where support teams extend additional support to
                        mitigate customer escalations for reasons attributable
                        to the Restaurant Partner including but not limited to
                        delay in preparation of food order cancellations from
                        the restaurant side.
                      </p>

                      <h4>Unaccepted Orders</h4>
                      <p>
                        All those Orders which are not accepted by the
                        Restaurant Partner within 15 Minutes from receipt of
                        Order notification.
                      </p>

                      <h4>Takeaway</h4>
                      <p>
                        Orders placed by customers on the Sortyfy Platform to be
                        picked up from the Restaurant outlet directly.
                      </p>

                      <h4>
                        In the event the above is found to be in order, please
                        proceed to agree to and sign the Form.
                      </h4>

                      <h4>AGREED AND SIGNED BY</h4>
                      <div>
                        <label>Signature: </label>
                        <span>..................................</span>
                      </div>
                      <div>
                        <label>Name: </label>
                        <span>..................................</span>
                      </div>
                      <div>
                        <label>Title: </label>
                        <span>..................................</span>
                      </div>
                      <div>
                        <label>Company Chop/Stamp: </label>
                        <span>..................................</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="form-container">
              <div className="form-statement">
                <p>
                  A) The information that we have provided is accurate to the
                  best of our knowledge.
                </p>
                <p>
                  B) Any material misrepresentation or deliberate omission of
                  fact may be justification for refusal of the registration.
                </p>
                <p>
                  C) By submitting below, I certify that I have read & fully
                  understand the responsibility.
                </p>
                <p className="warning">
                  DO NOT SUBMIT UNTIL YOU HAVE READ THE ABOVE STATEMENT.
                </p>
                <Link className="red" to={`/checkMou`} target="_blank">
                  Read Terms & Conditions
                </Link>
              </div>
              <form>
                <div className="form-group">
                  <label htmlFor="fullName">
                    Full Name <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    id="fullName"
                    name="fullName"
                    className="form-control"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    value={name}
                    placeholder="Enter Your Name"
                    required
                  />
                </div>
                <button
                  onClick={UpdateMou}
                  type="button"
                  className="steps-btn float-left w-25"
                >
                  SUBMIT
                </button>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default Mou;
