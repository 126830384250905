import { useFormik } from "formik";
import React, { useEffect } from "react";
import "../../Auth/Steps/styles.css";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  registerStepFour,
  registerStepSix,
} from "../../../../redux/slices/Registration";
import { toast } from "react-toastify";
import loginLogo from "../../../../assets/img/logo.png";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { stepSixSave } from "../../../../redux/slices/SaveDetails";
const StepSix = ({ setNextStep, nextStep }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { vendorId, isBar } = useSelector((state) => state.register);
  const { stepSix } = useSelector((state) => state.saveDetails);
  const [initialValues, setInitialValues] = React.useState(
    stepSix
      ? stepSix
      : {
          foodLicence: null,
          barLicence: null,
          tanCertificate: null,
          panCertificate: null,
          foodLicenceNumber: null,
          barLicenceNumber: null,
          gstNumber: null,
          gstCertificate: null,
          tanCertificateNumber: null,
          panCertificateNumber: null,
        }
  );
  const validationSchema = Yup.object().shape({
    foodLicence: Yup.mixed().required("Food File is required"),
    foodLicenceNumber: Yup.mixed().required("Food Licence number is required"),
    // barLicence: Yup.mixed().required("Bar File is required"),
    // barLicenceNumber: Yup.mixed().required("Bar Licence number is required"),
    gstCertificate: Yup.mixed().required("GST File is required"),
    gstNumber: Yup.mixed().required("GST number is required"),
    tanCertificate: Yup.mixed().required("Tan File is required"),
    tanCertificateNumber: Yup.mixed().required("Tan number is required"),
    panCertificate: Yup.mixed().required("Pan File is required"),
    panCertificateNumber: Yup.mixed().required("Pan Number is required"),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      // console.log(values);
      let data = new FormData();
      data.append("foodLicence", values.foodLicence);
      data.append("foodLicenceNumber", values.foodLicenceNumber);
      data.append("barLicence", values.barLicence);
      data.append("barLicenceNumber", values.barLicenceNumber);
      data.append("gstCertificate", values.gstCertificate);
      data.append("gstCertificateNumber", values.gstCertificateNumber);
      data.append("tanCertificate", values.tanCertificate);
      data.append("tanCertificateNumber", values.tanCertificateNumber);
      data.append("panCertificate", values.panCertificate);
      data.append("panCertificateNumber", values.panCertificateNumber);
      data.append("vendorId", vendorId);
      dispatch(stepSixSave(values));
      dispatch(registerStepSix(data))
        .unwrap()
        .then((res) => {
          // toast.success(res.message);
          Swal.fire({
            title: "Success",
            text: res.message,
            icon: "success",
            confirmButtonText: "OK",
            confirmButtonColor: "#00bcd4",
          }).then(() => {
            // setNextStep(1);
            history.push("/login");
          });
        })
        .catch((err) => {
          toast.error(err);
        });
    },
  });

  const renderFilePreview = (src, name) => {
    return (
      <div className="img-pos col-md-4">
        <img
          src={URL.createObjectURL(src)}
          alt="preview"
          style={{ width: "200px", height: "50px" }}
          onError={(e) => {
            e.target.src = "";
            e.target.alt = name;
          }}
        />
      </div>
    );
  };

  const renderFoodFile = () => {
    if (formik.values.foodLicence) {
      return (
        <>
          {renderFilePreview(
            formik.values.foodLicence,
            formik.values.foodLicence.name
          )}
        </>
      );
    }
  };
  const renderBarFile = () => {
    if (formik.values.barLicence) {
      return (
        <>
          {renderFilePreview(
            formik.values.barLicence,
            formik.values.barLicence.name
          )}
        </>
      );
    }
  };
  const renderGSTFile = () => {
    if (formik.values.gstCertificate) {
      return (
        <>
          {renderFilePreview(
            formik.values.gstCertificate,
            formik.values.gstCertificate.name
          )}
        </>
      );
    }
  };
  const renderTanFile = () => {
    if (formik.values.tanCertificate) {
      return (
        <>
          {renderFilePreview(
            formik.values.tanCertificate,
            formik.values.tanCertificate.name
          )}
        </>
      );
    }
  };
  const renderPanFile = () => {
    if (formik.values.panCertificate) {
      return (
        <>
          {renderFilePreview(
            formik.values.panCertificate,
            formik.values.panCertificate.name
          )}
        </>
      );
    }
  };
  useEffect(() => {
    if (stepSix) {
      setInitialValues(stepSix);
    }
  }, [dispatch, initialValues]);
  return (
    <div className="ms-content-wrapper ms-auth">
      <div className="regi-section">
        <Row>
          <Col md={5}>
            <div className="ms-auth-bg">
              <img src={loginLogo} />
            </div>
          </Col>

          <Col md={7}>
            <div className="regi-form form-vendor-regi">
              <form className="needs-validation" onSubmit={formik.handleSubmit}>
                <h3>
                    Registration : Step <span>{nextStep} of 6</span>
                </h3>
                <p>Please enter information to continue</p>
                <div className="form-row">
                  <div className="col-md-12 ">
                    <label
                      htmlFor="foodLicence"
                      className="form-label customLabel"
                    >
                      Food Licence<span className="error">*</span>
                    </label>
                    <div className="row">
                      <div className="col-md-6 ">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            id="validationCustom03"
                            placeholder="Enter Food Licence number"
                            name="foodLicenceNumber"
                            onChange={(e) => {
                              formik.handleChange(e);
                              dispatch(stepSixSave(formik.values));
                            }}
                            value={formik.values.foodLicenceNumber}
                            required
                          />
                          <div className="invalid-feedback">
                            Please provide a valid Name.
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 ">
                        <div className="input-group">
                          <input
                            type="file"
                            className="uploadFile"
                            id="foddFile"
                            placeholder="Upload Food Licence"
                            name="foodLicence"
                            onChange={(e) => {
                              formik.setFieldValue(
                                "foodLicence",
                                e.currentTarget.files[0]
                              );
                              dispatch(
                                stepSixSave({
                                  ...formik.values,
                                  foodLicence: e.currentTarget.files[0],
                                })
                              );
                            }}
                          />
                          <div>
                            {formik.errors.foodLicence &&
                              formik.touched.foodLicence && (
                                <div className="error">
                                  {formik.errors.foodLicence}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {renderFoodFile()}
                  </div>
                  <div className="col-md-12 ">
                    <label htmlFor="validationCustom03" className="customLabel">
                      Bar Licence (if registered into Bar category or opted
                      serving beverages and liquor)
                    </label>
                    <div className="row">
                      <div className="col-md-6 ">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            id="validationCustom03"
                            placeholder="Enter Bar Licence number"
                            name="barLicenceNumber"
                            onChange={(e) => {
                              formik.handleChange(e);
                              dispatch(stepSixSave(formik.values));
                            }}
                            value={formik.values.barLicenceNumber}
                            required={isBar}
                          />
                          <div className="invalid-feedback">
                            Please provide a valid Name.
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 ">
                        <div className="input-group">
                          <input
                            type="file"
                            className="uploadFile"
                            id="validationCustom03"
                            name="barLicence"
                            onChange={(e) => {
                              formik.setFieldValue(
                                "barLicence",
                                e.currentTarget.files[0]
                              );
                              dispatch(
                                stepSixSave({
                                  ...formik.values,
                                  barLicence: e.currentTarget.files[0],
                                })
                              );
                            }}
                            placeholder="Bar Licence"
                          />
                          <div>
                            {formik.errors.barLicence &&
                              formik.touched.barLicence && (
                                <div className="error">
                                  {formik.errors.barLicence}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {renderBarFile()}
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-md-12 ">
                    <label htmlFor="validationCustom03" className="customLabel">
                      TAN <span className="error">*</span>
                    </label>
                    <div className="row">
                      <div className="col-md-6 ">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            id="validationCustom03"
                            placeholder="Tan number"
                            name="tanCertificateNumber"
                            onChange={(e) => {
                              formik.handleChange(e);
                              dispatch(stepSixSave(formik.values));
                            }}
                            value={formik.values.tanCertificateNumber}
                            required
                          />
                          <div className="invalid-feedback">
                            Please provide a valid Name.
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 ">
                        <div className="input-group">
                          <input
                            type="file"
                            className="uploadFile"
                            id="validationCustom03"
                            name="tanCertificate"
                            onChange={(e) => {
                              formik.setFieldValue(
                                "tanCertificate",
                                e.currentTarget.files[0]
                              );
                              dispatch(
                                stepSixSave({
                                  ...formik.values,
                                  tanCertificate: e.currentTarget.files[0],
                                })
                              );
                            }}
                            placeholder="Upload TAN Certificate"
                          />

                          <div>
                            {formik.errors.tanCertificate &&
                              formik.touched.tanCertificate && (
                                <div className="error">
                                  {formik.errors.tanCertificate}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {renderTanFile()}
                  </div>
                  <div className="col-md-12 ">
                    <label htmlFor="validationCustom03" className="customLabel">
                      GSTIN <span className="error">*</span>
                    </label>
                    <div className="row">
                      <div className="col-md-6 ">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            id="validationCustom03"
                            placeholder="GST number "
                            name="gstNumber"
                            onChange={(e) => {
                              formik.handleChange(e);
                              dispatch(stepSixSave(formik.values));
                            }}
                            value={formik.values.gstNumber}
                            required
                          />
                          <div className="invalid-feedback">
                            Please provide a valid Name.
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 ">
                        <div className="input-group">
                          <input
                            type="file"
                            className="uploadFile"
                            id="validationCustom04"
                            name="gstCertificate"
                            onChange={(e) => {
                              formik.setFieldValue(
                                "gstCertificate",
                                e.currentTarget.files[0]
                              );
                              dispatch(
                                stepSixSave({
                                  ...formik.values,
                                  gstCertificate: e.currentTarget.files[0],
                                })
                              );
                            }}
                            placeholder="Upload GST Certificate"
                          />

                          <div>
                            {formik.errors.gstCertificate &&
                              formik.touched.gstCertificate && (
                                <div className="error">
                                  {formik.errors.gstCertificate}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {renderGSTFile()}
                  </div>
                  <div className="col-md-12 ">
                    <label htmlFor="validationCustom05" className="customLabel">
                      PAN <span className="error">*</span>
                    </label>
                    <div className="row">
                      <div className="col-md-6 ">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            id="validationCustom03"
                            placeholder="Enter Pan File "
                            name="panCertificateNumber"
                            onChange={(e) => {
                              formik.handleChange(e);
                              dispatch(stepSixSave(formik.values));
                            }}
                            value={formik.values.panCertificateNumber}
                            required
                          />
                          <div className="invalid-feedback">
                            Please provide a valid Name.
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 ">
                        <div className="input-group">
                          <input
                            type="file"
                            className="uploadFile"
                            id="validationCustom05"
                            name="panCertificate"
                            onChange={(e) => {
                              formik.setFieldValue(
                                "panCertificate",
                                e.currentTarget.files[0]
                              );
                              dispatch(
                                stepSixSave({
                                  ...formik.values,
                                  panCertificate: e.currentTarget.files[0],
                                })
                              );
                            }}
                            placeholder="PAN Certificate"
                          />

                          <div>
                            {formik.errors.panCertificate &&
                              formik.touched.panCertificate && (
                                <div className="error">
                                  {formik.errors.panCertificate}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {renderPanFile()}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <button
                      className="steps-btn float-left"
                      type="button"
                      onClick={() => setNextStep(5)}
                    >
                      <i>&#8592;</i> Previous
                    </button>
                  </div>
                  <div className="col-md-6">
                    <button className="steps-btn" type="submit">
                      Register <i>&#8594;</i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default StepSix;

