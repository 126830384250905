import React, { Component, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import $ from "jquery";
import Scrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

// import logo from '../../assets/img/foodtech/foodtech-logo-216x62.png';
import logo from "../../assets/img/logo.png";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAllDetails,
  outletStepFourSave,
  outletStepOneSave,
  outletStepThreeSave,
  outletStepTwoSave,
} from "../../redux/slices/SaveOutletDetails";

import { getSettingAddress } from "../../redux/slices/Registration";
import { getOutletDetail } from "../../redux/slices/Outlet";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";

const Sidenavigation = () => {
  const { outletId } = useSelector((state) => state.register);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  console.log("pathname", pathname);
  const [isCollapse, setIscollapse] = useState({
    menu: true,
    outlet: true,
    discount: true,
    report: true,
    settings: true,
  });
  const removeoverlay = () => {
    $(".ms-body").toggleClass("ms-aside-left-open");
    $("#ms-side-nav").toggleClass("ms-aside-open");
    $(".ms-aside-overlay.ms-overlay-left").toggleClass("d-block");
  };
  // componentDidMount() {

  // }
  useEffect(() => {
    function setActiveMenuItem() {
      $(".ms-main-aside .menu-item>a").on("click", function () {
        // $(this).removeAttr("href");

        var element = $(this).parent("li");
        if (element.hasClass("active")) {
          $(".menu-item").removeClass("active");
          $(".menu-collapse").addClass("collapse");
          // element.find("li").removeClass("active");
          // element.find(".collapse").slideUp();
        } else {
          element.addClass("active");
          element.find(".menu-collapse").removeClass("collapse");
          element.find(".menu-collapse").slideDown();
          // element.children(".collapse").slideDown();
          // element.siblings("li").children(".collapse").slideUp();
          // element.siblings("li").removeClass("active");
          // element.siblings("li").find("li").removeClass("active");
          // element.siblings("li").find(".collapse").slideUp();
        }
      });
    }
    setActiveMenuItem();
  });
  // render() {
  return (
    <div>
      <div
        className="ms-aside-overlay ms-overlay-left ms-toggler"
        onClick={removeoverlay}
      ></div>
      <div className="ms-aside-overlay ms-overlay-right ms-toggler"></div>
      <Scrollbar
        id="ms-side-nav"
        className="side-nav fixed ms-aside-scrollable ms-aside-left"
      >
        {/* Logo */}
        <div className="logo-sn ms-d-block-lg">
          <Link className="pl-0 ml-0 text-center" href="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        {/* Navigation */}
        <ul className="accordion ms-main-aside fs-14" id="side-nav-accordion">
          {/* Dashboard */}
          <li
            className={
              pathname == "/dashboard" ? "menu-item active-nav" : "menu-item"
            }
          >
            <Link to="/dashboard">
              {" "}
              <span>
                <i className="material-icons fs-16">dashboard</i>Dashboard{" "}
              </span>
            </Link>
          </li>
          {/* /Dashboard */}
          {/* product */}
          {localStorage.getItem("vendorType") === "single" && (
            <li
              className="menu-item"
              onClick={() => {
                setIscollapse({
                  ...isCollapse,
                  menu: !isCollapse.menu,
                });
              }}
            >
              <a href="javascript:void(0);" className="has-chevron">
                {" "}
                <span>
                  <i className="fa fa-archive fs-16" />
                  Menus <span className="red-text">*</span>{" "}
                </span>
              </a>
              <ul
                id="product"
                className={
                  isCollapse.menu ? "collapse menu-collapse" : "menu-collapse"
                }
                aria-labelledby="product"
                data-parent="#side-nav-accordion"
              >
                <li>
                  {" "}
                  <Link to="/menu-grid">Menu Items</Link>
                </li>
              </ul>
            </li>
          )}
     {localStorage.getItem("vendorType") === "single" && (
            <li className="menu-item">
              <div
                style={{ margin: "25px", cursor: "pointer" }}
                onClick={() => {
                  dispatch(clearAllDetails());
                  history.push(
                    `/edit-outlet/${
                      outletId
                        ? outletId
                        : localStorage.getItem("outletId")
                        ? localStorage.getItem("outletId")
                        : ""
                    }`
                  );
                }}
              >
                {" "}
                <span>
                  <i
                    className="fa fa-archive fs-16"
                    style={{ marginRight: "20px", marginBottom:"20px" }}
                  />
                  Edit Outlet{" "}
                </span>
              </div>
            </li>
          )}
          {localStorage.getItem("vendorType") === "single" && (
            <li className="menu-item">
              <div
                style={{ margin: "25px", cursor: "pointer" }}
                onClick={() => {
                  history.push(
                    `/view-outlet/${
                      outletId
                        ? outletId
                        : localStorage.getItem("outletId")
                        ? localStorage.getItem("outletId")
                        : ""
                    }`
                  );
                  // dispatch(
                  //   getOutletDetail({
                  //     outletId: localStorage.getItem("outletId"),
                  //   })
                  // )
                  //   .unwrap()
                  //   .then((res) => {
                  //     history.push(
                  //       `/view-outlet/${localStorage.getItem("outletId")}`
                  //     );
                  //   })
                  //   .catch((err) => {
                  //     toast.error(err);
                  //   });
                }}
              >
                {" "}
                <span>
                  <i
                    className="fa fa-archive fs-16"
                    style={{ marginRight: "20px" }}
                  />
                  Outlet Details{" "}
                </span>
              </div>
            </li>
          )}

        

          {localStorage.getItem("vendorType") === "multiple" && (
            <li
              className={
                pathname == "/add-outlet" || pathname == "/total-outlets"
                  ? "menu-item active-nav"
                  : "menu-item"
              }
              onClick={() => {
                setIscollapse({
                  ...isCollapse,
                  outlet: !isCollapse.outlet,
                });
              }}
            >
              <a href="javascript:void(0);" className="has-chevron">
                {" "}
                <span>
                  <i className="fa fa-archive fs-16" />
                  Outlets{" "}
                </span>
              </a>
              <ul
                id="product"
                className={
                  isCollapse.outlet ? "collapse menu-collapse" : "menu-collapse"
                }
                aria-labelledby="product"
                data-parent="#side-nav-accordion"
              >
                <li>
                  {" "}
                  <a
                    href="/add-outlet"
                    onClick={() => {
                      dispatch(clearAllDetails()).then(() => {
                        localStorage.removeItem("outletId");
                      });
                    }}
                  >
                    Add Outlets
                  </a>
                </li>
                <li>
                  {" "}
                  <Link to="/total-outlets">All Outlets</Link>
                </li>
              </ul>
            </li>
          )}
          <li
            className={
              pathname == "/discount-grid" || pathname == "/promotion-grid"
                ? "menu-item active-nav"
                : "menu-item"
            }
            onClick={() => {
              setIscollapse({
                ...isCollapse,
                discount: !isCollapse.discount,
              });
            }}
          >
            <a href="javascript:void(0);" className="has-chevron">
              {" "}
              <span>
                <i className="fa fa-archive fs-16" />
                Discounts & Promotions <span className="red-text">*</span>{" "}
              </span>
            </a>
            <ul
              id="product"
              className={
                isCollapse.discount ? "collapse menu-collapse" : "menu-collapse"
              }
              aria-labelledby="product"
              data-parent="#side-nav-accordion"
            >
              <li>
                {" "}
                <Link to="/discount-grid">Discount</Link>
                <Link to="/promotion-grid">Promotions</Link>
              </li>
            </ul>
          </li>
          <li
            className={
              pathname == "/competitor" ? "menu-item active-nav" : "menu-item"
            }
          >
            <Link to="/competitor">
              {" "}
              <span>
                <i className="material-icons fs-16">dashboard</i>Competitor{" "}
              </span>
            </Link>
          </li>
          {localStorage.getItem("vendorType") === "single" && (
            <li className="menu-item">
              <Link to={`/orders`}>
                {" "}
                <span>
                  <i className="fas fa-clipboard-list fs-16" />
                  Orders{" "}
                </span>
              </Link>
            </li>
          )}

          <li
            className={
              pathname == "/reports/business-reports" ||
              pathname == "/reports/trends" ||
              pathname == "/invoice/billing"
                ? "menu-item active-nav"
                : "menu-item"
            }
            onClick={() => {
              setIscollapse({
                ...isCollapse,
                report: !isCollapse.report,
              });
            }}
          >
            <a href="javascript:void(0);" className="has-chevron">
              {" "}
              <span>
                <i className="fa fa-archive fs-16" />
                Reports{" "}
              </span>
            </a>
            <ul
              id="product"
              className={
                isCollapse.report ? "collapse menu-collapse" : "menu-collapse"
              }
              // aria-labelledby="product"
              // data-parent="#side-nav-accordion"
            >
              <li>
                {" "}
                <Link to="/reports/business-reports">Business Report</Link>
                {/* <Link to="/reports/bookings">Bookings</Link>
                <Link to="/reports/revenue">Revenue</Link> */}
                <Link to="/reports/trends">Trends</Link>
                <Link to="/invoice/billing">Finance Reports </Link>
              </li>
            </ul>
          </li>

          <li
            className={
              pathname == "/rating" ? "menu-item active-nav" : "menu-item"
            }
          >
            <Link to="/rating">
              {" "}
              <span>
                <i className="material-icons fs-16">dashboard</i>Feedback &
                Reviews{" "}
              </span>
            </Link>
          </li>

          {/* <li className="menu-item">
            <Link to="/invoice/billing">
              {" "}
              <span>
                <i className="fa fa-archive fs-16" />Finance
                Reports{" "}
              </span>
            </Link>
          </li> */}

          {/* <li className="menu-item">
            <Link to="#" className="has-chevron">
              {" "}
              <span>
                
                Finance{" "}
              </span>
            </Link>
            <ul
              id="product"
              className="collapse"
              aria-labelledby="product"
              data-parent="#side-nav-accordion"
            >
              <li>
                {" "}
                <Link to="/invoice/billing">Billing</Link>
                <Link to="/invoice/payment">Payment</Link>
                <Link to="/invoice/pending">Pending</Link>
              </li>
            </ul>
          </li> */}

          <li
            className={
              pathname == "/faq" ? "menu-item active-nav" : "menu-item"
            }
          >
            <Link to="/faq">
              {" "}
              <span>
                <i className="material-icons fs-16">dashboard</i>FAQ{" "}
              </span>
            </Link>
          </li>
          <li
            className={
              pathname == "/support" ? "menu-item active-nav" : "menu-item"
            }
          >
            <Link to="/support">
              {" "}
              <span>
                <i className="material-icons fs-16">dashboard</i>Help & Support{" "}
              </span>
            </Link>
          </li>
          <li
            className={
              pathname == "/security-management"
                ? "menu-item active-nav"
                : "menu-item"
            }
          >
            <Link to="/security-management">
              {" "}
              <span>
                <i className="material-icons fs-16">dashboard</i>Security
                Measures <span className="red-text">*</span>{" "}
              </span>
            </Link>
          </li>
          <li
            className={
              pathname == "/reward-page" ? "menu-item active-nav" : "menu-item"
            }
          >
            <Link to="/reward-page">
              {" "}
              <span>
                <i className="material-icons fs-16">dashboard</i> Reward
              </span>
            </Link>
          </li>
          <li
            className={
              pathname == "/bank-account" ? "menu-item active-nav" : "menu-item"
            }
          >
            <Link to="/bank-account">
              {" "}
              <span>
                <i className="material-icons fs-16">dashboard</i>Bank Details{" "}
                <span className="red-text">*</span>{" "}
              </span>
            </Link>
          </li>

          <li
            className={
              pathname == "/settings/manager-profile" ||
              pathname == "/settings/managers-profile" ||
              pathname == "/settings/documents" ||
              pathname == "/settings/address" ||
              pathname == "/settings/bank-account" ||
              pathname == "/blocking-account"
                ? "menu-item active-nav"
                : "menu-item"
            }
            onClick={() => {
              setIscollapse({
                ...isCollapse,
                settings: !isCollapse.settings,
              });
            }}
          >
            <a href="javascript:void(0);" className="has-chevron">
              {" "}
              <span>
                <i className="fa fa-archive fs-16" />
                Settings{" "}
              </span>
            </a>
            <ul
              id="product"
              className={
                isCollapse.settings ? "collapse menu-collapse" : "menu-collapse"
              }
              // aria-labelledby="product"
              // data-parent="#side-nav-accordion"
            >
              <li>
                {" "}
                <Link to="/settings/manager-profile">Owners Profile</Link>
                <Link to="/settings/managers-profile">Manager Profile</Link>
                {/* <Link to="/settings/bank-account">Bank Details</Link> */}
                <Link to="/settings/documents">Documents</Link>
                <Link to="/settings/address">Address</Link>
                <Link to="/blocking-account">Block Users </Link>
                {/* <Link
                    to="/settings/address"
                    onClick={() => {
                      dispatch(getSettingAddress({vendorId: localStorage.getItem("vendorId")}));
                    }}
                  >
                    Address
                  </Link> */}
              </li>
            </ul>
          </li>
        </ul>
      </Scrollbar>
    </div>
  );
};
// }

export default Sidenavigation;

//  <li className="menu-item">
// <Link to="/orders"> <span><i className="fas fa-clipboard-list fs-16" />Orders</span>
// </Link>
// </li >
// <li className="menu-item">
// <Link to="#" className="has-chevron"> <span><i className="fas fa-file-invoice fs-16" />Invoice </span>
// </Link>
// <ul id="invoice" className="collapse" aria-labelledby="invoice" data-parent="#side-nav-accordion">
//     <li> <Link to="/invoice-detail" >Invoice Detail</Link>
//     </li>
//     <li> <Link to="/invoice-list" >Invoice List</Link>
//     </li>
// </ul >
// </li >
// <li className="menu-item">
// <Link to="/sales" > <span><i className="fa fa-briefcase fs-16" />Sales</span>
// </Link>
// </li >
// <li className="menu-item" >
// <Link to="#" className="has-chevron"> <span><i className="material-icons fs-16">filter_list</i>Basic UI Elements</span>
// </Link>
// <ul id="basic-elements" className="collapse" aria-labelledby="basic-elements" data-parent="#side-nav-accordion">
//     <li> <Link to="/accordions" >Accordions</Link>
//     </li>
//     <li> <Link to="/alerts" >Alerts</Link>
//     </li >
//     <li> <Link to="/buttons" >Buttons</Link>
//     </li >
//     <li> <Link to="/breadcrumbs" >Breadcrumbs</Link>
//     </li >
//     <li> <Link to="/badges" >Badges</Link>
//     </li >
//     <li> <Link to="/cards" >Cards</Link>
//     </li >
//     <li> <Link to="/progress-bars" >Progress Bars</Link>
//     </li >
//     <li> <Link to="/preloaders" >Pre-loaders</Link>
//     </li >
//     <li> <Link to="/pagination" >Pagination</Link>
//     </li >
//     <li> <Link to="/tabs" >Tabs</Link>
//     </li >
//     <li> <Link to="/typography" >Typography</Link>
//     </li >
// </ul >
// </li >
// < li className="menu-item" >
// <Link to="#" className="has-chevron"> <span><i className="material-icons fs-16">input</i>Form Elements</span>
// </Link>
// <ul id="form-elements" className="collapse" aria-labelledby="form-elements" data-parent="#side-nav-accordion">
//     <li> <Link to="/form-elements" >Form Elements</Link>
//     </li>
//     <li> <Link to="/form-layouts" >Form Layouts</Link>
//     </li >
//     <li> <Link to="/form-validation" >Form Validation</Link>
//     </li >
//     <li> <Link to="/form-wizard" >Form Wizard</Link>
//     </li >
// </ul >
// </li >

// <li className="menu-item">
// <Link to="#" className="has-chevron"> <span><i className="material-icons fs-16">tune</i>Tables</span>
// </Link>
// <ul id="tables" className="collapse" aria-labelledby="tables" data-parent="#side-nav-accordion">
//     <li> <Link to="/basic-tables" >Basic Tables</Link>
//     </li>
//     <li> <Link to="/data-tables" >Data tables</Link>
//     </li>
// </ul >
// </li >

// <li className="menu-item">
// <Link to="#" className="has-chevron" data-toggle="collapse" data-target="#pages" aria-expanded="false" aria-controls="pages"> <span><i className="material-icons fs-16">insert_drive_file</i>Pages</span>
// </Link>
// <ul id="pages" className="collapse" aria-labelledby="pages" data-parent="#side-nav-accordion">
//     <li className="menu-item"> <Link to="#" className="has-chevron" data-toggle="collapse" data-target="#authentication" aria-expanded="false" aria-controls="authentication">Authentication</Link>
//         <ul id="authentication" className="collapse" aria-labelledby="authentication" data-parent="#pages">
//             <li> <Link to="/default-login">Default Login</Link>
//             </li>
//             <li> <Link to="/modal-login">Modal Login</Link>
//             </li>
//             <li> <Link to="/default-register">Default Registration</Link>
//             </li>
//             <li> <Link to="/modal-register">Modal Registration</Link>
//             </li>
//             <li> <Link to="/lock-screen">Lock Screen</Link>
//             </li >
//         </ul >
//     </li >

//     <li className="menu-item"> <Link to="/portfolio"> Portfolio </Link>
//     </li >
//     <li className="menu-item"> <Link to="/user-profile"> User Profile </Link>
//     </li >

// </ul >
// </li >
