import React, { Component } from "react";
import Billing from "./Billing";
import Payment from "./Payment";
import Pending from "./Pending";

class Finance extends Component {
  render() {
    return (
      <>
        {window.location.pathname === "/invoice/billing" ? (
            <Billing />
          ) : window.location.pathname === "/invoice/payment" ? (
            <Payment />
          ) : window.location.pathname === "/invoice/pending" ? (
            <Pending />
          ) : null}
      </>
    );
  }
}

export default Finance;

