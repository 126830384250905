import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DiscountApi from "../services/discountApis";

export const addDiscount = createAsyncThunk(
    "discount/addDiscount",
    async (props, thunkAPI) => {
        try {
            const response = await DiscountApi.addDiscountApi(props);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const addPromotion = createAsyncThunk(
    "discount/addPromotion",
    async (props, thunkAPI) => {
        try {
            const response = await DiscountApi.addPromotionApi(props);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const getDiscountList = createAsyncThunk(
    "discount/getDiscountList",
    async (props, thunkAPI) => {
        try {
            const response = await DiscountApi.getDiscountListApi(props);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const getPromotionList = createAsyncThunk(
    "discount/getPromotionList",
    async (props, thunkAPI) => {
        try {
            const response = await DiscountApi.getPromotionListApi(props);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const updateDiscount = createAsyncThunk(
    "discount/updateDiscount",
    async (props, thunkAPI) => {
        try {
            const response = await DiscountApi.updateDiscountApi(props);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const updatePromotion = createAsyncThunk(
    "discount/updatePromotion",
    async (props, thunkAPI) => {
        try {
            const response = await DiscountApi.updatePromotionApi(props);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const removeDiscount = createAsyncThunk(
    "discount/removeDiscount",
    async (props, thunkAPI) => {
        try {
            const response = await DiscountApi.removeDiscountApi(props);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const removePromotion = createAsyncThunk(
    "discount/removePromotion",
    async (props, thunkAPI) => {
        try {
            const response = await DiscountApi.removePromotionApi(props);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
const initialState = {
    discountData: {},
    PromotionData: {},
    discountList: [],
    promotionList: [],
}

const discountSlice = createSlice({
    name: "discount",
    initialState,
    extraReducers: {
        [getDiscountList.fulfilled]: (state, action) => {
            state.discountList = action.payload.data;
            state.discountData = action.payload;
        },
        [getDiscountList.rejected]: (state, action) => {
            state.discountList = [];
        },
        [getPromotionList.fulfilled]: (state, action) => {
            state.promotionList = action.payload.data;
            state.promotionData = action.payload;
        },
        [getPromotionList.rejected]: (state, action) => {
            state.promotionList = [];
        },
        // [updateDiscount.fulfilled]: (state, action) => {
        //     state.discountList = action.payload.data;
        // },
        // [updateDiscount.rejected]: (state, action) => {
        //     state.discountList = [];
        // },
        // [removeDiscount.fulfilled]: (state, action) => {
        //     state.discountList = action.payload.data;
        // },
        // [removeDiscount.rejected]: (state, action) => {
        //     state.discountList = [];
        // }
    }
})
const { reducer } = discountSlice;
export default reducer;