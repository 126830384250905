import { combineReducers, configureStore } from '@reduxjs/toolkit'
import registerReducer from "./slices/Registration";
import menuReducer from "./slices/Menu";
import reportReducer from "./slices/Reports";
import financeReducer from "./slices/Finance";
import discountReducer from "./slices/Discount";
import outletReducer from "./slices/Outlet";
import ordersReducer from "./slices/Orders";
import dashboardReducer from "./slices/Dashboard";
import saveDetailsReducer from "./slices/SaveDetails";
import saveOutletDetailsReducer from "./slices/SaveOutletDetails";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
const persistConfig = {
    key: 'root',
    storage,
}

const reducer = {
    register: registerReducer,
    dashboard: dashboardReducer,
    menu: menuReducer,
    report: reportReducer,
    finance: financeReducer,
    discount: discountReducer,
    outlet: outletReducer,
    orders: ordersReducer,
    saveDetails: saveDetailsReducer,
    saveOutletDetails: saveOutletDetailsReducer
}
const persistedReducer = persistReducer(persistConfig, combineReducers({
    ...reducer
}))
export const store = configureStore({
    reducer: persistedReducer,
    devTools: true,
})
export const persistor = persistStore(store)