// import React, { useEffect, useState } from 'react';
// import Chart from 'react-apexcharts';

// const VerticalBarChart = ({ data, data1 }) => {
//     console.log("VerticalBarChart", data1)

//     const [categories, setCategories] = useState([])
//     const [values, setValues] = useState([])
//     useEffect(() => {
//         // if (data) {
//         //     setCategories(data.map(obj => obj.dateOfBooking))
//         //     const newData = data.map(obj => ({
//         //         data: Object.keys(obj).reduce((acc, key) => {
//         //             if (key !== 'dateOfBooking' && key !== 'total') {
//         //                 acc.push(obj[key]);
//         //             }
//         //             return acc;
//         //         }, [])
//         //     }));
//         //     setValues(newData)
//         // }
//         if (data1) {

//             setCategories(data1.map(obj => obj.dateOfBooking))
//             const newData = data1.map(obj => ({
//                 data: Object.keys(obj).reduce((acc, key) => {
//                     if (key !== 'dateOfBooking' && key !== 'total') {
//                         acc.push(obj[key]);
//                     }
//                     return acc;
//                 }, [])
//             }));
//             setValues(newData)

//         }

//     }, [data, data1])
//     const options = {
//         series:
//             values

//         ,
//         chart: {
//             type: 'bar',
//             height: 430,
//         },
//         plotOptions: {
//             bar: {
//                 horizontal: false,
//                 dataLabels: {
//                     position: 'top',
//                 },
//             },
//         },
//         dataLabels: {
//             enabled: true,
//             offsetX: -6,
//             style: {
//                 fontSize: '12px',
//                 colors: ['#fff'],
//             },
//         },
//         stroke: {
//             show: true,
//             width: 1,
//             colors: ['#fff'],
//         },
//         tooltip: {
//             shared: true,
//             intersect: false,
//         },
//         xaxis: {
//             categories: categories ? categories : [],
//         },
//     };
//     console.log("categories", data1, options.series, options)

//     return <Chart options={options} series={options.series} type="bar" height={430} />;
// };

// export default VerticalBarChart;



import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

const VerticalBarChart = ({ data, data1 }) => {
    const [categories, setCategories] = useState([]);
    const [values, setValues] = useState([]);

    useEffect(() => {
        if (data1) {
            const newCategories = data1.map(obj => obj.dateOfBooking);
            setCategories(newCategories);
            const convertedData = data1.reduce((result, item) => {
                const { dateOfBooking, total, ...times } = item; // Exclude 'total' from destructuring
                Object.keys(times).forEach(time => {
                    const value = times[time];
                    const index = result.findIndex(dataItem => dataItem.name === time);
                    if (index === -1) {
                        result.push({ name: time, data: [value] });
                    } else {
                        result[index].data.push(value);
                    }
                });
                return result;
            }, []);
            setValues(convertedData);
        }
    }, [data1]);

    useEffect(() => {
        if (data) {
            const newCategories = data.map(obj => obj.dateOfBooking);
            setCategories(newCategories);
            const convertedData = data.reduce((result, item) => {
                const { dateOfBooking, total, ...times } = item; // Exclude 'total' from destructuring
                Object.keys(times).forEach(time => {
                    const value = times[time];
                    const index = result.findIndex(dataItem => dataItem.name === time);
                    if (index === -1) {
                        result.push({ name: time, data: [value] });
                    } else {
                        result[index].data.push(value);
                    }
                });
                return result;
            }, []);


            setValues(convertedData);
        }
    }, [data]);
    console.log("values", values)
    const options = {
        series: values
        ,
        chart: {
            type: 'bar',
            height: 430,
        },
        plotOptions: {
            bar: {
                horizontal: false,
                dataLabels: {
                    position: 'top',
                },
            },
        },
        dataLabels: {
            enabled: true,
            offsetX: -6,
            style: {
                fontSize: '12px',
                colors: ['#fff'],
            },
        },
        stroke: {
            show: true,
            width: 1,
            colors: ['#fff'],
        },
        tooltip: {
            shared: true,
            intersect: false,
        },
        xaxis: {
            // categories: ['8to9', '9to10', '10to11', '11to12'] || [],
            categories: categories || [],
        },
        yaxis: {
            forceNiceScale: true, // Ensure integer values on the vertical axis
            labels: {
                formatter: value => parseInt(value), // Convert labels to integers
            },
        },
    };

    console.log("valuw", values)
    return <Chart options={options} series={options.series} type="bar" height={430} />;
};

export default VerticalBarChart;
