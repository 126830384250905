import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Button, Form, Modal, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";

import Breadcrumb from "../../Breadcrumb";
import useRequest from '../../../hooks/useRequest';

import { upperCase } from "../../../constants";

import { getDashboardData } from "../../../redux/slices/Dashboard";
import axios from "axios";
import { BASE_URL } from "../../../redux/services/apiPath";
import authHeader from "../../../redux/services/authHeader";

const Securitymanagement = () => {
  const history = useHistory();
  const [loader, setLoader] = useState(false)
  const dispatch = useDispatch();
  const { request, response } = useRequest();
  const { outletList } = useSelector((state) => state.outlet);
  const [selectedOutlet, setSelectedOutlet] = useState({
    outletId: localStorage.getItem("outletId")
  });
  const [additionalData, setAdditionalData] = useState(
    [
      { id: 1, title: 'Is your staff medically examined?', value: "" },
      { id: 2, title: 'Do you have the certificate from sanitary and medical inspection?', value: "" },
      { id: 3, title: 'Does your staff wash their hands frequently?', value: "" },
      { id: 4, title: 'Do you purchase the raw materials from the safe and authorized vendors?', value: "" },
      { id: 5, title: 'Do you have the water treatment system?', value: "" },
      { id: 6, title: 'Is your chefs wear the gloves and caps during the work?', value: "" },
      { id: 7, title: 'Do you keep the segregation of vegetables and meat items?', value: "" },
      { id: 8, title: 'Do you have the proper authorized storage system?', value: "" },
      { id: 9, title: 'Do you check daily the expired items in your store and discard them?', value: "" },
      { id: 10, title: 'Does your restaurant have the fire evacuation plan?', value: "" },
      { id: 11, title: 'Does your restaurant have the firefighting system or fire alarm?', value: "" },
      { id: 12, title: 'Do you have the fire license?', value: "" },
      { id: 13, title: 'Do you have certificate from pollution control department?', value: "" },
      { id: 14, title: 'Does your restaurants have basic medical facility for emergency?', value: "" },
      { id: 15, title: 'Does your restaurant have childcare facility?', value: "" },
      { id: 16, title: 'Does your restaurant have access for wheelchairs?', value: "" },
      { id: 17, title: 'Does your restaurants have baby Chairs?', value: "" },
      { id: 18, title: 'Do you control properly cross contamination?', value: "" },
      { id: 19, title: 'Does your place is properly ventilated and air-conditioned?', value: "" },
      { id: 20, title: 'How frequently do you do the sanitizing of the restaurant?', value: "" },
      { id: 21, title: 'How frequently do you do the pest control?', value: "" },
      { id: 22, title: 'What is the daily frequency for your kitchen cleaning?', value: "" },
      { id: 23, title: 'What is the daily frequency of your washrooms cleaning?', value: "" },

    ]);


  // const [additionalData, setAdditionalData] = useState([
  //   {
  //     title: 'Do you have the certificate from sanitary and medical inspection?', value: ""
  //   },
  //   { title: 'Does your staff wash their hands frequently?', value: "" },
  //   { title: 'Do you purchase the raw materials from the safe and authorizedvendors?', value: "" },
  //   { title: 'Do you have the water treatment system?', value: "" },
  //   { title: 'Is your chefs wear the gloves and caps during the work?', value: "" },
  //   { title: 'Do you keep the segregation of vegetables and meat items?', value: "" },
  //   { title: 'Do you have the proper authorized storage system?', value: "" },
  //   { title: 'Do you check daily the expired items in your store and discard them?', value: "" },
  //   { title: 'Does your restaurant have the fire evacuation plan?', value: "" },
  //   { title: 'Does your restaurant have the firefighting system or fire alarm?', value: "" },
  //   { title: 'Do you have the fire license?', value: "" },
  //   { title: 'Do you have certificate from pollution control department?', value: "" },
  //   { title: 'Does your restaurants have basic medical facility for emergency?', value: "" },
  //   { title: 'Does your restaurant have childcare facility?', value: "" },
  //   { title: 'Does your restaurant have access for wheelchairs?', value: "" },
  //   { title: 'Does your restaurants have baby Chairs?', value: "" },
  //   { title: 'Do you control properly cross contamination?', value: "" },
  //   { title: 'Does your place is properly ventilated and air-conditioned?', value: "" }


  // ])


  const handleInputChange = (index, value) => {
    const newData = [...additionalData];
    newData[index].value = value;
    setAdditionalData(newData);
  };


  const handleRadioChange = (index, value) => {
    const newData = [...additionalData];
    newData[index].value = value;
    setAdditionalData(newData);
  };



  const isAllDataFilled = () => {
    // Check if all fields in both tables are filled
    // const allSanitizationDataFilled = sanitizationData.every(item => item.value.trim() !== "");
    const allAdditionalDataFilled = additionalData.every(item => item.value.trim() !== "");
    return allAdditionalDataFilled;
  };


  const handleSubmit = async () => {

    // setLoader(true)
    if (isAllDataFilled()) {
      const contact = [...additionalData]
      const data = {
        securityData: contact,
        outletId: selectedOutlet.outletId
      }
      const res = await axios.post(`${BASE_URL}outlet/updateSecurityMeasures`, data, authHeader())
      if (res?.data?.status == "success") {
        toast.success("Update successfully")
      }
      else {
        toast.error("Something Went wrong")
      }
    } else {
      toast.error("Please Fill All Fields")

    }

  }


  const getdata = async () => {
    const data = {
      outletId: selectedOutlet.outletId
    }
    const res = await axios.post(`${BASE_URL}outlet/getSecurityMeasures`, data, authHeader())
    if (res?.data?.status == "success") {
      setAdditionalData(res?.data?.data)
      // getdata()
    }
    else {
      setAdditionalData([{ id: 1, title: 'How frequently do you do the sanitizing of the restaurant?', value: "" },
      { id: 2, title: 'How frequently do you do the pest control?', value: "" },
      { id: 3, title: 'Is your staff medically examined?', value: "" },
      { id: 4, title: 'What is the daily frequency for your kitchen cleaning?', value: "" },
      { id: 5, title: 'What is the daily frequency of your washrooms cleaning?', value: "" },
      { id: 6, title: 'Do you have the certificate from sanitary and medical inspection?', value: "" },
      { id: 7, title: 'Does your staff wash their hands frequently?', value: "" },
      { id: 8, title: 'Do you purchase the raw materials from the safe and authorized vendors?', value: "" },
      { id: 9, title: 'Do you have the water treatment system?', value: "" },
      { id: 10, title: 'Is your chefs wear the gloves and caps during the work?', value: "" },
      { id: 11, title: 'Do you keep the segregation of vegetables and meat items?', value: "" },
      { id: 12, title: 'Do you have the proper authorized storage system?', value: "" },
      { id: 13, title: 'Do you check daily the expired items in your store and discard them?', value: "" },
      { id: 14, title: 'Does your restaurant have the fire evacuation plan?', value: "" },
      { id: 15, title: 'Does your restaurant have the firefighting system or fire alarm?', value: "" },
      { id: 16, title: 'Do you have the fire license?', value: "" },
      { id: 17, title: 'Do you have certificate from pollution control department?', value: "" },
      { id: 18, title: 'Does your restaurants have basic medical facility for emergency?', value: "" },
      { id: 19, title: 'Does your restaurant have childcare facility?', value: "" },
      { id: 20, title: 'Does your restaurant have access for wheelchairs?', value: "" },
      { id: 21, title: 'Does your restaurants have baby Chairs?', value: "" },
      { id: 22, title: 'Do you control properly cross contamination?', value: "" },
      { id: 23, title: 'Does your place is properly ventilated and air-conditioned?', value: "" }])
      toast.error("Something Went Wrong")
    }
  }
  useEffect(() => {
    if (selectedOutlet) {
      getdata()
    }
  }, [selectedOutlet])


  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb
            title="Security Management"
            links={[
              { to: "/", name: "Home" },
              // { to: "/total-outlets", name: "Outlets" },
            ]}
          />
        </div>
        <div className="col-md-12">
          <div className="ms-panel ms-panel-fh">
            <div className="ms-panel-header">
              <h6>Security Management</h6>
            </div>
            <div className="ms-panel-body">
              <div className="row">
                <div className="col-md-4 mb-4">
                  {localStorage.getItem("vendorType") === "multiple" && (
                    <div className="col-xl-12 col-lg-12 col-md-12">
                      <label htmlFor="validationCustom01" className="form-label">
                        Select Outlet
                      </label>
                      <div className="dropdown-group form-vendor-regi">
                        <select
                          className="form-control"
                          id="validationCustom01"
                          name="outletId"
                          value={selectedOutlet?.outletId}
                          onChange={(e) => {
                            localStorage.setItem("outletId", e.target.value)
                            setSelectedOutlet({
                              ...selectedOutlet,
                              outletId: e.target.value,
                            });
                            dispatch(
                              getDashboardData({
                                ...selectedOutlet,
                                outletId: e.target.value,
                              })
                            );
                          }}
                        >
                          <option value={""}>Please Select</option>
                          {outletList.length ? (
                            outletList.map((item) => {
                              return (
                                <option value={item.outletId}>
                                  {upperCase(item.outletName)}
                                </option>
                              );
                            })
                          ) : (
                            <option value={0}> Not found</option>
                          )}
                        </select>
                        {/* <div className="invalid-feedback">
                            Please Select a valid Designation.
                          </div> */}
                      </div>
                    </div>
                  )}
                </div>

              </div>
              <div className="form-vendor-regi">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Value</th>
                      {/* <th></th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {additionalData?.map((item, index) => (
                      <tr>
                        <td>{item.title}</td>
                        {
                          index > 19 ?
                            <td>
                              <input
                                type="text"
                                maxLength="50"
                                value={item.value || ""}
                                onChange={(e) => handleInputChange(index, e.target.value)}
                              />
                            </td> : <td>
                              <div className="radioss">
                                <div>
                                  <label>Yes</label>

                                  <input
                                    type="radio"
                                    name={`sanitization-${index}`}
                                    value="yes"
                                    checked={item.value === 'yes'}
                                    onChange={() => handleRadioChange(index, 'yes')}
                                  />
                                </div>
                                <div>
                                  <label>No</label>
                                  <input
                                    type="radio"
                                    name={`sanitization-${index}`}
                                    value="no"
                                    checked={item.value === 'no'}
                                    onChange={() => handleRadioChange(index, 'no')}
                                  />
                                </div>
                              </div>

                            </td>
                        }


                      </tr>
                    ))}
                    {/* {additionalData?.map((item, index) => (
                      <tr key={index}>
                        <td>{item.title}</td>
                        <td>
                          <input
                            type="text"
                            maxLength="50"
                            value={item.value || ""}
                            onChange={(e) => handleInputChange(index, e.target.value)}
                          />
                        </td>
                      </tr>
                    ))} */}
                  </tbody>
                </table>
              </div>
              {/* <div className="form-vendor-regi">
                <table className="table">

                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Description (max 50 words)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {additionalData?.map((item, index) => (
                      <tr key={index}>
                        <td>{item.title}</td>
                        <td>
                          <input
                            type="text"
                            maxLength="50"
                            value={item.value || ""}
                            onChange={(e) => handleInputChange(index, e.target.value)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div> */}

              <div className="row mt-4">
                <div className="col-md-12 ">
                  {loader ? (
                    <div className="d-flex justify-content-center">
                      <Spinner animation="border" />
                    </div>
                  ) : (
                    <button className="btn btn-primary float-left" type="submit" onClick={() => {
                      handleSubmit()
                    }}>
                      Save
                    </button>
                  )}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div >

  )
}

export default Securitymanagement;
