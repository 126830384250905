import React, { Component } from 'react';
import Sidenavigation from '../layouts/Sidenavigation';
import VRegistration from './Auth/VRegistration';

class VendorRegistration extends Component {
    render() {
        return (
            <div className="ms-body ms-primary-theme ms-logged-out">
                <Sidenavigation />
                <main className="body-content">
                    <VRegistration />
                </main>
            </div>
        );
    }
}

export default VendorRegistration;