import axios from "axios";
import apiPath, { BASE_URL } from "./apiPath";
import authHeader from "./authHeader";
const getBillingListApi = (props) => {
    return axios.post(BASE_URL + apiPath.getBillingList, props, authHeader());
};
const getPaymentListApi = (props) => {
    return axios.post(BASE_URL + apiPath.getPaymentList, props, authHeader());
};
const getPendingListApi = (props) => {
    return axios.post(BASE_URL + apiPath.getPendingList, props, authHeader());
};
// const getTrendsReportsApi = (props) => {
//     return axios.post(BASE_URL + apiPath.getTrendsReports, props, authHeader());
// };

const financeApi = {
    getBillingListApi,
    getPaymentListApi,
    getPendingListApi,
    // getTrendsReportsApi
}

export default financeApi;