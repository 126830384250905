import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Button, Col, Modal, Row } from "react-bootstrap";
import {
  getCities,
  getCountries,
  getSettingAddress,
  getStates,
  updateSettingAddress,
  verifyAddress,
} from "../../../redux/slices/Registration";
import SimpleMap from "../../GoogleMaps";
import Breadcrumb from "./Breadcrumb";
import Select from "react-select";
import { useLoadScript, GoogleMap, Marker } from "@react-google-maps/api";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import useRequest from "../../../hooks/useRequest";
import axios from "axios";
import { BASE_URL } from "../../../redux/services/apiPath";
import authHeader from "../../../redux/services/authHeader";

const libraries = ["places"];
const mapContainerStyle = {
  width: "100%",
  height: "400px",
};
const center = {
  lat: 37.7749,
  lng: -122.4194,
};
const Address = () => {
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = React.useState(false);
  const {
    countries,
    address: adreesss,
    vendorId,
    outletId,
    settingsAddressDetails,
  } = useSelector((state) => state.register);
  const [address, setAddress] = useState("");
  const [markerPosition, setMarkerPosition] = useState(center);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDaQcyEyDvVeB8GG3NOzqtXB1i8ukYqcgs",
    libraries,
  });
  const [cityOption, setCityOptions] = useState([]);
  const [states, setStates] = useState([]);
  const [countryID, setCountryId] = useState('');
  const [cities, setCities] = useState([]);
  const [cityID, setCityId] = useState("");
  const [stateId, setStateID] = useState("");
  const { response: responseState, request: requestState } = useRequest();
  const { response: responseCity, request: requestCity } = useRequest();

  const [latLong, setLatLong] = React.useState({
    latitude: "",
    longitude: "",
  });


  useEffect(() => {
    
    if (stateId) {
      requestCity("POST", "getCity", {
        stateId: stateId,
      });
    }
   
  }, [stateId]);

  useEffect(() => {
    if (responseState) {
      if (responseState?.status == "success" || responseState?.status == true) {
        setStates(responseState?.data?.length ? responseState?.data : []);
      }
    }
  }, [responseState]);

  useEffect(() => {
    if (responseCity) {
      if (responseCity?.status == "success" || responseCity?.status == true) {
        setCities(responseCity?.data?.length ? responseCity?.data : []);
      }
    }
  }, [responseCity]);

  const [initialValues, setInitialValues] = React.useState({
    country: settingsAddressDetails?.country || "",
    state: settingsAddressDetails?.state || "",
    city: settingsAddressDetails?.city || "",
    address: settingsAddressDetails?.address || "",
    street: settingsAddressDetails?.street,
    landMark: settingsAddressDetails?.landMark || "",
    pincode: settingsAddressDetails?.pincode || "",
    // latitude: "12.912",
    // longitude: "77.912",
  });

  // useEffect(() => {
  //   if(countries?.length>0){
  //    const selectedCountry =  countries.find(c => c._id == settingsAddressDetails?.country );
  //    if(selectedCountry?._id){
  //     setInitialValues({...initialValues,country:selectedCountry?._id})
  //    }

  //   }
  // },[countries,settingsAddressDetails])
  const validationSchema = Yup.object().shape({
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    address: Yup.string().required("Address is required"),
    landMark: Yup.string().required("LandMark is required"),
    // street: Yup.string().required("Street is required"),
    pincode: Yup.string().required("Postal Code is required"),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      //   dispatch(stepFiveSave(values));
      // handleAddressVerification();
      let data = { ...values };
      delete data.city;
      delete data.address;
      data["city"] = cityID;
      data["address"] = address;
      dispatch(
        updateSettingAddress({
          ...data,
          ...latLong,
          vendorId: vendorId,
          outletId: outletId,
        })
      )
        .unwrap()
        .then((res) => {
          toast.success(res.message);
          setModalShow(false);
        })
        .catch((err) => {
          toast.error(err);
        });
    },
  });
  const handleShowOtpModal = async () => {
    setLatLong({
      latitude: address.lat ? address.lat : "",
      longitude: address.lng ? address.lng : "",
    });

    let data = { ...formik.values };
    delete data.city;
    data["city"] = cityID;
    dispatch(
      updateSettingAddress({
        ...data,
        ...latLong,
        vendorId: vendorId,
        outletId: outletId,
      })
    )
      .unwrap()
      .then((res) => {
        toast.success(res.message);
        setModalShow(false);
      })
      .catch((err) => {
        toast.error(err);
      });
  };
  const handleAddressVerification = () => {
    let data = { ...formik.values };
    delete data.city;
    data["city"] = cityID;

    dispatch(verifyAddress(data))
      .unwrap()
      .then((res) => {
        setLatLong({
          latitude: res.data.lat,
          longitude: res.data.lng,
        });
        setModalShow(true);
        toast.success("Address Verified");
      })
      .catch((err) => {
        toast.error(err);
      });
  };
  const onHandleChange = (e) => {
    formik.handleChange(e);
    if (e.target.name === "country") {
      dispatch(getStates(e.target.value)).then((res) => {
        setStates(res?.payload?.data?.length ? res?.payload?.data : []);
      });
    } else if (e.target.name === "state") {
      dispatch(getCities(e.target.value)).then((res) => {
        setCities(res?.payload?.data?.length ? res?.payload?.data : []);
      });
    }
  };

  useEffect(() => {
    const cityOptions = cities?.map((city) => ({
      value: city?._id,
      label: city?.name,
    }));
    setCityOptions(cityOptions);
  }, [cities]);

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Confirm Your Address
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{SimpleMap(latLong)}</Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Change Address</Button>
          <Button onClick={handleShowOtpModal}>Confirm</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const getAddress = async () => {
    let vendorId = localStorage.getItem("vendorId");
    const settingsAddressDetails = await axios.get(
      `${BASE_URL}setting/getVendorAddress?vendorId=${vendorId}`,
      authHeader()
    );

  
    setAddress(settingsAddressDetails?.data?.data?.address);
    setLatLong({
      latitude: settingsAddressDetails?.data?.data?.latitude,
      longitude: settingsAddressDetails?.data?.data?.longitude,
    });
    setMarkerPosition({
      lat: Number(settingsAddressDetails?.data?.data?.latitude),
      lng: Number(settingsAddressDetails?.data?.data?.longitude),
    });
    setInitialValues({
      country: settingsAddressDetails?.data?.data?.country || "",
      state: settingsAddressDetails?.data?.data?.state || "",
      city: settingsAddressDetails?.data?.data?.city || "",
      address: settingsAddressDetails?.data?.data?.address || "",
      street: settingsAddressDetails?.data?.data?.street || "",
      landMark: settingsAddressDetails?.data?.data?.landMark || "",
      pincode: settingsAddressDetails?.data?.data?.pincode || "",
      // longitude: "77.912",
      // latitude: "12.912",
    });
    setAddress(settingsAddressDetails?.data?.data?.address);
    setCountryId(settingsAddressDetails?.data?.data?.country);
    setCityId(settingsAddressDetails?.data?.data?.city);
    setStateID(settingsAddressDetails?.data?.data?.state);
    
  };
  useEffect(() => {
    dispatch(getCountries())
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, [dispatch]);
  useEffect(() => {
    getAddress();
  }, []);
  
  useEffect(() => {
    if (countryID) {
       requestState("POST", "getState", {
         countryId: countryID,
       });
     }
   }, [countryID]);

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setMarkerPosition(latLng);
    setLatLong({
      latitude: latLng.lat,
      longitude: latLng.lng,
    });
    setAddress(value);
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps...</div>;

  const geocodeLatLng = (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    const latLng = {
      lat: parseFloat(lat),
      lng: parseFloat(lng),
    };

    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          // formik.setFieldValue(
          //   "pincode",
          //   results[0].formatted_address?.split(",")[2]?.split(" ")[1]
          // );
          setAddress(results[0].formatted_address);
        } else {
          console.log("No results found");
        }
      } else {
        console.log("Geocoder failed due to: " + status);
      }
    });
  };


  return (
    <div className="ms-content-wrapper">
      {
        <div className="row">
          <div className="col-md-12">
            <Breadcrumb title="Documents" links={[{ to: "/", name: "Home" }]} />

            <div className="row">
              <div className="col-xl-12 col-md-12">
                <div className="ms-panel ms-panel-fh">
                  <div className="ms-panel-header">
                    <h6>Address</h6>
                  </div>
                  <div className="ms-panel-body">
                    <form className="needs-validation clearfix" noValidate>
                      <div className="form-row">
                        <div className="col-md-12 mb-3">
                          <label htmlFor="country">
                            Country<span className="error">*</span>
                          </label>
                          <div className="input-group">
                            <select
                              className="form-control"
                              name="country"
                              value={formik.values.country}
                              onChange={onHandleChange}
                              style={{
                                borderColor:
                                  formik.errors.country &&
                                  formik.touched.country
                                    ? "red"
                                    : "#3ea99f",
                              }}
                            >
                              <option>Select Country</option>
                              {countries.length > 0 ? (
                                countries.map((country) => {
                                  return (
                                    <option value={country._id}>
                                      {country.name}
                                    </option>
                                  );
                                })
                              ) : (
                                <option value={0}>No Country Found</option>
                              )}
                            </select>
                            {formik?.errors?.country && (
                              <div
                                style={{ display: "block" }}
                                className="invalid-feedback"
                              >
                                This field is required.
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label htmlFor="state">
                            State<span className="error">*</span>
                          </label>
                          <div className="input-group">
                            <select
                              className="form-control capitalize"
                              name="state"
                              aria-readonly={true}
                              onChange={onHandleChange}
                              value={formik.values.state}
                              style={{
                                borderColor:
                                  formik.errors.state && formik.touched.state
                                    ? "red"
                                    : "#3ea99f",
                              }}
                            >
                              <option>Select State</option>
                              {states.length > 0 ? (
                                states.map((state) => {
                                  return (
                                    <option value={state._id}>
                                      {state.name}
                                    </option>
                                  );
                                })
                              ) : (
                                <option value={0}>No State Found</option>
                              )}
                            </select>
                            {formik?.errors?.state && (
                              <div
                                style={{ display: "block" }}
                                className="invalid-feedback"
                              >
                                This field is required.
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label htmlFor="validationCustom03">
                            City<span className="error">*</span>
                          </label>
                          <div className="input-group">
                            {/* <select
                            className="form-control"
                            name="city"
                            onChange={onHandleChange}
                            value={formik.values.city}
                            style={{
                              borderColor:
                                formik.errors.city && formik.touched.city
                                  ? "red"
                                  : "#3ea99f",
                            }}
                          >
                            <option>Select City</option>
                            {cities.length > 0 ? (
                              cities.map((country) => {
                                return (
                                  <option value={country._id}>
                                    {country.name}
                                  </option>
                                );
                              })
                            ) : (
                              <option value={0}>No City Found</option>
                            )}
                          </select>  */}

                            <Select
                              id="city"
                              name="city"
                              className={`border-blue w-100 `}
                              options={cityOption}
                              onChange={({ value }) => {
                                setCityId(value);
                              }}
                              value={cityOption?.filter(
                                ({ value }) => value == cityID
                              )}
                              placeholder="Select City"
                            />

                            {!cityID && (
                              <div
                                style={{ display: "block" }}
                                className="invalid-feedback"
                              >
                                This field is required.
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label htmlFor="validationCustom03">
                            House NO./ Flat No.<span className="error">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              name="street"
                              onChange={formik.handleChange}
                              value={formik.values.street}
                              id="validationCustom03"
                              placeholder="street"
                              style={{
                                borderColor:
                                  formik.errors.street && formik.touched.street
                                    ? "red"
                                    : "#3ea99f",
                              }}
                            />
                            {formik?.errors?.street && (
                              <div
                                style={{ display: "block" }}
                                className="invalid-feedback"
                              >
                                This field is required.
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 mb-3 ">
                          <label htmlFor="validationCustom04">
                            Address<span className="error">*</span>
                          </label>

                          <div className="mb-3">
                            <PlacesAutocomplete
                              value={address}
                              onChange={setAddress}
                              onSelect={handleSelect}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                              }) => (
                                <div>
                                  <input
                                    {...getInputProps({
                                      placeholder: "Search Places ...",
                                      className: "form-control",
                                    })}
                                  />
                                  <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion) => {
                                      const className = suggestion.active
                                        ? "suggestion-item--active"
                                        : "suggestion-item";
                                      const style = suggestion.active
                                        ? {
                                            backgroundColor: "#fafafa",
                                            cursor: "pointer",
                                          }
                                        : {
                                            backgroundColor: "#ffffff",
                                            cursor: "pointer",
                                          };
                                      return (
                                        <div
                                          {...getSuggestionItemProps(
                                            suggestion,
                                            {
                                              className,
                                              style,
                                            }
                                          )}
                                        >
                                          <span>{suggestion.description}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>
                          </div>
                        </div>

                        <div className="col-md-12 mb-3 ">
                          <GoogleMap
                            mapContainerStyle={mapContainerStyle}
                            zoom={10}
                            center={markerPosition}
                            onClick={(event) => {
                              geocodeLatLng(
                                event.latLng.lat(),
                                event.latLng.lng()
                              );
                              setMarkerPosition({
                                lat: event.latLng.lat(),
                                lng: event.latLng.lng(),
                              });
                              setLatLong({
                                latitude: event.latLng.lat(),
                                longitude: event.latLng.lng(),
                              });
                            }}
                          >
                            {/* Place a marker at the selected position */}
                            <Marker position={markerPosition} />
                          </GoogleMap>
                        </div>

                        <div className="col-md-6 mb-3">
                          <label htmlFor="validationCustom04">
                            Nearest Land Mark<span className="error">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              name="landMark"
                              onChange={formik.handleChange}
                              value={formik.values.landMark}
                              id="validationCustom04"
                              placeholder="Land Mark"
                              style={{
                                borderColor:
                                  formik.errors.landMark &&
                                  formik.touched.landMark
                                    ? "red"
                                    : "#3ea99f",
                              }}
                            />
                            {formik?.errors?.landMark && (
                              <div
                                style={{ display: "block" }}
                                className="invalid-feedback"
                              >
                                This field is required.
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6 mb-3">
                          <label htmlFor="validationCustom04">
                            Postal code<span className="error">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              name="pincode"
                              onChange={formik.handleChange}
                              value={formik.values.pincode}
                              id="validationCustom04"
                              placeholder="Postal code"
                              style={{
                                borderColor:
                                  formik.errors.pincode &&
                                  formik.touched.pincode
                                    ? "red"
                                    : "#3ea99f",
                              }}
                            />
                            {formik?.errors?.pincode && (
                              <div
                                style={{ display: "block" }}
                                className="invalid-feedback"
                              >
                                This field is required.
                              </div>
                            )}
                          </div>
                        </div>

                        {latLong.latitude && latLong.longitude && (
                          <>
                            <div className="col-md-6 mb-3">
                              <label htmlFor="validationCustom03">
                                Latitude
                              </label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="latitude"
                                  onChange={formik.handleChange}
                                  value={latLong.latitude}
                                  id="validationCustom03"
                                  placeholder="Latitude"
                                  disabled
                                />
                                {/* {formik?.errors?.street && (
                                        <div style={{"display":"block"}} className="invalid-feedback">
                                          This field is required.
                                        </div>
                                      )} */}
                              </div>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label htmlFor="validationCustom04">
                                Longitude
                              </label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="longitude"
                                  onChange={formik.handleChange}
                                  value={latLong.longitude}
                                  id="validationCustom04"
                                  placeholder="Longitude"
                                  disabled
                                />
                                {/* {formik?.errors?.pincode && (
                                      <div style={{"display":"block"}} className="invalid-feedback">
                                        This field is required.
                                      </div>
                                    )} */}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </form>
                  </div>
                  <div className="ms-panel-header new">
                    {/* <button className="btn btn-secondary d-block" type="submit">
                                                          Save
                                                      </button> */}
                    <button
                      className="btn btn-primary d-block"
                      type="submit"
                      onClick={formik.handleSubmit}
                    >
                      Verify Address
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      {latLong.latitude && (
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      )}
    </div>
  );
};
export default Address;
