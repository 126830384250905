import React, { useEffect } from 'react';
import Sidenavigation from '../layouts/Sidenavigation';
import Topnavigation from '../layouts/Topnavigation';
import Quickbar from '../layouts/Quickbar';
import OutletGrids from './Outlet/OutletGrids';
import { getAllOutlets } from '../../redux/slices/Outlet';
import { useDispatch } from 'react-redux';

const TotalOutlets = () => {
    const dispatch = useDispatch();
    // useEffect(() => {
    //     dispatch(getAllOutlets({ vendorId: localStorage.getItem("vendorId") }));
    // });
    return (
        <>
            <OutletGrids />
        </>
    );
}

export default TotalOutlets;