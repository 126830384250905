import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Button, Form, Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import loginLogo from "../../assets/img/logo.png";
import { stepFourSave } from "../../redux/slices/SaveDetails";
import { registerStepFour } from "../../redux/slices/Registration";
import { maxSize } from "../../constants";

const StepFour = ({ nextStep, handleNextStep }) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const { outletId, vendorType } = useSelector((state) => state.register);
  const { stepFour } = useSelector((state) => state.saveDetails);
  const [touchedError, setTouchedError] = useState(false);
  const [textCount, setTextCount] = useState();
  const [displayImage, setDisplayImage] = useState({
    coverPicture: "",
    facadePicture: [],
    interiorsPicture: [],
    sittingPicture: [],
    teamStaffPicture: [],
    otherPicture: [],
  });
  const [storeImage, setStoreImage] = useState({
    coverPicture: [],
    facadePicture: [],
    interiorsPicture: [],
    sittingPicture: [],
    teamStaffPicture: [],
    otherPicture: [],
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
    setValue,
  } = useForm();

  useEffect(() => {
    register("coverPicture", { required: true });
    register("facadeImage", { required: true });

    if (vendorType == "single") {
      register("interiorsImage", { required: false });
      register("uploadSitting", { required: false });
    }
  }, []);

  useEffect(() => {
    if (stepFour) {
      const length = stepFour?.description?.length ?? "";
      setTextCount(length);
      setValue("description", stepFour?.description);

      let coverPictureBlobImage = "";
      let facadePictureBlobImage = [];
      let interiorsPictureBlobImage = [];
      let sittingPictureBlobImage = [];
      let teamStaffPictureBlobImage = [];
      let otherPictureBlobImage = [];

      let coverPictureStoreImage = "";
      let facadePictureStoreImage = [];
      let interiorsPictureStoreImage = [];
      let sittingPictureStoreImage = [];
      let teamStaffPictureStoreImage = [];
      let otherPictureStoreImage = [];

      if (stepFour?.coverPicture?.length > 0) {
        if (stepFour?.coverPicture[0]) {
          const files = stepFour?.coverPicture;
          if (files) {
            coverPictureBlobImage = URL.createObjectURL(files[0]);
            coverPictureStoreImage = files;
            // setDisplayImage({...displayImage,coverPicture:URL.createObjectURL(files[0])});
            // setStoreImage({...storeImage,coverPicture:files});

            setError("coverPicture", "");
            setValue("coverPicture", files[0]);
            register("coverPicture", { required: false });
            setError("coverPicture", "");
          } else {
            setError("coverPicture", "");
            coverPictureBlobImage = "";
            coverPictureStoreImage = [];
            setValue("coverPicture", "");
            register("coverPicture", { required: true });
          }
        }
      }

      if (stepFour?.facadeImage?.length > 0) {
        if (stepFour?.facadeImage[0]) {
          const files = stepFour?.facadeImage;
          if (files?.length > 0) {
            let facadeP = [];
            let facadeFile = [];

            for (let i = 0; i < files.length; i++) {
              facadeP.push(URL.createObjectURL(files[i]));
              facadeFile.push(files[i]);
            }

            facadePictureBlobImage = facadeP;
            facadePictureStoreImage = files;
            // setDisplayImage({...displayImage,facadePicture:facadeP});
            // setStoreImage({...storeImage,facadePicture:files});
            setError("facadeImage", "");
            setValue("facadeImage", facadeFile);
            register("facadeImage", { required: false });
            setError("facadeImage", "");
          } else {
            setError("facadePicture", "");
            facadePictureBlobImage = [];
            facadePictureStoreImage = [];
            setValue("facadeImage", []);
            register("facadeImage", { required: true });
          }
        }
      }

      if (stepFour?.interiorsImage?.length > 0) {
        if (stepFour?.interiorsImage[0]) {
          const files = stepFour?.interiorsImage;

          if (files?.length) {
            let interiorsP = [];
            let interiorsFile = [];

            for (let i = 0; i < files.length; i++) {
              interiorsP.push(URL.createObjectURL(files[i]));
              interiorsFile.push(files[i]);
            }

            interiorsPictureBlobImage = interiorsP;
            interiorsPictureStoreImage = files;
            // setDisplayImage({...displayImage,interiorsPicture:interiorsP});
            // setStoreImage({...storeImage,interiorsPicture:files});

            setError("interiorsImage", "");
            setValue("interiorsImage", interiorsFile);
            register("interiorsImage", { required: false });
          } else {
            setError("interiorsImage", "");
            interiorsPictureBlobImage = [];
            interiorsPictureStoreImage = [];
            setValue("interiorsImage", []);
            register("interiorsImage", { required: true });
          }
        }
      }

      if (stepFour?.sittingImage?.length > 0) {
        if (stepFour?.sittingImage[0]) {
          const files = stepFour?.sittingImage;

          if (files?.length) {
            let sittingP = [];
            let sittingFile = [];

            for (let i = 0; i < files.length; i++) {
              sittingP.push(URL.createObjectURL(files[i]));
              sittingFile.push(files[i]);
            }

            sittingPictureBlobImage = sittingP;
            sittingPictureStoreImage = files;
            setError("uploadSitting", "");
            setValue("uploadSitting", sittingFile);
            register("uploadSitting", { required: false });
          } else {
            sittingPictureBlobImage = [];
            sittingPictureStoreImage = [];
            setError("uploadSitting", "");
            setValue("uploadSitting", []);
            register("uploadSitting", { required: false });
          }
        }
      }

      if (stepFour?.staffImage?.length > 0) {
        if (stepFour?.staffImage[0]) {
          const files = stepFour?.staffImage;
          if (files?.length) {
            let teamsP = [];
            let teamsFile = [];

            for (let i = 0; i < files.length; i++) {
              teamsP.push(URL.createObjectURL(files[i]));
              teamsFile.push(files[i]);
            }

            teamStaffPictureBlobImage = teamsP;
            teamStaffPictureStoreImage = files;

            setValue("uploadStaff", teamsFile);
          } else {
            teamStaffPictureBlobImage = [];
            teamStaffPictureStoreImage = [];
            setValue("uploadStaff", []);
          }
        }
      }

      if (stepFour?.image?.length > 0) {
        if (stepFour?.image[0]) {
          const files = stepFour?.image;
          if (files?.length) {
            let otherP = [];
            let otherFile = [];

            for (let i = 0; i < files.length; i++) {
              otherP.push(URL.createObjectURL(files[i]));
              otherFile.push(files[i]);
            }
            otherPictureBlobImage = otherP;
            otherPictureStoreImage = files;

            setValue("images", otherFile);
          } else {
            otherPictureBlobImage = [];
            otherPictureStoreImage = [];
            setValue("images", []);
          }
        }
      }

      setDisplayImage({
        ...displayImage,
        coverPicture: coverPictureBlobImage,
        facadePicture: facadePictureBlobImage,
        interiorsPicture: interiorsPictureBlobImage,
        sittingPicture: sittingPictureBlobImage,
        teamStaffPicture: teamStaffPictureBlobImage,
        otherPicture: otherPictureBlobImage,
      });
      setStoreImage({
        ...storeImage,
        coverPicture: coverPictureStoreImage,
        facadePicture: facadePictureStoreImage,
        interiorsPicture: interiorsPictureStoreImage,
        sittingPicture: sittingPictureStoreImage,
        teamStaffPicture: teamStaffPictureStoreImage,
        otherPicture: otherPictureStoreImage,
      });
    }
  }, [stepFour]);

  const onSubmit = (data) => {
    setLoader(true);

    const {
      description,
      coverPicture,
      facadeImage,
      interiorsImage,
      uploadSitting,
      uploadStaff,
      images,
    } = data;

    const fd = new FormData();

    if (vendorType == "single") {
      if (description?.length >= 50 && description?.length <= 200) {
        fd.append("aboutVendor", description);
        fd.append("outletId", outletId);
        if (coverPicture) {
          fd.append("coverPicture", coverPicture);
        }

        if (interiorsImage?.length) {
          for (let i = 0; i < interiorsImage.length; i++) {
            fd.append("interiorsImage", interiorsImage[i]);
          }
        }

        if (facadeImage?.length) {
          for (let i = 0; i < facadeImage.length; i++) {
            fd.append("facadeImage", facadeImage[i]);
          }
        }

        if (uploadSitting?.length) {
          for (let i = 0; i < uploadSitting.length; i++) {
            fd.append("sittingImage", uploadSitting[i]);
          }
        }

        if (uploadStaff?.length) {
          for (let i = 0; i < uploadStaff.length; i++) {
            fd.append("staffImage", uploadStaff[i]);
          }
        }

        if (images?.length) {
          for (let i = 0; i < images.length; i++) {
            fd.append("otherImage", images[i]);
          }
        }

        if (vendorType == "single") {
          if (
            facadeImage?.length > 0

            // uploadSitting?.length > 0
          ) {
            // requestSaveStepFour("POST","registerStepFour",fd)

            dispatch(registerStepFour(fd))
              .unwrap()
              .then((res) => {
                toast.success(res.message);
                dispatch(
                  stepFourSave({
                    coverPicture: storeImage.coverPicture,
                    description: description,
                    image: storeImage.otherPicture,
                    facadeImage: storeImage.facadePicture,
                    interiorsImage: storeImage.interiorsPicture,
                    sittingImage: storeImage.sittingPicture,
                    staffImage: storeImage.teamStaffPicture,
                  })
                );
                handleNextStep(5);
              })
              .catch((err) => {
                setLoader(false);

                toast.error(err);
              });
          }
        } else {
          if (facadeImage.length > 0) {
            dispatch(registerStepFour(fd))
              .unwrap()
              .then((res) => {
                toast.success(res.message);
                handleNextStep(5);
                dispatch(
                  stepFourSave({
                    coverPicture: storeImage.coverPicture,
                    description: description,
                    image: storeImage.otherPicture,
                    facadeImage: storeImage.facadePicture,
                    interiorsImage: storeImage.interiorsPicture,
                    sittingImage: storeImage.sittingPicture,
                    staffImage: storeImage.teamStaffPicture,
                  })
                );
              })
              .catch((err) => {
                toast.error(err);
                setLoader(false);
              });
          }
        }
      } else {
        setLoader(false);
        toast.error("Must be of minimum 50 and maximum 200 characters.");
      }
    } else {
      fd.append("aboutVendor", description);
      fd.append("outletId", outletId);
      if (coverPicture) {
        fd.append("coverPicture", coverPicture);
      }

      if (interiorsImage?.length) {
        for (let i = 0; i < interiorsImage.length; i++) {
          fd.append("interiorsImage", interiorsImage[i]);
        }
      }

      if (facadeImage?.length) {
        for (let i = 0; i < facadeImage.length; i++) {
          fd.append("facadeImage", facadeImage[i]);
        }
      }

      if (uploadSitting?.length) {
        for (let i = 0; i < uploadSitting.length; i++) {
          fd.append("sittingImage", uploadSitting[i]);
        }
      }

      if (uploadStaff?.length) {
        for (let i = 0; i < uploadStaff.length; i++) {
          fd.append("staffImage", uploadStaff[i]);
        }
      }

      if (images?.length) {
        for (let i = 0; i < images.length; i++) {
          fd.append("otherImage", images[i]);
        }
      }

      if (vendorType == "single") {
        if (facadeImage.length > 0 && uploadSitting.length > 0) {
          // requestSaveStepFour("POST","registerStepFour",fd)

          dispatch(registerStepFour(fd))
            .unwrap()
            .then((res) => {
              toast.success(res.message);
              dispatch(
                stepFourSave({
                  coverPicture: storeImage.coverPicture,
                  description: description,
                  image: storeImage.otherPicture,
                  facadeImage: storeImage.facadePicture,
                  interiorsImage: storeImage.interiorsPicture,
                  sittingImage: storeImage.sittingPicture,
                  staffImage: storeImage.teamStaffPicture,
                })
              );
              handleNextStep(5);
            })
            .catch((err) => {
              setLoader(false);
              toast.error(err);
            });
        }
      } else {
        if (facadeImage.length > 0) {
          dispatch(registerStepFour(fd))
            .unwrap()
            .then((res) => {
              toast.success(res.message);
              handleNextStep(5);
              dispatch(
                stepFourSave({
                  coverPicture: storeImage.coverPicture,
                  description: description,
                  image: storeImage.otherPicture,
                  facadeImage: storeImage.facadePicture,
                  interiorsImage: storeImage.interiorsPicture,
                  sittingImage: storeImage.sittingPicture,
                  staffImage: storeImage.teamStaffPicture,
                })
              );
            })
            .catch((err) => {
              setLoader(false);
              toast.error(err);
            });
        }
      }
    }
  };

  // useEffect(() => {
  //   if(responseSaveStepFour){

  //   }
  // },[responseSaveStepFour])

  // const handleCoverPicture = (files) => {
  //   if (files?.length) {
  //     setDisplayImage({ ...displayImage, coverPicture: URL.createObjectURL(files[0]) });
  //     setStoreImage({ ...storeImage, coverPicture: files });

  //     setError("coverPicture", "");
  //     setValue("coverPicture", files[0]);
  //     register("coverPicture", { required: false });
  //     setError("coverPicture", "")
  //   } else {
  //     setError("coverPicture", "")
  //     setDisplayImage({ ...displayImage, coverPicture: "" });
  //     setStoreImage({ ...storeImage, coverPicture: "" });
  //     setValue("coverPicture", "");
  //     register("coverPicture", { required: true })
  //   }
  // };

  const handleFacadePicture = (files) => {
    if (files?.length > 0) {
      let facadeP = [];
      let facadeFile = [];

      for (let i = 0; i < files.length; i++) {
        facadeP.push(URL.createObjectURL(files[i]));
        facadeFile.push(files[i]);
      }

      setDisplayImage({ ...displayImage, facadePicture: facadeP });
      setStoreImage({ ...storeImage, facadePicture: files });
      setError("facadeImage", "");
      setValue("facadeImage", facadeFile);
      register("facadeImage", { required: false });
      setError("facadeImage", "");
    } else {
      setError("facadePicture", "");
      setDisplayImage({ ...displayImage, facadePicture: [] });
      setStoreImage({ ...storeImage, facadePicture: [] });
      setValue("facadeImage", []);
      register("facadeImage", { required: true });
    }
  };

  // const handleInteriorsPicture = (files) => {
  //   if (files?.length) {

  //     let interiorsP = [];
  //     let interiorsFile = [];

  //     for (let i = 0; i < files.length; i++) {
  //       interiorsP.push(URL.createObjectURL(files[i]));
  //       interiorsFile.push(files[i]);
  //     }

  //     setDisplayImage({ ...displayImage, interiorsPicture: interiorsP });
  //     setStoreImage({ ...storeImage, interiorsPicture: files });

  //     setError("interiorsImage", "");
  //     setValue("interiorsImage", interiorsFile);
  //     register("interiorsImage", { required: false });
  //   } else {
  //     setError("interiorsImage", "")
  //     setDisplayImage({ ...displayImage, interiorsPicture: [] });
  //     setStoreImage({ ...storeImage, interiorsPicture: [] });
  //     setValue("interiorsImage", []);
  //     register("interiorsImage", { required: true })
  //   }
  // };

  // const handleSittingPicture = (files) => {
  //   if (files?.length) {

  //     let sittingP = [];
  //     let sittingFile = [];

  //     for (let i = 0; i < files.length; i++) {
  //       sittingP.push(URL.createObjectURL(files[i]));
  //       sittingFile.push(files[i]);
  //     }

  //     setError("uploadSitting", "");
  //     setDisplayImage({ ...displayImage, sittingPicture: sittingP });
  //     setStoreImage({ ...storeImage, sittingPicture: files });
  //     setValue("uploadSitting", sittingFile);
  //     register("uploadSitting", { required: false });
  //   } else {
  //     setError("uploadSitting", "")
  //     setDisplayImage({ ...displayImage, sittingPicture: [] });
  //     setStoreImage({ ...storeImage, sittingPicture: [] });
  //     setValue("uploadSitting", []);
  //     register("uploadSitting", { required: true })
  //   }
  // };

  // const handleTeamStaffPicture = (files) => {
  //   if (files?.length) {

  //     let teamsP = [];
  //     let teamsFile = [];

  //     for (let i = 0; i < files.length; i++) {
  //       teamsP.push(URL.createObjectURL(files[i]));
  //       teamsFile.push(files[i]);
  //     }

  //     setDisplayImage({ ...displayImage, teamStaffPicture: teamsP });
  //     setStoreImage({ ...storeImage, teamStaffPicture: files });

  //     setValue("uploadStaff", teamsFile);
  //   } else {
  //     setDisplayImage({ ...displayImage, teamStaffPicture: [] });
  //     setStoreImage({ ...storeImage, teamStaffPicture: [] });
  //     setValue("uploadStaff", []);
  //   }
  // };

  // const handleOtherPicture = (files) => {
  //   if (files?.length) {

  //     let otherP = [];
  //     let otherFile = [];

  //     for (let i = 0; i < files.length; i++) {
  //       otherP.push(URL.createObjectURL(files[i]));
  //       otherFile.push(files[i]);
  //     }

  //     setDisplayImage({ ...displayImage, otherPicture: otherP });
  //     setStoreImage({ ...storeImage, otherPicture: files });

  //     setValue("images", otherFile);
  //   } else {
  //     setDisplayImage({ ...displayImage, otherPicture: [] });
  //     setStoreImage({ ...storeImage, otherPicture: [] });

  //     setValue("images", []);
  //   }
  // };

  const handleCoverPicture = (files) => {
    if (files?.length) {
      const file = files[0];
      const fileSize = file.size; // Size in bytes

      if (fileSize > maxSize) {
        toast.error(
          `File size exceeds maximum allowed size (${
            maxSize / 1024 / 1024
          } mb).`
        );

        return;
      }

      setDisplayImage({
        ...displayImage,
        coverPicture: URL.createObjectURL(file),
      });
      setStoreImage({ ...storeImage, coverPicture: files });
      setError("coverPicture", "");
      setValue("coverPicture", file);
      register("coverPicture", { required: false });
      setError("coverPicture", "");
    } else {
      setError("coverPicture", "");
      setDisplayImage({ ...displayImage, coverPicture: "" });
      setStoreImage({ ...storeImage, coverPicture: "" });
      setValue("coverPicture", "");
      register("coverPicture", { required: true });
    }
  };

  const handleInteriorsPicture = (files) => {
    if (files?.length > 0) {
      let interiorsP = [];
      let interiorsFile = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileSize = file.size;

        if (fileSize > maxSize) {
          // Display error message or take appropriate action
          toast.error(
            `File size exceeds maximum allowed size (${
              maxSize / 1024 / 1024
            } mb).`
          );

          return;
        }

        interiorsP.push(URL.createObjectURL(file));
        interiorsFile.push(file);
      }

      setDisplayImage({ ...displayImage, interiorsPicture: interiorsP });
      setStoreImage({ ...storeImage, interiorsPicture: files });

      setError("interiorsImage", "");
      setValue("interiorsImage", interiorsFile);
      register("interiorsImage", { required: false });
    } else {
      setError("interiorsImage", "Please Select at least one image.");
      setDisplayImage({ ...displayImage, interiorsPicture: [] });
      setStoreImage({ ...storeImage, interiorsPicture: [] });
      setValue("interiorsImage", []);
      register("interiorsImage", { required: true });
    }
  };

  const handleSittingPicture = (files) => {
    if (files?.length > 0) {
      let sittingP = [];
      let sittingFile = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileSize = file.size;

        if (fileSize > maxSize) {
          // Display error message or take appropriate action
          toast.error(
            `File size exceeds maximum allowed size (${
              maxSize / 1024 / 1024
            } mb).`
          );

          return;
        }

        sittingP.push(URL.createObjectURL(file));
        sittingFile.push(file);
      }

      setError("uploadSitting", "");
      setDisplayImage({ ...displayImage, sittingPicture: sittingP });
      setStoreImage({ ...storeImage, sittingPicture: files });
      setValue("uploadSitting", sittingFile);
      register("uploadSitting", { required: false });
    } else {
      setError("uploadSitting", "Please Select at least one image.");
      setDisplayImage({ ...displayImage, sittingPicture: [] });
      setStoreImage({ ...storeImage, sittingPicture: [] });
      setValue("uploadSitting", []);
      register("uploadSitting", { required: true });
    }
  };

  const handleTeamStaffPicture = (files) => {
    if (files?.length > 0) {
      let teamsP = [];
      let teamsFile = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileSize = file.size;

        if (fileSize > maxSize) {
          toast.error(
            `File size exceeds maximum allowed size (${
              maxSize / 1024 / 1024
            } mb).`
          );

          return;
        }

        teamsP.push(URL.createObjectURL(file));
        teamsFile.push(file);
      }

      // setError("uploadStaff", ""); // Clear any previous error
      setDisplayImage({ ...displayImage, teamStaffPicture: teamsP });
      setStoreImage({ ...storeImage, teamStaffPicture: files });
      setValue("uploadStaff", teamsFile);
    } else {
      // Handle case when no files are selected
      setDisplayImage({ ...displayImage, teamStaffPicture: [] });
      setStoreImage({ ...storeImage, teamStaffPicture: [] });
      setValue("uploadStaff", []);
    }
  };

  const handleOtherPicture = (files) => {
    if (files?.length > 0) {
      let otherP = [];
      let otherFile = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileSize = file.size;

        if (fileSize > maxSize) {
          toast.error(
            `File size exceeds maximum allowed size (${
              maxSize / 1024 / 1024
            } mb).`
          );

          return;
        }

        otherP.push(URL.createObjectURL(file));
        otherFile.push(file);
      }

      // setError("images", ""); // Clear any previous error
      setDisplayImage({ ...displayImage, otherPicture: otherP });
      setStoreImage({ ...storeImage, otherPicture: files });
      setValue("images", otherFile);
    } else {
      // Handle case when no files are selected
      setDisplayImage({ ...displayImage, otherPicture: [] });
      setStoreImage({ ...storeImage, otherPicture: [] });
      setValue("images", []);
    }
  };

  return (
    <div className="ms-content-wrapper ms-auth">
      <div className="regi-section">
        <Row>
          <Col md={5}>
            <div className="ms-auth-bg">
              <img src={loginLogo} alt="loginLogo" />
            </div>
          </Col>
          <Col md={7}>
            <div className="regi-form">
              <form
                className="needs-validation"
                onSubmit={handleSubmit(onSubmit)}
                style={{ width: "100%" }}
              >
                <h3>
                  Registration : Step <span>{nextStep} of 6</span>
                </h3>
                <p>Please enter information to continue</p>

                <div className="form-vendor-regi">
                  <div className="form-vendor">
                    <Row>
                      {vendorType == "single" && (
                        <Col md={12}>
                          <div className="form-group">
                            <label htmlFor="description">
                              Describe Your Restaurant (minimum of 50 characters
                              and max of 200 chars)
                              <span className="error">*</span>{" "}
                              {textCount ? `(${textCount})` : ""}
                            </label>
                            <div className="input-group">
                              <textarea
                                type="text"
                                // minLength={50}
                                // maxLength={200}
                                className={`form-control ${
                                  touchedError
                                    ? errors.description?.type == "required" ||
                                      errors.description?.type == "pattern"
                                      ? "is-invalid"
                                      : "is-valid"
                                    : ""
                                }`}
                                id="description"
                                name="description"
                                {...register("description", {
                                  required: true,
                                  // pattern: /^[A-Za-z0-9 !@#$%^&*()-_+=,.'’]{50,200}$/gm,
                                })}
                                onChange={(e) => {
                                  // formik.setFieldValue("description", action.target.value)
                                  const length = e.target.value.length;
                                  setTextCount(length);
                                  setError("description", "");
                                  setValue("description", e.target.value);
                                }}
                                placeholder="Description"
                              />

                              {errors.description?.type === "required" && (
                                <div className="invalid-feedback">
                                  The description field is required.
                                </div>
                              )}
                              {/* {errors.description?.type === "pattern" && (
                                <div className="invalid-feedback">
                                  Must be of minimum 50 and maximum 200 characters.
                                </div>
                              )} */}
                            </div>
                          </div>
                        </Col>
                      )}

                      <Col md={6}>
                        <div className="form-group ">
                          <label
                            htmlFor="foodLicence"
                            className="form-label customLabel"
                          >
                            Hotel Picture 
                            <span className="error">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              type="file"
                              id="coverPicture"
                              placeholder="Upload ID Proof"
                              name="coverPicture"
                              accept="image/*"
                              className={`uploadFile form-control ${
                                touchedError
                                  ? errors.coverPicture?.type === "required"
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                              onChange={(e) =>
                                handleCoverPicture(e?.target?.files)
                              }
                            />

                            <div>
                              {errors.coverPicture?.type === "required" && (
                                <div className="invalid-field">
                                  The cover picture field is required.
                                </div>
                              )}
                            </div>
                          </div>

                          {displayImage?.coverPicture && (
                            <div className="img-pos col-md-4">
                              <img
                                src={
                                  displayImage?.coverPicture
                                    ? `${displayImage?.coverPicture}`
                                    : ""
                                }
                                alt="preview"
                                style={{ width: "200px", height: "50px" }}
                              />
                            </div>
                          )}
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="form-group ">
                          <label htmlFor="facadeImage">
                            Hotel Façade 
                            <span className="error">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              type="file"
                              id="facadeImage"
                              placeholder="Upload Images"
                              name="facadeImage"
                              accept="image/*"
                              className={`uploadFile form-control ${
                                touchedError
                                  ? errors.facadeImage?.type === "required"
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                              style={{ color: "transparent" }}
                              // multiple

                              onChange={(e) =>
                                handleFacadePicture(e?.target?.files)
                              }
                            />
                            <div>
                              {errors.facadeImage?.type === "required" && (
                                <div className="invalid-field">
                                  The facade picture field is required.
                                </div>
                              )}
                            </div>
                          </div>

                          {displayImage?.facadePicture?.length
                            ? displayImage.facadePicture.map((image) => (
                                <div className="img-pos col-md-4">
                                  <img
                                    src={image ? `${image}` : ""}
                                    alt="preview"
                                    style={{ width: "200px", height: "50px" }}
                                  />
                                </div>
                              ))
                            : ""}
                        </div>
                      </Col>

                      {vendorType == "single" && (
                        <>
                          <Col md={6}>
                            <div className="form-group ">
                              <label htmlFor="interiorsImage">
                                Interiors / Décor
                              </label>
                              <div className="input-group">
                                <input
                                  type="file"
                                  id="interiorsImage"
                                  placeholder="Upload Images"
                                  name="interiorsImage"
                                  accept="image/*"
                                  className={`uploadFile form-control `}
                                  style={{ color: "transparent" }}
                                  multiple
                                  onChange={(e) =>
                                    handleInteriorsPicture(e?.target?.files)
                                  }
                                />
                              </div>

                              {displayImage?.interiorsPicture?.length
                                ? displayImage.interiorsPicture.map((image) => (
                                    <div className="img-pos col-md-4">
                                      <img
                                        src={image ? `${image}` : ""}
                                        alt="preview"
                                        style={{
                                          width: "200px",
                                          height: "50px",
                                        }}
                                      />
                                    </div>
                                  ))
                                : ""}
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="form-group ">
                              <label htmlFor="uploadSitting">
                                Sitting Arrangements
                              </label>
                              <div className="input-group">
                                <input
                                  type="file"
                                  id="uploadSitting"
                                  placeholder="Upload Images"
                                  name="uploadSitting"
                                  accept="image/*"
                                  className={`uploadFile form-control`}
                                  style={{ color: "transparent" }}
                                  multiple
                                  onChange={(e) =>
                                    handleSittingPicture(e?.target?.files)
                                  }
                                />
                              </div>

                              {displayImage?.sittingPicture?.length
                                ? displayImage.sittingPicture.map((image) => (
                                    <div className="img-pos col-md-4">
                                      <img
                                        src={image ? `${image}` : ""}
                                        alt="preview"
                                        style={{
                                          width: "200px",
                                          height: "50px",
                                        }}
                                      />
                                    </div>
                                  ))
                                : ""}
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="form-group ">
                              <label htmlFor="uploadStaff">Team / Staff</label>
                              <div className="input-group">
                                <input
                                  type="file"
                                  id="uploadStaff"
                                  placeholder="Upload Images"
                                  name="uploadStaff"
                                  accept="image/*"
                                  className={`uploadFile form-control`}
                                  style={{ color: "transparent" }}
                                  multiple
                                  onChange={(e) =>
                                    handleTeamStaffPicture(e?.target?.files)
                                  }
                                />
                                <div></div>
                              </div>
                              {displayImage?.teamStaffPicture?.length
                                ? displayImage.teamStaffPicture.map((image) => (
                                    <div className="img-pos col-md-4">
                                      <img
                                        src={image ? `${image}` : ""}
                                        alt="preview"
                                        style={{
                                          width: "200px",
                                          height: "50px",
                                        }}
                                      />
                                    </div>
                                  ))
                                : ""}
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="form-group ">
                              <label htmlFor="images">Other Image</label>
                              <div className="input-group">
                                <input
                                  type="file"
                                  id="images"
                                  placeholder="Upload Images"
                                  name="images"
                                  accept="image/*"
                                  className={`uploadFile form-control`}
                                  style={{ color: "transparent" }}
                                  multiple
                                  onChange={(e) =>
                                    handleOtherPicture(e?.target?.files)
                                  }
                                />
                                <div></div>
                              </div>

                              {displayImage?.otherPicture?.length
                                ? displayImage.otherPicture.map((image) => (
                                    <div className="img-pos col-md-4">
                                      <img
                                        src={image ? `${image}` : ""}
                                        alt="preview"
                                        style={{
                                          width: "200px",
                                          height: "50px",
                                        }}
                                      />
                                    </div>
                                  ))
                                : ""}
                            </div>
                          </Col>
                        </>
                      )}
                    </Row>
                  </div>
                </div>

                {/* {isImageSelected && (
                <div className="error">{"please select all Images"}</div>
              )} */}
                <div className="row mt-4">
                  <div className="col-md-6">
                    <button
                      className="steps-btn float-left"
                      type="button"
                      onClick={() => {
                        if (vendorType == "single") {
                          handleNextStep(3);
                        } else {
                          handleNextStep(2);
                        }
                      }}
                    >
                      <i>&#8592;</i> Previous
                    </button>
                  </div>
                  <div className="col-md-6">
                    {loader ? (
                      <center>
                        <div
                          style={{
                            flex: 1,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Spinner
                            animation="border"
                            className="d-flex justify-content-center"
                          />
                        </div>
                      </center>
                    ) : (
                      <button
                        className="steps-btn"
                        type="submit"
                        onClick={() => setTouchedError(true)}
                      >
                        Next <i>&#8594;</i>
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default StepFour;
