import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategories, getProfileDetails, updateSettingProfile } from "../../../redux/slices/Registration";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { upperCase } from "../../../constants";
import { useFormik } from "formik";
import { getAllOutlets } from "../../../redux/slices/Outlet";
import Productslider from "../../sections/Addproduct/Productslider";
import Breadcrumb from "./Breadcrumb";

const ManagerProfile = () =>{
    const { designations, profile,managerId } = useSelector((state) => state.register);
    const dispatch = useDispatch();
    const history = useHistory();
    const [files, setFiles] = useState();
    const [image, setImage] = useState();
    const [defaultImage, setDefaultImage] = useState();
    const [initialValues, setInitialValues] = useState({
      designation: profile?.designation ? profile?.designation : "",
      name: profile?.name ? profile?.name : "",
      vendorName: profile?.vendorName ? profile?.vendorName : "",
      email: profile?.email ? profile?.email : "",
      phone: profile?.phone ? profile?.phone : "",
    });
    const validationSchema = Yup.object().shape({
      designation: Yup.string().required("Required"),
      name: Yup.string().required("Required"),
      email: Yup.string().required("Required"),
      phone: Yup.string().min(10,"Phone number must be of 10 digits.").required("This field is required."),
    });
    const formik = useFormik({
      initialValues,
      validationSchema,
      onSubmit: (values) => {
        const form = new FormData();
        form.append("managerId", managerId);
        for (const key in values) {
          if (key === "image") {
            continue;
          } else {
            form.append(key, values[key]);
          }
        }
        if (files) {
          for (let i = 0; i < files.length; i++) {
            form.append("image", files[i]);
          }
        } else if(image){
          form.append("oldImage", image);
        }
        dispatch(updateSettingProfile(form))
          .unwrap()
          .then((res) => {
            toast.success(res.message);
            dispatch(
              getProfileDetails({
                vendorId: localStorage.getItem("vendorId"),
                isSetting:true
              })
            )
            dispatch(
              getAllOutlets({ vendorId: localStorage.getItem("vendorId") })
            )
              .unwrap()
              .then(() => {});
          })
          .catch((err) => {
            toast.error(err);
          });
      },
    });
    useEffect(() => {
      dispatch(getCategories("designation"));
      dispatch(
        getProfileDetails({
          vendorId: localStorage.getItem("vendorId"),
          isSetting:true
        })
      )
        .unwrap()
        .then((res) => {
          setDefaultImage(res.data.default ? true : false)
          setImage(res.data.image ? res.data.image : "")
          setInitialValues({
            designation: res.data.designation ? res.data.designation : "",
            vendorName: res.data.vendorName ? res.data.vendorName : "",
            name: res.data.name ? res.data.name : "",
            email: res.data.email ? res.data.email : "",
            phone: res.data.phone ? res.data.phone : "",
            image: res.data.image ? res.data.image : "",
          });
        });
    }, [dispatch]);
    return (
      <div className="ms-content-wrapper">
        <Breadcrumb
              title="Account Profile"
              links={[
                  { to: "/", name: "Home" },
              ]}
          />
        <div className="row">
          <div className="col-xl-6 col-md-12">
            <div className="ms-panel ms-panel-fh">
              <div className="ms-panel-header">
                <h6>Account Profile</h6>
              </div>
              <div className="ms-panel-body">
                <form className="needs-validation clearfix" noValidate>
                  <div className="form-row">
                {initialValues?.type=='multiple'&&  <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom18">Vendor Name<span className="error">*</span></label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          id="validationCustom18"
                          name="vendorName"
                          value={formik.values.vendorName}
                          onChange={formik.handleChange}
                          placeholder="Name"
                          style={{
                            borderColor:
                              formik.errors.vendorName && formik.touched.vendorName
                                ? "red"
                                : "black",
                          }}
                          required
                        />
                         {formik?.errors?.vendorName && (
                         <div style={{"display":"block"}} className="invalid-feedback">
                          This field is required.
                        </div>
                      )}
                      </div>
                    </div>}
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom18">Name<span className="error">*</span></label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          id="validationCustom18"
                          name="name"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          placeholder="Name"
                          style={{
                            borderColor:
                              formik.errors.name && formik.touched.name
                                ? "red"
                                : "black",
                          }}
                          required
                        />
                         {formik?.errors?.name && (
                         <div style={{"display":"block"}} className="invalid-feedback">
                          This field is required.
                        </div>
                      )}
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom18">Main email<span className="error">*</span></label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          id="validationCustom18"
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          disabled={true}
                          placeholder="email"
                          style={{
                            borderColor:
                              formik.errors.email && formik.touched.email
                                ? "red"
                                : "black",
                          }}
                          required
                        />
                         {formik?.errors?.email && (
                         <div style={{"display":"block"}} className="invalid-feedback">
                          This field is required.
                        </div>
                      )}
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="validationCustom22">
                        Select Designation<span className="error">*</span>
                      </label>
                      <div className="input-group">
                        <select
                          className="form-control"
                          id="validationCustom22"
                          name="designation"
                          value={formik.values.designation}
                          onChange={formik.handleChange}
                          style={{
                            borderColor:
                              formik.errors.designation &&
                              formik.touched.designation
                                ? "red"
                                : "black",
                          }}
                          required
                        >
                          <option value="">Select Designation</option>
                          {designations.length > 0 ? (
                            designations.map((country) => {
                              return (
                                <option value={country._id}>
                                  {upperCase(country.name)}
                                </option>
                              );
                            })
                          ) : (
                            <option value={0}> Not found</option>
                          )}
                        </select>
                        {formik?.errors?.designation && (
                         <div style={{"display":"block"}} className="invalid-feedback">
                          This field is required.
                        </div>
                      )}
                      </div>
                    </div>
                    {/* <div className="col-md-6 mb-3">
                      <label htmlFor="validationCustom23">Food Category</label>
                      <div className="input-group">
                        <select
                          className="form-control"
                          id="validationCustom23"
                          value={formik.values.foodCategory}
                          onChange={formik.handleChange}
                          style={{
                            borderColor:
                              formik.errors.foodCategory &&
                              formik.touched.foodCategory
                                ? "red"
                                : "black",
                          }}
                          name="foodCategory"
                          required
                        >
                          <option value="">Select Food Category</option>
                          <option value="veg">Veg</option>
                          <option value="egg">Egg</option>
                          <option value="nonveg">Non-Veg</option>
                        </select>
                        <div className="invalid-feedback">
                          Please Select a Currency
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label htmlFor="validationCustom23">Currency</label>
                      <div className="input-group">
                        <select
                          className="form-control"
                          id="validationCustom23"
                          value={formik.values.currency}
                          onChange={formik.handleChange}
                          style={{
                            borderColor:
                              formik.errors.currency && formik.touched.currency
                                ? "red"
                                : "black",
                          }}
                          name="currency"
                          required
                        >
                          <option value="">Select currency type</option>
                          <option value="inr">INR</option>
                          <option value="usd">USD</option>
                        </select>
                        <div className="invalid-feedback">
                          Please Select a Currency
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="col-md-6 mb-3">
                      <label htmlFor="validationCustom24">Full Price</label>
                      <div className="input-group">
                        <input
                          type="number"
                          className="form-control"
                          id="validationCustom24"
                          placeholder="01"
                          name="fullPrice"
                          value={formik.values.fullPrice}
                          onChange={formik.handleChange}
                          style={{
                            borderColor:
                              formik.errors.fullPrice && formik.touched.fullPrice
                                ? "red"
                                : "black",
                          }}
                          required
                        />
                        <div className="invalid-feedback"></div>
                      </div>
                    </div> */}
                    <div className="col-md-6 mb-3">
                      <label htmlFor="validationCustom24">Phone<span className="error">*</span></label>
                      <div className="input-group">
                        <input
                          type="number"
                          className="form-control"
                          id="validationCustom24"
                          name="phone"
                          value={formik.values.phone}
                          onChange={formik.handleChange}
                          style={{
                            borderColor:
                              formik.errors.phone && formik.touched.phone
                                ? "red"
                                : "black",
                          }}
                          // placeholder="01"
                          required
                        />
                       {formik.touched.phone && formik.errors.phone && (
                          <div style={{"display":"block"}} className="invalid-feedback">
                            {formik.errors.phone}
                          </div>
                        )}
                      </div>
                    </div>
                    {/* <div className="col-md-6 mb-3">
                      <label htmlFor="validationCustom25">Quat Price</label>
                      <div className="input-group">
                        <input
                          type="number"
                          className="form-control"
                          id="validationCustom25"
                          // placeholder="$10"
                          name="quatPrice"
                          value={formik.values.quatPrice}
                          onChange={formik.handleChange}
                          style={{
                            borderColor:
                              formik.errors.quatPrice && formik.touched.quatPrice
                                ? "red"
                                : "black",
                          }}
                          required
                        />
                        <div className="invalid-feedback">Price</div>
                      </div>
                    </div> */}
                    {/* <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom12">Description</label>
                      <div className="input-group">
                        <textarea
                          rows={5}
                          id="validationCustom12"
                          className="form-control"
                          placeholder="Description"
                          name="description"
                          value={formik.values.description}
                          onChange={formik.handleChange}
                          style={{
                            borderColor:
                              formik.errors.description &&
                              formik.touched.description
                                ? "red"
                                : "black",
                          }}
                          required
                        />
                        <div className="invalid-feedback">
                          Please provide a message.
                        </div>
                      </div>
                    </div> */}
                    <div className="col-md-12 mb-3">
                      <label htmlFor="validationCustom12">Profile Image</label>
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="validatedCustomFile"
                          // multiple
                          accept="image/*"
                          onChange={(e) => {
                            setDefaultImage(false)
                            setImage("")
                            setFiles(e.target.files);
                          }}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="validatedCustomFile"
                        >
                          Upload Image...
                        </label>
                        <div className="invalid-feedback">
                          Example invalid custom file feedback
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
                <div className="ms-panel-header new">
                  {/* <button className="btn btn-secondary d-block" type="submit">
                                              Save
                                          </button> */}
                  <button
                    className="btn btn-primary d-block"
                    type="submit"
                    onClick={formik.handleSubmit}
                  >
                    Update
                  </button>
                </div>
            
            </div>
          </div>
          {(files || image) && (!defaultImage) && (
            <div className="col-xl-6 col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="ms-panel">
                    <div className="ms-panel-header">
                      <h6>Image </h6>
                    </div>
                    <div className="ms-panel-body">
                      <Productslider
                        images={
                          files
                            ? Array.from(files)
                            : image
                            ? [image]
                            : [1]
                        }
                        isEdit={files ? true : false}
                      />
                    </div>
                    {/* <div className="ms-panel-header new">
                    <p className="medium">Status Available</p>
                    <div>
                      <label className="ms-switch">
                        <input
                          type="checkbox"
                          checked={formik.values.status == "active"}
                          onChange={(e) => {
                            let status =
                              e.target.checked === true ? "active" : "inactive";
                            formik.setFieldValue("status", status);
                          }}
                        />
                        <span className="ms-switch-slider round" />
                      </label>
                    </div>
                  </div> */}
                    {/* <div className="ms-panel-header new">
                    
                      <button
                        className="btn btn-primary d-block"
                        type="submit"
                        onClick={formik.handleSubmit}
                      >
                        Update
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div> 
      </div>
    );
}
export default ManagerProfile