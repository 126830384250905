import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import OutletApi from "../services/outletApis";

export const registerOutletStepOne = createAsyncThunk(
    "outlet/registerOutletStepOne",
    async (props, thunkAPI) => {
        try {
            const response = await OutletApi.registerOutletStepOneApi(props);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const registerOutletStepTwo = createAsyncThunk(
    "outlet/registerOutletStepTwo",
    async (props, thunkAPI) => {
        try {
            const response = await OutletApi.registerOutletStepTwoApi(props);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const registerOutletStepThree = createAsyncThunk(
    "outlet/registerOutletStepThree",
    async (props, thunkAPI) => {
        try {
            const response = await OutletApi.registerOutletStepThreeApi(props);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const registerOutletStepFour = createAsyncThunk(
    "outlet/registerOutletStepFour",
    async (props, thunkAPI) => {
        try {
            const response = await OutletApi.registerOutletStepFourApi(props);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const getOutletDetail = createAsyncThunk(
    "outlet/getOutletDetail",
    async (props, thunkAPI) => {
        try {
            const response = await OutletApi.getOutletDetailApi(props);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const getAllOutlets = createAsyncThunk(
    "outlet/getOutletByArea",
    async (props, thunkAPI) => {
        try {
            const response = await OutletApi.getAllOutletsApi(props);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const getarea = createAsyncThunk(
    "outlet/getOutletByArea",
    async (props, thunkAPI) => {
        try {
            const response = await OutletApi.getAllArea(props);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const getAllReviews = createAsyncThunk(
    "outlet/getAllReviews",
    async (props, thunkAPI) => {
        try {
            const response = await OutletApi.getAllReviewsApi(props);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const getOutletVistors = createAsyncThunk(
    "outlet/getOutletVistors",
    async (props, thunkAPI) => {
        try {
            const response = await OutletApi.getOutletVistorsApi(props);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const sendFeedBackApi = createAsyncThunk(
    "outlet/sendFeedBackApi",
    async (props, thunkAPI) => {
        try {
            const response = await OutletApi.sendFeedBackApi(props);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const blockUser = createAsyncThunk(
    "outlet/blockUser",
    async (props, thunkAPI) => {
        try {
            const response = await OutletApi.blockUserApi(props);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const unblockUser = createAsyncThunk(
    "outlet/unblockUser",
    async (props, thunkAPI) => {
        try {
            const response = await OutletApi.unblockUserApi(props);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const getOutletRating = createAsyncThunk(
    "outlet/getOutletRating",
    async (props, thunkAPI) => {
        try {
            const response = await OutletApi.getOutletRating(props);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const getAllOutlet = createAsyncThunk(
    "outlet/getAllOutlet",
    async (thunkAPI) => {
        try {
            const response = await OutletApi.getAllOutlet();
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const setCommision = createAsyncThunk(
    "outlet/setCommision",
    async (props, thunkAPI) => {
        try {
            const response = await OutletApi.setCommision(props);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const setRewardPoint = createAsyncThunk(
    "outlet/setRewardPoint",
    async (props, thunkAPI) => {
        try {
            const response = await OutletApi.setRewardPoint(props);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

const initialState = {
    outletId: "",
    outletList: [],
    outletReviews: [],
    outletVisitors: [],
    outletBlockingAccDetails: null,
    outletDetails: null,
    outletRatings: null,
    allOutlet: []
};

const outletSlice = createSlice({
    name: "outlet",
    initialState,
    extraReducers: {
        [registerOutletStepOne.fulfilled]: (state, action) => {
            state.outletId = action.payload.outletId;
            localStorage.setItem("outletId", action.payload.outletId);
        },
        [registerOutletStepOne.rejected]: (state, action) => {
            state.outletId = "";
        },
        [getOutletDetail.fulfilled]: (state, action) => {
            state.outletDetails = action.payload.data;
        },
        [getOutletDetail.rejected]: (state, action) => {
            state.outletDetails = null;
        },
        [getAllOutlets.fulfilled]: (state, action) => {
            state.outletList = action.payload.data;
        },
        [getAllOutlets.rejected]: (state, action) => {
            state.outletList = [];
        },
        [getAllReviews.fulfilled]: (state, action) => {
            state.outletReviews = action.payload.data;
        },
        [getAllReviews.rejected]: (state, action) => {
            state.outletReviews = [];
        },
        [getOutletVistors.fulfilled]: (state, action) => {
            state.outletVisitors = action.payload.data;
            state.outletBlockingAccDetails = action.payload;

        },
        [getOutletVistors.rejected]: (state, action) => {
            state.outletVisitors = [];
            state.outletBlockingAccDetails = null;
        },
        [getOutletRating.fulfilled]: (state, action) => {
            state.outletRatings = action.payload;
        },
        [getOutletRating.rejected]: (state, action) => {
            state.outletRatings = null;
        },
        [getAllOutlet.fulfilled]: (state, action) => {
            state.allOutlet = action.payload.data;
        },
        [getAllOutlet.rejected]: (state, action) => {
            state.allOutlet = [];
        }
    }
});

const { reducer } = outletSlice;
export default reducer;
