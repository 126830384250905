import React, { Component } from 'react';
import Content from '../sections/Rating/Content';
class Rating extends Component {
    render() {
        return (
            <>
                <Content/>
            </>
        );
    }
}

export default Rating;