// Step 3: Modal component with Formik form inside
import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { BASE_URL } from '../../../redux/services/apiPath';
import { toast } from 'react-toastify';

const validationSchema = Yup.object().shape({
    discountAmount: Yup.number().required('Discount Amount is required').min(0).max(100),
    title: Yup.string().required('Title is required'),
    type: Yup.string().required('Type is required'),
    startDate: Yup.date().required('Start Date is required'),
    endDate: Yup.date().required('End Date is required'),
    startTime: Yup.string().required('Start Time is required'),
    endTime: Yup.string().required('End Time is required'),
    status: Yup.string().required('Status is required'),
    venue: Yup.string().required('venue is required'),
    // discount_on: Yup.string().required('Discount On is required'),
    price: Yup.number().required('Price is required'),
    description: Yup.string().required('Description is required'),
});

const ModalAdd = ({ show, handleClose, data, discountId, outlet, setCallback, callback,outletId }) => {
    const [image, setImage] = useState("")
    const [outletIds, setOutlet] = useState(outletId)
    const [token, setToken] = useState(localStorage.getItem("user"))
    useEffect(() => {
        if (outletId) {

            setOutlet(outletId)
        }
    
    }, [outletId])


    const handleSubmit = async (values, { setSubmitting }) => {


        const formData = new FormData()

        formData.append('outletId', outletIds);
        formData.append('discount', values?.discountAmount);
        formData.append('amount', values?.price);
        formData.append('type', values?.type);
        formData.append('title', values?.title);
        // formData.append('discountType', 'percent');
        // formData.append('discountCategory', 'flat');
        formData.append('status', values?.status);
        formData.append('venue', values?.venue);
        formData.append('startDate', values?.startDate);
        formData.append('endDate', values?.endDate);
        formData.append('startTime', values?.startTime);
        formData.append('endTime', values?.endTime);
        formData.append('description', values?.description);
        if (image) {
            formData.append("image", image);
        }
    
        
        const res = await axios.post(`${BASE_URL}discount/addPromotion`, formData, {
            headers: {
                "Authorization": "Bearer " + token
            }
        })
       
        if (res?.data?.status == "success") {
           
            handleClose()
            toast.success("Updated!")

        }
        else {
            toast.error("Something Went Wrong")
        }

    };
    const formatDate = (inputDate) => {
        const [day, month, year] = inputDate.split('-');
        return `${year}-${month?.padStart(2, '0')}-${day?.padStart(2, '0')}`;
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add Promotion</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={{
                        type:"",
                        discountAmount:  "",
                        title: "",
                        startDate:  '',
                        endDate:  '',
                        status: "active",
                        startTime:"",
                        endTime:"",
                        venue:"",
                        discount_on:  "",
                        price:  "",
                        description:  ""
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    {({ handleSubmit, isSubmitting }) => (
                        <Form onSubmit={handleSubmit}>
                                   <Form.Group controlId="type">
                                <Form.Label>Type</Form.Label>
                                <Field name="type" as="select" className="form-control">
                                    <option value="">Select Type</option>
                                    <option value="promotion">Promotion</option>
                                    <option value="buffet">Buffet</option>
                                </Field>
                                <ErrorMessage name="type" component="div" className="text-danger" />
                            </Form.Group>
                            <Form.Group controlId="title">
                                <Form.Label>Title</Form.Label>
                                <Field type="text" name="title" as={Form.Control} />
                                <ErrorMessage name="title" component="div" className="text-danger" />
                            </Form.Group>
                            <Form.Group controlId="discountAmount">
                                <Form.Label>Discount Percentage(%)</Form.Label>
                                <Field type="text" name="discountAmount" as={Form.Control} />
                                <ErrorMessage name="discountAmount" component="div" className="text-danger" />
                            </Form.Group>

                            <Form.Group controlId="title">
                                <Form.Label>Price Per Person</Form.Label>
                                <Field type="number" name="price" as={Form.Control} />
                                <ErrorMessage name="price" component="div" className="text-danger" />
                            </Form.Group>



                            <Form.Group controlId="startDate">
                                <Form.Label>Start Date</Form.Label>
                                {/* <Field type="date" name="startDate" as={Form.Control} /> */}
                                {/* <Field name="startDate">
                                    {({ field, form }) => (
                                        <input
                                            {...field}
                                            className="form-control"
                                            type="date"
                                            onChange={e => {
                                                const formattedDate = formatDate(e.target.value);
                                                form.setFieldValue('startDate', formattedDate);
                                            }}
                                        />
                                    )}
                                </Field> */}

                                <Field type="date" name="startDate" as={Form.Control} />
                                <ErrorMessage name="startDate" component="div" className="text-danger" />
                            </Form.Group>
                            <Form.Group controlId="endDate">
                                <Form.Label>End Date</Form.Label>
                                {/* <Field type="date" name="endDate" as={Form.Control} /> */}
                                {/* 
                                <Field name="endDate">
                                    {({ field, form }) => (
                                        <input
                                            {...field}
                                            className="form-control"
                                            type="date"
                                            onChange={e => {
                                                const formattedDate = formatDate(e.target.value);
                                                form.setFieldValue('endDate', formattedDate);
                                            }}
                                        />
                                    )}
                                </Field> */}

                                <Field type="date" name="endDate" as={Form.Control} />
                                <ErrorMessage name="endDate" component="div" className="text-danger" />
                            </Form.Group>


                            <Form.Group controlId="title">
                                <Form.Label>Start Time</Form.Label>
                                
                                <Field type="time" name="startTime" as={Form.Control} />

                                <ErrorMessage name="startTime" component="div" className="text-danger" />
                            </Form.Group>

                            <Form.Group controlId="title">
                                <Form.Label>End Time</Form.Label>
                                
                                <Field type="time" name="endTime" as={Form.Control} />

                                <ErrorMessage name="endTime" component="div" className="text-danger" />
                            </Form.Group>

                            <Form.Group controlId="title">
                                <Form.Label>Description</Form.Label>
                                <Field name="description" as="textarea" className="form-control" />
                                <ErrorMessage name="description" component="div" className="text-danger" />
                            </Form.Group>
                            <Form.Group controlId="title">
                                <Form.Label>Venue</Form.Label>
                                <Field name="venue" as="textarea" className="form-control" />
                                <ErrorMessage name="venue" component="div" className="text-danger" />
                            </Form.Group>
                            <Form.Group controlId="status">
                                <Form.Label>Status</Form.Label>
                                <Field name="status" as="select" className="form-control">
                                    <option value="active">Active</option>
                                    <option value="inactive">Inactive</option>
                                </Field>
                                <ErrorMessage name="status" component="div" className="text-danger" />
                            </Form.Group>
                            <Form.Group controlId="file">
                                <Form.Label>Image</Form.Label> <br />
                                <input type='file' onChange={(e) => {
                                    setImage(e.target.files[0])
                                }} />
                                <ErrorMessage name="status" component="div" className="text-danger" />
                            </Form.Group>
                            <Button variant="primary" type="submit" disabled={isSubmitting}>
                                Submit
                            </Button>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
};

export default ModalAdd;
