
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react'
import { ErrorMessage, Field, Form, Formik, useFormik } from "formik";
import * as Yup from "yup";
import axios from 'axios';

import { BASE_URL } from '../redux/services/apiPath';
import authHeader from '../redux/services/authHeader';
const BankdetailsModal = (props) => {
  const [bankData, setbankData] = useState('')
  const [initialValues, setInitialValues] = useState({
    bankName: "",
    accountNumber: "",
    ifscCode: "",
    branchName: "",
  });
  const validationSchema = Yup.object().shape({
    bankName: Yup.string().required("Bank Name Is Required"),
    accountNumber: Yup.string().required("Account Numnber Is Required"),
    ifscCode: Yup.string().required("Ifsc Code Is Required"),
    branchName: Yup.string().required("Branch Name Is Required"),

  });
  const handleSubmit = async (values) => {
    const data = {
      vendorId: localStorage.getItem('vendorId') ? localStorage.getItem('vendorId') : "",
      bankDetail: {
        ...values
      }
    }
    const res = await axios.post(`${BASE_URL}outlet/updateBankDetail`, data, authHeader())

    if (res?.data?.status == 'success') {

      toast.success('Bank Updated Successfully')

      props.onHide()
      props.setMou(true)
    }
    else {
      toast.error('Something Went Wrong ')
    }


  }

  const GetBankDetails = async () => {
    const data = {
      vendorId: localStorage.getItem('vendorId') ? localStorage.getItem('vendorId') : ""
    }
    const res = await axios.post(`${BASE_URL}outlet/getBankDetail`, data, authHeader())


    if (res?.data?.status == 'success') {
      setbankData(res?.data?.data)
      setInitialValues({
        bankName: res?.data?.data?.bankName ? res?.data?.data?.bankName : "",
        accountNumber: res?.data?.data?.accountNumber ? res?.data?.data?.accountNumber : "",
        ifscCode: res?.data?.data?.ifscCode ? res?.data?.data?.ifscCode : "",
        branchName: res?.data?.data?.branchName ? res?.data?.data?.branchName : ""
      })
    }
  }

  useEffect(() => {
    GetBankDetails()
  }, [])


  const handleClose = () => {
    if (bankData) {
      props.onHide();
    }
  };

  
  return (
    <div>
      <Modal
        {...props}
        onHide={handleClose} 
        size="lg"
        fullScreen
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton={Object.keys(bankData).length == 0 ? false :true}>
          <Modal.Title id="contained-modal-title-vcenter">
            <h6>Bank Details</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="ms-panel ms-panel-fh">
                <div className="ms-panel-header">

                </div>
                <div className="ms-panel-body">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                  >
                    {(formik) => (
                      <Form className="needs-validation clearfix" noValidate>
                        <div className="form-row">
                          <div className="col-md-12 mb-3">
                            <label htmlFor="bankName">Bank Name<span className="error">*</span></label>
                            <Field
                              type="text"
                              className={`form-control ${formik.errors.bankName && formik.touched.bankName ? 'is-invalid' : ''}`}
                              id="bankName"
                              name="bankName"
                              placeholder="Bank Name"
                            />
                            <ErrorMessage name="bankName" component="div" className="invalid-feedback" />
                          </div>
                          <div className="col-md-12 mb-3">
                            <label htmlFor="accountNumber">Account Number<span className="error">*</span></label>
                            <Field
                              type="text"
                              className={`form-control ${formik.errors.accountNumber && formik.touched.accountNumber ? 'is-invalid' : ''}`}
                              id="accountNumber"
                              name="accountNumber"
                              placeholder="Account Number"
                            />
                            <ErrorMessage name="accountNumber" component="div" className="invalid-feedback" />
                          </div>
                          <div className="col-md-12 mb-3">
                            <label htmlFor="ifscCode">IFSC Code<span className="error">*</span></label>
                            <Field
                              type="text"
                              className={`form-control ${formik.errors.ifscCode && formik.touched.ifscCode ? 'is-invalid' : ''}`}
                              id="ifscCode"
                              name="ifscCode"
                              placeholder="IFSC Code"
                            />
                            <ErrorMessage name="ifscCode" component="div" className="invalid-feedback" />
                          </div>
                          <div className="col-md-12 mb-3">
                            <label htmlFor="branchName">Branch Name<span className="error">*</span></label>
                            <Field
                              type="text"
                              className={`form-control ${formik.errors.branchName && formik.touched.branchName ? 'is-invalid' : ''}`}
                              id="branchName"
                              name="branchName"
                              placeholder="Branch Name"
                            />
                            <ErrorMessage name="branchName" component="div" className="invalid-feedback" />
                          </div>

                        </div>
                        <button type="submit" className="btn btn-primary">Submit</button>
                      </Form>
                    )}
                  </Formik>
                </div>


              </div>
            </div>

          </div>
        </Modal.Body>

      </Modal>
    </div>
  );
}

export default BankdetailsModal;
